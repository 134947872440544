import { useContext } from 'react';
import { Redirect, Route } from 'react-router';

import { userCtx } from 'contexts/UserContext';
import { useAccess } from 'helpers/access';
import { EDP_DEFAULT, EDP_NO_ACCESS } from 'helpers/endpoints';

/**
 * Check user privileges and redirect if user has not access to route
 * @param {*} props Router components props
 */
export const AuthRoute = ({ access, ...props }) => {
  const user = useContext(userCtx);
  const canAccessRoute = useAccess(access);

  // Only when user exist, otherwise redirect to no-access route
  if (!user) {
    return <Redirect to={EDP_NO_ACCESS} />;
  }

  if (access && !canAccessRoute.condition) {
    return <Redirect to={EDP_DEFAULT} />;
  }

  return <Route {...props} />;
};
