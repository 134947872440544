/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';

import { composerCxt } from 'contexts/ComposerContext';
import { Container, Layout, Content } from 'components/atoms/Grid';
import { TemplateCustomList } from 'components/organisms/TemplateCustomList';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';

const StandardCompositionsPage = () => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          <Header title={getLiteral('KompozycjeStandardowe')} />
          <TemplateCustomList type="standard" standardEdition={true}></TemplateCustomList>
        </Container>
      </Content>
    </Layout>
  );
};

export default StandardCompositionsPage;
