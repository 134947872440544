/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Controller } from 'react-hook-form';

import { Input, PictureDropZone, Textarea } from 'components/atoms/Form';
import { Marginer } from 'components/atoms/Grid';
import { SupportMark } from 'components/atoms/SupportMark';
import { composerCxt } from 'contexts/ComposerContext';
import { componentCxt } from 'contexts/ComponentContext';
import { toastCtx } from 'contexts/ToastContext';
import { useAccess } from 'helpers/access';
import { API_MODULES_SET_ICON } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const ModuleFormBasicData = ({ readOnly, register, control }) => {
  const {
    id,
    group,
    version,
    commitMessage,
    lastModificationDate,
    createUser,
    isDraft,
    dgId,
    image,
    setName,
  } = useContext(componentCxt);
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const postSetComponentIcon = useFetch(API_MODULES_SET_ICON, 'POST');
  const { groups, pagesIds } = useContext(composerCxt);
  const canAccessConfigs = useAccess('canAccessConfigs');

  const updateIcon = (icon) => {
    postSetComponentIcon.execute({ id: id, version: version, image: icon });
  };

  useEffect(() => {
    if (postSetComponentIcon.response) {
      createToast({
        title: getLiteral('PomyslnieZapisanoDane'),
        message: postSetComponentIcon.response.message,
        context: 'success',
        delay: 5,
      });
    }
  }, [postSetComponentIcon.response, postSetComponentIcon.error]);

  const groupDescription = groups.find((el) => el.id === group);
  const pageDescription = groupDescription && pagesIds.find((el) => el.id === groupDescription.siteId);

  return (
    <Wrapper>
      <Flex>
        <InputsDiv>
          <Input
            title={getLiteral('NazwaKomponentu')}
            type="text"
            name="name"
            ref={register}
            readOnly={readOnly}
            onChange={(event) => setName(event.target.value)}
          />
          <Controller
            control={control}
            name="description"
            render={({ onChange, value }) => (
              <Textarea
                title={getLiteral('OpisKomponentu')}
                defaultValue={value}
                onChange={onChange}
                ref={register}
                readOnly={readOnly}
              />
            )}
          />
        </InputsDiv>

        <StyledInfoDiv>
          <h3>{getLiteral('Informacje')}</h3>
          <Marginer bottom={2}>
            {pageDescription && (
              <StyledInfo>
                <span>
                  {getLiteral('Strona')}:
                  <strong>{`${pageDescription ? pageDescription.description : ''} (${pageDescription.name})`}</strong>
                </span>
              </StyledInfo>
            )}
            <StyledInfo>
              <span>
                {getLiteral('TypKomponentu')}:
                <strong>{`${groupDescription ? groupDescription.name : ''} (${group})`}</strong>
              </span>
            </StyledInfo>
            <StyledInfo>
              <span>
                {getLiteral('Wersja')}:<strong>{`${version}`}</strong>
              </span>
            </StyledInfo>
            <StyledInfo>
              <span>
                {getLiteral('Autor')}:<strong>{`${createUser}`}</strong>
              </span>
            </StyledInfo>
            <StyledInfo>
              <span>
                {getLiteral('Zaaktulizowano')}:<strong>{`${lastModificationDate}`}</strong>
              </span>
            </StyledInfo>
            {!!commitMessage && isDraft === 'n' && (
              <StyledInfo>
                <span>
                  {getLiteral('OpisZmian')}:<strong>{`${commitMessage}`}</strong>
                </span>
              </StyledInfo>
            )}
            {canAccessConfigs.condition && dgId && (
              <StyledInfo>
                <span>
                  DG id
                  <SupportMark />: <strong>{`${dgId}`}</strong>
                </span>
              </StyledInfo>
            )}
          </Marginer>
        </StyledInfoDiv>
      </Flex>
      <PictureDropZone
        text={getLiteral('UpuscPlikTutajLubKliknijWWolnePole')}
        title={getLiteral('PodgladWygladuKomponentu')}
        onChange={(value) => updateIcon(value)}
        preview={image ? image : ''}
        readOnly={readOnly}
        setOnChangePreview={false}
      />
    </Wrapper>
  );
};

const InputsDiv = styled.div`
  display: inline-grid;
  width: 75%;
  padding-right: 5rem;
`;

const StyledInfoDiv = styled.div`
  display: inline-grid;
  align-self: self-start;
  min-width: 25%;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
`;

const StyledInfo = styled.div`
  margin-bottom: 1rem;

  div {
    margin-bottom: 0.5rem;

    &.mb-1 {
      margin-bottom: 1rem;
    }
  }

  span {
    strong {
      margin-left: 5px;
    }
  }

  p {
    font-style: italic;
  }
`;

const Wrapper = styled.div`
  ${({ isHidden }) =>
    isHidden === 'true'
      ? css`
          display: none;
        `
      : ''}
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
