/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { templateListItemCtx } from 'contexts/TemplateListItemContext';
import { Loader } from 'components/atoms/Loader';
import { Logo } from 'components/atoms/Logo';
import { Tag, Tags } from 'components/atoms/Tag';
import WordWrapSpan from 'components/atoms/WordWrapSpan';
import { TemplateItemButtons } from 'components/molecules/TemplateListItemButtons';
import { useFetch } from 'utils/useFetch';
import { API_TEMPLATES_DUPLICATE, EDP_TEMPLATES } from 'helpers/endpoints';
import { TEMPLATE_LIST_MODE_MANAGE, TEMPLATE_LIST_MODE_SELECTION } from 'components/organisms/TemplateCustomList';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const TemplateTileItem = ({ view, mode = TEMPLATE_LIST_MODE_MANAGE, onSelect, templateSelected }) => {
  const history = useHistory();
  const { createToast } = useContext(toastCtx);
  const { getLiteral, getTagIcon, getTagLabel } = useContext(composerCxt);
  const {
    id,
    version,
    model,
    isDraft,
    name,
    description,
    image,
    createUser,
    lastModificationDate,
    parentVersion,
    badges,
  } = useContext(templateListItemCtx);

  const postDuplicateTemplate = useFetch(API_TEMPLATES_DUPLICATE, 'POST');

  // Template duplicating completed
  useEffect(() => {
    if (postDuplicateTemplate.error) {
      createToast({
        title: getLiteral('NieMoznaSkopiowacKompozycji'),
        message: postDuplicateTemplate.error.message,
        context: 'error',
      });
      return;
    }

    if (postDuplicateTemplate.response) {
      createToast({
        title: getLiteral('KompozycjaSkopiowana'),
        message: `${getLiteral('PrzekierowanoDoKompozycji')} ${postDuplicateTemplate.response.id}`,
        context: 'success',
      });
      history.push(`${EDP_TEMPLATES}${postDuplicateTemplate.response.id}`);
    }
  }, [postDuplicateTemplate.response, postDuplicateTemplate.error]);

  const renderedBadges = badges?.map((badge) => (
    <Tag type={badge.type} title={badge.title} key={`${badge.type}_${id}`}>
      {badge.icon ? getTagIcon(badge.icon) : ''}
      {getTagLabel(badge.type, badge.label)}
    </Tag>
  ));

  const itemSelected = templateSelected === id;

  return (
    <Template key={`${mode}-${id}`} view={view} mode={mode} loading={postDuplicateTemplate.loading ? 1 : 0}>
      <TemplatePreview view={view}>
        <div>{image ? <Preview src={image} alt={id} effect="opacity" /> : <TemplatePreviewLogo />}</div>
        {mode === TEMPLATE_LIST_MODE_MANAGE && (
          <Tags>
            {renderedBadges}
            {model === 'wrapper' && <Tag type="model">Wrapper</Tag>}
          </Tags>
        )}
        <CheckedCircle selected={itemSelected}>
          <FontAwesomeIcon icon={faCheck} />
        </CheckedCircle>
      </TemplatePreview>
      <Item mode={mode}>
        {mode === TEMPLATE_LIST_MODE_MANAGE ? (
          <EditLinkDiv onClick={() => history.push(`${EDP_TEMPLATES}${id}`)}>
            <FlexItem>
              <WordWrapSpan>
                <span>
                  {getLiteral('Wersja')}: {isDraft === 'y' ? parentVersion : version}
                </span>
                <span>/</span>
                <span>
                  {getLiteral('Zaaktulizowano')}: {lastModificationDate} [{createUser}]
                </span>
              </WordWrapSpan>
            </FlexItem>
            <EditLink to={`${EDP_TEMPLATES}${id}`}>
              <SpanTitle mode={mode}>{name}</SpanTitle>
            </EditLink>
            <Description>{description}</Description>
          </EditLinkDiv>
        ) : (
          <div>
            <SpanTitle mode={mode}>{name}</SpanTitle>
          </div>
        )}
      </Item>
      <DuplicateWrapper>
        <TemplateItemButtons mode={mode} onSelect={onSelect} itemSelected={itemSelected}></TemplateItemButtons>
      </DuplicateWrapper>
      {postDuplicateTemplate.loading && <Loader relative />}
    </Template>
  );
};

const EditLinkDiv = styled.div`
  cursor: pointer;
`;

const DuplicateWrapper = styled.div`
  margin-top: auto;
  padding: 0 2rem 2rem 2rem;
`;

const TemplatePreviewLogo = styled(Logo)`
  width: 50px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
`;

const Preview = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 100%;
  min-width: 100%;
  width: auto;
`;

const TemplatePreview = styled.div`
  position: relative;

  padding-top: 100%;
  overflow: hidden;

  ${({ view }) =>
    view === 'list'
      ? css`
          padding-top: 300px;
          border-right: 1px solid var(--listItemBorderColor);
        `
      : css`
          min-width: 301px;
          padding-top: calc(100% / 3 * 2 + 1px);
          border-bottom: 1px solid var(--listItemBorderColor);
        `}

  img {
    width: 100%;
  }
`;

const Template = styled.div`
  height: 100%;
  transition: box-shadow 0.3s;
  position: relative;
  border: 1px solid var(--listItemBorderColor);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({ loading }) =>
    loading
      ? css`
          pointer-events: none;
          opacity: 0.3;
        `
      : ''}

  ${({ view }) =>
    view === 'list'
      ? css`
          display: flex;
        `
      : ''}
      
  ${({ mode }) =>
    mode === TEMPLATE_LIST_MODE_SELECTION
      ? css`
          border-radius: 5px;
        `
      : ''}

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }
`;

const Item = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  ${({ mode }) =>
    mode === TEMPLATE_LIST_MODE_SELECTION
      ? css`
          padding: 2rem 2rem 0 2rem;
        `
      : ''}
`;

const SpanTitle = styled.span`
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0;
  color: var(--listItemColor);
  display: block;
  margin-bottom: 1rem;

  ${({ mode }) =>
    mode === TEMPLATE_LIST_MODE_SELECTION
      ? css`
          font-size: 1.4rem;
          line-height: 1.9rem;
        `
      : ''}
`;

const EditLink = styled(Link)`
  text-decoration: none;
`;

const FlexItem = styled.div`
  margin-right: 2rem;
  font-size: 1.3rem;
  color: var(--listItemColorLight);
  white-space: nowrap;
  margin-bottom: 0.5rem;

  span {
    margin-right: 0.5rem;
  }
`;

const Description = styled.p`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1.9rem;
`;

const CheckedCircle = styled.div`
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 3.25rem;
  background-color: var(--green);
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-size: 3rem;
  text-align: center;
  line-height: 6.5rem;
  opacity: 0;
  ${({ selected }) =>
    selected
      ? css`
          opacity: 1;
          animation: ${CheckedCircleBounce} 0.5s;
        `
      : css`
          opacity: 0;
        `}
`;

const CheckedCircleBounce = keyframes`
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  30% {
    transform: scale(1.1) translate(-45%, -45%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
`;
