export const colors = {
  BG_COLOR: '#fff',
  BG_TEXT_COLOR: '#000',
  BG_LINK_COLOR: '#7868e6',
  BG_LINK_COLOR_HOVER: '#000',
  BG_BORDER_COLOR: '#ECECEC',
  BG_ACTIVE_COLOR: '#7868e6',

  ALT_COLOR: '#f0f0f0',
  ALT_TEXT_COLOR: '#000',
  ALT_LINK_COLOR: '#7868e6',
  ALT_LINK_COLOR_HOVER: '#000',
  ALT_BORDER_COLOR: '#dedede',
  ALT_ACTIVE_COLOR: '#7868e6',

  BUTTON_BG_COLOR: '#7868e6',
  BUTTON_BR_COLOR: '#7868e6',
  BUTTON_COLOR: '#fff',
  BUTTON_BG_COLOR_HOVER: '#b8b5ff',
  BUTTON_BR_COLOR_HOVER: '#b8b5ff',
  BUTTON_COLOR_HOVER: '#fff',

  COLOR_INFO: '#fff',
  COLOR_SUCCESS: '#fff',
  COLOR_WARNING: '#fff',
  COLOR_DANGER: '#D00B22',

  TYPE_COLOR_PRO: '#9231d4',
  TYPE_COLOR_LITE: '#02b574',
};
