/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faCheckCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { StickyMargin } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { AcceptButton, RemoveButton, STATUS_TYPES } from 'components/organisms/ComponentsPullRequests';
import { PullRequestDiff } from 'components/organisms/PullRequest/PullRequestDiff';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { API_MODULES_GET, API_PULL_REQUESTS_GET_COMMENTS } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { useTabs } from 'utils/useTabs';
import { useAccess } from 'helpers/access';

const replaceEndLines = (element) => element?.replace(/\r\n/g, '\n') || '';

export const PullRequestCompareDialog = ({ data, diffData, pullRequestData, changePullRequestStatus }) => {
  const { getLiteral } = useContext(composerCxt);
  const { id: userId } = useContext(userCtx);
  const canAcceptPullRequests = useAccess('canAcceptChanges');

  const getModuleData = useFetch(`${API_MODULES_GET}/id/${diffData.id}/version/${diffData.version}`, 'GET');
  const getComments = useFetch(`${API_PULL_REQUESTS_GET_COMMENTS}/id/${pullRequestData.id}`, 'POST');

  const [comments, setComments] = useState({
    javascript: {},
    jsLocal: {},
    css: {},
    xml: {},
  });

  const oldValues = useMemo(() => {
    return {
      javascript: replaceEndLines(getModuleData.response?.sources.javascript),
      jsLocal: replaceEndLines(getModuleData.response?.sources.jsLocal),
      css: replaceEndLines(getModuleData.response?.sources.css),
      xml: replaceEndLines(getModuleData.response?.sources.xml),
    };
  }, [getModuleData.response?.sources]);

  const newValues = useMemo(() => {
    return {
      javascript: replaceEndLines(data.sources.javascript),
      jsLocal: replaceEndLines(data.sources.jsLocal),
      css: replaceEndLines(data.sources.css),
      xml: replaceEndLines(data.sources.xml),
    };
  }, [data.sources]);

  const tabsArr = [
    newValues.css !== oldValues.css && {
      title: getLiteral('CssLess'),
      component: (
        <PullRequestDiff
          oldText={oldValues.css}
          newText={newValues.css}
          pullRequestId={pullRequestData.id}
          diffComment={comments.css}
          file="css"
          language={'css'}
        />
      ),
    },
    newValues.javascript !== oldValues.javascript && {
      title: getLiteral('JavaScript'),
      component: (
        <PullRequestDiff
          oldText={oldValues.javascript}
          newText={newValues.javascript}
          diffComment={comments.javascript}
          pullRequestId={pullRequestData.id}
          file="javascript"
          language={'javascript'}
        />
      ),
    },
    newValues.jsLocal !== oldValues.jsLocal && {
      title: getLiteral('WydzielonyJavascript'),
      component: (
        <PullRequestDiff
          oldText={oldValues.jsLocal}
          newText={newValues.jsLocal}
          pullRequestId={pullRequestData.id}
          diffComment={comments.jsLocal}
          file="jsLocal"
          language={'javascript'}
        />
      ),
    },
    newValues.xml !== oldValues.xml && {
      title: getLiteral('XMLXSLT'),
      component: (
        <PullRequestDiff
          oldText={oldValues.xml}
          newText={newValues.xml}
          pullRequestId={pullRequestData.id}
          diffComment={comments.xml}
          file="xml"
          language={'xml'}
        />
      ),
    },
  ].filter((item) => !!item);
  const tabs = useTabs(tabsArr, true);

  useEffect(() => {
    if (diffData.id && diffData.version) {
      getModuleData.execute();
    }
  }, [diffData.id, diffData.version]);

  useEffect(() => {
    getComments.execute();
  }, []);

  useEffect(() => {
    if (getComments.response) {
      const allComments = {};

      getComments.response.forEach((el) => {
        if (!allComments[el.fileName]) {
          allComments[el.fileName] = {};
        }

        if (!allComments[el.fileName][el.lineCode]) {
          allComments[el.fileName][el.lineCode] = {
            comments: [],
          };
        }

        allComments[el.fileName][el.lineCode].comments.push({
          id: el.id,
          createUserName: el.createUserName,
          createUser: el.createUser,
          content: el.content,
          createTime: el.createTime,
        });
      });

      setComments(allComments);
    }
  }, [getComments.response, getComments.error]);

  return (
    <>
      {getModuleData.loading || getComments.loading ? (
        <Loader />
      ) : tabsArr.length ? (
        <>
          <StickyMargin bottom={2}>
            <AcceptsPanel>
              {pullRequestData?.activity?.length ? (
                <UserIconsDiv>
                  {pullRequestData.activity.map((activity, index, elements) => (
                    <UserStatus activity={activity} index={index} elements={elements} />
                  ))}
                </UserIconsDiv>
              ) : null}

              {pullRequestData.createUser !== userId && canAcceptPullRequests.condition ? (
                <>
                  <AcceptButton
                    loading={changePullRequestStatus.loading.toString()}
                    title={getLiteral('AkceptujZmiany')}
                    onClick={() => {
                      changePullRequestStatus.execute({
                        id: pullRequestData.id,
                        status: STATUS_TYPES.ACCEPTED,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </AcceptButton>
                  <RemoveButton
                    loading={changePullRequestStatus.loading.toString()}
                    title={getLiteral('OdrzucZmiany')}
                    onClick={() => {
                      changePullRequestStatus.execute({
                        id: pullRequestData.id,
                        status: STATUS_TYPES.NEEDS_WORK,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                  </RemoveButton>
                </>
              ) : null}
            </AcceptsPanel>
            {tabs.tabs}
          </StickyMargin>
          {tabs.tabsPanel}
        </>
      ) : (
        <NoChangesWrapper>{getLiteral('BrakHistoriiZmian')}</NoChangesWrapper>
      )}
    </>
  );
};

const UserStatus = ({ activity, index, elements }) => {
  const { getUserIconLink } = useContext(userCtx);

  const getIcon = (status) => {
    if (status === STATUS_TYPES.ACCEPTED) {
      return faCheckCircle;
    } else if (status === STATUS_TYPES.NEEDS_WORK) {
      return faTimesCircle;
    }

    return null;
  };

  return (
    <li key={activity.createUser} style={{ zIndex: elements.length - index }}>
      <UserStatusDiv title={activity.createUser}>
        <UserIcon src={getUserIconLink(activity.createUser)} alt={activity.createUser} />
        {getIcon(activity.status) && (
          <UserStatusIcon status={activity.status}>
            <FontAwesomeIcon icon={getIcon(activity.status)} />
          </UserStatusIcon>
        )}
      </UserStatusDiv>
    </li>
  );
};

const UserStatusDiv = styled.div`
  position: relative;
`;

const UserStatusIcon = styled.div`
  svg {
    position: absolute;
    color: #00875a;

    ${({ status }) =>
      status === STATUS_TYPES.NEEDS_WORK &&
      css`
        color: #ffab00;
      `}

    height: 14px;
    right: 0px;
    top: 0px;
    width: 14px !important;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
  }
`;

const UserIconsDiv = styled.ul`
  margin: 0px 2rem 0px 0px;
  padding: 0px;
  display: flex;
  line-height: 1;
  list-style-type: none !important;

  li {
    margin: 0px -8px 0px 0px;
  }
`;

const UserIcon = styled.img`
  background: rgb(255, 255, 255);
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  width: 40px;
`;

const AcceptsPanel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NoChangesWrapper = styled.div`
  text-align: center;
  font-weight: 600;
`;

export const PullRequestDialogStyles = css`
  .MuiDialog-paper,
  .MuiDialogContent-root {
    width: 75vw;
    height: 90vh;
    max-height: 90vh;
  }
`;
