/* eslint-disable react-hooks/exhaustive-deps */
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useContext, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import CustomDialog from './CustomDialog';
import { CustomDialogTitle } from './CustomDialogTitle';

import { Button } from 'components/atoms/Button';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { userCtx } from 'contexts/UserContext';
import { API_PREVIEW } from 'helpers/endpoints';
import { Checkbox } from 'components/atoms/Form';
import { SupportMark } from 'components/atoms/SupportMark';
import { Marginer } from 'components/atoms/Grid';

export const PreviewDialog = (props) => {
  const { id, version, languages } = useContext(templateCxt);
  const { getLiteral } = useContext(composerCxt);
  const user = useContext(userCtx);

  const shopIds = user.shops
    ? user.shops.map((el) => {
        return { value: el.id, label: el.name };
      })
    : [];

  let defaultLanguage = false;

  const shopLanguages = languages.map((item) => {
    if (item.id === user.language) {
      defaultLanguage = { value: item.id, label: item.name };
    }

    return { value: item.id, label: item.name };
  });

  if (!defaultLanguage) {
    defaultLanguage = shopLanguages[0];
  }

  const [shopId, setShopId] = useState(shopIds[0]);
  const [literalWithComponentLink, setLiteralWithComponentLink] = useState(false);
  const [optimizedMinification, setOptimizedMinification] = useState(false);
  const [shopLanguage, setShopLanguage] = useState(defaultLanguage);

  const handleFormConfirm = () => {
    let url = `${user.domain}${API_PREVIEW}?designId=${id}&designVersion=${version}&shopId=${shopId.value}&lang=${shopLanguage.value}`;

    if (literalWithComponentLink) {
      url += '&literalWithComponentLink=true';
    }

    if (optimizedMinification) {
      url += '&optimizedMinification=true';
    }

    window.open(url, '_blank');

    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <>
      <CustomDialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <CustomDialogTitle
          id="form-dialog-title"
          title={getLiteral('WybierzSklepIJezykDlaPodgladu')}
          closeCallback={handleClose}
        />
        <DialogContent>
          <SectionName>{getLiteral('NazwaSklepu')}</SectionName>
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={(selected) => setShopId(selected)}
            value={shopId}
            options={shopIds}
          />
          <SectionName>{getLiteral('JezykSklepu')}</SectionName>
          <Select
            value={shopLanguage}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={(selected) => setShopLanguage(selected)}
            options={shopLanguages}
          />
          {user.isSupport ? (
            <>
              <Marginer top={1}>
                <Checkbox
                  onChange={() => {
                    setLiteralWithComponentLink((state) => {
                      return !state;
                    });
                  }}
                  title={
                    <>
                      {getLiteral('TrybOznaczaniaLiteralow')}
                      <SupportMark />
                    </>
                  }
                  checked={literalWithComponentLink}
                />
              </Marginer>
              <Marginer top={1}>
                <Checkbox
                  onChange={() => {
                    setOptimizedMinification((state) => {
                      return !state;
                    });
                  }}
                  title={
                    <>
                      {getLiteral('WlaczZoptymalizowanaMinifikacjePlikow')}
                      <SupportMark />
                    </>
                  }
                  checked={optimizedMinification}
                />
              </Marginer>
            </>
          ) : null}
        </DialogContent>
        <DialogActions disableSpacing={true}>
          <PreviewButton onClick={handleFormConfirm} primary style={{ marginRight: '16px' }}>
            <CommitIcon>
              <FontAwesomeIcon icon={faMagic} />
            </CommitIcon>
            {getLiteral('Podglad')}
          </PreviewButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

const SectionName = styled.h3`
  width: 15vw;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const PreviewButton = styled(Button)`
  background-color: #0488e7;
`;

const CommitIcon = styled.span`
  font-size: 2rem;
  margin-right: 1.5rem;
`;
