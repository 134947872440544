/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { InfoLabel } from 'components/atoms/InfoLabel';
import { Button } from 'components/atoms/Button';
import { Checkbox, Input } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { componentCxt } from 'contexts/ComponentContext';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { EDP_MODULES, API_MODULES_DELETE } from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { useFetch } from 'utils/useFetch';

export const ComponentOlderVersionsDialog = ({ closeCb, componentVersions, ...props }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const { id, name, isDraft, parentId } = useContext(componentCxt);

  const discardDraftChanges = useFetch(`${API_MODULES_DELETE}`, 'POST');
  const [versionToRedirectAfterDelete, setVersionToRedirectAfterDelete] = useState(null);
  const history = useHistory();
  const [stageChanges, setStageChanges] = useState(false);
  const [stageComponentName, setStageComponentName] = useState(false);

  const generateOptions = () => {
    if (componentVersions.length) {
      return componentVersions.map((item) => {
        return makeSelectOption(
          {
            id: item.id,
            name: item.name,
            version: item.version,
          },
          `${item.name} v.${item.version} ${item.commitMessage || ''}`,
        );
      });
    } else {
      return [];
    }
  };

  const options = generateOptions();
  const [componentVersion, setComponentVersion] = useState(options.length ? options[0] : []);

  const resetToCommit = useCallback(
    (versionToRedirect, copyBeforeDelete, copiedComponentName) => {
      if (isDraft === 'y') {
        setVersionToRedirectAfterDelete(versionToRedirect);
        discardDraftChanges.execute({
          id: id,
          copyBeforeDelete: copyBeforeDelete,
          name: copiedComponentName,
        });
      } else {
        history.push(`${EDP_MODULES}${id}/version/${versionToRedirect}${window.location.search}`);
        closeCb();
      }
    },
    [id],
  );

  const handleFormConfirm = () => {
    if (stageChanges) {
      if (!stageComponentName) {
        setStageComponentName('');
        return;
      }
    }
    resetToCommit(componentVersion.value.version, stageChanges, stageComponentName);
  };

  useEffect(() => {
    setComponentVersion(options.length ? options[0] : []);
  }, [options.length]);

  useEffect(() => {
    if (discardDraftChanges.response) {
      closeCb();
      history.push(`${EDP_MODULES}${parentId}/version/${versionToRedirectAfterDelete}${window.location.search}`);
    } else if (discardDraftChanges.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: discardDraftChanges.error.message,
        context: 'error',
      });
    }
  }, [discardDraftChanges.response, discardDraftChanges.error]);

  return (
    <>
      {isDraft === 'y' ? (
        <>
          <InfoLabel type="danger" text={getLiteral('TwojaWersjaRoboczaZostanieUsunieta')} />
          <Checkbox
            checked={stageChanges}
            bottom="2"
            title={getLiteral('UtworzNowyKomponentNaPodstawieObecnejWersjiRoboczej')}
            onChange={() => setStageChanges((state) => !state)}
          />
          {stageChanges && (
            <Input
              title={getLiteral('NazwaKomponentu')}
              bottom="0"
              placeholder={name}
              value={stageComponentName === false ? '' : stageComponentName}
              error={stageComponentName === '' ? getLiteral('PoleObowiazkowe') : null}
              onChange={({ target }) => setStageComponentName(target.value)}
            ></Input>
          )}
        </>
      ) : null}
      <SectionName>{getLiteral('WersjaKomponentu')}</SectionName>
      <Select onChange={(selected) => setComponentVersion(selected)} value={componentVersion} options={options} />
      <ResetButton
        onClick={handleFormConfirm}
        loading={discardDraftChanges.loading.toString()}
        primary
        style={{ marginRight: '16px' }}
      >
        {getLiteral('Przywroc')}
      </ResetButton>
    </>
  );
};

const SectionName = styled.h3`
  width: 15vw;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const ResetButton = styled(Button)`
  background-color: #a94442;
`;
