import styled from 'styled-components';

export const Footer = styled.footer`
  border-top: var(--border);
  background-color: #fff;
  padding-bottom: 10px;
  padding-top: 10px;
  position: sticky;
  width: 100%;
  height: 60px;
  z-index: 999;
  bottom: 0;
  margin-top: auto;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
