import { useContext } from 'react';
import styled from 'styled-components';

import { DropField } from 'components/atoms/DropField';
import { composerCxt } from 'contexts/ComposerContext';
import FileSvg from 'data/icon-file.svg';
import { pictureRegex } from 'helpers/regexp';

export const FileReplaceDialogComponent = ({ picturePath, name, openManager, addNewFile }) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <DialogInfoWrapper>
      <PictureWrapper>
        <PictureLabel>{getLiteral('ObecnyPlik')}</PictureLabel>
        <img
          src={pictureRegex.test(picturePath) ? picturePath : FileSvg}
          className={!pictureRegex.test(picturePath) ? 'no-bg' : ''}
          alt={name}
          loading="lazy"
        />
      </PictureWrapper>
      <div className="data">
        <NewPicture>
          <div className="wrapper">
            <PictureLabel>{getLiteral('NowyPlik')}</PictureLabel>
            <div>{getLiteral('WybierzZ')}</div>
            <ButtonLink type="button" onClick={openManager}>
              {getLiteral('MenadzeraPlikow')}
            </ButtonLink>
            <div>{getLiteral('lub')}</div>
          </div>
          <DropField
            title={getLiteral('PrzeciagnijPlikTutajAbyDodacNowy')}
            titleOnDragOver={getLiteral('UpuscTutaj')}
            onBaseRender={addNewFile}
            styles={{ textAlign: 'center' }}
          />
        </NewPicture>
      </div>
    </DialogInfoWrapper>
  );
};

export const Picture = styled.div`
  margin-bottom: 1rem;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 240px;
    max-width: 100%;
    height: auto;
    background: repeating-conic-gradient(#d0d0d0 0% 25%, transparent 0% 50%) 50% / 20px 20px;

    &.no-bg {
      background: transparent;
    }
  }
`;

export const PictureLabel = styled.span`
  display: inline-flex;
  padding: 0 8px;
  background-color: #fff;
  height: 20px;
  top: -10px;
  left: 10px;
  font-weight: bold;
  position: absolute;
`;

export const PictureWrapper = styled(Picture)`
  width: 240px;
  height: 240px;
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-radius: 5px;

  img {
    max-width: 238px;
  }
`;

export const NewPicture = styled.div`
  width: 240px;
  max-width: 240px;
  height: 240px;
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const DialogInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  .data {
    padding-left: 3rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    padding-left: 1.2rem;
  }
  p {
    margin-bottom: 2rem;
    font-size: 1.3rem;
  }

  li {
    margin-bottom: 1rem;
  }
`;

export const ButtonLink = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-weight: bold;
  margin: 5px 0;
  font-size: 1.4rem;
  cursor: pointer;
`;
