/* eslint-disable react-hooks/exhaustive-deps */
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { composerCxt } from 'contexts/ComposerContext';
import { InfoLabel } from 'components/atoms/InfoLabel';

export const CommitMessageDialog = ({ onSubmit, type = 'custom' }) => {
  const { getLiteral } = useContext(composerCxt);
  const formRef = useRef();

  const { register, errors, handleSubmit } = useForm();

  // Handle Enter key press
  const keyPress = (event) => {
    if (event.keyCode === 13) {
      return handleSubmit(onSubmit);
    }
  };

  return (
    <Wrapper>
      <form onKeyUp={keyPress} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        {type === 'standard' && (
          <InfoLabel
            type="info"
            text={getLiteral(
              'ZmianyWKomponentachStandardowychWymagajaAkceptacjiZostanieUtworzonyWniosekOZgodeNaWydanieWprowadzonychRoznicPoJegoZaakcepotwaniuZmianyBedaMoglyZostacOpublikowane',
            )}
          />
        )}
        <Input
          autoFocus={1}
          ref={register({ required: true })}
          name="commitMessage"
          error={errors.commitMessage && getLiteral('PoleObowiazkowe')}
        />
        <Button color="green">
          <FontAwesomeIcon icon={faSave} />
          <span>{getLiteral('ZakonczEdycje')}</span>
        </Button>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: calc(480px + 3rem);
`;
