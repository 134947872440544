import styled from 'styled-components';
import React, { forwardRef, useContext } from 'react';

import { composerCxt } from 'contexts/ComposerContext';
import { Label, Radio } from 'components/atoms/Form';
import { SupportMark } from 'components/atoms/SupportMark';

const ASSET_DIVIDE_ALL = 'all';
const ASSET_DIVIDE_CHOSEN = 'chosen';
const ASSET_DIVIDE_CHOSEN_ONE_FILE = 'chosen_one_file';
const ASSET_DIVIDE_NONE = 'none';

export const AssetsDividingRadioGroup = forwardRef(({ readOnly, bottom = 4, onChange, name, label, ...props }, ref) => {
  const { getLiteral } = useContext(composerCxt);

  const chosenPages = [getLiteral('StronaGlowna'), getLiteral('KartaProduktu'), getLiteral('ListaTowarow')];

  return (
    <BorderedRow bottom={bottom}>
      <Label isReadOnly={readOnly}>
        {label}
        <SupportMark></SupportMark>
      </Label>
      <Radio
        ref={ref}
        bottom={0.4}
        title={getLiteral('BrakPodzialuJedenPlikDlaWszystkichPodstron')}
        readOnly={readOnly}
        name={name}
        value={ASSET_DIVIDE_NONE}
        onChange={onChange}
      />
      <Radio
        ref={ref}
        bottom={0.4}
        title={`${getLiteral('PodzialJedynieDlaWybranychPodstron')} (${chosenPages.join(', ')})`}
        readOnly={readOnly}
        name={name}
        value={ASSET_DIVIDE_CHOSEN}
        onChange={onChange}
      />
      <Radio
        ref={ref}
        title={`${getLiteral('PodzialJedynieDlaWybranychPodstronZJednymPlikiem')} (${chosenPages.join(', ')})`}
        readOnly={readOnly}
        name={name}
        value={ASSET_DIVIDE_CHOSEN_ONE_FILE}
        onChange={onChange}
      />
      <Radio
        ref={ref}
        title={getLiteral('PodzialDlaWszystkichPodstron')}
        readOnly={readOnly}
        name={name}
        value={ASSET_DIVIDE_ALL}
        onChange={onChange}
      />
    </BorderedRow>
  );
});

const BorderedRow = styled.div`
  position: relative;
  margin-left: 0;
  margin-right: 0;
  border: var(--border);
  border-radius: var(--border-radius);
  font-size: 1.4rem;
  padding: 2rem 1rem 1rem;
  width: 100%;
`;
