/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';

import { templateCxt } from 'contexts/TemplateContext';
import { TemplateAddModule } from 'components/molecules/TemplateAddModule';
import { ModuleReplace } from 'components/molecules/ModuleReplace';
import { DraggableModule, Module } from 'components/atoms/Module';
import { Loader } from 'components/atoms/Loader';

export const TemplateSectionWrapper = ({
  items,
  name,
  title,
  borderless,
  moduleName,
  removeCallback,
  addModuleToPage,
  replaceModuleOnPage,
  getPageModules,
  isEnvelopeSection,
  replaceModuleWrapperItemOnPage,
  addModuleToWrapperOnPage,
}) => {
  const [replaceModule, setReplaceModule] = useState({});
  const { addModuleToComposition, replaceModuleInComposition, id, version, isDraft, editView } = useContext(
    templateCxt,
  );

  //loader when downloading data
  if (getPageModules && getPageModules.loading) {
    return <Loader />;
  }

  const moduleReplaceView = () => {
    return (
      <ModuleReplace
        type={replaceModule.group}
        onClick={(module) => {
          replaceModuleOnPage
            ? replaceModuleOnPage({
                oldModule: replaceModule,
                newModule: module,
                name,
              })
            : replaceModuleInComposition({
                oldModule: replaceModule,
                newModule: module,
                name,
              });
          setReplaceModule({});
        }}
        clear={() => setReplaceModule({})}
      />
    );
  };

  if (name && !isEnvelopeSection) {
    return (
      <>
        <Droppable droppableId={name} type="component">
          {(provided, snapshot) => (
            <>
              {title && <SectionName>{title}</SectionName>}
              <Wrapper
                id={`${name}_wrapper`}
                ref={provided.innerRef}
                {...provided.droppableProps}
                over={snapshot.isDraggingOver}
                borderless={borderless}
              >
                {items &&
                  items.map((item, index) => (
                    <DraggableModule
                      key={item.id}
                      index={index}
                      compositionId={id}
                      compositionVersion={version}
                      disabled={!editView}
                      pageName={name}
                      siteType={moduleName || name}
                      replaceModule={(module) => setReplaceModule(module)}
                      updateComponentCallback={replaceModuleOnPage ? replaceModuleOnPage : replaceModuleInComposition}
                      {...item}
                      onRemove={removeCallback}
                      replaceModuleWrapperItemOnPage={replaceModuleWrapperItemOnPage}
                      addModuleToWrapperOnPage={addModuleToWrapperOnPage}
                    />
                  ))}
                {provided.placeholder}
                {editView ? (
                  <TemplateAddModule
                    id={id}
                    version={version}
                    page={moduleName || name}
                    addModule={(module) =>
                      addModuleToPage
                        ? addModuleToPage({ module, container: name })
                        : addModuleToComposition({ module, container: name })
                    }
                  />
                ) : null}
              </Wrapper>
            </>
          )}
        </Droppable>
        {replaceModule.group ? moduleReplaceView() : null}
      </>
    );
  }

  return (
    <>
      {title && <SectionName>{title}</SectionName>}
      <Wrapper>
        {items.map((item) => (
          <Module
            compositionId={id}
            disabled={!editView}
            replaceModule={(module) => setReplaceModule(module)}
            key={item.id}
            isEnvelopeSection={isEnvelopeSection}
            {...item}
            pageName={name}
            onRemove={removeCallback}
            updateComponentCallback={replaceModuleOnPage ? replaceModuleOnPage : replaceModuleInComposition}
          />
        ))}
      </Wrapper>
      {replaceModule.group ? moduleReplaceView() : null}
    </>
  );
};

TemplateSectionWrapper.defaultProps = {
  isEnvelopeSection: false,
};

const SectionName = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const Wrapper = styled.div`
  padding: 1rem;
  border: 2px dashed #f0f0f0;

  ${({ borderless }) =>
    borderless &&
    css`
      border: none;
      padding: 0;
    `}
`;
