/* eslint-disable react-hooks/exhaustive-deps */
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { InfoLabel } from 'components/atoms/InfoLabel';
import { Button } from 'components/atoms/Button';
import CustomDialog from 'components/atoms/CustomDialog';
import { CustomDialogTitle } from 'components/atoms/CustomDialogTitle';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { makeSelectOption } from 'helpers/makeSelectOption';

export const OlderVersionsDialog = (props) => {
  const { isDraft, resetToCommit, templateVersions } = useContext(templateCxt);
  const { getLiteral } = useContext(composerCxt);

  const generateOptions = () => {
    if (templateVersions.length) {
      return templateVersions.map((item) => {
        return makeSelectOption(
          {
            id: item.id,
            name: item.name,
            version: item.version,
          },
          `${item.name} v.${item.version} ${item.commitMessage || ''}`,
        );
      });
    } else {
      return [];
    }
  };

  const options = generateOptions();
  const [designVersion, setDesignVersion] = useState(options.length ? options[0] : []);

  const handleFormConfirm = () => {
    resetToCommit(designVersion.value.version);

    props.setOpen(false);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    setDesignVersion(options.length ? options[0] : []);
  }, [options.length]);

  return (
    <>
      <CustomDialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <CustomDialogTitle
          id="form-dialog-title"
          title={getLiteral('WybierzWersjeKtoraChceszZobaczyc')}
          closeCallback={handleClose}
        />
        <DialogContent>
          {isDraft === 'y' ? <InfoLabel type="danger" text={getLiteral('TwojaWersjaRoboczaZostanieUsunieta')} /> : null}
          <SectionName>{getLiteral('WersjaKompozycji')}</SectionName>
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={(selected) => setDesignVersion(selected)}
            value={designVersion}
            options={options}
          />
        </DialogContent>
        <DialogActions disableSpacing={true}>
          <ResetButton onClick={handleFormConfirm} primary style={{ marginRight: '16px' }}>
            {getLiteral('Przywroc')}
          </ResetButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

const SectionName = styled.h3`
  width: 15vw;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const ResetButton = styled(Button)`
  background-color: #a94442;
`;
