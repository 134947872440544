import { useContext } from 'react';
import styled from 'styled-components';

import { userCtx } from 'contexts/UserContext';

export const UserLogged = () => {
  const user = useContext(userCtx);

  const LogoClick = () => {
    window.open(`${user.domain}/panel/main.php`, '_blank');
  };

  return (
    <StyledDiv>
      <ImgLogo src="/sources/IdoSell-new.svg?t=1123" alt="IdoSell panel" onClick={LogoClick} />
      <UserInfo>
        <strong style={{ fontSize: '1.6rem' }}>{user.name}</strong>
        <strong>{user.shops && user.shops[0].name}</strong>
      </UserInfo>
    </StyledDiv>
  );
};

const UserInfo = styled.div`
  display: grid;
  font-size: 1.2rem;

  strong {
    width: 140px;
  }
`;

const ImgLogo = styled.img`
  margin-right: 1.5rem;
  height: 2.5rem;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  height: 60px;
  padding: 2.5rem;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  margin-top: auto;

  span {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d0d0d0;
    margin-right: 2rem;
    font-size: 2.5rem;
    color: #333;
  }
  small {
    font-size: 1.2rem;
  }
`;
