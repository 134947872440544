import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Group } from './';

export const Radio = forwardRef(({ title, readOnly, bottom, ...props }, ref) => {
  return (
    <Group mb={bottom || '0'}>
      <RadioLabel readOnly={readOnly}>
        <input type="radio" ref={ref} disabled={readOnly} {...props} />
        <span>{title}</span>
      </RadioLabel>
    </Group>
  );
});

const RadioLabel = styled.label`
  display: block;
  margin-right: 2rem;

  input {
    display: none;

    &:checked + span {
      &:after {
        opacity: 1;
      }
    }
  }

  > span {
    display: flex;
    position: relative;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-size: 1.4rem;

    &:before {
      content: '';
      min-width: 20px;
      height: 20px;
      display: block;
      border: 2px solid #e0e0e0;
      margin-right: 1rem;
      border-radius: 100%;
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      display: block;
      background-color: green;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  ${({ readOnly }) =>
    readOnly
      ? css`
          & > span {
            cursor: not-allowed;
            &:before {
              content: '';
              background-color: #f0f0f0;
            }
            &:after {
              content: '';
              background-color: #999;
            }
          }
        `
      : ''}
`;
