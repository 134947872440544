/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import { Row, Col, Marginer } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { ModuleListItem } from 'components/atoms/ModuleListItem';
import { composerCxt } from 'contexts/ComposerContext';
import { API_MODULES_SEARCH } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';
import { useAccess } from 'helpers/access';

export const ModulesCustomList = ({ filters, type = 'custom' }) => {
  const { getLiteral } = useContext(composerCxt);

  const [list, setList] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const postSearchComponent = useFetch(API_MODULES_SEARCH, 'POST');
  const canEditStandardComponents = useAccess('canEditStandardComponents');

  useEffect(() => {
    if (!page) {
      return;
    }

    const data = {
      page,
      sort: 'create-date',
      order: 'desc',
      type,
    };

    if (filters) {
      filters.split('&').forEach((filter) => {
        const [key, value] = filter.split('=');
        data[key] = decodeURIComponent(value);
      });
    }
    postSearchComponent.execute(data);
  }, [page, type, filters]);

  useEffect(() => {
    if (postSearchComponent.response) {
      canEditStandardComponents.condition
        ? setList(postSearchComponent.response.models)
        : setList(postSearchComponent.response.models.filter((item) => item.clientReadOnly !== 'y'));
      setPagesCount(postSearchComponent.response.pagesCount);
    }
  }, [postSearchComponent.response]);

  const renderedList =
    !list.length && postSearchComponent.response ? (
      <EmptyList>{getLiteral('NieZnalezionoWynikow')}</EmptyList>
    ) : (
      list.map((module) => (
        <ColMargin size={3} key={module.id}>
          <ModuleListItem {...module} />
        </ColMargin>
      ))
    );

  return (
    <>
      <Row>{postSearchComponent.loading ? <Loader relative /> : renderedList}</Row>
      <Marginer bottom={2} top={1}>
        {pagesCount ? <Pagination {...paginationProps} /> : null}
      </Marginer>
    </>
  );
};

const ColMargin = styled(Col)`
  margin-bottom: 2rem;
`;

const EmptyList = styled.div`
  margin: auto;
  font-size: 2rem;
  font-weight: 700;
  padding: 3rem 0;
`;
