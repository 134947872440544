import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled, { css } from 'styled-components';

export const Menu = ({ children, title, isDefaultOpen = true, isSubMenu = false }) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  return (
    <MenuOuter isSubMenu={isSubMenu.toString()}>
      {title && (
        <MenuHeadline type="button" onClick={() => setIsOpen((state) => !state)}>
          {title}
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
        </MenuHeadline>
      )}
      {isOpen && <MenuWrapper>{children}</MenuWrapper>}
    </MenuOuter>
  );
};

const MenuOuter = styled.nav`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  position: relative;

  ${({ isSubMenu }) =>
    isSubMenu !== 'true'
      ? css`
          margin-top: 2rem;
          padding-bottom: 1.5rem;
          border-bottom: 1px solid #d0d0d0;
        `
      : css`
          margin-bottom: 0.5rem;
        `}
`;

const MenuWrapper = styled.div`
  padding: 0 2rem;
  position: relative;
`;

const MenuHeadline = styled.button`
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
  background: none;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  svg {
    margin-left: auto;
  }
`;
