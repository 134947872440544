/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { StyledLink, Table } from 'components/atoms/Table';
import { Label } from 'components/atoms/TypeLabel';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { getTodayDate, getWeekAgoDate } from 'helpers/dateHelper';
import { API_TEMPLATES_DESIGNS_BUILDS_HISTORY, EDP_TEMPLATES } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const QueueHistory = ({ setHistoryErrorsCount }) => {
  const { getLiteral } = useContext(composerCxt);
  const [pagesCount, setPagesCount] = useState(0);
  const [history, setHistory] = useState([]);
  const [historyStartTime, setHistoryStartTime] = useState(getWeekAgoDate());
  const [historyEndTime, setHistoryEndTime] = useState(getTodayDate());

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getDesignsBuildsHistory = useFetch(
    `${API_TEMPLATES_DESIGNS_BUILDS_HISTORY}/startDate/${historyStartTime}/endDate/${historyEndTime}/page/${page}`,
    'GET',
  );

  const filterHistoryExec = () => {
    getDesignsBuildsHistory.execute();
  };

  useEffect(() => {
    getDesignsBuildsHistory.execute();
  }, [page]);

  useEffect(() => {
    if (getDesignsBuildsHistory.response) {
      setHistory(getDesignsBuildsHistory.response.models);
      setPagesCount(getDesignsBuildsHistory.response.pagesCount);
      setHistoryErrorsCount(getDesignsBuildsHistory.response.models.filter((el) => el.status === 'error').length);
    }
  }, [getDesignsBuildsHistory.response, getDesignsBuildsHistory.error]);

  return (
    <>
      <Row>
        <Col size={3}>
          <Input
            type="date"
            name="startDate"
            title={getLiteral('ZakresOd')}
            value={historyStartTime}
            onChange={(event) => setHistoryStartTime(event.target.value)}
          />
        </Col>
        <Col size={3}>
          <Input
            type="date"
            name="endDate"
            title={getLiteral('ZakresDo')}
            value={historyEndTime}
            min={historyStartTime}
            onChange={(event) => setHistoryEndTime(event.target.value)}
          />
        </Col>
        <Col size={6}>
          <Button onClick={filterHistoryExec}>{getLiteral('Filtruj')}</Button>
        </Col>
      </Row>
      {getDesignsBuildsHistory.loading ? (
        <Loader />
      ) : (
        <>
          {history.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>{getLiteral('nazwa')}</th>
                    <th>{getLiteral('Typ')}</th>
                    <th>{getLiteral('OpisZmian')}</th>
                    <th>{getLiteral('Autor')}</th>
                    <th>{getLiteral('Zaaktulizowano')}</th>
                    <th>{getLiteral('Status')}</th>
                    <th>{getLiteral('OpisBledu')}</th>
                    <th>{getLiteral('CzasTrwania')}</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((el) => {
                    return (
                      <tr key={`${el.id}_${el.version}`}>
                        <th>
                          <StyledLink to={`${EDP_TEMPLATES}${el.id}/${el.version}`}>link</StyledLink>
                          {`${el.name} v.${el.version}`}
                        </th>
                        <th>
                          <Tag type={el.type}>{el.type}</Tag>
                        </th>
                        <th>{el.commitMessage}</th>
                        <th>{el.createUser}</th>
                        <th>{el.lastModificationTime}</th>
                        <th>
                          <StyledLabel type={el.status}>{el.status}</StyledLabel>
                        </th>
                        <th>{el.status === 'ok' ? '' : el.details.substring(0, 500)}</th>
                        <th>{el.elapsedTime}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Marginer bottom={2} top={2}>
                {pagesCount ? <Pagination {...paginationProps} /> : null}
              </Marginer>
            </>
          ) : (
            <span>{getLiteral('BrakHistoriiSzablonowZPodanegoZakresu')}</span>
          )}
        </>
      )}
    </>
  );
};

const StyledLabel = styled(Label)`
  ${({ type }) => {
    switch (type) {
      case 'ok':
        return css`
          background-color: var(--green);
        `;
      default:
        return css`
          background-color: var(--red);
        `;
    }
  }}
`;
