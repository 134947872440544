/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { CopyComponent } from 'components/atoms/CopyComponent';
import { Loader, LoaderBackground } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import {
  API_MODULES_COPY,
  API_MODULES_GET,
  API_MODULES_START_EDIT,
  API_PULL_REQUESTS_DELETE,
  EDP_MODULES,
} from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';

export const componentCxt = createContext();

const ComponentCxtProvider = ({ children }) => {
  const { id, version } = useParams();
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const [moduleData, setModuleData] = useState(null);
  const [name, setName] = useState(null);
  const [sources, setSources] = useState(null);
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const token = params ? params.token : null;

  const getModuleData = useFetch(`${API_MODULES_GET}/id/${id}/version/${version}`, 'GET');
  const getStartComponentEdit = useFetch(`${API_MODULES_START_EDIT}${id}/version/${version}`, 'GET');
  const postCopyComponent = useFetch(`${API_MODULES_COPY}`, 'POST');
  const postDeletePullRequest = useFetch(`${API_PULL_REQUESTS_DELETE}`, 'POST');

  const deletePullRequest = (id) => postDeletePullRequest.execute({ id: id });
  const startEdit = () => getStartComponentEdit.execute();

  const getMergedObject = useCallback(
    (formData) => {
      const object = { ...moduleData, ...formData };
      delete object.image;

      return object;
    },
    [moduleData],
  );

  const createSharedComponent = (name, password) => {
    postCopyComponent.execute({
      id: id,
      version: version,
      token: token,
      name: name,
      password: password,
    });
  };

  const getDefaultValues = useMemo(() => {
    if (!moduleData) {
      return {};
    }

    return {
      name: moduleData.name,
      description: moduleData.description,
      editable: moduleData.editable,
      jsAddTo: moduleData.jsAddTo,
      jsAddToAttributes: moduleData.jsAddToAttributes,
      libraries: moduleData.libraries,
      visibility: moduleData.visibility,
      sources: {
        jsLocal: moduleData.sources ? moduleData.sources.jsLocal || '' : '',
        css: moduleData.sources ? moduleData.sources.css || '' : '',
        javascript: moduleData.sources ? moduleData.sources.javascript || '' : '',
        xml: moduleData.sources ? moduleData.sources.xml || '' : '',
        xmlBefore: moduleData.sources ? moduleData.sources.xmlBefore || '' : '',
        xmlAfter: moduleData.sources ? moduleData.sources.xmlAfter || '' : '',
      },
      wrapperWithItemsXML: moduleData.wrapperWithItemsXML ? moduleData.wrapperWithItemsXML || '' : '',
      componentModel: moduleData.model ? moduleData.model || '' : '',
      clientReadOnly: moduleData.clientReadOnly ? moduleData.clientReadOnly || '' : '',
      model: moduleData.model,
    };
  }, [id, moduleData]);

  const replaceImageInComponentCode = (currentData, oldFile, newFile) => {
    setSources((state) => {
      const obj = { ...state, ...currentData };

      obj.css = obj.css.replaceAll(oldFile, newFile);
      obj.javascript = obj.javascript.replaceAll(oldFile, newFile);
      obj.jsLocal = obj.jsLocal.replaceAll(oldFile, newFile);
      obj.xml = obj.xml.replaceAll(oldFile, newFile);

      return obj;
    });
  };

  const setJsLocalLoadSetting = (data) => {
    if (data.sources.jsLocal.length && (data.jsAddTo === 'n' || moduleData.jsAddTo === 'n')) {
      data.jsAddTo = 'footer';
      setModuleData((state) => ({ ...state, jsAddTo: data.jsAddTo }));

      createToast({
        title: getLiteral('ZmianaDanych'),
        message: getLiteral(
          'WKomponencieWykrytoUzycieWydzielonegoKoduJavaScriptAbyKodZostalDolaczonyDoStronyZmienionaOpcjeKolejnosciLadowaniaWydzielonegoSkryptu',
        ),
        context: 'info',
      });
    }

    return data;
  };

  const { openDialog, closeDialog, dialog } = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: (
      <CopyComponent
        submitFunction={createSharedComponent}
        loading={postCopyComponent.loading.toString()}
        type="custom"
        passwordField={true}
      />
    ),
  });

  const setPullRequest = (pullRequest) => {
    setModuleData((state) => ({ ...state, pullRequest: pullRequest }));
  };

  useEffect(() => {
    if (id && !token) {
      getModuleData.execute();
    } else if (token) {
      openDialog();
    }
  }, [id, version]);

  useEffect(() => {
    if (postDeletePullRequest.response) {
      setModuleData((state) => ({ ...state, pullRequest: null }));

      createToast({
        title: `${getLiteral('WniosekOAkceptacjeZmianZostalWycofany')}!`,
        context: 'success',
      });
    }
  }, [postDeletePullRequest.response, postDeletePullRequest.error]);

  useEffect(() => {
    if (getStartComponentEdit.response) {
      createToast({
        title: `${getLiteral('WersjaRoboczaZostalaUtworzona')}!`,
        message: `${getLiteral('JestesWTrybieEdycjiKomponentu')}.`,
        context: 'success',
      });
      history.push(
        `${EDP_MODULES}${getStartComponentEdit.response.id}/version/${getStartComponentEdit.response.version}${window.location.search}`,
      );
    }
  }, [getStartComponentEdit.response, getStartComponentEdit.error]);

  useEffect(() => {
    if (getModuleData.response) {
      setModuleData({ ...getModuleData.response });
      setName(getModuleData.response.name);
      setSources(getModuleData.response.sources);
    } else if (getModuleData.error) {
      setModuleData(null);
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getModuleData.error.message,
        context: 'error',
      });
      history.push(`${EDP_MODULES}`);
    }
  }, [getModuleData.response, getModuleData.error]);

  useEffect(() => {
    if (postCopyComponent.error) {
      createToast({
        title: getLiteral('NieMoznaUtworzycKomponentu'),
        message: postCopyComponent.error.message,
        context: 'error',
      });
      return;
    }

    if (postCopyComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUtworzony'),
        message: `${getLiteral('PrzekierowanoDoEdycjiKomponentu')} ${postCopyComponent.response.id}`,
        context: 'success',
      });
      closeDialog();

      history.push(`${EDP_MODULES}${postCopyComponent.response.id}/version/${postCopyComponent.response.version}`);
    }
  }, [postCopyComponent.response, postCopyComponent.error]);

  return (
    <componentCxt.Provider
      value={{
        ...moduleData,
        name: name,
        sources: sources,
        setName,
        setPullRequest,
        getMergedObject,
        getDefaultValues,
        startEdit,
        startEditLoading: getStartComponentEdit.loading,
        replaceImageInComponentCode,
        setJsLocalLoadSetting,
        deletePullRequest,
        componentModel: moduleData?.model ? moduleData.model : 'component',
      }}
    >
      {getModuleData.loading ? (
        <LoaderBackground content="true">
          <Loader />
        </LoaderBackground>
      ) : null}
      {moduleData && children}
      {dialog}
    </componentCxt.Provider>
  );
};

export default ComponentCxtProvider;
