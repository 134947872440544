/* eslint-disable react-hooks/exhaustive-deps */
import { faDownload, faMagic, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Button, ButtonLink } from 'components/atoms/Button';
import { OlderVersionsDialog } from 'components/atoms/OlderVersionsDialog';
import { PreviewDialog } from 'components/atoms/PreviewDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { userCtx } from 'contexts/UserContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_TEMPLATES_DOWNLOAD } from 'helpers/endpoints';

export const TemplatePrieview = () => {
  const { templateVersions, isDraft, editView, id } = useContext(templateCxt);
  const [openVersionDialog, setOpenVersionDialog] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const { getLiteral } = useContext(composerCxt);
  const { isSupport } = useContext(userCtx);
  const { createToast } = useContext(toastCtx);

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };

  return (
    <>
      <Wrapper>
        {isSupport && (
          <DownloadButton
            primary
            onClick={() =>
              createToast({
                title: getLiteral('ZleconoEksportKompozycji'),
                message: getLiteral('OperacjeMozePotrwacKilkaSekund'),
                context: 'success',
                delay: 10,
              })
            }
            title={getLiteral('ZlecEksportKompozycji') + ' [' + getLiteral('support') + ']'}
            download
            href={`${API_TEMPLATES_DOWNLOAD}/${id}`}
          >
            <CommitIcon>
              <FontAwesomeIcon icon={faDownload} />
            </CommitIcon>
          </DownloadButton>
        )}
        <PreviewButton onClick={handleClickOpenPreview} primary>
          <CommitIcon>
            <FontAwesomeIcon icon={faMagic} />
          </CommitIcon>
          {getLiteral('Podglad')}
        </PreviewButton>
        <PreviewDialog open={openPreview} setOpen={setOpenPreview}></PreviewDialog>
        {templateVersions.length > 1 || editView ? (
          <>
            <OlderVersionsDialog open={openVersionDialog} setOpen={setOpenVersionDialog} />
            <ResetButton type="button" onClick={() => setOpenVersionDialog(true)}>
              <CommitIcon>
                <FontAwesomeIcon icon={faRedoAlt} />
              </CommitIcon>
              <span>{isDraft === 'y' ? getLiteral('OdrzucZmiany') : getLiteral('Przywroc')}</span>
            </ResetButton>
          </>
        ) : null}
      </Wrapper>
    </>
  );
};

const DownloadButton = styled(ButtonLink)`
  background-color: #0488e7;
  margin-right: 1rem;
  span {
    margin: 0;
  }
`;

const PreviewButton = styled(Button)`
  min-width: 10vh;
  background-color: #0488e7;
`;

const ResetButton = styled(Button)`
  min-width: 10vh;
  margin-left: 1rem;
  background-color: #a94442;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-left: auto;
`;

const CommitIcon = styled.span`
  font-size: 2rem;
  margin-right: 1.5rem;
`;
