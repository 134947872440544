import styled, { css } from 'styled-components';
import { useContext } from 'react';

import { TEMPLATE_LIST_MODE_SELECTION } from './TemplateCustomList';

import { composerCxt } from 'contexts/ComposerContext';
import { TemplateListItemCtxProvider } from 'contexts/TemplateListItemContext';
import { TemplateTileItem } from 'components/atoms/TemplateTileItem';
import { CompactCol } from 'components/atoms/Grid';

export const TemplateListTiles = ({ list, listTemplates, mode, type, setTemplateSelected, templateSelected }) => {
  const { getLiteral } = useContext(composerCxt);

  if ((!list || !list.length) && listTemplates) {
    return <EmptyList>{getLiteral('NieZnalezionoWynikow')}</EmptyList>;
  }

  return list.map((template) => {
    return (
      <ColMargin mode={mode} size={mode === TEMPLATE_LIST_MODE_SELECTION ? 4 : 6} key={`${type}_${template.id}`}>
        <TemplateListItemCtxProvider template={template}>
          <TemplateTileItem mode={mode} onSelect={setTemplateSelected} templateSelected={templateSelected} />
        </TemplateListItemCtxProvider>
      </ColMargin>
    );
  });
};

const ColMargin = styled(CompactCol)`
  margin-bottom: 3rem;
  @media (min-width: 1680px) {
    max-width: 33.3333%;
  }

  ${({ mode }) =>
    mode === TEMPLATE_LIST_MODE_SELECTION
      ? css`
          padding-left: 1rem;
          padding-right: 1rem;
          margin-bottom: 2rem;
        `
      : ''}
`;

const EmptyList = styled.div`
  margin: auto;
  font-size: 2rem;
  font-weight: 700;
  padding: 3rem 0;
`;
