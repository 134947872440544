/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Marginer, Row, StickyMargin } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { ModuleListItem } from 'components/atoms/ModuleListItem';
import { ColMargin } from 'components/molecules/ModuleReplace';
import { composerCxt } from 'contexts/ComposerContext';
import FileSvg from 'data/icon-file.svg';
import { API_GFX_GET_USAGES } from 'helpers/endpoints';
import { formatBytes } from 'helpers/formatBytes';
import { pictureRegex } from 'helpers/regexp';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';
import { useTabs } from 'utils/useTabs';

const InformationTab = ({
  showOriginal,
  clientGfx,
  picturePath,
  setShowOriginal,
  fileSize,
  createUser,
  createTime,
  name,
}) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <DialogInfoWrapper>
      <PictureWrapper>
        {showOriginal ? (
          <img
            src={pictureRegex.test(picturePath) ? picturePath : FileSvg}
            className={!pictureRegex.test(picturePath) ? 'no-bg' : ''}
            alt={name}
            loading="lazy"
          />
        ) : (
          <img
            src={
              clientGfx
                ? pictureRegex.test(clientGfx.path)
                  ? `/gfx/${clientGfx.path}`
                  : FileSvg
                : pictureRegex.test(picturePath)
                ? picturePath
                : FileSvg
            }
            alt={name}
            className={!pictureRegex.test(picturePath) ? 'no-bg' : ''}
            loading="lazy"
          />
        )}

        {clientGfx && (
          <OriginalPicture onClick={() => setShowOriginal((state) => !state)}>
            {showOriginal ? getLiteral('PokazObecne') : getLiteral('ZobaczOryginal')}
          </OriginalPicture>
        )}
      </PictureWrapper>
      <div className="data">
        <h3>{getLiteral('Autor')}</h3>
        <p>
          <strong>{createUser}</strong>
        </p>
        <h3>{getLiteral('DataModyfikacji')}</h3>
        <p>{createTime}</p>
        <h3>{getLiteral('RozmiarPliku')}</h3>
        <p>{formatBytes(fileSize)}</p>
      </div>
    </DialogInfoWrapper>
  );
};

export const FileInfoDialogStyles = css`
  .MuiDialog-paper,
  .MuiDialogContent-root {
    width: 1000px;
    height: 600px;
  }
`;

export const FileInfoDialogoComponent = ({
  picturePath,
  name,
  clientGfx,
  isStandardFile,
  createTime,
  createUser,
  fileSize,
}) => {
  const { getLiteral } = useContext(composerCxt);
  const [showOriginal, setShowOriginal] = useState(false);
  const [components, setComponents] = useState([]);
  const getFileUsages = useFetch(`${API_GFX_GET_USAGES}`, 'POST');
  const [pagesCount, setPagesCount] = useState(0);

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  useEffect(() => {
    getFileUsages.execute({
      page: page,
      name: name,
      standardFiles: isStandardFile,
    });
  }, [page]);

  useEffect(() => {
    if (getFileUsages.response) {
      setComponents(getFileUsages.response.models);
      setPagesCount(getFileUsages.response.pagesCount);
    }
  }, [getFileUsages.response, getFileUsages.error]);

  useEffect(() => {
    setShowOriginal(false);

    return () => setShowOriginal(false);
  }, [clientGfx]);

  const tabsArray = [
    {
      title: getLiteral('Informacje'),
      component: (
        <InformationTab
          showOriginal={showOriginal}
          clientGfx={clientGfx}
          picturePath={picturePath}
          setShowOriginal={setShowOriginal}
          fileSize={fileSize}
          createUser={createUser}
          createTime={createTime}
          name={name}
        />
      ),
    },
    {
      title: getLiteral('Komponenty'),
      component: (
        <Marginer top={2}>
          {getFileUsages.loading ? (
            <Loader />
          ) : (
            <>
              {components.length ? (
                <>
                  <Row>
                    {components.map((component) => {
                      return (
                        <ColMargin size={3} key={component.id}>
                          <ModuleListItem {...component}></ModuleListItem>
                        </ColMargin>
                      );
                    })}
                  </Row>
                  {pagesCount ? <Pagination {...paginationProps} /> : null}
                </>
              ) : (
                <>{getLiteral('PlikNieJestPrzypisanyDoZadnegoKomponentu')}</>
              )}
            </>
          )}
        </Marginer>
      ),
    },
  ];
  const { tabs, tabsPanel } = useTabs(tabsArray);

  return (
    <>
      <StickyMargin bottom={2}>{tabs}</StickyMargin>
      {tabsPanel}
    </>
  );
};

const OriginalPicture = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #0488e7;
  color: #fff;
  border: none;
  height: 3rem;
  font-weight: 700;
  font-size: 1.3rem;
  padding: 0 1rem;
  border-radius: 5px;
`;

const Picture = styled.div`
  margin-bottom: 1rem;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 440px;
    max-width: 438px;
    height: auto;
    background: repeating-conic-gradient(#d0d0d0 0% 25%, transparent 0% 50%) 50% / 20px 20px;

    &.no-bg {
      background: transparent;
    }
  }
`;

const PictureWrapper = styled(Picture)`
  min-width: 440px;
  height: 440px;
  border: 1px solid #e0e0e0;
  padding: 5px;
  border-radius: 5px;
`;

const DialogInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  .data {
    padding-left: 3rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    padding-left: 1.2rem;
  }
  p {
    margin-bottom: 2rem;
    font-size: 1.3rem;
  }

  li {
    margin-bottom: 1rem;
  }
`;
