/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { FullCenter } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { API_USERS, EDP_NO_ACCESS } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const userCtx = createContext();

export const UserCtxProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const getUser = useFetch(API_USERS, 'GET');

  const isSupport = useMemo(() => user && user.type === 'iai', [user]);

  const getUserIconLink = (userLogin) =>
    `https://www.iai-system.com/panel/data/users/pictures/${userLogin.replace('iai-', '')}.jpg`;

  // Execute first visit's data load
  useEffect(() => {
    getUser.execute();
  }, []);

  // Handle user data
  useEffect(() => {
    if (!getUser.response) {
      setUser(null);
    }

    if (getUser.response) {
      setUser(getUser.response);
    }
  }, [getUser.response, getUser.error]);

  if (getUser.executed && getUser.error) {
    return <Redirect to={EDP_NO_ACCESS} />;
  }

  return (
    <userCtx.Provider value={{ ...user, isSupport, getUserIconLink }}>
      {!getUser.executed || !user ? (
        <FullCenter>
          <Loader />
        </FullCenter>
      ) : (
        children
      )}
    </userCtx.Provider>
  );
};
