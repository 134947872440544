import { useContext, useEffect, useState } from 'react';

import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { TemplateJsCodeLang } from 'components/organisms/TemplateJsCodeLang';
import { Col, Row } from 'components/atoms/Grid';
import { Select } from 'components/atoms/Form/Select';

export const TemplateJsCode = () => {
  const { getLiteral } = useContext(composerCxt);
  const { languages } = useContext(templateCxt);

  const [language, setLanguage] = useState({
    value: languages[0].id,
    label: languages[0].name,
  });

  const jsLanguages = languages.map((lang) => {
    return makeSelectOption(lang.id, lang.name);
  });

  useEffect(() => {
    if (language.value) {
      setLanguage(language);
    } else {
      setLanguage({ value: languages[0].id, label: languages[0].name });
    }
  }, [language, languages]);

  return (
    <>
      <Row mb={2} align="center">
        <Col size={3}>
          <Select
            defaultValue={language ? language : { value: languages[0].id, label: languages[0].name }}
            options={jsLanguages}
            bottom="0"
            placeholder={getLiteral('WyswietlKodDlaJezyka')}
            onChange={(value) => setLanguage(value)}
          />
        </Col>
      </Row>
      <TemplateJsCodeLang language={language} />
    </>
  );
};
