/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sprintf } from 'sprintf-js';

import { Button } from 'components/atoms/Button';
import { CopyComponent } from 'components/atoms/CopyComponent';
import { Marginer } from 'components/atoms/Grid';
import { Heading } from 'components/atoms/Heading';
import { ModuleShareForm } from 'components/organisms/ModuleShareForm';
import { componentCxt } from 'contexts/ComponentContext';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { useAccess } from 'helpers/access';
import { API_MODULES_COPY, API_MODULES_DELETE, EDP_MODULES } from 'helpers/endpoints';
import { isComponentEditable } from 'helpers/isComponentEditable';
import { useAlert } from 'utils/useAlert';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';

export const ModuleInfo = () => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const { id, type, version, editable, allowedActions } = useContext(componentCxt);
  const postCopyComponent = useFetch(`${API_MODULES_COPY}`, 'POST');
  const postDeleteComponent = useFetch(`${API_MODULES_DELETE}`, 'POST');

  const history = useHistory();

  const canUserEditComponent = isComponentEditable({
    userCanEdit: editable,
    componentEditable: allowedActions.edit,
    validFunction: useAccess,
  });

  const submitFunction = (name) => {
    postCopyComponent.execute({
      id: id,
      version: version,
      name: name,
    });
  };

  const { openDialog, closeDialog, dialog } = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: (
      <CopyComponent submitFunction={submitFunction} loading={postCopyComponent.loading.toString()} type={type} />
    ),
  });

  const shareComponentDialog = useDialog({
    title: getLiteral('UdostepnianieKomponentu'),
    content: <ModuleShareForm id={id} />,
  });

  const { alert, openAlert } = useAlert({
    onAccept: () => {
      deleteComponent();
    },
    text: getLiteral('TaOperacjaUsunieDefinitywnieKomponentWrazZeWszystkimiWczesniejszymiWersjami'),
  });

  const deleteComponent = () => {
    postDeleteComponent.execute({ id: id });
  };

  useEffect(() => {
    if (postCopyComponent.error) {
      createToast({
        title: getLiteral('NieMoznaUtworzycKomponentu'),
        message: postCopyComponent.error.message,
        context: 'error',
      });
      return;
    }

    if (postCopyComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUtworzony'),
        message: `${getLiteral('PrzekierowanoDoEdycjiKomponentu')} ${postCopyComponent.response.id}`,
        context: 'success',
      });
      closeDialog();

      history.push(`${EDP_MODULES}${postCopyComponent.response.id}/version/${postCopyComponent.response.version}`);
    }
  }, [postCopyComponent.response, postCopyComponent.error]);

  useEffect(() => {
    if (postDeleteComponent.response) {
      createToast({
        title: sprintf(getLiteral('KomponentXZostalUsuniety'), postDeleteComponent.response.id),
        message: getLiteral('AbyPrzywrocicKomponentSkontaktujSieZSupportem'),
        context: 'success',
      });
      history.push(`${EDP_MODULES}`);
    } else if (postDeleteComponent.error) {
      createToast({
        title: getLiteral('KomponentNieZostalUsuniety'),
        message: postDeleteComponent.error.message,
        context: 'error',
        delay: 0,
      });
    }
  }, [postDeleteComponent.response, postDeleteComponent.error]);

  return (
    <>
      <Marginer bottom={2}>
        <Heading size="medium">{getLiteral('OperacjeNaKomponencie')}</Heading>
      </Marginer>
      <Marginer bottom={1}>
        <Button fullWidth onClick={openDialog} color="gray">
          {getLiteral('KopiujKomponent')}
        </Button>
      </Marginer>
      {type !== 'standard' && (
        <>
          <Marginer bottom={3}>
            <Button fullWidth onClick={shareComponentDialog.openDialog} color="gray">
              {getLiteral('UdostepnijKomponent')}
            </Button>
          </Marginer>
          {canUserEditComponent && (
            <Button fullWidth onClick={openAlert} loading={postDeleteComponent.loading.toString()} color="red">
              {getLiteral('UsunKomponent')}
            </Button>
          )}
        </>
      )}
      {alert}
      {dialog}
      {shareComponentDialog.dialog}
    </>
  );
};
