/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';

import { IconLock } from 'components/atoms/Icon';
import {
  SUBSCRIPTION_DIALOG_TYPE_ACTIVATED,
  SUBSCRIPTION_DIALOG_TYPE_ACTIVATION,
  SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED,
  SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION,
  SubscriptionDialog,
} from 'components/organisms/SubscriptionDialog';
import { NewProjectDialog } from 'components/molecules/NewProjectDialog';
import { FullCenter } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { textsCtx } from 'contexts/TextsContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { useAccess } from 'helpers/access';
import { countryToFlag } from 'helpers/countryToFlag';
import {
  API_LIBS_GET,
  API_PAGES_GET,
  API_GROUPS_GET,
  API_LANG_GET,
  API_COMPOSER_VERSION,
  API_COMPOSER_VERSION_SET_PRO,
  API_COMPOSER_VERSION_SET_LITE,
} from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { useFetch } from 'utils/useFetch';
import { STATUS_TYPES } from 'components/organisms/ComponentsPullRequests';
import { COMPOSER_SUBSCRIPTION_LEVEL_LITE, COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';

export const composerCxt = createContext();

/**
 * Tymczasowa flaga, przygotowana na potrzeby instalacji projektu P4865
 * wyłącza mechanizm kontroli subskrypcji
 * wyłącza odwołanie do nowych endpointów projektu iai-shop
 * wymusza poziom uprawnień dla subskrypcji Composer Pro
 */
export const FORCE_COMPOSER_PRO = false;
export const TEMPLATE_TYPE_STANDARD = 'standard';
export const TEMPLATE_TYPE_CUSTOM = 'custom';
export const TEMPLATE_TYPE_CUSTOM_STD = 'custom-std';
export const TEMPLATE_TYPE_INDIVIDUAL = 'individual';

export const ComposerCxtProvider = ({ children }) => {
  const { createToast } = useContext(toastCtx);
  const { getLiteralData } = useContext(textsCtx);
  const user = useContext(userCtx);

  const [composerSubscriptionLevel, setComposerSubscriptionLevel] = useState('');
  const [pages, setPages] = useState([]);
  const [pagesIds, setPagesIds] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [layoutsIds, setLayoutsIds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsMap, setGroupsMap] = useState({});
  const [libraries, setLibraries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [languagesWithIso, setLanguagesWithIso] = useState([]);
  const [subscriptionDialogOpened, setSubscriptionDialogOpened] = useState(false);
  const [subscriptionDialogType, setSubscriptionDialogType] = useState(SUBSCRIPTION_DIALOG_TYPE_ACTIVATION);
  const [newCompositionDialogOpened, setNewCompositionDialogOpened] = useState(false);

  const getComposerSubscriptionLevel = useFetch(API_COMPOSER_VERSION, 'GET');
  const setComposerSubscriptionLevelPro = useFetch(API_COMPOSER_VERSION_SET_PRO, 'GET');
  const setComposerSubscriptionLevelLite = useFetch(API_COMPOSER_VERSION_SET_LITE, 'GET');
  const getPages = useFetch(API_PAGES_GET, 'GET');
  const getGroups = useFetch(API_GROUPS_GET, 'GET');
  const getLibraries = useFetch(API_LIBS_GET, 'GET');
  const getLanguages = useFetch(API_LANG_GET, 'GET');

  const canDeubgComposer = useAccess('canDebugComposer');
  const getLiteral = (key) => getLiteralData(key, canDeubgComposer.condition);

  const getStatusLabel = (status) => {
    switch (status) {
      case STATUS_TYPES.TO_VERIFY:
        return getLiteral('doWeryfikacji');
      case STATUS_TYPES.ACCEPTED:
        return getLiteral('Zaakceptowano');
      default:
        return getLiteral('DoPoprawy');
    }
  };

  const getTagIcon = (icon) => {
    switch (icon) {
      case 'lock':
        return <IconLock />;
      default:
        return '';
    }
  };

  const getTagLabel = (type, label) => {
    switch (type) {
      case 'custom':
        return getLiteral('Wlasny');
      case 'individual':
        return getLiteral('Indywidualny');
      case 'standard':
        return getLiteral('BASIC');
      case 'lite':
        return getLiteral('Lite');
      case 'pro':
        return getLiteral('Pro');
      case 'draft':
        return getLiteral('Roboczy');
      default:
        return label;
    }
  };

  const openSubscriptionDialog = (type) => {
    if (
      type === SUBSCRIPTION_DIALOG_TYPE_ACTIVATION ||
      type === SUBSCRIPTION_DIALOG_TYPE_ACTIVATED ||
      type === SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION ||
      type === SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED
    ) {
      setSubscriptionDialogType(type);
      setSubscriptionDialogOpened(true);
    }
  };

  const setComposerVersionPro = () => {
    setComposerSubscriptionLevelPro.execute();
  };

  const setComposerVersionLite = () => {
    setComposerSubscriptionLevelLite.execute();
  };

  const subscriptionDialog = (
    <SubscriptionDialog
      open={subscriptionDialogOpened}
      setOpen={setSubscriptionDialogOpened}
      type={subscriptionDialogType}
    />
  );

  const newCompositionDialog = (
    <NewProjectDialog openList={newCompositionDialogOpened} setOpenList={setNewCompositionDialogOpened} />
  );

  // Execute first visit's data load
  useEffect(() => {
    getComposerSubscriptionLevel.execute();
    getPages.execute();
    getGroups.execute();
    getLibraries.execute();
    getLanguages.execute();
  }, []);

  useEffect(() => {
    if (getComposerSubscriptionLevel.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (!getComposerSubscriptionLevel.response) {
      setComposerSubscriptionLevel(COMPOSER_SUBSCRIPTION_LEVEL_LITE);
    }

    if (getComposerSubscriptionLevel.response) {
      switch (getComposerSubscriptionLevel.response.subscription_level) {
        case 'pro':
          setComposerSubscriptionLevel(COMPOSER_SUBSCRIPTION_LEVEL_PRO);
          break;
        case 'lite':
        default:
          setComposerSubscriptionLevel(COMPOSER_SUBSCRIPTION_LEVEL_LITE);
      }
    }
  }, [getComposerSubscriptionLevel.response]);

  useEffect(() => {
    if (!setComposerSubscriptionLevelPro.response) {
      return;
    }
    if (setComposerSubscriptionLevelPro.response.subscription_level_result === 'ok') {
      setComposerSubscriptionLevel(COMPOSER_SUBSCRIPTION_LEVEL_PRO);
      createToast({
        title: getLiteral('AktywacjaComposerProPrzebieglaPomyslnie'),
        message: getLiteral('OdTejChwiliZyskujeszPelnyDostepDoKoduXrodlowegoKomponentowISzablonow'),
        context: 'success',
      });
      openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_ACTIVATED);
    } else {
      createToast({
        title: getLiteral('NieUdaloSieAktywowacComposerPro'),
        message:
          setComposerSubscriptionLevelPro.status && setComposerSubscriptionLevelPro.status.message
            ? setComposerSubscriptionLevelPro.status.message
            : '',
        context: 'error',
      });
    }
  }, [setComposerSubscriptionLevelPro.response]);

  useEffect(() => {
    if (!setComposerSubscriptionLevelLite.response) {
      return;
    }
    if (setComposerSubscriptionLevelLite.response.subscription_level_result === 'ok') {
      setComposerSubscriptionLevel(COMPOSER_SUBSCRIPTION_LEVEL_LITE);
      createToast({
        title: getLiteral('DezaktywacjaComposerProPrzebieglaPomyslnie'),
        message: '',
        context: 'success',
      });
      openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED);
    } else {
      createToast({
        title: getLiteral('RezygnacjaZComposerProNiePowiodlaSie'),
        message:
          setComposerSubscriptionLevelLite.status && setComposerSubscriptionLevelLite.status.message
            ? setComposerSubscriptionLevelLite.status.message
            : '',
        context: 'error',
      });
    }
  }, [setComposerSubscriptionLevelLite.response]);

  // Handle Pages data
  useEffect(() => {
    if (getPages.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (!getPages.response) {
      setPages([]);
      setLayouts([]);
    }

    if (getPages.response) {
      const pagesList = getPages.response.pages.map((item) => {
        if (user.type === 'iai') {
          return makeSelectOption(item.name, item.description ? `${item.description} (${item.name})` : item.name);
        }

        return makeSelectOption(item.name, item.description || item.name);
      });
      const layoutsList = getPages.response.layouts.map((item) =>
        makeSelectOption(item.name, item.description || item.name),
      );
      setPagesIds(getPages.response.pages);
      setLayoutsIds(getPages.response.layouts);
      setPages(pagesList);
      setLayouts(layoutsList);
    }
  }, [getPages.response, getPages.error]);

  // Handle Libraries data
  useEffect(() => {
    if (getLibraries.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (!getLibraries.response) {
      setLibraries([]);
    }

    if (getLibraries.response) {
      setLibraries(getLibraries.response);
    }
  }, [getLibraries.response, getLibraries.error]);

  // Handle groups data
  useEffect(() => {
    if (getGroups.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (!getGroups.response) {
      setGroups([]);
      setGroupsMap({});
    }

    if (getGroups.response) {
      setGroups(getGroups.response);
      const groupMap = {};

      getGroups.response.forEach((group) => (groupMap[group.id] = group));
      setGroupsMap(groupMap);
    }
  }, [getGroups.response, getGroups.error]);

  // Handle languages data
  useEffect(() => {
    if (getLanguages.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (!getLanguages.response) {
      setLanguages([]);
    }

    if (getLanguages.response) {
      setLanguages(
        getLanguages.response.map(({ id, name, iso6391 }) =>
          makeSelectOption(id, iso6391 ? `${countryToFlag(iso6391)} ${name}` : name),
        ),
      );
      setLanguagesWithIso(getLanguages.response);
    }
  }, [getLanguages.response, getLanguages.error]);

  const addPageType = (data) => {
    const newItem =
      user.type === 'iai'
        ? makeSelectOption(data.name, data.description ? `${data.description} (${data.name})` : data.name)
        : makeSelectOption(data.name, data.description || data.name);

    setPagesIds((state) => [...state, data]);
    setPages((state) => [...state, newItem]);
  };

  const isComposerProForced = () => {
    return FORCE_COMPOSER_PRO;
  };

  // Waiting for all data reqired to Composer works
  const shopLoader =
    (getLibraries.loading || !getLibraries.executed) &&
    (getLanguages.loading || !getLanguages.executed) &&
    (getPages.loading || !getPages.executed) &&
    (getGroups.loading || !getGroups.executed);

  return (
    <composerCxt.Provider
      value={{
        composerSubscriptionLevel,
        pagesIds,
        layoutsIds,
        pages,
        layouts,
        libraries,
        languages,
        groups,
        groupsMap,
        getLiteral,
        getTagIcon,
        getTagLabel,
        languagesWithIso,
        getStatusLabel,
        subscriptionDialog,
        setSubscriptionDialogOpened,
        openSubscriptionDialog,
        newCompositionDialog,
        setNewCompositionDialogOpened,
        setComposerVersionPro,
        setComposerVersionLite,
        isComposerProForced,
        methods: {
          addLibrary: (data) => setLibraries((state) => [...state, data]),
          updateLibrary: (data) => setLibraries((state) => [...state.filter((item) => item.id !== data.id), data]),
          addModuleType: (data) => setGroups((state) => [...state, data]),
          addPageType,
          fetchGroups: getGroups.execute,
        },
      }}
    >
      {shopLoader || !pages || !libraries.length || !groups.length || !languages.length ? (
        <FullCenter>
          <Loader />
        </FullCenter>
      ) : (
        children
      )}
    </composerCxt.Provider>
  );
};
