/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { Layout, Content, Container } from 'components/atoms/Grid';
import { Button } from 'components/atoms/Button';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { TemplateCustomList } from 'components/organisms/TemplateCustomList';
import { MarketingBannerTop } from 'components/molecules/MarketingBannerTop';
import { useHashTabs } from 'utils/useHashTabs';
import { hashToName } from 'helpers/hashToName';
import { composerCxt } from 'contexts/ComposerContext';

const DefaultPage = () => {
  const { getLiteral, setNewCompositionDialogOpened, isComposerProForced } = useContext(composerCxt);
  const location = useLocation();
  const history = useHistory();
  const [numStandardDesigns, setNumStandardDesigns] = useState('');
  const [numOwnDesigns, setNumOwnDesigns] = useState('');
  const [numDraftDesigns, setNumDraftDesigns] = useState('');
  const [numPublishedDesigns, setNumPublishedDesigns] = useState('');

  useEffect(() => {
    switch (location.hash) {
      case hashToName(getLiteral('Wlasne')):
        if (numOwnDesigns === 0) {
          redirectToFirstTab();
        }
        break;
      case hashToName(getLiteral('Robocze')):
        if (numDraftDesigns === 0) {
          redirectToFirstTab();
        }
        break;
      case hashToName(getLiteral('BASIC')):
        if (numStandardDesigns === 0) {
          redirectToFirstTab();
        }
        break;
      case hashToName(getLiteral('Opublikowane')):
        if (numPublishedDesigns === 0) {
          redirectToFirstTab();
        }
        break;
      default:
    }
  }, [location]);

  const redirectToFirstTab = () => {
    if (numOwnDesigns > 0) {
      history.push('/' + hashToName(getLiteral('Wlasne')));
    } else if (numDraftDesigns > 0) {
      history.push('/' + hashToName(getLiteral('Robocze')));
    } else if (numStandardDesigns > 0) {
      history.push('/' + hashToName(getLiteral('BASIC')));
    } else if (numPublishedDesigns > 0) {
      history.push('/' + hashToName(getLiteral('Opublikowane')));
    } else {
      history.push('/');
    }
  };

  const tabsArr = [
    (numOwnDesigns > 0 || numOwnDesigns === '') && {
      title: getLiteral('Wlasne'),
      titleSuffix: numOwnDesigns > 0 ? ` (${numOwnDesigns})` : ``,
      component: <TemplateCustomList type="own" setNumElements={setNumOwnDesigns}></TemplateCustomList>,
    },
    (numDraftDesigns > 0 || numDraftDesigns === '') && {
      title: `${getLiteral('Robocze')}`,
      titleSuffix: numDraftDesigns > 0 ? ` (${numDraftDesigns})` : ``,
      component: <TemplateCustomList type="draft" setNumElements={setNumDraftDesigns}></TemplateCustomList>,
    },
    (numStandardDesigns > 0 || numStandardDesigns === '') && {
      title: getLiteral('BASIC'),
      titleSuffix: numStandardDesigns > 0 ? ` (${numStandardDesigns})` : ``,
      component: <TemplateCustomList type="standard" setNumElements={setNumStandardDesigns}></TemplateCustomList>,
    },
    isComposerProForced() !== true &&
      (numPublishedDesigns > 0 || numPublishedDesigns === '') && {
        title: getLiteral('Opublikowane'),
        titleSuffix: numPublishedDesigns > 0 ? ` (${numPublishedDesigns})` : ``,
        component: (
          <TemplateCustomList
            type="published"
            render="list"
            setNumElements={setNumPublishedDesigns}
          ></TemplateCustomList>
        ),
      },
  ].filter((item) => item);

  const { tabs, tabsPanel } = useHashTabs(tabsArr);

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          <MarketingBannerTop />
          <WhiteBackground>
            <Header title={getLiteral('TwojeKompozycje')} tabs={tabs}>
              <Button
                onClick={() => {
                  setNewCompositionDialogOpened(true);
                }}
                color="green"
              >
                {getLiteral('StworzKompozycje')}
              </Button>
            </Header>
            {tabsPanel}
          </WhiteBackground>
        </Container>
      </Content>
    </Layout>
  );
};

export default DefaultPage;

const WhiteBackground = styled.div`
  background: #fff;
  z-index: 1;
`;
