import { createContext, useContext } from 'react';
import styled from 'styled-components';

import { TopBanner } from 'components/organisms/articles/banners/TopBanner';
import { LiteVsProPol } from 'components/organisms/articles/LiteVsProPol';
import { LiteVsProEng } from 'components/organisms/articles/LiteVsProEng';
import { Page404 } from 'components/organisms/articles/Page404';
import { AsideBanner } from 'components/organisms/articles/banners/AsideBanner';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { COMPOSER_SUBSCRIPTION_LEVEL_LITE } from 'helpers/composerVersion';

export const PAGE_LITE_VS_PRO = 'lite-vs-pro';

export const marketingCtx = createContext();
export const articlesIndex = {
  'lite-vs-pro-pol': <LiteVsProPol />,
  'lite-vs-pro-eng': <LiteVsProEng />,
};

export const MarketingCtxProvider = ({ children }) => {
  const { composerSubscriptionLevel, isComposerProForced } = useContext(composerCxt);
  const user = useContext(userCtx);

  const getArticle = (articleName) => {
    if (undefined !== articlesIndex[articleName + '-' + user.language]) {
      return articlesIndex[articleName + '-' + user.language];
    }
    return <Page404 />;
  };

  const asideBannerEnabled = () => {
    return false;
  };

  const getAsideBannerContent = () => {
    return (
      <CustomAsideBlogBanner>
        <AsideBanner />
      </CustomAsideBlogBanner>
    );
  };

  const topBannerEnabled = () => {
    if (isComposerProForced()) {
      return true;
    }
    return composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_LITE;
  };

  const getTopBannerContent = () => {
    return (
      <CustomTopBlogBanner>
        <TopBanner />
      </CustomTopBlogBanner>
    );
  };

  return (
    <marketingCtx.Provider
      value={{
        asideBannerEnabled,
        getAsideBannerContent,
        topBannerEnabled,
        getTopBannerContent,
        getArticle,
      }}
    >
      {children}
    </marketingCtx.Provider>
  );
};

const CustomTopBlogBanner = styled.div``;

const CustomAsideBlogBanner = styled.div`
  margin: 3rem 2rem;
  img {
    width: 100%;
    height: auto;
  }
`;
