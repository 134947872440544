import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';

import {
  SUBSCRIPTION_DIALOG_TYPE_ACTIVATION,
  SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION,
} from 'components/organisms/SubscriptionDialog';
import { COMPOSER_SUBSCRIPTION_LEVEL_LITE, COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';
import { AddComponentDialog } from 'components/atoms/AddComponentDialog';
import { Logo } from 'components/atoms/Logo';
import { MenuLink } from 'components/atoms/MenuLink';
import { SupportMark } from 'components/atoms/SupportMark';
import { Menu } from 'components/molecules/Menu';
import { UserLogged } from 'components/molecules/UserLogged';
import { composerCxt } from 'contexts/ComposerContext';
import { PAGE_LITE_VS_PRO } from 'contexts/MarketingContext';
import { useAccess } from 'helpers/access';
import {
  EDP_CONFIG,
  EDP_DEFAULT,
  EDP_DICTIONARY,
  EDP_EVENTS_LOG,
  EDP_FILES,
  EDP_MIGRATION,
  EDP_MODULES,
  EDP_PULL_DOCUMENTATION,
  EDP_PULL_INSTALLATIONS,
  EDP_PULL_REQUESTS,
  EDP_QUEUE,
  EDP_TRANSLATION,
  EDP_REMOVED_ELEMENTS,
  EDP_TEMPLATES_COMPARE,
  EDP_TEMPLATES_STANDARDS,
} from 'helpers/endpoints';
import { hashToName } from 'helpers/hashToName';
import { useDialog } from 'utils/useDialog';
import { MarketingBannerAside } from 'components/molecules/MarketingBannerAside';

export const Aside = ({ children, empty, noMenu, isOpenMenu }) => {
  const {
    getLiteral,
    composerSubscriptionLevel,
    subscriptionDialog,
    openSubscriptionDialog,
    newCompositionDialog,
    setNewCompositionDialogOpened,
    isComposerProForced,
  } = useContext(composerCxt);
  const history = useHistory();
  const canEditStandardCompositions = useAccess('canEditStandardDesigns');
  const canAccessConfigs = useAccess('canAccessConfigs');
  const canAddModules = useAccess('canAddModules');
  const canUseDocumentation = useAccess('canUseDocumentation');
  const addComponent = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: <AddComponentDialog afterSubmit={() => addComponent.closeDialog()}></AddComponentDialog>,
  });

  if (empty) {
    return <StyledAside>{children}</StyledAside>;
  }

  return (
    <StyledAside>
      <StyledLink to={EDP_DEFAULT}>
        <StyledLogo />
        <span>{getLiteral('Composer')}</span>
        {isComposerProForced() !== true &&
          (composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_LITE ? (
            <span className="lite">{composerSubscriptionLevel}</span>
          ) : (
            <span className="pro">{composerSubscriptionLevel}</span>
          ))}
      </StyledLink>
      {!noMenu ? (
        <>
          <Menu title={getLiteral('Kompozycje')} isDefaultOpen={isOpenMenu}>
            <MenuLink to={`${EDP_DEFAULT}${hashToName(getLiteral('Wlasne'))}`}>
              {getLiteral('ZarzadzanieKompozycjami')}
            </MenuLink>
            <MenuLink
              to="#add-composition"
              onClick={(e) => {
                e.preventDefault();
                setNewCompositionDialogOpened(true);
              }}
            >
              {getLiteral('StworzNowaKompozycje')}
            </MenuLink>
            {canEditStandardCompositions.condition && (
              <MenuLink to={EDP_TEMPLATES_STANDARDS}>
                {getLiteral('KompozycjeStandardowe')}
                <SupportMark></SupportMark>
              </MenuLink>
            )}
            <MenuLink to={EDP_FILES}>{getLiteral('ManagerPlikow')}</MenuLink>
            <MenuLink to={EDP_DICTIONARY}>{getLiteral('SlownikTlumaczen')}</MenuLink>
          </Menu>
          <Menu title={<>{getLiteral('Komponenty')}</>} isDefaultOpen={isOpenMenu}>
            {(canAddModules.condition || composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO) && (
              <>
                <MenuLink
                  to="#add-component"
                  onClick={(e) => {
                    e.preventDefault();
                    addComponent.openDialog();
                  }}
                >
                  {getLiteral('DodajKomponent')}
                </MenuLink>
                {addComponent.dialog}
              </>
            )}
            <MenuLink to={`${EDP_MODULES}${hashToName(getLiteral('KomponentyWlasne'))}`}>
              {getLiteral('KomponentyWlasne')}
            </MenuLink>
            <MenuLink to={`${EDP_MODULES}${hashToName(getLiteral('KomponentyIndywidualne'))}`}>
              {getLiteral('KomponentyIndywidualne')}
            </MenuLink>
            <MenuLink to={`${EDP_MODULES}${hashToName(getLiteral('KomponentyStandardowe'))}`}>
              {getLiteral('KomponentyStandardowe')}
            </MenuLink>
          </Menu>
          {canAccessConfigs.condition && (
            <Menu
              title={
                <>
                  {getLiteral('Narzedzia')}
                  <SupportMark>[{getLiteral('support')}]</SupportMark>
                </>
              }
              isDefaultOpen={isOpenMenu}
            >
              <MenuLink to={EDP_TEMPLATES_COMPARE}>{getLiteral('PorownajKompozycje')}</MenuLink>
              <MenuLink to={EDP_CONFIG}>{getLiteral('KonfiguracjaSzablonow')}</MenuLink>
              <MenuLink to={EDP_TRANSLATION}>{getLiteral('KolejkaTlumaczen')}</MenuLink>
              <MenuLink to={EDP_QUEUE}>{getLiteral('ZadaniaWTle')}</MenuLink>
              <MenuLink to={EDP_REMOVED_ELEMENTS}>{getLiteral('ElementyUsuniete')}</MenuLink>
              <MenuLink to={EDP_EVENTS_LOG}>{getLiteral('DziennikZdarzen')}</MenuLink>
              <MenuLink to={EDP_MIGRATION}>{getLiteral('MigracjaSzablonow')}</MenuLink>
              <MenuLink to={EDP_PULL_INSTALLATIONS}>{getLiteral('InstalacjeZmian')}</MenuLink>
              <MenuLink to={EDP_PULL_REQUESTS}>{getLiteral('AkceptacjaZmian')}</MenuLink>
              {canUseDocumentation.condition ? (
                <StyledAnchor href={EDP_PULL_DOCUMENTATION}>{getLiteral('Dokumentacja')}</StyledAnchor>
              ) : null}
            </Menu>
          )}
          {isComposerProForced() !== true && (
            <Menu title={getLiteral('Zarzadzanie')} isDefaultOpen={isOpenMenu}>
              <MenuLink
                to={'/articles/' + PAGE_LITE_VS_PRO}
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/articles/' + PAGE_LITE_VS_PRO);
                }}
              >
                {getLiteral('PorownajWersje')}
              </MenuLink>
              {composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO ? (
                <MenuLink
                  to="#deactivate"
                  onClick={(e) => {
                    e.preventDefault();
                    openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION);
                  }}
                >
                  {getLiteral('RezygnacjaZComposerPro')}
                </MenuLink>
              ) : (
                <MenuLink
                  to="#activate"
                  onClick={(e) => {
                    e.preventDefault();
                    openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_ACTIVATION);
                  }}
                >
                  {getLiteral('AktywujComposerPro')}
                </MenuLink>
              )}
            </Menu>
          )}
          <MarketingBannerAside />
        </>
      ) : null}
      {children}
      <UserLogged />
      {subscriptionDialog}
      {newCompositionDialog}
    </StyledAside>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 2rem;

  span {
    font-size: 2.2rem;
    font-weight: bold;
    margin-left: 2rem;
    & + span {
      margin-left: 0.6rem;
      font-size: 1rem;
      font-weight: bold;
      line-height: 0.9rem;
      padding: 0.5rem 1rem 0.7rem;
      border-radius: 0.3rem;
      &.lite {
        ${({ theme }) => css`
          background-color: ${theme.colors.TYPE_COLOR_LITE};
          color: var(--white);
        `}
      }
      &.pro {
        ${({ theme }) => css`
          background-color: ${theme.colors.TYPE_COLOR_PRO};
          color: var(--white);
        `}
      }
    }
  }
`;

const StyledAnchor = styled.a`
  font-size: 1.5rem;
  display: block;
  text-decoration: none;
  color: #000;
  position: relative;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &:hover {
    color: #999;
  }
`;

const StyledLogo = styled(Logo)`
  max-width: 40px;
`;

const StyledAside = styled.aside`
  width: 300px;
  min-width: 300px;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 499;
  border-right: 1px solid #d0d0d0;

  ${({ theme }) => css`
    background-color: ${theme.colors.ALT_COLOR};
  `}
`;
