/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import 'react-image-lightbox/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-image-lightbox';
import {
  faArrowsAltV as dragAndDropIcon,
  faCode,
  faEdit,
  faEye,
  faEyeSlash,
  faRetweet,
  faSync,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { SmallLoaderAfter } from './Loader';
import { DraggableModuleWrapper } from './ModuleWrapper';

import { ModuleReplace } from 'components/molecules/ModuleReplace';
import { templateCxt } from 'contexts/TemplateContext';
import { CopyComponent } from 'components/atoms/CopyComponent';
import { TypeLabel } from 'components/atoms/TypeLabel';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { useAccess } from 'helpers/access';
import {
  API_MODULES_START_EDIT,
  API_TEMPLATES_HIDE_MODULE,
  API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY,
  EDP_MODULES,
} from 'helpers/endpoints';
import { COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';
import { isComponentEditable } from 'helpers/isComponentEditable';
import { isRemovableComponent } from 'helpers/isRemovableComponent';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { TemplateAddModule } from 'components/molecules/TemplateAddModule';
import { Tag } from 'components/atoms/Tag';

export const Module = ({
  items,
  compositionId,
  compositionVersion,
  replaceModuleWrapperItemOnPage,
  addModuleToWrapperOnPage,
  name,
  id,
  group,
  groupDescription,
  editable,
  allowedActions,
  lastUpdateUser,
  lastUpdateTime,
  disabled,
  onRemove,
  hidden,
  changeHidden,
  replaceable,
  replaceModule,
  version,
  isRemovable,
  pageName,
  updateComponentCallback,
  isEnvelopeSection,
  position,
  ...props
}) => {
  const { isSupport } = useContext(userCtx);
  const { pathname, hash } = useLocation();
  const { createToast } = useContext(toastCtx);
  const { getLiteral, composerSubscriptionLevel } = useContext(composerCxt);
  const { replaceModuleInWrapper, addModuleToWrapper } = useContext(templateCxt);
  const { editView } = useContext(templateCxt);
  const canUserEditComponent = isComponentEditable({
    userCanEdit: editable,
    componentEditable: allowedActions.edit,
    validFunction: useAccess,
  });
  const canEditStandardComponents = useAccess('canEditStandardComponents');

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [hiddenStatus, setHiddenStatus] = useState(hidden);
  const [replaceModuleWrapperItem, setReplaceModuleWrapperItem] = useState({});
  const postModuleHidden = useFetch(API_TEMPLATES_HIDE_MODULE, 'POST');
  const postEditStandardComponent = useFetch(API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY, 'POST');
  const getStartComponentEdit = useFetch(`${API_MODULES_START_EDIT}${id}/version/${version}`, 'GET');
  const firstUpdate = useRef(true);
  const history = useHistory();

  const editComponent = () => {
    if (props.type === 'standard') {
      openDialog();
    } else if (props.isDraft === 'n') {
      getStartComponentEdit.execute();
    } else {
      history.push(`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`);
    }
  };

  const submitFunction = (name) => {
    postEditStandardComponent.execute({
      standardComponentId: id,
      standardComponentVersion: version,
      designId: compositionId,
      designVersion: props.designVersion,
      newComponentName: name,
    });
  };

  const { openDialog, dialog } = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: (
      <CopyComponent
        submitFunction={submitFunction}
        loading={postEditStandardComponent.loading.toString()}
        placeholder={name}
        type={props.type}
      />
    ),
  });

  const updateToLatestVersion = () => {
    if (updateComponentCallback) {
      const replaceModuleElement = {
        id,
        version,
        hidden: hiddenStatus,
      };

      const newModule = { id: props.recommendedId ? props.recommendedId : id };
      updateComponentCallback({ oldModule: replaceModuleElement, newModule: newModule, name: pageName });
    }
  };

  const moduleReplaceView = () => {
    return (
      <ModuleReplace
        type={replaceModuleWrapperItem.group}
        onClick={(module) => {
          if (replaceModuleWrapperItemOnPage) {
            replaceModuleWrapperItemOnPage({
              oldModule: replaceModuleWrapperItem,
              newModule: module,
              pageName,
              wrapperId: id,
              wrapperVersion: version,
            });
          } else {
            replaceModuleInWrapper({
              oldModule: replaceModuleWrapperItem,
              newModule: module,
              pageName,
              wrapperId: id,
              wrapperVersion: version,
            });
          }
          setReplaceModuleWrapperItem({});
        }}
        clear={() => setReplaceModuleWrapperItem({})}
        componentModel={props.model}
      />
    );
  };

  // After status change, send to endpoint
  useEffect(() => {
    if (!firstUpdate.current) {
      postModuleHidden.execute({ moduleId: id, status: hiddenStatus, compositionId });
    }
    firstUpdate.current = false;
  }, [hiddenStatus]);

  useEffect(() => {
    if (postModuleHidden.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postModuleHidden.error.message,
        context: 'error',
      });
    } else if (postModuleHidden.response && postModuleHidden.response.status === 'ok') {
      createToast({
        title: hiddenStatus === 'n' ? getLiteral('KomponentWidoczny') : getLiteral('KomponentNieWidoczny'),
        message: getLiteral('PomyslnieZapisanoDane'),
        context: 'success',
      });
    }
  }, [postModuleHidden.response, postModuleHidden.error]);

  useEffect(() => {
    if (postEditStandardComponent.error) {
      createToast({
        title: getLiteral('NieMoznaUtworzycKomponentu'),
        message: postEditStandardComponent.error.message,
        context: 'error',
      });
      return;
    }

    if (postEditStandardComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUtworzony'),
        message: `${getLiteral('PrzekierowanoDoEdycjiKomponentu')} ${postEditStandardComponent.response.id}`,
        context: 'success',
      });

      history.push(
        `${EDP_MODULES}${postEditStandardComponent.response.id}/version/${
          postEditStandardComponent.response.version
        }?link=${encodeURIComponent(pathname + hash)}`,
      );
    }
  }, [postEditStandardComponent.response, postEditStandardComponent.error]);

  useEffect(() => {
    if (getStartComponentEdit.response) {
      createToast({
        title: `${getLiteral('WersjaRoboczaZostalaUtworzona')}!`,
        message: `${getLiteral('JestesWTrybieEdycjiKomponentu')}.`,
        context: 'success',
      });
      history.push(
        `${EDP_MODULES}${getStartComponentEdit.response.id}/version/${
          getStartComponentEdit.response.version
        }?link=${encodeURIComponent(pathname + hash)}`,
      );
    } else if (getStartComponentEdit.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getStartComponentEdit.error.message,
        context: 'error',
      });
    }
  }, [getStartComponentEdit.response, getStartComponentEdit.error]);

  return (
    <StyledModule ref={props.innerRef || null} {...props} isDisabled={disabled.toString()} isHidden={hiddenStatus}>
      <StyledModuleWrapper model={props.model}>
        <StyledMoveIcon
          disabled={disabled || !isRemovableComponent(group, isSupport) || isEnvelopeSection}
          isDisabled={disabled.toString()}
          {...props.dragHandleProps}
        >
          <FontAwesomeIcon icon={dragAndDropIcon} />
        </StyledMoveIcon>
        <ComponentIconDiv>
          {props.image ? (
            <ComponentIconImg
              src={`/components/${props.image}`}
              onClick={() => setIsLightBoxOpen(true)}
            ></ComponentIconImg>
          ) : (
            <NoImageIcon icon={faCode} />
          )}
        </ComponentIconDiv>
        {isLightBoxOpen && (
          <Lightbox onCloseRequest={() => setIsLightBoxOpen(false)} mainSrc={`/components/${props.image}`} />
        )}
        <div>
          <Tag type={props.type}>{props.type}</Tag>
          {props.isDraft === 'y' ? <TypeLabel type="draft" text={getLiteral('wersjaRobocza')}></TypeLabel> : null}
          {!!props.recommendedId ? (
            <Tag
              type="deprecated"
              title={getLiteral(
                'KomponentNieJestJuzPrzezNasWpieranyAktualizacjaKomponentuSpowodujeWymianeNaKomponentRekomendowany',
              )}
            >
              {getLiteral('wycofywany')}
            </Tag>
          ) : (
            <>
              {props.isLatestVersion === 'n' && (
                <Tag
                  type="hasNeverVersion"
                  title={getLiteral('KomponentPosiadaNowszaWersjeZaktualizujKomponentAbyCieszycSieZNajnowszychZmian')}
                >
                  {getLiteral('doAktualizacji')}
                </Tag>
              )}
            </>
          )}
          {props.model === 'wrapper' && <Tag type="model">{getLiteral('Wrapper')}</Tag>}
          <StyledName>
            <NameElement>
              <SupportLink
                title={name}
                to={`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`}
              >
                {name}
              </SupportLink>
            </NameElement>
          </StyledName>
          <StyledAttribiutes>
            <span>
              {getLiteral('Grupa')}: <strong>{groupDescription ? groupDescription : group}</strong>,
            </span>
            <span>
              {getLiteral('Wersja')}: <strong>{`${version}`}</strong>,
            </span>
            <span>
              {getLiteral('Zaaktulizowano')}: <strong>{lastUpdateTime}</strong> {getLiteral('Przez')}{' '}
              <strong>{lastUpdateUser}</strong>
            </span>
          </StyledAttribiutes>
        </div>
        <ActionsWrapper>
          {!disabled && (props.isLatestVersion === 'n' || !!props.recommendedId) ? (
            <>
              <ActionButton
                title={getLiteral('AktualizujKomponent')}
                type="button"
                onClick={() => updateToLatestVersion()}
              >
                <FontAwesomeIcon icon={faSync} />
              </ActionButton>
            </>
          ) : null}
          {!disabled && isRemovable && canUserEditComponent && (
            <>
              <>
                {(props.clientReadOnly && props.clientReadOnly === 'n') || isSupport ? (
                  <ActionButton
                    loading={getStartComponentEdit.loading.toString()}
                    title={getLiteral('EdytujKomponent')}
                    type="button"
                    onClick={() => editComponent()}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </ActionButton>
                ) : null}
              </>
            </>
          )}
          {!disabled && editView && replaceable ? (
            <ActionButton
              title={getLiteral('WymienKomponent')}
              type="button"
              onClick={() =>
                replaceModule({
                  compositionId,
                  name,
                  id,
                  group,
                  lastUpdateUser,
                  lastUpdateTime,
                  disabled,
                  onRemove,
                  hidden,
                  changeHidden,
                  replaceable,
                  replaceModule,
                  version,
                  ...props,
                })
              }
            >
              <FontAwesomeIcon icon={faRetweet} />
            </ActionButton>
          ) : null}
          {!disabled && isEnvelopeSection === false ? (
            <ActionButton
              title={hiddenStatus === 'n' ? getLiteral('KomponentWidoczny') : getLiteral('KomponentNieWidoczny')}
              type="button"
              onClick={() => (hiddenStatus === 'n' ? setHiddenStatus('y') : setHiddenStatus('n'))}
            >
              {hiddenStatus === 'n' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
            </ActionButton>
          ) : null}
          {disabled && hiddenStatus === 'y' ? (
            <ActionButton title={getLiteral('KomponentNieWidoczny')} type="button" disabled={disabled}>
              <FontAwesomeIcon icon={faEyeSlash} />
            </ActionButton>
          ) : null}
          {!disabled &&
            editView &&
            (composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO ||
              canEditStandardComponents.condition ||
              isSupport) &&
            !isEnvelopeSection && (
              <ActionButton
                title={getLiteral('UsunKomponent')}
                type="button"
                onClick={() => {
                  onRemove(id, version, position, pageName);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </ActionButton>
            )}
        </ActionsWrapper>
      </StyledModuleWrapper>
      {dialog}
      {
        //poniżej logika odpowiedzialna za drag&drop potomków Wrappera, które obecnie dostępne są dla Supportu
      }
      {(props.model && props.model === 'wrapper' && props.clientReadOnly && props.clientReadOnly === 'n') ||
      (props.model && props.model === 'wrapper' && isSupport) ? (
        <Droppable droppableId={`${pageName}#${id}`} type="wrapperChildren">
          {(provided, snapshot) => (
            <Wrapper
              id={`${id}_wrapper_sub`}
              ref={provided.innerRef}
              {...provided.droppableProps}
              over={snapshot.isDraggingOver}
            >
              {props.components &&
                props.components.map((component, index) => (
                  <DraggableModuleWrapper
                    key={component.id}
                    index={index}
                    compositionId={compositionId}
                    compositionVersion={compositionVersion}
                    wrapperId={id}
                    wrapperVersion={version}
                    wrapperIsDraft={props.isDraft === 'y'}
                    disabled={disabled}
                    pageName={pageName}
                    replaceModule={(module) => setReplaceModuleWrapperItem(module)}
                    updateComponentCallback={
                      replaceModuleWrapperItemOnPage ? replaceModuleWrapperItemOnPage : replaceModuleInWrapper
                    }
                    {...component}
                    isWrapperHidden={hiddenStatus === 'y'}
                    onRemove={onRemove}
                  />
                ))}
              {provided.placeholder}
              {!disabled && props.isDraft === 'y' && (
                <TemplateAddModule
                  id={compositionId}
                  version={compositionVersion}
                  wrapperId={id}
                  page={props.siteType}
                  addModule={(module) =>
                    addModuleToWrapperOnPage
                      ? addModuleToWrapperOnPage({ module, container: pageName, wrapperId: id })
                      : addModuleToWrapper({ module, container: pageName, wrapperId: id })
                  }
                  disabled={hiddenStatus === 'y'}
                />
              )}
            </Wrapper>
          )}
        </Droppable>
      ) : null}
      {replaceModuleWrapperItem.group ? moduleReplaceView() : null}
    </StyledModule>
  );
};

Module.defaultProps = {
  isEnvelopeSection: false,
};

export const DraggableModule = ({ id, index, ...props }) => {
  const { isSupport } = useContext(userCtx);

  return (
    <Draggable index={index} draggableId={id.toString()} isDragDisabled={!isRemovableComponent(props.group, isSupport)}>
      {(provided) => (
        <Module
          items={props.components}
          innerRef={provided.innerRef}
          id={id}
          {...provided}
          {...provided.draggableProps}
          position={index + 1} //positions have indexes from 1 not from 0
          {...props}
        />
      )}
    </Draggable>
  );
};

const NameElement = styled.span`
  margin-right: 1.5rem;
`;

const NoImageIcon = styled(FontAwesomeIcon)`
  width: 50px !important;
  color: #2e2e2e;
  height: 50px;
`;

const ComponentIconDiv = styled.div`
  width: 200px;
  margin: 0 2rem;
  display: flex;
  justify-content: center;
`;

const ComponentIconImg = styled.img`
  height: fit-content;
  max-height: 150px;
  cursor: pointer;
`;

const SupportLink = styled(Link)`
  color: #000;
`;

const ActionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  min-width: 40px;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: #e0e0e0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #fafafa;
    `}

  ${({ withText }) =>
    withText &&
    css`
      padding: 0 1.5rem;

      span {
        margin-right: 1rem;
      }
    `}

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
      position: relative;

      ${SmallLoaderAfter}

      &:after {
        border-color: transparent #fff transparent #fff;
      }
    `}
`;

const StyledMoveIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  cursor: move;

  ${({ theme, disabled, isDisabled }) => css`
    border-right: 1px solid ${theme.colors.BG_BORDER_COLOR};

    ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}

    ${isDisabled === 'true' ? 'display: none;' : ''}
  `}
`;

const StyledName = styled.div`
  font-size: 1.8rem;
  margin: 0.5rem 0;

  br {
    display: none;
  }

  b,
  strong {
    font-weight: 600;
  }
`;

const StyledAttribiutes = styled.div`
  font-size: 1.3rem;
  color: #999;

  span {
    margin-right: 1rem;
  }
`;

const StyledModule = styled.div`
  width: 100%;
  min-height: 170px;
  background-color: #fafafa;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin-bottom: 0.5rem;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.BG_BORDER_COLOR};
  `}

  ${({ changed }) =>
    changed === true
      ? css`
          background-color: #ecf7f9;
          border-color: #0488e7;
          & > div:first-child {
            background-color: #ecf7f9;
            border-color: #0488e7;
          }
        `
      : ''}

  ${({ isDisabled }) =>
    isDisabled === 'true'
      ? css`
          padding: 0 1rem;
          min-height: 60px;
        `
      : ''}

  ${({ isHidden }) =>
    isHidden === 'y'
      ? css`
          opacity: 0.5;
        `
      : ''}
  
  ${({ model, theme }) =>
    model === 'wrapper'
      ? css`
          border: none;
          background-color: ${theme.colors.BG_COLOR};
          padding: 0;
        `
      : ''}
`;

const StyledModuleWrapper = styled.div`
  display: flex;
  height: 170px;
  width: 100%;
  align-items: center;
  position: relative;

  ${({ model, theme }) =>
    model === 'wrapper'
      ? css`
          border: 1px solid ${theme.colors.BG_BORDER_COLOR};
          background-color: #fafafa;
        `
      : ''}
`;

const Wrapper = styled.div`
  padding: 1rem 0 1rem 1rem;
  width: 100%;
  margin-left: 5rem;

  ${({ borderless }) =>
    borderless &&
    css`
      border: none;
      padding: 0;
    `}
`;
