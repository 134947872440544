import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { useFetch } from 'utils/useFetch';
import { TemplateListDialog } from 'components/organisms/TemplateListDialog';
import { CopyTemplateDialog } from 'components/organisms/CopyTemplateDialog';
import { API_TEMPLATES_CHECK_NANE, API_TEMPLATES_DUPLICATE, EDP_TEMPLATES } from 'helpers/endpoints';

export const NewProjectDialog = ({ openList, setOpenList }) => {
  const history = useHistory();

  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const [openForm, setOpenForm] = useState(false);
  const [srcTemplateId, setSrcTemplateId] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState(false);
  const [newTemplateDescription, setNewTemplateDescription] = useState(false);

  const postDuplicateTemplate = useFetch(API_TEMPLATES_DUPLICATE, 'POST');
  const postCheckUniqueName = useFetch(API_TEMPLATES_CHECK_NANE, 'POST');

  const callbackSetNameDescription = (name, description) => {
    if (name) {
      setNewTemplateName(name);
      setNewTemplateDescription(description);
    }
  };

  const callbackSetSrcTemplateId = (templateId) => {
    setSrcTemplateId(templateId);
    setOpenList(false);
    setOpenForm(true);
  };

  useEffect(() => {
    if (newTemplateName !== false) {
      if (newTemplateName === '') {
        createToast({
          title: getLiteral('NieMoznaSkopiowacKompozycji'),
          message: getLiteral('NiePodanoNazwyNowejKompozycji'),
          context: 'error',
        });
      } else {
        postCheckUniqueName.execute({ name: newTemplateName });
      }
    }
  }, [newTemplateName]);

  useEffect(() => {
    if (postCheckUniqueName.response) {
      if (postCheckUniqueName.error || !postCheckUniqueName.response.isUnique) {
        createToast({
          title: getLiteral('NieMoznaSkopiowacKompozycji'),
          message: getLiteral('NazwaKompozycjiMusiBycUnikalna'),
          context: 'error',
        });
      } else {
        postDuplicateTemplate.execute({
          id: srcTemplateId,
          description: newTemplateDescription,
          name: newTemplateName,
        });
      }
    }
  }, [postCheckUniqueName.response, postCheckUniqueName.error]);

  useEffect(() => {
    if (postDuplicateTemplate.error) {
      createToast({
        title: getLiteral('NieMoznaSkopiowacKompozycji'),
        message: postDuplicateTemplate.error.message,
        context: 'error',
      });
      return;
    }

    if (postDuplicateTemplate.response) {
      createToast({
        title: getLiteral('KompozycjaZostalaUtworzona'),
        message: `${getLiteral('PrzekierowanoDoKompozycji')} ${postDuplicateTemplate.response.id}`,
        context: 'success',
      });

      history.push(`${EDP_TEMPLATES}${postDuplicateTemplate.response.id}`);
    }
  }, [postDuplicateTemplate.response, postDuplicateTemplate.error]);

  return (
    <>
      <CopyTemplateDialog open={openForm} setOpen={setOpenForm} nextStep={callbackSetNameDescription} />
      <TemplateListDialog open={openList} setOpen={setOpenList} nextStep={callbackSetSrcTemplateId} />
    </>
  );
};
