/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Table } from 'components/atoms/Table';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { EDP_MODULES, API_MODULES_SEARCH } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const LibraryUsedInComponents = ({ id }) => {
  const { getLiteral } = useContext(composerCxt);
  const history = useHistory();

  const postLibraryUsedComponent = useFetch(API_MODULES_SEARCH, 'POST');

  useEffect(() => {
    postLibraryUsedComponent.execute({
      page: 1,
      sort: 'create-date',
      jsLibrary: id,
    });
  }, [id]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>{getLiteral('nazwa')}</th>
            <th>{getLiteral('Wersja')}</th>
            <th>{getLiteral('Grupa')}</th>
            <th>{getLiteral('Typ')}</th>
            <th>{getLiteral('Zaaktulizowano')}</th>
            <th>{getLiteral('Autor')}</th>
          </tr>
        </thead>
        <tbody>
          {postLibraryUsedComponent.response &&
            postLibraryUsedComponent.response.models.map((item) => (
              <tr
                key={`${item.id}_${item.version}`}
                onClick={() => history.push(`${EDP_MODULES}${item.id}/version/${item.version}`)}
              >
                <td>{item.name}</td>
                <td>{item.version}</td>
                <td>{item.group}</td>
                <td>
                  <Tag type={item.type}>{item.type}</Tag>
                  {item.isDraft === 'y' && (
                    <Tag type="draft" title={getLiteral('wersjaRobocza')}>
                      {getLiteral('wersjaRobocza')}
                    </Tag>
                  )}
                </td>
                <td>{item.lastModificationDate}</td>
                <td>{item.createUser}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {!postLibraryUsedComponent.response && !postLibraryUsedComponent.executed && (
        <NotAssigned>{getLiteral('BibliotekaNieJestPrzypisanaDoZadnegoKomponentu')}</NotAssigned>
      )}
    </>
  );
};

const NotAssigned = styled.div`
  font-weight: 700;
  text-align: center;
  margin-top: 3rem;
`;
