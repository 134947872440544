/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';
import { Button } from 'components/atoms/Button';
import { Input, Checkbox } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { API_MODULES_ADD, EDP_MODULES } from 'helpers/endpoints';
import { makeSelectDataOption } from 'helpers/makeSelectOption';
import { useFetch } from 'utils/useFetch';
import { SupportMark } from 'components/atoms/SupportMark';
import { useAccess } from 'helpers/access';
import { universalSiteData } from 'helpers/universalSiteData';

export const AddComponentDialog = ({ afterSubmit }) => {
  const { isSupport } = useContext(userCtx);
  const { getLiteral, composerSubscriptionLevel } = useContext(composerCxt);
  const { groups } = useContext(composerCxt);
  const postAddComponent = useFetch(`${API_MODULES_ADD}`, 'POST');
  const history = useHistory();
  const { createToast } = useContext(toastCtx);
  const canEditStandardComponents = useAccess('canEditStandardComponents');
  const [standardComponent, setStandardComponents] = useState(false);
  const [wrapperComponent, setWrapperComponents] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const groupsOptions = useMemo(() => {
    if (composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO || canEditStandardComponents.condition) {
      return groups.map((item) => makeSelectDataOption(item.id, `${item.description} (${item.id})`, { name: item.id }));
    }

    return groups
      .filter((item) => item.siteId !== universalSiteData.siteId)
      .map((item) => makeSelectDataOption(item.id, `${item.description} (${item.id})`, { name: item.id }));
  }, [groups]);

  const onSubmit = (data) => {
    if (!data.group) {
      data.group = groupsOptions[0].value;
    }

    data.type = standardComponent ? 'standard' : 'custom';
    data.model = wrapperComponent ? 'wrapper' : 'component';
    postAddComponent.execute(data);
  };

  useEffect(() => {
    if (postAddComponent.response && postAddComponent.response.id) {
      if (afterSubmit) {
        afterSubmit();
      }
      history.push(`${EDP_MODULES}${postAddComponent.response.id}/version/${postAddComponent.response.version}`);
    } else if (postAddComponent.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postAddComponent.error.message,
        context: 'error',
      });
    }
  }, [postAddComponent.response, postAddComponent.error]);
  register('group');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register({ required: true })}
        name="name"
        title={getLiteral('NazwaKomponentu')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
      />

      <Select
        placeholder={getLiteral('TypKomponentu')}
        defaultValue={groupsOptions ? groupsOptions[0] : null}
        options={groupsOptions}
        onChange={(item) => setValue('group', item.value)}
      ></Select>

      {(canEditStandardComponents.condition || isSupport) && (
        <Checkbox
          checked={standardComponent}
          bottom="2"
          title={
            <>
              {getLiteral('KomponentStandardowy')}
              <SupportMark />
            </>
          }
          onChange={() => setStandardComponents((state) => !state)}
        />
      )}
      {(composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO ||
        canEditStandardComponents.condition ||
        isSupport) && (
        <Checkbox
          checked={wrapperComponent}
          bottom="2"
          title={getLiteral('Wrapper')}
          onChange={() => setWrapperComponents((state) => !state)}
        />
      )}

      <Button loading={postAddComponent.loading.toString()} type="submit">
        {getLiteral('Dodaj')}
      </Button>
    </form>
  );
};
