/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { API_GFX_GET, API_GFX_REMOVE, API_GFX_ADD } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { Content, Layout, Container, Col, Row, Marginer } from 'components/atoms/Grid';
import { ManagerFileListItem } from 'components/atoms/ManagerFileListItem';
import { SortingComponent } from 'components/atoms/SortingComponent';
import { Header } from 'components/molecules/Header';
import { MarketingBannerTop } from 'components/molecules/MarketingBannerTop';
import { Aside } from 'components/organisms/Aside';
import { ReactComponent as DownloadIcon } from 'data/icon-download.svg';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { useAccess } from 'helpers/access';
import { Checkbox } from 'components/atoms/Form';
import { SupportMark } from 'components/atoms/SupportMark';
import { usePagination } from 'utils/usePagination';
import { DropField } from 'components/atoms/DropField';
import { Loader } from 'components/atoms/Loader';
import { base64replace } from 'helpers/base64helper';

const FileManagerPage = () => {
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const cadEditStandardFiles = useAccess('canEditStandardFiles');
  const [pagesCount, setPagesCount] = useState(0);

  const [files, setFiles] = useState([]);
  const [types, setTypes] = useState([]);
  const [filters, setFilters] = useState({
    text: '',
    type: '',
    sort: 'date',
    standardFiles: false,
  });

  const getClientFiles = useFetch(API_GFX_GET, 'POST');
  const postClientFileRemove = useFetch(API_GFX_REMOVE, 'POST');
  const postClientFileAdd = useFetch(API_GFX_ADD, 'POST');
  const searchTimeoutRef = useRef();

  const { page, paginationProps, Pagination, resetPage } = usePagination({
    pageLimit: pagesCount,
  });

  // Getting files after component mount
  useEffect(() => {
    if (!filters.text) {
      getClientFiles.execute({
        page: page,
        standardFiles: filters.standardFiles,
        sort: filters.sort,
        name: filters.text,
      });
    } else {
      clearTimeout(searchTimeoutRef.current);
      searchTimeoutRef.current = setTimeout(
        () =>
          getClientFiles.execute({
            page: page,
            standardFiles: filters.standardFiles,
            sort: filters.sort,
            name: filters.text,
          }),
        500,
      );
    }
  }, [filters.standardFiles, page, filters.sort, filters.text]);

  useEffect(() => {
    if (getClientFiles.response) {
      setFiles(getClientFiles.response.models);
      setPagesCount(getClientFiles.response.pagesCount);

      // Creating set of uniqe extension names
      const uniqeTypesList = new Set(
        getClientFiles.response.models.map((item) => item.name.split('.').at(-1)?.toLowerCase()),
      );

      // Building select option based on unique set
      // if someone of item in set is undefined, function change it into value "without extension"
      setTypes(
        [...uniqeTypesList].map((item) => makeSelectOption(item || 'none', item || getLiteral('BezRozszerzenia'))),
      );
    }
  }, [getClientFiles.response]);

  useEffect(() => {
    if (postClientFileRemove.response) {
      createToast({
        title: getLiteral('PoprawnieUsunietoPlik'),
        context: 'success',
        delay: 4,
      });
    } else if (postClientFileRemove.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        context: 'error',
        message: postClientFileRemove.error.message,
      });

      setFiles((state) =>
        state.map((el) => {
          el.isLoading = false;

          return el;
        }),
      );
    }
  }, [postClientFileRemove.response, postClientFileRemove.error]);

  useEffect(() => {
    if (postClientFileAdd.response) {
      createToast({
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        context: 'success',
        message: getLiteral('PrzeslanyPlikZostalZapisany'),
        delay: 4,
      });

      setFiles((state) => [
        postClientFileAdd.response,
        ...state.filter((file) => file.name !== postClientFileAdd.response.name),
      ]);
    }
  }, [postClientFileAdd.response, postClientFileAdd.error]);

  /**
   * Add new file into manager
   * @param {string} base64 value from file reader
   * @param {File} file file API object
   */
  const addFile = (base64, { name, type }) => {
    postClientFileAdd.execute({
      gfx: name,
      standardFiles: filters.standardFiles,
      gfxContent: base64replace(base64),
    });
  };

  /**
   * Remove linking to the client's file
   * @param {string} name id of removing file
   */
  const removeFile = (name) => {
    postClientFileRemove.execute({ gfx: name, standardFiles: filters.standardFiles }, () =>
      setFiles((state) => state.filter((file) => file.name !== name)),
    );

    setFiles((state) =>
      state.map((el) => {
        el.isLoading = false;
        if (el.name === name) {
          el.isLoading = true;
        }

        return el;
      }),
    );
  };

  return (
    <>
      <Layout>
        <Aside />
        <Content>
          <Container>
            <MarketingBannerTop />
            <WhiteBackground>
              <Header
                title={getLiteral('ManagerPlikow')}
                children={
                  <>
                    {cadEditStandardFiles.condition && (
                      <Marginer top={1}>
                        <Checkbox
                          checked={filters.standardFiles}
                          bottom="0"
                          title={
                            <>
                              {getLiteral('PlikiStandardowe')}
                              <SupportMark />
                            </>
                          }
                          onChange={() => {
                            resetPage();
                            setFilters((state) => ({ ...state, standardFiles: !state.standardFiles }));
                          }}
                        />
                      </Marginer>
                    )}
                  </>
                }
              />
              <StickySortingComponent
                setTextFilter={(value) => {
                  resetPage();
                  setFilters((state) => ({ ...state, text: value }));
                }}
                setTypeFilter={(value) => setFilters((state) => ({ ...state, type: value }))}
                setSortFilter={(value) => {
                  resetPage();
                  setFilters((state) => ({ ...state, sort: value }));
                }}
                typeTitle={getLiteral('SzukajPoTypie')}
                typeOptions={types}
              />
              <Row>
                {(getClientFiles.loading || !getClientFiles.response) && <Loader />}
                {!getClientFiles.loading && getClientFiles.response && (
                  <>
                    <Col size={3} style={{ position: 'relative' }}>
                      {postClientFileAdd.loading && <Loader relative />}
                      <DropField
                        onBaseRender={addFile}
                        title={
                          <DropFileTitle>
                            <DownloadIcon />
                            <strong>{getLiteral('PrzeciagnijTutaj')}</strong>
                            <span>{getLiteral('lubKliknijTutajAbyDodacNowyPlik')}</span>
                          </DropFileTitle>
                        }
                        titleOnDragOver={
                          <DropFileTitle>
                            <DownloadIcon />
                            <strong>{getLiteral('PrzeciagnijTutaj')}</strong>
                            <span>{getLiteral('lubKliknijTutajAbyDodacNowyPlik')}</span>
                          </DropFileTitle>
                        }
                        styles={{
                          height: 'calc(100% - 30px)',
                          minHeight: '300px',
                          opacity: postClientFileAdd.loading ? 0.5 : 1,
                        }}
                      />
                    </Col>
                    {files
                      .filter(({ name }) => {
                        if (filters.type === 'none' && name.split('.').length === 1) return true;
                        if (filters.type && !name.includes(`.${filters.type}`)) return false;

                        return true;
                      })
                      .map((file) => (
                        <Col size={3} key={file.name}>
                          <ManagerFileListItem {...file} loader={file.isLoading} removeFile={removeFile} />
                        </Col>
                      ))}
                  </>
                )}
              </Row>
              <Marginer bottom={1}>{pagesCount ? <Pagination {...paginationProps} /> : null}</Marginer>
            </WhiteBackground>
          </Container>
        </Content>
      </Layout>
    </>
  );
};

const DropFileTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 2rem;
  }

  strong {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }

  > span {
    font-size: 1.3rem;
  }
`;

const StickySortingComponent = styled(SortingComponent)`
  position: sticky;
  top: 75px;
  padding: 1rem 0;
  background-color: #fff;
  z-index: 19;
`;

export default FileManagerPage;

const WhiteBackground = styled.div`
  background: #fff;
  z-index: 1;
`;
