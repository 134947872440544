/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useRef } from 'react';
import { sprintf } from 'sprintf-js';
import { useLocation } from 'react-router';

import { CopyComponent } from 'components/atoms/CopyComponent';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { Loader } from 'components/atoms/Loader';
import { Editor } from 'components/molecules/Editor';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_TEMPLATES_GET_JS, API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY, EDP_MODULES } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { useDialog } from 'utils/useDialog';
import useOnScreen from 'utils/useOnScreen';

export const TemplateJsCodeLang = ({ language }) => {
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const { id, version, isDraft } = useContext(templateCxt);
  const [currentComponent, setCurrentComponent] = useState({});

  const [jsCode, setJsCode] = useState('');
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const getJsCode = useFetch(`${API_TEMPLATES_GET_JS}${id}/version/${version}/language/${language.value}`, 'GET');
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const { pathname, hash } = useLocation();

  const postEditStandardComponent = useFetch(API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY, 'POST');

  const submitFunction = (name) => {
    postEditStandardComponent.execute({
      standardComponentId: currentComponent.id,
      standardComponentVersion: currentComponent.version,
      designId: id,
      designVersion: version,
      newComponentName: name,
    });
  };

  const { openDialog, dialog, closeDialog } = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: (
      <CopyComponent
        submitFunction={submitFunction}
        placeholder={currentComponent.name}
        loading={postEditStandardComponent.loading.toString()}
        type="standard"
      />
    ),
  });

  const goToComponent = (editor, edit) => {
    const regex = /\/\*! AssetManager Container type: "([^"]*)", Component type: "([^"]*)", Component id: "([^"]*)", Component version: "([^"]*)", Component variant: "([^"]*)", Component name: (.*) \*\//;

    const currline = editor.getSelectionRange().start.row;
    const textValue = editor.getValue();
    let upperLines = '';
    if (textValue) {
      upperLines = textValue.split('\n').slice(0, currline + 1);

      for (let i = upperLines.length - 1; i >= 0; i--) {
        const match = upperLines[i].match(regex);
        if (match && match[1]) {
          const id = match[3];
          const version = match[4] ?? 1;
          const type = match[5] ?? 'standard';
          const name = match[6] ?? '';
          if (!id) {
            break;
          }
          if (edit) {
            if (type === 'custom' || type === 'individual') {
              window.open(
                `${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`,
                '_blank',
              );
            } else {
              setCurrentComponent({
                id,
                version,
                type,
                name,
              });
              openDialog();
            }
          } else {
            window.open(`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`, '_blank');
          }

          break;
        }
      }
    }
  };

  const commands = [
    {
      name: 'goToComponent',
      bindKey: { win: 'Ctrl-P', mac: 'Command-P' },
      exec: (editor) => goToComponent(editor, false),
      readOnly: true,
    },
  ];

  if (isDraft === 'y') {
    commands.push({
      name: 'editComponent',
      bindKey: { win: 'Ctrl-M', mac: 'Command-M' },
      exec: (editor) => goToComponent(editor, true),
      readOnly: true,
    });
  }

  useEffect(() => {
    setIsComponentVisible(isVisible);
    if (language.value && isVisible === true) {
      getJsCode.execute();
    }
  }, [isVisible, language]);

  useEffect(() => {
    if (getJsCode.response) {
      setJsCode(getJsCode.response.content);
    } else if (getJsCode.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getJsCode.error.message,
        context: 'error',
      });
    }
  }, [getJsCode.response, getJsCode.error]);

  useEffect(() => {
    if (postEditStandardComponent.error) {
      createToast({
        title: getLiteral('NieMoznaUtworzycKomponentu'),
        message: postEditStandardComponent.error.message,
        context: 'error',
      });
      return;
    }

    if (postEditStandardComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUtworzony'),
        message: `${getLiteral('PrzekierowanoDoEdycjiKomponentu')} ${postEditStandardComponent.response.id}`,
        context: 'success',
      });

      window.open(
        `${EDP_MODULES}${postEditStandardComponent.response.id}/version/${
          postEditStandardComponent.response.version
        }?link=${encodeURIComponent(pathname + hash)}`,
        '_blank',
      );

      // setRefreshCss((state) => !state);
      closeDialog();
    }
  }, [postEditStandardComponent.response, postEditStandardComponent.error]);

  return (
    <div ref={ref}>
      {getJsCode.loading || isComponentVisible === false ? (
        <Loader />
      ) : (
        <>
          <InfoLabel
            text={sprintf(getLiteral('PonizejZnajdujeSieAktualnaZawartoscKoduJSDlaJezyka'), language.label, 'ctrl + M')}
          />
          <Editor mode="javascript" value={jsCode} readOnly={true} commands={commands} refreshCommands={id} />
          {dialog}
        </>
      )}
    </div>
  );
};
