/* eslint-disable react-hooks/exhaustive-deps */
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { API_PAGES_ALLOWED_TYPES } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { Loader } from 'components/atoms/Loader';
import { Col, Row } from 'components/atoms/Grid';
import { AddButton } from 'components/atoms/Button';
import { CloseButton, ModuleReplace, Scroller, Toplayer, ToplayerHeading } from 'components/molecules/ModuleReplace';

export const TemplateAddModule = ({ page, type, addModule, moduleData, id, version, disabled }) => {
  const { createToast } = useContext(toastCtx);
  const [typeName, setTypeName] = useState(null);
  const [modulesTypes, setModulesTypes] = useState([]);
  const [modules, setModules] = useState([]);
  const postAllowedTypes = useFetch(API_PAGES_ALLOWED_TYPES, 'POST');
  const { getLiteral } = useContext(composerCxt);

  // Get types for page
  useEffect(() => {
    if (postAllowedTypes.response && !postAllowedTypes.response.filter((type) => !type.isUsed).length) {
      createToast({
        title: getLiteral('KomponentNieIstnieje'),
        message: getLiteral('NieIstniejeWiecejTypowKomponentowDlaTejPodstrony'),
        context: 'error',
        delay: 5,
      });
    }
    if (postAllowedTypes.response) {
      setModulesTypes(postAllowedTypes.response.filter((type) => !type.isUsed));
    }
  }, [postAllowedTypes.response]);

  const getModulesToAdd = () => {
    const sortedModules = modulesTypes.sort((a, b) => {
      return a.isUsed - b.isUsed;
    });

    return sortedModules.map((type) => (
      <ColMargin size={4} key={type.id}>
        <ElementSelector used={type.isUsed.toString()}>
          <div>
            <h4 dangerouslySetInnerHTML={{ __html: type.name }} />
            <PreWrapped dangerouslySetInnerHTML={{ __html: type.description }} />
            <PreWrapped dangerouslySetInnerHTML={{ __html: '(' + type.id + ')' }} />
          </div>
          <SelectButton
            type="button"
            disabled={type.isUsed === true ? 1 : 0}
            onClick={() => {
              if (typeName !== type.id) {
                setTypeName(type.id);
                setModules([]);
              }
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </SelectButton>
        </ElementSelector>
      </ColMargin>
    ));
  };

  return (
    <>
      <Wrapper>
        <AddButton
          type="button"
          onClick={() => postAllowedTypes.execute({ page, compositionId: id, version, skipEmpty: true })}
          loading={postAllowedTypes.loading ? 1 : 0}
          disabled={disabled}
        >
          <span>{getLiteral('DodajKomponent')}</span>
        </AddButton>
      </Wrapper>
      {modulesTypes.length && !modules.length ? (
        <Toplayer>
          <ToplayerHeading>
            <h2>{getLiteral('WyborKomponentu')}</h2>
            <CloseButton type="button" onClick={() => setModulesTypes([])}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
          </ToplayerHeading>
          <Scroller>
            <RowMargin>{getModulesToAdd()}</RowMargin>
          </Scroller>
        </Toplayer>
      ) : null}
      {typeName ? (
        <ModuleReplace
          type={typeName}
          onClick={(module) => {
            addModule(moduleData ? module : module.id);
            setModules([]);
            setModulesTypes([]);
            setTypeName(null);
          }}
          clear={() => setTypeName(null)}
        />
      ) : null}
      {postAllowedTypes.loading ? <Loader relative /> : null}
    </>
  );
};

const PreWrapped = styled.pre`
  margin-top: 0.3rem;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const RowMargin = styled(Row)`
  margin: 0 -0.5rem;
`;

const ColMargin = styled(Col)`
  margin-bottom: 1rem;
  padding: 0 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 60px;
  z-index: 99;
`;

const ElementSelector = styled.div`
  border: 1px solid #e0e0e0;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  height: 100%;

  ${({ used }) =>
    used === 'true'
      ? css`
          background-color: #f0f0f0;
          border-color: #d0d0d0;

          button {
            background-color: #d0d0d0 !important;
            color: #fff;
          }
        `
      : css`
          button {
            background-color: #0488e7 !important;
            color: #fff;
          }
        `}

  div {
    padding-right: 1rem;
  }

  h4 {
    font-size: 1.6rem;
    margin-bottom: 1rem;

    b,
    strong {
      font-weight: 600;
    }
  }

  pre {
    font-size: 1.3rem;
  }
`;

const SelectButton = styled.button`
  border-radius: 100%;
  border: none;
  background-color: #e0e0e0;
  min-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  font-size: 1.6rem;
`;
