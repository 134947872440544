export const textContent = (elem) => {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }

  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join('');
  }
  return textContent(children);
};
