/* eslint-disable react-hooks/exhaustive-deps */
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Group, Input } from 'components/atoms/Form';
import { Marginer } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { RowButton, StyledLink, Table } from 'components/atoms/Table';
import { composerCxt } from 'contexts/ComposerContext';
import { useAccess } from 'helpers/access';
import {
  API_INSTALLATIONS_CHANGE_DATE,
  API_INSTALLATIONS_COMPONENTS,
  API_INSTALLATIONS_DESIGNS,
  API_INSTALLATIONS_EDIT,
  EDP_MODULES,
  EDP_TEMPLATES,
} from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';
import { Select } from 'components/atoms/Form/Select';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { toastCtx } from 'contexts/ToastContext';

export const ITEM_TYPE = {
  COMPONENT: 'component',
  DESIGN: 'design',
};

export const PendingItems = ({ type }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const canChangeInstallationDates = useAccess('canChangeInstallationDates');

  const [pagesCount, setPagesCount] = useState(0);
  const [history, setHistory] = useState([]);

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const endpoint = type === ITEM_TYPE.COMPONENT ? API_INSTALLATIONS_COMPONENTS : API_INSTALLATIONS_DESIGNS;

  const getPendingItems = useFetch(`${endpoint}/page/${page}`, 'GET');
  const updateBranchInstallationDate = useFetch(`${API_INSTALLATIONS_CHANGE_DATE}`, 'POST');

  const changeInstallationDate = useDialog({
    title: getLiteral('ZmienDateInstalacji'),
    content: <ChangeInstallationDateDialog updateBranchInstallationDate={updateBranchInstallationDate} type={type} />,
  });

  useEffect(() => {
    getPendingItems.execute();
  }, [page]);

  useEffect(() => {
    if (getPendingItems.response) {
      setHistory(getPendingItems.response.models);
      setPagesCount(getPendingItems.response.pagesCount);
    }
  }, [getPendingItems.response, getPendingItems.error]);

  useEffect(() => {
    if (updateBranchInstallationDate.response) {
      getPendingItems.execute();
      changeInstallationDate.closeDialog();
      createToast({
        title: getLiteral('zapisanoZmiany'),
        context: 'success',
      });
    } else if (updateBranchInstallationDate.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: updateBranchInstallationDate.error.message,
        context: 'error',
      });
    }
  }, [updateBranchInstallationDate.response, updateBranchInstallationDate.error]);

  return (
    <>
      {getPendingItems.loading ? (
        <Loader />
      ) : (
        <>
          {history.length > 0 ? (
            <>
              {canChangeInstallationDates.condition ? (
                <>
                  <Marginer bottom={1}>
                    <Button onClick={changeInstallationDate.openDialog}>{getLiteral('ZmienDateInstalacji')}</Button>
                  </Marginer>
                  {changeInstallationDate.dialog}
                </>
              ) : null}

              <Table>
                <thead>
                  <tr>
                    <th>{getLiteral('nazwa')}</th>
                    <th>{getLiteral('OpisZmian')}</th>
                    <th>{getLiteral('Autor')}</th>
                    <th>{getLiteral('Alfa')}</th>
                    <th>{getLiteral('Beta')}</th>
                    <th>{getLiteral('Go')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((el) => (
                    <ItemRow key={`${el.id}_${el.version}`} {...el} refreshList={getPendingItems} type={type} />
                  ))}
                </tbody>
              </Table>
              <Marginer bottom={2} top={2}>
                {pagesCount ? <Pagination {...paginationProps} /> : null}
              </Marginer>
            </>
          ) : (
            <span>{getLiteral('BrakKomponentowOczekujacychNaInstalacje')}</span>
          )}
        </>
      )}
    </>
  );
};

const ChangeInstallationDateDialog = ({ updateBranchInstallationDate, type }) => {
  const { getLiteral } = useContext(composerCxt);
  const options = useMemo(() => {
    return [
      makeSelectOption('alfa', getLiteral('Alfa')),
      makeSelectOption('beta', getLiteral('Beta')),
      makeSelectOption('go', getLiteral('Go')),
    ];
  }, []);

  const [branchName, setBranchName] = useState(options[0].value);
  const [oldDate, setOldDate] = useState(today);
  const [newDate, setNewDate] = useState(today);

  const saveDates = () => {
    if (branchName && oldDate && newDate) {
      updateBranchInstallationDate.execute({ branchName, oldDate, newDate, type });
    }
  };

  return (
    <Group>
      <Select
        defaultValue={options[0]}
        placeholder={getLiteral('BranchKlienta')}
        options={options}
        onChange={(event) => setBranchName(event.value)}
      />
      <Input
        type="date"
        name="oldDate"
        title={getLiteral('AktaulnaDataInstalacji')}
        min={today}
        value={oldDate}
        onChange={(event) => setOldDate(event.target.value)}
      />

      <Input
        type="date"
        name="oldDate"
        title={getLiteral('NowaDataInstalacji')}
        min={today}
        value={newDate}
        onChange={(event) => setNewDate(event.target.value)}
      />
      <Button onClick={saveDates} loading={updateBranchInstallationDate.loading.toString()}>
        {getLiteral('ZapiszZmiany')}
      </Button>
    </Group>
  );
};

const ItemRow = ({ id, version, name, commitMessage, createUser, alfa, beta, go, refreshList, type }) => {
  const { getLiteral } = useContext(composerCxt);
  const canChangeInstallationDates = useAccess('canChangeInstallationDates');
  const editComponentDates = useFetch(API_INSTALLATIONS_EDIT, 'POST');
  const { pathname, hash } = useLocation();

  const changeComponentDates = useDialog({
    title: getLiteral('ZmianaDatInstalacji'),
    content: (
      <ItemDates
        alfaDate={alfa}
        betaDate={beta}
        goDate={go}
        id={id}
        version={version}
        saveFetch={editComponentDates}
        type={type}
      />
    ),
  });

  useEffect(() => {
    if (editComponentDates.response) {
      changeComponentDates.closeDialog();
      refreshList.execute();
    }
  }, [editComponentDates.response, editComponentDates.error]);

  const itemLink = useMemo(
    () =>
      `${type === ITEM_TYPE.COMPONENT ? EDP_MODULES : EDP_TEMPLATES}${id}/${
        type === ITEM_TYPE.COMPONENT ? 'version' : ''
      }/${version}?link=${encodeURIComponent(pathname + hash)}`,
    [type, pathname, hash],
  );

  return (
    <tr>
      <td>
        <StyledLink to={itemLink}>link</StyledLink>
        {`${name} v.${version}`}
      </td>
      <td>{commitMessage}</td>
      <td>{createUser}</td>
      <FixedColumn>{alfa}</FixedColumn>
      <FixedColumn>{beta}</FixedColumn>
      <FixedColumn>{go}</FixedColumn>
      {canChangeInstallationDates.condition ? (
        <>
          <td>
            <RowButton onClick={() => changeComponentDates.openDialog()}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </RowButton>
          </td>
          {changeComponentDates.dialog}
        </>
      ) : null}
    </tr>
  );
};

const ItemDates = ({ id, version, alfaDate, betaDate, goDate, saveFetch, type }) => {
  const { getLiteral } = useContext(composerCxt);
  const [alfa, setAlfa] = useState(alfaDate);
  const [beta, setBeta] = useState(betaDate);
  const [go, setGo] = useState(goDate);

  const saveDates = () => {
    saveFetch.execute({
      id: id,
      version: version,
      type: type,
      alfa: alfa,
      beta: beta,
      go: go,
    });
  };

  return (
    <Group>
      <Input
        type="date"
        name="alfa"
        title={getLiteral('Alfa')}
        value={alfa}
        min={today}
        onChange={(event) => setAlfa(event.target.value)}
      />
      <Input
        type="date"
        name="beta"
        title={getLiteral('Beta')}
        min={alfa}
        value={beta}
        onChange={(event) => setBeta(event.target.value)}
      />
      <Input
        type="date"
        name="go"
        title={getLiteral('Go')}
        min={beta}
        value={go}
        onChange={(event) => setGo(event.target.value)}
      />
      <Button loading={saveFetch.loading.toString()} onClick={saveDates}>
        {getLiteral('ZapiszZmiany')}
      </Button>
    </Group>
  );
};

const today = () => new Date().toISOString().split('T')[0];
export const FixedColumn = styled.td`
  white-space: nowrap;
`;
