import styled, { css } from 'styled-components';

export const InfoLabel = ({ type, text, marginBottom = true }) => {
  return (
    <Wrapper type={type} marginBottom={marginBottom.toString()}>
      <span>{text}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-style: solid;
  border-width: 1px;
  padding: 1rem;

  ${({ marginBottom }) =>
    marginBottom === 'true'
      ? css`
          margin-bottom: 20px;
        `
      : ''}

  width: 100%;

  ${({ type }) => {
    switch (type) {
      case 'danger':
        return css`
          color: #842029;
          background-color: #f8d7da;
          border-color: #f5c2c7;
        `;
      case 'success':
        return css`
          color: #0f5132;
          background-color: #d1e7dd;
          border-color: #badbcc;
        `;
      case 'warning':
        return css`
          color: #664d03;
          background-color: #fff3cd;
          border-color: #ffecb5;
        `;
      default:
        return css`
          color: #0b2f50;
          background-color: #bed9ee;
          border-color: #8dd3ef;
        `;
    }
  }}
`;
