import { forwardRef } from 'react';
import styled from 'styled-components';

import { Group } from './';

export const Toggler = forwardRef(({ title, titleChecked, column, disabled, ...props }, ref) => {
  return (
    <Group mb={0}>
      <ChecboxLabel>
        <input type="checkbox" ref={ref} {...props} disabled={disabled} />
        <strong></strong>
      </ChecboxLabel>
    </Group>
  );
});

const ChecboxLabel = styled.label`
  display: block;

  input {
    display: none;

    &:checked + strong {
      justify-content: flex-start;
      background-color: #52b01a;
      color: #fff;

      .checked {
        display: block;
      }
      .default {
        display: none;
      }
      &:after {
        transform: translate(26px, -50%);
        background-color: #fff;
      }
    }
  }

  strong {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;
    margin-right: 1.5rem;
    padding: 0 1rem;
    width: 50px;
    height: 24px;
    background-color: #e0e0e0;
    border-radius: 20px;
    transition: background-color 0.2s;

    .checked {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 100%;
      background-color: #b0b0b0;
      transition: transform 0.2s, background-color 0.2s;
    }
  }
`;
