/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { Col, Marginer, Row } from 'components/atoms/Grid';
import { ModuleListItem } from 'components/atoms/ModuleListItem';
import { usePagination } from 'utils/usePagination';
import { useFetch } from 'utils/useFetch';
import { API_MODULES_BY_TYPE } from 'helpers/endpoints';
import { Loader } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';

export const ModuleReplace = ({ type, onClick, clear, componentModel }) => {
  const { getLiteral } = useContext(composerCxt);
  const [modules, setModules] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getAvailableModules = useFetch(`${API_MODULES_BY_TYPE}${type || ''}/page/${page}`, 'GET');

  useEffect(() => {
    if (getAvailableModules.response) {
      let availableModulesWithoutWrapperModules;

      if (componentModel === 'wrapper') {
        availableModulesWithoutWrapperModules = getAvailableModules.response.models.filter(
          ({ model }) => model !== componentModel,
        );
      }

      setModules(availableModulesWithoutWrapperModules ?? getAvailableModules.response.models);
      setPagesCount(getAvailableModules.response.pagesCount);
    } else if (getAvailableModules.error) {
      clear();
    }
  }, [getAvailableModules.response, getAvailableModules.error]);

  useEffect(() => {
    if (type) {
      getAvailableModules.execute();
    }
  }, []);

  return (
    <>
      <Toplayer>
        <ToplayerHeading>
          <h2>{getLiteral('WymianaKomponentu')}</h2>
          <CloseButton type="button" onClick={() => clear()}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </ToplayerHeading>
        <Scroller>
          {modules.length ? (
            <>
              <RowMargin>
                {modules.map((module) => (
                  <ColMargin size={3} key={module.id}>
                    <ModuleListItem
                      {...module}
                      onClickEvent={() => onClick(module)}
                      onClickTitle={getLiteral('WymienKomponent')}
                    />
                  </ColMargin>
                ))}
              </RowMargin>
              <Marginer bottom={2} top={2}>
                {pagesCount ? <Pagination {...paginationProps} /> : null}
              </Marginer>
            </>
          ) : (
            <Loader></Loader>
          )}
        </Scroller>
      </Toplayer>
    </>
  );
};

const RowMargin = styled(Row)`
  margin: 0 -0.5rem;
`;

export const ColMargin = styled(Col)`
  margin-bottom: 3rem;
`;

export const ToplayerHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h2 {
    font-size: 2rem;
    font-weight: 600;
  }
`;

export const CloseButton = styled.button`
  background-color: #fff;
  border: none;
  padding: 0;
  font-size: 2.6rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Scroller = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 160px);
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 900px;
  display: flex;
  flex-direction: column;
`;

export const Toplayer = styled.div`
  position: fixed;
  padding: 3rem;
  top: 0;
  left: 300px;
  right: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 99999;
`;
