import { useContext } from 'react';

import { COMPOSER_SUBSCRIPTION_LEVEL_LITE, COMPOSER_SUBSCRIPTION_LEVEL_PRO } from './composerVersion';

import { userCtx } from 'contexts/UserContext';
import { composerCxt } from 'contexts/ComposerContext';

export const USER_PERM_ADMIN = 'admin';
export const USER_PERM_SUPP = 'support';
export const USER_PERM_DEF = 'user';

// Hook checking if user can access supplies
export const useAccess = (perm) => {
  const user = useContext(userCtx);

  if (!perm) {
    return {
      condition: true,
      checkCondition: () => true,
      AccessRoute: ({ children }) => <>{children}</>,
    };
  }

  // Check user has access to the module
  const checkCondition = (perms) =>
    user.type === 'iai' &&
    Object.keys(user?.supportPrivileges || {}).find((el) => el === perms) &&
    user.supportPrivileges[perms];

  return {
    condition: checkCondition(perm),
    checkCondition,
    AccessRoute: ({ children }) => <>{checkCondition(perm) ? children : null}</>,
  };
};

export const UserCanEditComposition = (compositionType, standardEdition) => {
  const user = useContext(userCtx);
  const composerSubscriptionLevel = useContext(composerCxt);

  //Kompozycje standard edytować może jedynie użytkownik, posiadający odpowiednie uprawnienia
  if (compositionType === 'standard') {
    return !!standardEdition;
  }
  //Użytkownicy IAI mogą edytować wszystkie kompozycje (poza standardami, które dostępne są jedynie dla części osób)
  if (user.type === 'iai') {
    return true;
  } else if (user.type === 'shop') {
    //Użytkownicy Shop w subskrypcji PRO mogą edytować wszystko, poza INDIVIDUAL,
    //które są przygotowywane przez pracowników IAI
    if (composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO) {
      if (compositionType !== 'individual') {
        return true;
      }
      //Użytkownicy Shop w subskrypcji LITE mogą edytować jedynie kompozycje CUSTOM-STD
    } else if (composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_LITE) {
      if (compositionType === 'custom-std') {
        return true;
      }
    }
  }
  return false;
};
