import { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useLocation } from 'react-router';

import { Button } from 'components/atoms/Button';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { useAccess } from 'helpers/access';
import { EDP_MODULES } from 'helpers/endpoints';

export const ModuleListItem = ({
  image,
  name,
  id,
  group,
  version,
  model,
  onClickEvent,
  onClickTitle,
  lastModificationDate,
  lastUpdateUser,
  badges,
}) => {
  const { getLiteral, groupsMap, getTagIcon, getTagLabel } = useContext(composerCxt);
  const canDebugComposer = useAccess('canDebugComposer');
  const { pathname, hash } = useLocation();

  const renderedBadges = badges?.map((badge) => (
    <Tag type={badge.type} title={badge.title} key={`${badge.type}_${id}`}>
      {badge.icon ? getTagIcon(badge.icon) : ''}
      {getTagLabel(badge.type, badge.label)}
    </Tag>
  ));

  return (
    <Module>
      <ModulePreview>
        <Tags>{renderedBadges}</Tags>
        {model === 'wrapper' && (
          <WrapperBadge>
            <Tag type="model">{getLiteral('Wrapper')}</Tag>
          </WrapperBadge>
        )}
        <ModulePreviewLink to={`${EDP_MODULES}${id}/version/${version}`}>
          {image ? (
            <Preview src={`/components/${image}`} effect="opacity" />
          ) : (
            <ModulePreviewPlaceholder icon={faCode} />
          )}
        </ModulePreviewLink>
      </ModulePreview>
      <Content>
        <Name to={`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`} title={name}>
          {name}
        </Name>
        {group && (
          <Group>
            {getLiteral('Grupa')}: <strong>{groupsMap[group]?.name} </strong>
            {canDebugComposer.condition && <>({group})</>}
          </Group>
        )}
        <Group>
          {getLiteral('Zaaktulizowano')}: <strong>{lastModificationDate}</strong> {getLiteral('Przez')}{' '}
          <strong>{lastUpdateUser}</strong>
        </Group>
      </Content>
      {onClickEvent && (
        <ButtonWrapper>
          <CustomnButton type="button" color="blue" onClick={() => onClickEvent()}>
            {onClickTitle}
          </CustomnButton>
        </ButtonWrapper>
      )}
    </Module>
  );
};

const ButtonWrapper = styled.div`
  padding: 0 2rem 2rem 2rem;
`;

const ModulePreviewPlaceholder = styled(FontAwesomeIcon)`
  font-size: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
  color: #000;
`;

const Preview = styled(LazyLoadImage)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Module = styled.div`
  position: relative;
  border: var(--border);
  transition: box-shadow 0.3s;
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }
`;

const ModulePreviewLink = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-flex !important;
  }
`;

const ModulePreview = styled.picture`
  position: relative;
  background-color: #e0e0e0;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  display: block;
  border-bottom: var(--border);
`;

const Content = styled.div`
  padding: 2rem;
  overflow: hidden;
`;

const Name = styled(Link)`
  color: #000;
  margin-bottom: 1rem;
  display: block;
  min-height: 70px;
`;

const Tags = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  display: flex;
  align-items: flex-start;
  z-index: 99;
`;

const Group = styled.div`
  font-size: 1.3rem;
  color: var(--gray-dark);

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const CustomnButton = styled(Button)`
  width: 100%;
  margin-top: auto;
`;

const WrapperBadge = styled.div`
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 99;
`;
