/* eslint-disable react-hooks/exhaustive-deps */
import { faTrashAlt, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import {
  API_MODULES_DELETE,
  API_MODULES_RESTORE,
  API_TEMPLATES_DELETE,
  API_TEMPLATES_RESTORE,
} from 'helpers/endpoints';
import { useAlert } from 'utils/useAlert';
import { useFetch } from 'utils/useFetch';

export const RemovedItem = ({
  id,
  version,
  name,
  createUser,
  lastModificationDate,
  deleteDate,
  onActionCallback,
  itemType,
  badges,
}) => {
  const { getLiteral, getTagIcon, getTagLabel } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const restore = useFetch(itemType === 'component' ? API_MODULES_RESTORE : API_TEMPLATES_RESTORE, 'POST');
  const forceDelete = useFetch(itemType === 'component' ? API_MODULES_DELETE : API_TEMPLATES_DELETE, 'POST');

  const { alert, openAlert } = useAlert({
    onAccept: () => {
      forceDelete.execute({ id, removeParent: true, deleteBackup: true });
    },
    text: getLiteral('TaOperacjaUsunieDefinitywnieKompozycjeWrazZeWszystkimiWczesniejszymiWersjami'),
  });

  useEffect(() => {
    if (restore.response) {
      onActionCallback(id);

      createToast({
        title:
          itemType === 'component'
            ? `${getLiteral('KomponentZostalPrzywrocony')}!`
            : `${getLiteral('KompozycjaZostalaPrzywrocona')}!`,
        context: 'success',
      });
    } else if (restore.error) {
      createToast({
        title: `${getLiteral('CosPoszloNieTak')}!`,
        message: restore.error.message,
        context: 'error',
      });
    }
  }, [restore.response, restore.error]);

  useEffect(() => {
    if (forceDelete.response) {
      onActionCallback(id);

      createToast({
        title:
          itemType === 'component' ? `${getLiteral('KomponentUsuniety')}!` : `${getLiteral('KompozycjaUsunieta')}!`,
        context: 'success',
      });
    } else if (forceDelete.error) {
      createToast({
        title: `${getLiteral('CosPoszloNieTak')}!`,
        message: forceDelete.error.message,
        context: 'error',
      });
    }
  }, [forceDelete.response, forceDelete.error]);

  const renderedBadges = badges?.map(
    (badge) =>
      badge.type !== 'pro' &&
      badge.type !== 'lite' && (
        <Tag type={badge.type} title={badge.title} key={`${badge.type}_${id}`}>
          {badge.icon ? getTagIcon(badge.icon) : ''}
          {getTagLabel(badge.type, badge.label)}
        </Tag>
      ),
  );

  return (
    <>
      <tr key={`${id}_${version}`}>
        <th>{`${name} v.${version}`}</th>
        <th>{renderedBadges}</th>
        <th>{createUser}</th>
        <th>{lastModificationDate}</th>
        <th>{deleteDate}</th>
        <th>
          <ActionButtons>
            <ActionButton
              loading={restore.loading.toString()}
              title={getLiteral('Przywroc')}
              onClick={() => {
                restore.execute({ id });
              }}
            >
              <FontAwesomeIcon icon={faTrashRestore}></FontAwesomeIcon>
            </ActionButton>
            <RemoveButton
              loading={forceDelete.loading.toString()}
              title={getLiteral('Usun')}
              onClick={() => openAlert()}
            >
              <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
            </RemoveButton>
          </ActionButtons>
        </th>
      </tr>
      {alert}
    </>
  );
};

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionButton = styled(Button)`
  width: 40px;
  height: 40px;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: #d9ffdc;
  color: var(--text);

  svg {
    margin: 0;
  }

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
    `}
`;

const RemoveButton = styled(ActionButton)`
  background-color: #ffe8e8;
`;
