/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';

import { Loader } from 'components/atoms/Loader';
import { StyledLink, Table } from 'components/atoms/Table';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { EDP_TEMPLATES } from 'helpers/endpoints';

export const ModuleCompositions = ({ compositions, loading }) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {compositions.length === 0 ? (
            <>
              <div>{getLiteral('KomponentNieJestUzytyWZadnejKompozycji')}</div>
            </>
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>{getLiteral('nazwa')}</th>
                    <th>{getLiteral('Typ')}</th>
                    <th>{getLiteral('OpisZmian')}</th>
                    <th>{getLiteral('Zaaktulizowano')}</th>
                    <th>{getLiteral('Autor')}</th>
                  </tr>
                </thead>
                <tbody>
                  {compositions.map((el) => {
                    return (
                      <tr key={`${el.id}_${el.version}`}>
                        <th>
                          <StyledLink to={`${EDP_TEMPLATES}${el.id}/${el.version}`}>link</StyledLink>
                          {`${el.name} v.${el.version}`}
                        </th>
                        <th>
                          <Tag type={el.type}>{el.type}</Tag>
                          {el.isDraft === 'y' ? (
                            <Tag type="draft" title={getLiteral('wersjaRobocza')}>
                              {getLiteral('wersjaRobocza')}
                            </Tag>
                          ) : null}
                        </th>
                        <th>{el.commitMessage}</th>
                        <th>{el.lastModificationDate}</th>
                        <th>{el.createUser}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};

ModuleCompositions.defaultProps = {
  loading: false,
};
