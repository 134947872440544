import { useContext } from 'react';

import { Loader } from 'components/atoms/Loader';
import { RemovedItem } from 'components/atoms/RemovedItem';
import { Table } from 'components/atoms/Table';
import { composerCxt } from 'contexts/ComposerContext';

export const RemovedItems = ({ items, loading, itemsType }) => {
  const { getLiteral } = useContext(composerCxt);

  const refreshList = (id) => {
    const itemToDelete = items.findIndex((el) => el.id === id);
    if (itemToDelete > -1) {
      items.splice(itemToDelete, 1);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {items.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>{getLiteral('nazwa')}</th>
                    <th>{getLiteral('Typ')}</th>
                    <th>{getLiteral('Autor')}</th>
                    <th>{getLiteral('Zaaktulizowano')}</th>
                    <th>{getLiteral('DataUsuniecia')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((el) => {
                    return <RemovedItem {...el} onActionCallback={refreshList} itemType={itemsType}></RemovedItem>;
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <span>{getLiteral('BrakElementowOczekujacychNaUsuniecie')}</span>
          )}
        </>
      )}
    </>
  );
};
