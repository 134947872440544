import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TemplateSectionWrapper } from 'components/molecules/TemplateSectionWrapper';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { hashToName } from 'helpers/hashToName';

// Layout view tab
export const TemplateSections = () => {
  const {
    envelope,
    layout,
    layout_top,
    layout_left,
    layout_projector_bottom,
    layout_projector,
    layout_bottom,
    removeModule,
  } = useContext(templateCxt);

  const { getLiteral } = useContext(composerCxt);
  const { search } = useLocation();
  const hashName = hashToName(getLiteral('Strony'));

  const scrollIntoView = (event) => {
    const id = event.target.id + '_wrapper';
    const yOffset = -189;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  function setVisibleSections() {
    const TOP_MARGIN = 0.1,
      BOTTOM_MARGIN = 0.1;
    const windowHeight = window.innerHeight;

    const sections = ['layout_top', 'layout_left', 'layout_projector', 'layout_projector_bottom', 'layout_bottom'];

    sections.forEach(function (id) {
      const item = document.getElementById(`${id}_wrapper`);
      const layoutItem = document.getElementById(`${id}`);
      if (!!item === false || !!layoutItem === false) {
        return;
      }

      var targetBounds = item.getBoundingClientRect();

      if (targetBounds.bottom > windowHeight * TOP_MARGIN && targetBounds.top < windowHeight * (1 - BOTTOM_MARGIN)) {
        layoutItem.classList.add('section-visible');
      } else {
        layoutItem.classList.remove('section-visible');
      }
    });
  }

  useEffect(() => {
    setVisibleSections();
    window.addEventListener('scroll', setVisibleSections, false);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <div id="layout_section_wrappers">
        <Wrapper>
          <TemplateSectionWrapper
            items={[envelope] || []}
            title={getLiteral('Uklad')}
            name="envelope"
            isEnvelopeSection={true}
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
        <Wrapper>
          <TemplateSectionWrapper
            items={[layout] || []}
            title={getLiteral('UkladGraficzny')}
            name="layout"
            isEnvelopeSection={true}
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
        <Wrapper>
          <TemplateSectionWrapper
            items={layout_top || []}
            title={getLiteral('NaglowekStrony')}
            name="layout_top"
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
        <Wrapper>
          <TemplateSectionWrapper
            items={layout_left || []}
            title={getLiteral('LewaCzescStrony')}
            name="layout_left"
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
        <Wrapper>
          <TemplateSectionWrapper
            items={layout_projector || []}
            title={getLiteral('GornaCzescZawartosciStrony')}
            name="layout_projector"
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
        <Wrapper>
          <TemplateSectionWrapper
            items={layout_projector_bottom || []}
            title={getLiteral('DolnaCzescZawartosciStrony')}
            name="layout_projector_bottom"
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
        <Wrapper>
          <TemplateSectionWrapper
            items={layout_bottom || []}
            title={getLiteral('StopkaStrony')}
            name="layout_bottom"
            moduleName="menu"
            removeCallback={removeModule}
          />
        </Wrapper>
      </div>
      <div>
        <Navigation>
          <div id="layout">
            <div
              id="layout_top"
              className={'hover-container'}
              title={getLiteral('NaglowekStrony')}
              onClick={scrollIntoView}
            />
            <div id="with_left_margin">
              <div
                id="layout_left"
                className={'hover-container'}
                title={getLiteral('LewaCzescStrony')}
                onClick={scrollIntoView}
              />
              <div>
                <div
                  id="layout_projector"
                  className={'hover-container'}
                  title={getLiteral('GornaCzescZawartosciStrony')}
                  onClick={scrollIntoView}
                />
                <div id="content" title={getLiteral('Strona')}>
                  <ContentLink title={getLiteral('Strona')} to={`${search}${hashName}`}>
                    link
                  </ContentLink>
                </div>

                <div
                  id="layout_projector_bottom"
                  className={'hover-container'}
                  title={getLiteral('DolnaCzescZawartosciStrony')}
                  onClick={scrollIntoView}
                />
              </div>
            </div>
            <div
              id="layout_bottom"
              className={'hover-container'}
              title={getLiteral('StopkaStrony')}
              onClick={scrollIntoView}
            />
          </div>
        </Navigation>
      </div>
    </div>
  );
};

const ContentLink = styled(Link)`
  content: '';
  color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
`;

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Navigation = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 189px;
  padding-left: 1rem;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: end;

  --hoverColor: #eff8ff;
  --hoverborderColor: #0488e7;
  --border: #b9b9b9 2px dashed;
  --margin: 0.5rem;
  --backgroundColor: #fafafa;
  --disabledColor: #ececec;
  --whiteColor: white;

  .section-visible {
    background-color: var(--hoverColor) !important;
    border-color: var(--hoverborderColor) !important;
    cursor: pointer;
  }

  .hover-container {
    border: var(--border);
    background-color: var(--backgroundColor);

    &:hover {
      background-color: var(--hoverColor) !important;
      border-color: var(--hoverborderColor) !important;
      cursor: pointer;
    }
  }

  div#layout {
    width: 150px;
    padding: 1rem;
    border: var(--border);
    border-color: var(--disabledColor);
    background-color: var(--whiteColor);

    div#layout_top,
    div#layout_bottom {
      width: 100%;
      margin-bottom: var(--margin);
      height: 30px;
      z-index: 100;
    }

    div#layout_bottom {
      margin-bottom: 0;
    }

    div#with_left_margin {
      display: flex;
      background-color: var(--whiteColor);
      width: 100%;
      height: 105px;

      div#layout_left {
        width: 25%;
        background-color: var(--backgroundColor);
        height: calc(100% - var(--margin));
        margin-bottom: var(--margin);
      }

      div {
        width: 100%;
        padding-left: var(--margin);
        background-color: var(--whiteColor);

        div {
          height: calc(100% / 3 - var(--margin));
          background-color: var(--backgroundColor);
          margin-bottom: var(--margin);
          border: var(--border);
        }

        div#content {
          position: relative;
          background-color: var(--disabledColor);

          &:hover {
            border-color: var(--hoverborderColor) !important;
          }
        }
      }
    }
  }
`;
