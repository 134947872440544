import styled, { css } from 'styled-components';

export const IconPublish = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <rect data-name="Rectangle 767" width="100%" height="100%" fill="none" />
        <g data-name="Layer 24" transform="translate(-0.898 -0.902)">
          <path
            data-name="Union 14"
            d="M15.466,15H2.46A2.474,2.474,0,0,1,0,12.593V6.675a.927.927,0,0,1,.917-.933.929.929,0,0,1,.924.933v5.907a.644.644,0,0,0,.688.554H15.477a.648.648,0,0,0,.688-.554V6.675a.918.918,0,1,1,1.835,0v5.915A2.464,2.464,0,0,1,15.551,15ZM8.085,9.96V3.185l-.952.968a.917.917,0,0,1-1.308,0,.955.955,0,0,1,0-1.323L8.384.19H8.43A.91.91,0,0,1,8.894,0h.172a.9.9,0,0,1,.591.268l2.517,2.562a.956.956,0,0,1,.27.665.942.942,0,0,1-.27.659.9.9,0,0,1-1.3,0L9.92,3.185V9.96a.918.918,0,1,1-1.835,0Z"
            transform="translate(0.899 2.902)"
            fill="#1d1f21"
          />
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const IconEdit = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <rect data-name="Rectangle 276" width="100%" height="100%" fill="none" />
        <g>
          <g data-name="Group 433" transform="translate(0 1.084)">
            <g data-name="Group 432">
              <path
                data-name="Path 166"
                d="M13.991,45.508a.874.874,0,0,0-.874.874v2.747a.875.875,0,0,1-.874.874H2.623a.875.875,0,0,1-.874-.874V37.761a.875.875,0,0,1,.874-.874H7.7a.874.874,0,0,0,0-1.749H2.623A2.626,2.626,0,0,0,0,37.761V49.128a2.626,2.626,0,0,0,2.623,2.623h9.618a2.626,2.626,0,0,0,2.623-2.623V46.382A.874.874,0,0,0,13.991,45.508Z"
                transform="translate(0 -35.138)"
                fill="#1d1f21"
              />
            </g>
          </g>
          <g data-name="Group 435" transform="translate(5.037)">
            <g data-name="Group 434" transform="translate(0 0)">
              <path
                data-name="Path 167"
                d="M155.251,5.269a3.366,3.366,0,0,0-4.726-.008l-5.92,5.92a4.563,4.563,0,0,0-1.34,3.241V16.38a.874.874,0,0,0,.874.874H146.1a4.564,4.564,0,0,0,3.241-1.34L155.251,10A3.354,3.354,0,0,0,155.251,5.269ZM148.1,14.677a2.825,2.825,0,0,1-2.006.829h-1.084V14.421a2.825,2.825,0,0,1,.828-2.005l3.9-3.9,2.261,2.261Zm5.91-5.91-.772.772-2.261-2.261.772-.772a1.6,1.6,0,0,1,2.261,2.261Z"
                transform="translate(-143.265 -4.296)"
                fill="#1d1f21"
              />
            </g>
          </g>
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const IconPreview = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <rect data-name="Rectangle 280" width="100%" height="100%" fill="none" />
        <g transform="translate(0 3)">
          <path
            data-name="Path 193"
            d="M17.73,8.85C15.39,5.07,12.33,3,9,3S2.61,5.07.27,8.85L0,9.3l.27.45C2.52,13.53,5.67,15.6,9,15.6s6.39-2.07,8.73-5.85L18,9.3ZM9,13.8c-2.52,0-4.95-1.62-6.84-4.5C4.05,6.42,6.48,4.8,9,4.8s4.95,1.62,6.84,4.5c-1.89,2.88-4.32,4.5-6.84,4.5Z"
            transform="translate(0 -3)"
            fill="#1d1f21"
          />
          <ellipse data-name="Ellipse 11" cx="3" cy="2.5" rx="3" ry="2.5" transform="translate(6 4)" fill="#1d1f21" />
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const IconListPoint = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <rect data-name="Rectangle 514" width="100%" height="100%" fill="none" />
        <path
          data-name="Path 425"
          d="M2.823-12.5a.762.762,0,0,1,.774.75v1.5a.761.761,0,0,1-.774.75H1.274A.762.762,0,0,1,.5-10.25v-1.5a.763.763,0,0,1,.774-.75Zm12.645.5A1.016,1.016,0,0,1,16.5-11a1.015,1.015,0,0,1-1.032,1H6.177a1.015,1.015,0,0,1-1.032-1,1.016,1.016,0,0,1,1.032-1Zm0,5A1.015,1.015,0,0,1,16.5-6a1.015,1.015,0,0,1-1.032,1H6.177A1.015,1.015,0,0,1,5.145-6,1.015,1.015,0,0,1,6.177-7Zm0,5A1.015,1.015,0,0,1,16.5-1a1.015,1.015,0,0,1-1.032,1H6.177A1.015,1.015,0,0,1,5.145-1,1.015,1.015,0,0,1,6.177-2ZM.5-6.75a.762.762,0,0,1,.774-.75H2.823a.761.761,0,0,1,.774.75v1.5a.761.761,0,0,1-.774.75H1.274A.762.762,0,0,1,.5-5.25ZM2.823-2.5a.761.761,0,0,1,.774.75v1.5A.761.761,0,0,1,2.823.5H1.274A.762.762,0,0,1,.5-.25v-1.5a.762.762,0,0,1,.774-.75Z"
          transform="translate(0.5 15.5)"
          fill="#1d1f21"
        />
      </CustomSvg>
    </Icon>
  );
};

export const IconLock = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <rect data-name="Rectangle 418" width="100%" height="100%" fill="none" />
        <g transform="translate(2)">
          <path
            data-name="Path 392"
            d="M11.874,16.74a1.206,1.206,0,0,0-.78-.24,1.206,1.206,0,0,0-.78.24,1.134,1.134,0,0,0-.48.6,1.17,1.17,0,0,0-.06.78,1.553,1.553,0,0,0,.42.66v1.26a1.258,1.258,0,0,0,.24.66.9.9,0,0,0,.66.24,1.258,1.258,0,0,0,.66-.24.9.9,0,0,0,.24-.66V18.78a1.553,1.553,0,0,0,.42-.66,1.17,1.17,0,0,0-.06-.78A1.2,1.2,0,0,0,11.874,16.74Z"
            transform="translate(-3.894 -6.6)"
            fill="#1d1f21"
          />
          <path
            data-name="Path 393"
            d="M13.62,7.08A2.775,2.775,0,0,0,11.7,6.3V4.5a4.5,4.5,0,0,0-9,0V6.3a2.89,2.89,0,0,0-1.92.78A2.89,2.89,0,0,0,0,9v6.3a2.89,2.89,0,0,0,.78,1.92A2.775,2.775,0,0,0,2.7,18h9a2.89,2.89,0,0,0,1.92-.78,2.775,2.775,0,0,0,.78-1.92V9A2.89,2.89,0,0,0,13.62,7.08ZM4.5,4.5a2.89,2.89,0,0,1,.78-1.92A2.89,2.89,0,0,1,7.2,1.8a2.89,2.89,0,0,1,1.92.78A2.775,2.775,0,0,1,9.9,4.5V6.3H4.5Zm8.1,10.8a1.258,1.258,0,0,1-.24.66.9.9,0,0,1-.66.24h-9a1.258,1.258,0,0,1-.66-.24,1.258,1.258,0,0,1-.24-.66V9a1.258,1.258,0,0,1,.24-.66A.9.9,0,0,1,2.7,8.1h9a1.258,1.258,0,0,1,.66.24A.9.9,0,0,1,12.6,9Z"
            transform="translate(0 0)"
            fill="#1d1f21"
          />
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const IconSuccess = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <path
          data-name="icon-success"
          d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2ZM9.409,12.273l4.455-4.455a.9.9,0,0,1,1.273,1.273l-5.091,5.091a.9.9,0,0,1-1.273,0l-1.909-1.91A.9.9,0,1,1,8.136,11Z"
          transform="translate(-2 -2)"
          fill="#1d1f21"
        />
      </CustomSvg>
    </Icon>
  );
};

export const IconArrowDown = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <rect data-name="Rectangle 237" width="100%" height="100%" fill="none" />
        <g transform="translate(0.574 4.5)">
          <path
            data-name="Path 151"
            d="M8.714,107.074a1.217,1.217,0,0,1-.863-.357L.358,99.224A1.22,1.22,0,0,1,2.084,97.5l6.63,6.63,6.63-6.63a1.22,1.22,0,0,1,1.726,1.726l-7.493,7.493A1.217,1.217,0,0,1,8.714,107.074Z"
            transform="translate(-0.001 -97.141)"
            fill="#1d1f21"
          />
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const IconStar = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
      </CustomSvg>
    </Icon>
  );
};

export const IconClose = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.001 18.001">
        <rect
          data-name="Rectangle 265"
          width="2.546"
          height="22.91"
          rx="1.273"
          transform="translate(16.201) rotate(45)"
          fill="#1d1f21"
        />
        <rect
          data-name="Rectangle 266"
          width="2.546"
          height="22.91"
          rx="1.273"
          transform="translate(18 16.201) rotate(135)"
          fill="#1d1f21"
        />
      </CustomSvg>
    </Icon>
  );
};

export const PictogramText = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <g transform="translate(-829 -66)">
          <rect data-name="Rectangle 755" width="64" height="64" rx="10" transform="translate(829 66)" fill="#02a767" />
          <g data-name="Layer 5" transform="translate(845 82)">
            <path
              data-name="Path 444"
              d="M19.2,7H8.355A1.355,1.355,0,0,0,7,8.355v2.711a1.355,1.355,0,0,0,2.711,0V9.711h2.711v8.132H11.066a1.355,1.355,0,0,0,0,2.711h5.421a1.355,1.355,0,1,0,0-2.711H15.132V9.711h2.711v1.355a1.355,1.355,0,0,0,2.711,0V8.355A1.355,1.355,0,0,0,19.2,7Z"
              transform="translate(2.487 2.487)"
              fill="#fff"
            />
            <path
              data-name="Path 445"
              d="M32.527,5.421V2.711A2.713,2.713,0,0,0,29.816,0H27.106A2.713,2.713,0,0,0,24.4,2.711H8.132A2.713,2.713,0,0,0,5.421,0H2.711A2.713,2.713,0,0,0,0,2.711V5.421A2.713,2.713,0,0,0,2.711,8.132V24.4A2.713,2.713,0,0,0,0,27.106v2.711a2.713,2.713,0,0,0,2.711,2.711H5.421a2.713,2.713,0,0,0,2.711-2.711H24.4a2.713,2.713,0,0,0,2.711,2.711h2.711a2.713,2.713,0,0,0,2.711-2.711V27.106A2.713,2.713,0,0,0,29.816,24.4V8.132a2.713,2.713,0,0,0,2.711-2.711ZM27.106,2.711h2.711l0,2.711H27.106Zm-24.4,0H5.421V5.421H2.711Zm0,27.106V27.106H5.421v2.711Zm27.108,0H27.106V27.106h2.711ZM27.106,24.4A2.713,2.713,0,0,0,24.4,27.106H8.132A2.713,2.713,0,0,0,5.421,24.4V8.132A2.713,2.713,0,0,0,8.132,5.421H24.4a2.713,2.713,0,0,0,2.711,2.711Z"
              fill="#fff"
            />
          </g>
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const PictogramImage = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
        <g transform="translate(-0.27 -0.363)">
          <rect
            id="Rectangle_755"
            data-name="Rectangle 755"
            width="92"
            height="92"
            rx="10"
            transform="translate(0.269 0.363)"
            fill="#ffc301"
          />
          <g transform="translate(21.992 22.085)">
            <path
              id="Path_491"
              data-name="Path 491"
              d="M7.393,12.171a32.571,32.571,0,0,0-.53,6.791V33.668a32.568,32.568,0,0,0,.53,6.791A6.311,6.311,0,0,0,8.929,43.7a6.309,6.309,0,0,0,3.242,1.536,32.567,32.567,0,0,0,6.791.53H33.668a32.564,32.564,0,0,0,6.791-.53A6.31,6.31,0,0,0,43.7,43.7a6.31,6.31,0,0,0,1.536-3.242,32.564,32.564,0,0,0,.53-6.791V18.962a32.567,32.567,0,0,0-.53-6.791A6.309,6.309,0,0,0,43.7,8.929a6.311,6.311,0,0,0-3.242-1.536,32.568,32.568,0,0,0-6.791-.53H18.962a32.571,32.571,0,0,0-6.791.53A6.31,6.31,0,0,0,8.929,8.929,6.31,6.31,0,0,0,7.393,12.171Zm3.723-9.526A37.129,37.129,0,0,1,18.962,2H33.668a37.127,37.127,0,0,1,7.846.646A11.087,11.087,0,0,1,47.14,5.49a11.087,11.087,0,0,1,2.845,5.626,37.126,37.126,0,0,1,.646,7.846V33.668a37.125,37.125,0,0,1-.646,7.846A11.087,11.087,0,0,1,47.14,47.14a11.087,11.087,0,0,1-5.626,2.845,37.124,37.124,0,0,1-7.846.646H18.962a37.126,37.126,0,0,1-7.846-.646A11.087,11.087,0,0,1,5.49,47.14a11.087,11.087,0,0,1-2.845-5.626A37.127,37.127,0,0,1,2,33.668V18.962a37.129,37.129,0,0,1,.646-7.846A11.087,11.087,0,0,1,5.49,5.49a11.087,11.087,0,0,1,5.626-2.845Z"
              transform="translate(-2 -2)"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              data-name="Path 492"
              d="M2.712,22.217a2.432,2.432,0,0,1,0-3.439l3.1-3.1a37.129,37.129,0,0,1,6-5.092,11.087,11.087,0,0,1,5.99-1.967,11.087,11.087,0,0,1,5.99,1.967,37.13,37.13,0,0,1,6,5.092l10.4,10.4a2.432,2.432,0,1,1-3.439,3.439l-10.4-10.4a32.563,32.563,0,0,0-5.177-4.427,6.31,6.31,0,0,0-3.379-1.207,6.31,6.31,0,0,0-3.379,1.207,32.566,32.566,0,0,0-5.177,4.427l-3.1,3.1a2.432,2.432,0,0,1-3.439,0Z"
              transform="translate(-2 7.471)"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              data-name="Path 493"
              d="M14.823,19.882A2.431,2.431,0,0,1,15,16.448c2.848-2.566,5.575-4.392,8.551-4.763,3.111-.388,5.891.873,8.663,3a2.432,2.432,0,1,1-2.957,3.861c-2.318-1.776-3.82-2.191-5.1-2.031-1.42.177-3.2,1.122-5.9,3.55A2.432,2.432,0,0,1,14.823,19.882Z"
              transform="translate(15.462 11.765)"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              data-name="Path 494"
              d="M14,10.863a4.863,4.863,0,1,1,4.863,4.863A4.863,4.863,0,0,1,14,10.863Z"
              transform="translate(15.178 3.726)"
              fill="#fff"
              fillRule="evenodd"
            />
          </g>
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const PictogramLines = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <g transform="translate(0.433 -0.311)">
          <rect
            data-name="Rectangle 755"
            width="64"
            height="64"
            rx="10"
            transform="translate(-0.433 0.311)"
            fill="#0268d9"
          />
          <rect
            data-name="Rectangle 756"
            width="35.31"
            height="6.621"
            rx="3.31"
            transform="translate(15.015 22.38)"
            fill="#fff"
          />
          <rect
            data-name="Rectangle 757"
            width="11.034"
            height="6.621"
            rx="3.31"
            transform="translate(15.015 37.828)"
            fill="#fff"
          />
          <rect
            data-name="Rectangle 758"
            width="19.862"
            height="6.621"
            rx="3.31"
            transform="translate(30.463 37.828)"
            fill="#fff"
          />
        </g>
      </CustomSvg>
    </Icon>
  );
};

export const Pictogramcode = ({ color, className }) => {
  return (
    <Icon className={`svg-icon ${className}`}>
      <CustomSvg color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
        <g transform="translate(0.349 -0.234)">
          <rect
            data-name="Rectangle 755"
            width="92"
            height="92"
            rx="10"
            transform="translate(-0.349 0.234)"
            fill="#8a20d1"
          />
          <path
            d="M30.669,8.963A2.3,2.3,0,1,0,26.261,7.64l-6.9,23.013a2.3,2.3,0,0,0,4.408,1.323Zm-15.536.013a2.3,2.3,0,0,1,0,3.255L7.556,19.808l7.578,7.578a2.3,2.3,0,0,1-3.255,3.255L2.674,21.436a2.3,2.3,0,0,1,0-3.255l9.205-9.205A2.3,2.3,0,0,1,15.134,8.976Zm19.759,0a2.3,2.3,0,0,0,0,3.255l7.578,7.578-7.578,7.578a2.3,2.3,0,0,0,3.255,3.255l9.205-9.205a2.3,2.3,0,0,0,0-3.255L38.147,8.976A2.3,2.3,0,0,0,34.893,8.976Z"
            transform="translate(20.795 25.331)"
            fill="#fff"
            fillRule="evenodd"
          />
        </g>
      </CustomSvg>
    </Icon>
  );
};

const CustomSvg = styled.svg`
  ${({ color }) =>
    color &&
    css`
      fill: var(--${color});
    `}
`;

const Icon = styled.span`
  max-width: 2rem;
  max-height: 2rem;
  width: 100%;
  height: 100%;
  display: inline-block;
`;
