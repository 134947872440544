import React, { useCallback, useContext } from 'react';
import { Decoration } from 'react-diff-view';
import styled from 'styled-components';

import { composerCxt } from 'contexts/ComposerContext';

const ExpandButton = ({ direction, start, lines, onExpand, linesCount }) => {
  const { getLiteral } = useContext(composerCxt);

  const ExpandDownLine = start + lines + 10;
  const collapsedLines = linesCount > ExpandDownLine ? ExpandDownLine : linesCount;
  const click = useCallback(() => {
    if (direction === 'up') {
      onExpand(Math.max(start - 10, 1), start);
    } else {
      onExpand(start + lines, collapsedLines);
    }
  }, [direction, start, lines, onExpand, collapsedLines]);

  return (
    <Decoration>
      <StyledExpand onClick={click}>
        {direction === 'up' ? getLiteral('RozwinWDol') : getLiteral('RozwinWGore')}
      </StyledExpand>
    </Decoration>
  );
};

const StyledExpand = styled.div`
  background-color: #f2f8ff;
  color: #666;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #0666d6;
    color: #fff;
  }
`;

export default ExpandButton;
