import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import App from 'App';
import NotFoundPage from 'components/pages/NotFoundPage';
import { TextCtxProvider } from 'contexts/TextsContext';
import { colors } from 'helpers/colors';
import { EDP_DEFAULT, EDP_NO_ACCESS } from 'helpers/endpoints';
import { GlobalStyles } from 'helpers/globalStyles';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GlobalStyles />
      <ThemeProvider theme={{ colors }}>
        <TextCtxProvider>
          <Switch>
            <Route exact path={EDP_NO_ACCESS} component={NotFoundPage} />
            <Route path={EDP_DEFAULT} component={App} />
          </Switch>
        </TextCtxProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
