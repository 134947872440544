import { useContext } from 'react';
import styled from 'styled-components';

import {
  ButtonLink,
  DialogInfoWrapper,
  NewPicture,
  PictureLabel,
  PictureWrapper,
} from 'components/atoms/FileReplaceDialogComponent';
import { DropField } from 'components/atoms/DropField';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { composerCxt } from 'contexts/ComposerContext';

export const FileAddOrChooseDialogComponent = ({ openManager, addNewFile, isStandardComponent = false }) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <Wrapper>
      {isStandardComponent && (
        <InfoLabel
          type="info"
          text={getLiteral(
            'JestesWTrybieEdycjiKomponentuStandardowegoKomponentTegoTypuMozeKorzystacZPlikowStandardowychDodajacNowyPlikZostanieOnAutomatycznieOznaczonyJakoPlikStandardowy',
          )}
        />
      )}
      <DialogInfoWrapper>
        <PictureWrapper>
          <PictureLabel>
            {isStandardComponent ? getLiteral('NowyPlikStandardowy') : getLiteral('NowyPlik')}
          </PictureLabel>
          <DropField
            title={getLiteral('PrzeciagnijPlikTutajAbyDodacNowy')}
            titleOnDragOver={getLiteral('UpuscTutaj')}
            onBaseRender={addNewFile}
            styles={{ textAlign: 'center' }}
          />
        </PictureWrapper>
        <div className="data">
          <NewPicture>
            <div className="wrapper">
              <PictureLabel>{getLiteral('IstniejacyPlik')}</PictureLabel>
              <div>{getLiteral('WybierzZ')}</div>
              <ButtonLink type="button" onClick={openManager}>
                {getLiteral('ManageraPlikow')}
              </ButtonLink>
            </div>
          </NewPicture>
        </div>
      </DialogInfoWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: calc(480px + 3rem);
`;
