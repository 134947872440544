/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import CompareCompositionList from 'components/atoms/CompareCompositionList';
import { Select } from 'components/atoms/Form/Select';
import { Loader } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { API_TEMPLATES_COMPARE, API_TEMPLATES_GET_LIST_TO_COMPARE } from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { useFetch } from 'utils/useFetch';

/**
 * Komponent porównania dwóch kompozycji
 *
 * @returns string
 */
const CompareComposition = () => {
  const getList = useFetch(API_TEMPLATES_GET_LIST_TO_COMPARE, 'GET');
  const postCompositionCompare = useFetch(API_TEMPLATES_COMPARE, 'POST');
  const [designSelected, setDesignSelected] = useState(null);
  const [designDestSelected, setDesignDestSelected] = useState(null);

  const { getLiteral } = useContext(composerCxt);

  const getData = () => {
    const compositionIdNew = designDestSelected.id;
    const compositionIdOld = designSelected.id;
    const versionOld = designSelected.version;
    const versionNew = designDestSelected.version;

    postCompositionCompare.execute({
      compositionIdNew,
      compositionIdOld,
      versionOld,
      versionNew,
    });
  };

  useEffect(() => {
    getList.execute();
  }, []);

  useEffect(() => {}, [postCompositionCompare.response]);

  const generateDiffs = () => {
    if (postCompositionCompare.response && postCompositionCompare.response.components) {
      return <CompareCompositionList elements={postCompositionCompare.response.components}></CompareCompositionList>;
    } else if (postCompositionCompare.loading) {
      return <Loader />;
    }
  };

  const generateOptions = () => {
    return getList.response.map((item) => {
      const draft = item.isDraft === 'y' ? ` (${getLiteral('wersjaRobocza')})` : '';

      return makeSelectOption(
        {
          id: item.id,
          name: item.name,
          version: item.version,
        },
        `${item.name} v.${item.version}${draft}`,
      );
    });
  };

  /**
   * Pokazuje kolejnego selecta z wyborem drugiej kompozycji do porównania
   *
   * @returns string
   */
  const showVersions = () => {
    return (
      <>
        <Wrapper>
          <SectionName>{getLiteral('KompozycjaDocelowa')}</SectionName>
          <Select
            onChange={(selected) => {
              setDesignDestSelected(selected ? selected.value : null);
            }}
            isClearable
            options={generateOptions()}
          />
        </Wrapper>
        <WrapperCenter>
          <Button onClick={getData}>{getLiteral('Porownaj')}</Button>
        </WrapperCenter>
      </>
    );
  };

  return (
    <>
      <Wrapper>
        {getList.loading ? (
          <Loader />
        ) : getList.response ? (
          <>
            <SectionName>{getLiteral('KompozycjaZrodlowa')}</SectionName>
            <Select
              onChange={(selected) => {
                setDesignSelected(selected ? selected.value : null);
              }}
              isClearable
              options={generateOptions()}
            />
          </>
        ) : null}
      </Wrapper>
      {designSelected ? showVersions() : null}
      {generateDiffs()}
    </>
  );
};

export default CompareComposition;

const WrapperCenter = styled.div`
  padding: 2vh 2vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  padding: 2vh 2vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SectionName = styled.h3`
  width: 15vw;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;
