import { useContext } from 'react';
import styled from 'styled-components';

import { textsCtx } from 'contexts/TextsContext';

const NotFoundPage = () => {
  const { getLiteralData } = useContext(textsCtx);

  return (
    <Wrapper>
      <Title>{getLiteralData('ZalogujSiePonownieZPoziomuPanelu', false)}!</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 2.4rem;
  text-align: center;
`;

export default NotFoundPage;
