import { useContext, useEffect, useRef, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';

import CustomDialog from 'components/atoms/CustomDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { CustomDialogTitle } from 'components/atoms/CustomDialogTitle';
import { Button } from 'components/atoms/Button';
import { Group, Input, Textarea } from 'components/atoms/Form';
import { useFetch } from 'utils/useFetch';
import { API_TEMPLATES_CHECK_NANE } from 'helpers/endpoints';

export const CopyTemplateDialog = ({ open, setOpen, nextStep }) => {
  const [isEmptyError, setIsEmptyError] = useState(false);
  const { getLiteral } = useContext(composerCxt);
  const formNameRef = useRef(null);
  const formDescriptionRef = useRef(null);

  const postCheckUniqueName = useFetch(API_TEMPLATES_CHECK_NANE, 'POST');

  const handleClose = () => {
    setOpen(false);
  };
  const handleNextStep = (name) => {
    if (!name) {
      setIsEmptyError(`${getLiteral('PoleObowiazkowe')}!`);
    }
    if (name && !isEmptyError) {
      postCheckUniqueName.execute({ name });
    }
  };

  useEffect(() => {
    if (postCheckUniqueName.response) {
      if (!postCheckUniqueName.response.isUnique) {
        setIsEmptyError(`${getLiteral('NazwaKompozycjiMusiBycUnikalna')}!`);
      } else {
        nextStep(formNameRef.current.value, formDescriptionRef.current.value);
      }
    } else if (postCheckUniqueName.error) {
      setIsEmptyError(`${getLiteral('NazwaKompozycjiMusiBycUnikalna')}!`);
    }
  }, [postCheckUniqueName.response, postCheckUniqueName.error]);

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle title={getLiteral('TworzenieKompozycji')} closeCallback={handleClose} />
      <DialogContent sx={{ padding: 20 }}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleNextStep(formNameRef.current.value);
          }}
        >
          <Group>
            <Input
              ref={formNameRef}
              title={getLiteral('NazwaKompozycji')}
              placeholder={getLiteral('WpiszNazweKompozycji')}
              error={isEmptyError ? isEmptyError : false}
              autoFocus
              onChange={({ target }) => {
                if (target.value) {
                  setIsEmptyError(false);
                } else {
                  setIsEmptyError(`${getLiteral('PoleObowiazkowe')}!`);
                }
              }}
              isLabelDetached={true}
            />
            <Textarea
              ref={formDescriptionRef}
              title={getLiteral('OpisKompozycji')}
              placeholder={getLiteral('WpiszOpisKompozycji')}
              minRows={5}
              isLabelDetached={true}
            />
          </Group>
        </form>
      </DialogContent>
      <CustomDialogFooter>
        <Flex>
          <Button
            onClick={() => {
              handleNextStep(formNameRef.current.value);
            }}
            color="green"
          >
            {getLiteral('UtworzKompozycje')}
          </Button>
          <Button onClick={handleClose} color="white">
            {getLiteral('Anuluj')}
          </Button>
        </Flex>
      </CustomDialogFooter>
    </CustomDialog>
  );
};

const CustomDialogFooter = styled.div`
  padding: 2rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  button {
    margin-right: 1rem;
  }
`;
