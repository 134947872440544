import styled from 'styled-components';
import { useContext } from 'react';
import { useHistory } from 'react-router';

import { PAGE_LITE_VS_PRO } from '../../../../contexts/MarketingContext';

import { SUBSCRIPTION_DIALOG_TYPE_ACTIVATION } from 'components/organisms/SubscriptionDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';

export const TopBanner = () => {
  const { openSubscriptionDialog, getLiteral, isComposerProForced } = useContext(composerCxt);
  const user = useContext(userCtx);
  const history = useHistory();

  const handleClickOpenDialog = () => {
    openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_ACTIVATION);
  };

  const bannerActivateComposerPro = () => {
    return (
      <Link onClick={handleClickOpenDialog}>
        <picture>
          <source
            srcSet={`/sources/banner-composer-pro-big-1360px-${user.language}-fix.webp 1x, /sources/banner-composer-pro-big-1360px-${user.language}-fix@2x.webp 2x
            type="image/png`}
          />
          <img
            alt={getLiteral('AktywujComposerPro')}
            src={`/sources/banner-composer-pro-big-1360px-${user.language}-fix.webp`}
          />
        </picture>
      </Link>
    );
  };

  const bannerInfoLiteVsPro = () => {
    return (
      <Link
        onClick={(e) => {
          history.push('/articles/' + PAGE_LITE_VS_PRO);
        }}
      >
        <picture>
          <source
            srcSet={`/sources/baner-composer-lite-pro-1360-${user.language}-fix.webp 1x, /sources/baner-composer-lite-pro-1360-${user.language}-fix@2x.webp 2x`}
            type="image/png"
          />
          <img alt="" src={`/sources/baner-composer-lite-pro-1360-${user.language}-fix.webp`} />
        </picture>
      </Link>
    );
  };

  return isComposerProForced() ? bannerInfoLiteVsPro() : bannerActivateComposerPro();
};

const Link = styled.a`
  cursor: pointer;
`;
