import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/atoms/Form';
import { Input } from 'components/atoms/Form/Inputs';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { Heading } from 'components/atoms/Heading';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { PageHeader } from 'components/atoms/PageHeader';
import { SupportMark } from 'components/atoms/SupportMark';
import { composerCxt } from 'contexts/ComposerContext';
import { useAccess } from 'helpers/access';

export const DictionaryHeader = ({ filters, setText, setEmpty, setStandardLiterals }) => {
  const canEditStandardLiterals = useAccess('canEditStandardLiterals');
  const { getLiteral } = useContext(composerCxt);

  return (
    <PageHeader>
      <Row align="center">
        <Col size={4}>
          <Heading>{getLiteral('SlownikTlumaczen')}</Heading>
        </Col>
        <RelativeCol size={4}>
          <StyledInput
            bottom="0"
            value={filters.text}
            title={getLiteral('ZnajdxLiteral')}
            placeholder={getLiteral('WpiszNazweLiteralu')}
            onChange={({ target }) => setText(target.value)}
          />
          {filters.text && (
            <RemoveButton type="button" onClick={() => setText('')}>
              <FontAwesomeIcon icon={faTimes} />
            </RemoveButton>
          )}
        </RelativeCol>
        <PaddedCol size={4}>
          <Checkbox
            checked={filters.emptyLiterals}
            bottom="0"
            title={getLiteral('PokazTylkoBrakujaceLiteraly')}
            onChange={() => setEmpty()}
          />
          {canEditStandardLiterals.condition && (
            <Marginer top={1}>
              <Checkbox
                checked={filters.standardLiterals}
                bottom="0"
                title={
                  <>
                    {getLiteral('LiteralyStandardowe')}
                    <SupportMark></SupportMark>
                  </>
                }
                onChange={() => setStandardLiterals()}
              />
            </Marginer>
          )}
        </PaddedCol>
      </Row>

      {canEditStandardLiterals.condition && filters.standardLiterals ? (
        <Marginer top={2}>
          <InfoLabel
            type="info"
            text={getLiteral('EdytujeszLiteralyStandardoweZmianyBedaWidoczneUWszystkichKlientow')}
            marginBottom={false}
          />
        </Marginer>
      ) : null}
    </PageHeader>
  );
};

const StyledInput = styled(Input)`
  padding-right: 30px;
`;

const RelativeCol = styled(Col)`
  position: relative;
`;

const PaddedCol = styled(Col)`
  padding-left: 50px;
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
`;
