/* eslint-disable react-hooks/exhaustive-deps */
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { Button } from 'components/atoms/Button';
import { Container, Content, Layout } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Footer } from 'components/molecules/Footer';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { ModuleTypeForm } from 'components/organisms/ModuleTypeForm';
import { ModuleTypeUsedIn } from 'components/organisms/ModuleTypeUsedIn';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_GROUPS, API_GROUPS_DELETE, API_GROUPS_EDIT, EDP_CONFIG } from 'helpers/endpoints';
import { useAlert } from 'utils/useAlert';
import { useFetch } from 'utils/useFetch';
import { useTabs } from 'utils/useTabs';
import { hashToName } from 'helpers/hashToName';
import { useAccess } from 'helpers/access';

const ModuleTypePage = () => {
  const { id } = useParams();
  const { getLiteral, methods } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const canEditConfigs = useAccess('canEditConfigs');

  const getGroupData = useFetch(`${API_GROUPS}/get-by-id/id/${id}`, 'POST');
  const postUpdateGroupData = useFetch(API_GROUPS_EDIT, 'POST');
  const postDeleteGroupData = useFetch(API_GROUPS_DELETE, 'POST');

  const { handleSubmit, reset, watch, setValue, control, ...props } = useForm();
  const state = watch();

  const { tabs, tabsPanel } = useTabs(
    [
      {
        title: getLiteral('PodstawoweInformacje'),
        component: (
          <ModuleTypeForm
            siteId={state.siteId}
            control={control}
            {...props}
            readOnly={(getGroupData.response && !!state.deleteDate) || !canEditConfigs.condition}
          />
        ),
      },
      {
        title: getLiteral('KomponentyTegoTypu'),
        component: <ModuleTypeUsedIn id={id} />,
      },
    ],
    true,
  );

  const markAsDeprecatedAlert = useAlert({
    onAccept: () => postDeleteGroupData.execute({ id }),
    text: getLiteral(
      'UsuniecieGroupySpowodujeBlokadeMozliwosciDodawaniaKolejnychKomponentowTegoTypuIUsuniecieJeJWPrzyszlosci',
    ),
  });

  useEffect(() => {
    if (id) {
      getGroupData.execute();
    }
  }, [id]);

  useEffect(() => {
    if (getGroupData.response) {
      reset(getGroupData.response);
    }
  }, [getGroupData.response]);

  useEffect(() => {
    if (postUpdateGroupData.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postUpdateGroupData.error.message,
        delay: 5,
      });
    }

    if (postUpdateGroupData.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
    }
  }, [postUpdateGroupData.response, postUpdateGroupData.error]);

  useEffect(() => {
    if (postDeleteGroupData.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postDeleteGroupData.error.message,
        delay: 5,
      });
    }

    if (postDeleteGroupData.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
    }
  }, [postDeleteGroupData.response, postDeleteGroupData.error]);

  const onSubmit = (data) => {
    postUpdateGroupData.execute(data, () => {
      methods.fetchGroups();
    });
  };

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          {getGroupData.loading && <Loader relative />}
          <Header
            tabs={tabs}
            title={getGroupData.response && getGroupData.response.name}
            backwardTitle={getLiteral('Powrot')}
            backwardTo={`${EDP_CONFIG}${hashToName(getLiteral('TypyKomponentow'))}`}
          />
          {tabsPanel}

          {getGroupData.executed && (
            <Footer>
              <div>
                {getGroupData.response && !state.deleteDate && (
                  <>
                    <Button
                      style={{ margin: '0 1rem' }}
                      color="green"
                      type="button"
                      disabled={!canEditConfigs.condition}
                      onClick={handleSubmit(onSubmit)}
                      loading={postUpdateGroupData.loading.toString()}
                    >
                      <FontAwesomeIcon icon={faSave} />
                      {getLiteral('ZapiszZmiany')}
                    </Button>

                    <Button
                      type="button"
                      color="red"
                      disabled={!canEditConfigs.condition}
                      onClick={() => markAsDeprecatedAlert.openAlert()}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      {getLiteral('OznaczJakoWycofywane')}
                    </Button>
                  </>
                )}
              </div>
            </Footer>
          )}
        </Container>
      </Content>
      {markAsDeprecatedAlert.alert}
    </Layout>
  );
};

export default ModuleTypePage;
