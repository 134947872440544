import { useState } from 'react';
import styled, { css } from 'styled-components';

// const tabsExample = [
//   {
//     title: 'Title',
//     component: <></>,
//   },
// ];

/**
 * Create tabs and return prepared structures
 * @param {*} tabs array os tabs objects
 * @returns object with components tabs and panel
 */
export const useTabs = (tabs, useDisplay) => {
  const [tabIndex, setTabIndex] = useState(0);

  return {
    tabs: (
      <TabsWrapper>
        {tabs.map((tab, index) => (
          <Tab
            type="button"
            key={`${tab.title}_${tab.index}`}
            onClick={() => {
              setTabIndex(index);
              if (tab.setFocus) {
                tab.setFocus(true);
              }
            }}
            onBlur={() => {
              if (tab.setFocus) {
                tab.setFocus(false);
              }
            }}
            toRight={tab.toRight ? true : false}
            active={index === tabIndex}
          >
            {tab.title}
          </Tab>
        ))}
      </TabsWrapper>
    ),
    tabsPanel: useDisplay
      ? tabs.map((tab, index) => (
          <div key={tab.title} style={{ display: index !== tabIndex ? 'none' : 'block' }}>
            {tab.component}
          </div>
        ))
      : tabs[tabIndex]?.component,
  };
};

const TabsWrapper = styled.div`
  display: flex;
  position: relative;
`;

const Tab = styled.button`
  background: none;
  font-size: 1.5rem;
  padding: 0 0 1rem 0;
  color: var(--black);
  position: relative;
  margin: 0 2rem 0 0;
  border: none;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: var(--black);
    width: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    transition: width 0.2s ease-in-out;
    margin: auto;
  }

  &:empty {
    margin: 0;
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: bold;

      &:after {
        width: 100%;
        transition: width 0.2s;
      }
    `}

  ${({ toRight }) =>
    toRight &&
    css`
      position: absolute;
      margin: 0;
      right: 0;
    `}
`;
