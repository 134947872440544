import { useContext } from 'react';
import styled from 'styled-components';
import { Accordion } from '@material-ui/core';
import { useParams } from 'react-router';

import { Tag } from 'components/atoms/Tag';
import { marketingCtx } from 'contexts/MarketingContext';

export const MarketingArticle = () => {
  const { article_name } = useParams();
  const { getArticle } = useContext(marketingCtx);

  return <StyledArticle>{getArticle(article_name)}</StyledArticle>;
};

const StyledArticle = styled.article`
  max-width: 930px;
  margin: 0 auto;
  color: var(--text);
  font-size: 1.8rem;
  line-height: 2.8rem;
  section {
    margin-top: 6rem;
    margin-bottom: 10rem;
  }
  .MuiGrid-root.section {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .lite {
    h1,
    h2,
    h3 {
      > span {
        color: var(--liteColor);
      }
    }
    .variant-color,
    .variant-color > svg {
      color: var(--liteColor);
      path {
        fill: var(--liteColor);
      }
    }
  }
  .pro {
    h1,
    h2,
    h3 {
      > span {
        color: var(--proColor);
      }
    }
    .variant-color,
    .variant-color > svg {
      color: var(--proColor);
      path {
        fill: var(--proColor);
      }
    }
  }
  header {
    text-align: left;
  }
  h1,
  h2,
  h3 {
    text-align: left;
    > span {
      display: block;
    }
  }
  h1 {
    font-size: 4rem;
    line-height: 5rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
  h2 {
    font-size: 3rem;
    line-height: 3.9rem;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1.9rem;
  }
  h2 + p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
  section p + p {
    margin-top: 1em;
  }
  p,
  li {
    color: #67686c;
  }
  ul {
    display: inline-block;
    padding-left: 3rem;
    li {
      margin-top: 2rem;
      position: relative;
      > span {
        position: absolute;
        left: -3rem;
        top: 0.4rem;
        path {
          fill: var(--liteColor);
        }
      }
    }
  }
  .cols {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    .col {
      width: 50%;
    }
  }
  .banner {
    border-radius: 10px;
    padding: 40px;
    text-align: left;
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    &:first-child {
      border-radius: 0;
    }
    &.Mui-expanded {
      margin: 0;
    }
    &:before {
      display: none;
    }
    &:first-child:before,
    &:after {
      top: unset;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      content: '';
      display: block;
      position: absolute;
      transition: none;
      background-color: var(--gray);
      opacity: 0.4;
      position: absolute;
    }
    &:first-child:before {
      top: 0;
      bottom: unset;
    }
  }
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    padding: 0;
    .MuiAccordionSummary-expandIcon {
      .svg-icon {
        width: 1.7rem;
      }
      &:not(.Mui-expanded) .svg-icon.variant-color path {
        fill: var(--text);
      }
    }
    .MuiAccordionSummary-content {
      p {
        font-size: 1.8rem;
        line-height: 2.6rem;
        font-weight: bold;
      }
      &:not(.Mui-expanded) p {
        color: var(--text);
      }
      &.Mui-expanded {
        margin: 12px 0;
      }
    }
    &.Mui-expanded {
      min-height: 48px;
    }
  }
  .MuiAccordionDetails-root {
    p {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
  }
  .MuiCollapse-container {
    // border-bottom: 1px solid var(--gray);
    .MuiAccordionDetails-root {
      padding-left: 0;
    }
  }
`;

export const Masonry = styled.div`
  column-count: 3;
  column-gap: 3rem;
  margin-top: 7rem;
  & > div {
    margin: 0;
    display: grid;
    grid-template-rows: auto;
    margin-bottom: 3rem;
    break-inside: avoid;
  }
`;

export const RoundedTag = styled(Tag)`
  height: 2.8rem;
  font-size: 1.4rem;
  line-height: 2.6rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
`;
