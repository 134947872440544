/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/atoms/Table';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_MIGRATION_IMPORT, API_MIGRATION_LIST, API_TEMPLATES_FULL_COPY } from 'helpers/endpoints';
import { useAccess } from 'helpers/access';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const QueueMigration = ({ setBuildsWaiting }) => {
  const { getLiteral } = useContext(composerCxt);
  const [pagesCount, setPagesCount] = useState(0);
  const [builds, setBuilds] = useState([]);
  const { createToast } = useContext(toastCtx);
  const canMigrateDesigns = useAccess('canMigrateDesigns');

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getDesignsToBuild = useFetch(`${API_MIGRATION_LIST}/page/${page}`, 'GET');
  const postDesignImport = useFetch(`${API_MIGRATION_IMPORT}`, 'POST');
  const postDesignExportToOtherPanel = useFetch(`${API_TEMPLATES_FULL_COPY}`, 'POST');

  const onDesignImportSubmit = (data) => {
    if (!(data.id && data.name)) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('BledneDaneWFormularzu'),
        context: 'error',
      });
      return;
    }

    postDesignImport.execute({ dgId: data.id, name: data.name });
  };

  const onDesignExportToOtherPanelSubmit = (data) => {
    if (!(data.designId && data.designVersion && data.designName && data.destinationClientId)) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('BledneDaneWFormularzu'),
        context: 'error',
      });
      return;
    }

    postDesignExportToOtherPanel.execute({
      id: data.designId,
      version: data.designVersion,
      name: data.designName,
      destinationClientId: data.destinationClientId,
    });
  };

  const dialogFileReplace = useDialog({
    title: getLiteral('ImportujSzablonZDG'),
    content: <AddMigrationDialog loading={postDesignImport.loading} onSubmit={onDesignImportSubmit} />,
  });

  const dialogExportDesignToOtherPanel = useDialog({
    title: getLiteral('SkopiujSzablonDoInnegoPaneluComposer'),
    content: (
      <AddExportDesignToOtherPanelDialog
        loading={postDesignExportToOtherPanel.loading}
        onSubmit={onDesignExportToOtherPanelSubmit}
      />
    ),
  });

  useEffect(() => {
    if (postDesignImport.response) {
      dialogFileReplace.closeDialog();
      getDesignsToBuild.execute();
      createToast({
        title: getLiteral('SzablonZostalDodanyDoListyOczekujacychNaImport'),
        message: '',
        context: 'success',
      });
    } else if (postDesignImport.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postDesignImport.error.message,
        context: 'error',
      });
    }
  }, [postDesignImport.response, postDesignImport.error]);

  useEffect(() => {
    if (postDesignExportToOtherPanel.response) {
      dialogExportDesignToOtherPanel.closeDialog();
      createToast({
        title: getLiteral('SzablonZostalPrzeniesionyDoWskazanegoPaneluKlienta'),
        message: '',
        context: 'success',
      });
    } else if (postDesignExportToOtherPanel.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postDesignExportToOtherPanel.error.message,
        context: 'error',
      });
    }
  }, [postDesignExportToOtherPanel.response, postDesignExportToOtherPanel.error]);

  useEffect(() => {
    if (getDesignsToBuild.response) {
      setBuilds(getDesignsToBuild.response.models);
      setPagesCount(getDesignsToBuild.response.pagesCount);
      setBuildsWaiting(getDesignsToBuild.response.models.length);
    }
  }, [getDesignsToBuild.response, getDesignsToBuild.error]);

  useEffect(() => {
    getDesignsToBuild.execute();
  }, [page]);

  return (
    <>
      <Row>
        <Col size={6}>
          <Button onClick={() => dialogFileReplace.openDialog()}>{getLiteral('ImportujSzablonZDG')}</Button>
          {canMigrateDesigns.condition && (
            <ExportDesignButton onClick={() => dialogExportDesignToOtherPanel.openDialog()}>
              {getLiteral('SkopiujSzablonDoInnegoPaneluComposer')}
            </ExportDesignButton>
          )}
        </Col>
      </Row>
      <Marginer bottom={2}></Marginer>
      {getDesignsToBuild.loading ? (
        <Loader />
      ) : builds.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>{getLiteral('ID')}</th>
                <th>{getLiteral('nazwa')}</th>
                <th>{getLiteral('Typ')}</th>
                <th>{getLiteral('Status')}</th>
                <th>{getLiteral('DodanoDoMigracji')}</th>
              </tr>
            </thead>
            <tbody>
              {builds.map((el) => (
                <tr key={`${el.dgId}`}>
                  <th>{`${el.dgId}`}</th>
                  <th>{`${el.name}`}</th>
                  <th>
                    <Tag type={el.type}>{el.type}</Tag>
                  </th>
                  <th>
                    {parseInt(el.processing) !== 0
                      ? `${getLiteral('Rozpoczeto')} ${el.processing}`
                      : getLiteral('Oczekuje')}
                  </th>
                  <th>{el.addedTime}</th>
                </tr>
              ))}
            </tbody>
          </Table>
          <Marginer bottom={2} top={2}>
            {pagesCount ? <Pagination {...paginationProps} /> : null}
          </Marginer>
        </>
      ) : (
        <span>{getLiteral('BrakSzablonowOczekujacychWKolejce')}</span>
      )}
      {dialogFileReplace.dialog}
      {dialogExportDesignToOtherPanel.dialog}
    </>
  );
};

const AddMigrationDialog = ({ loading, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { getLiteral } = useContext(composerCxt);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register({ required: true })}
        name="name"
        title={getLiteral('NazwaKompozycji')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
      />

      <Input
        ref={register({ required: true })}
        name="id"
        title={getLiteral('IdentyfikatorSzablonuWDG')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />

      <Button loading={loading.toString()} type="submit">
        {getLiteral('Dodaj')}
      </Button>
    </form>
  );
};

const AddExportDesignToOtherPanelDialog = ({ loading, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { getLiteral } = useContext(composerCxt);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        ref={register({ required: true })}
        name="designId"
        title={getLiteral('IdKompozycjiDoSkopiowania')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
      />

      <Input
        ref={register({ required: true })}
        name="designVersion"
        title={getLiteral('WersjaKompozycjiDoSkopiowania')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />

      <Input
        ref={register({ required: true })}
        name="designName"
        title={getLiteral('NowaNazwaKompozycji')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
      />

      <Input
        ref={register({ required: true })}
        name="destinationClientId"
        title={getLiteral('IdKlientaDoKtoregoZostanieSkopiowanaKompozycja')}
        error={errors.name && getLiteral('PoleObowiazkowe')}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />

      <Button loading={loading.toString()} type="submit">
        {getLiteral('Skopiuj')}
      </Button>
    </form>
  );
};

const ExportDesignButton = styled(Button)`
  margin-left: 1rem;
`;
