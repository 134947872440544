import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { composerCxt } from 'contexts/ComposerContext';

export const useAlert = ({ onAccept, onDecilne, headline, text, succesText, removeText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [alertParams, setAlertParams] = useState(null);
  const { getLiteral } = useContext(composerCxt);

  // handle escape button press
  const keyPress = (event) => {
    if (event.keyCode === 27) {
      setIsVisible(false);
    }
  };

  return {
    openAlert: (...params) => {
      setAlertParams(params);
      setIsVisible(true);
    },
    alert: isVisible && (
      <AlertWrapper onClick={() => setIsVisible(false)} onKeyUp={keyPress}>
        <Alert>
          <Headline>
            {headline || getLiteral('czypewien')}
            <ButtonClose>
              <FontAwesomeIcon icon={faTimes} />
            </ButtonClose>
          </Headline>
          {text && <AlertDescription>{text}</AlertDescription>}
          <Flex>
            <AlertButton
              success
              onClick={() => {
                setIsVisible(false);
                onAccept(alertParams);
              }}
            >
              {succesText || getLiteral('Ok')}
            </AlertButton>
            <AlertButton error autoFocus={1} onClick={onDecilne ? onDecilne : () => setIsVisible(false)}>
              {removeText || getLiteral('Anuluj')}
            </AlertButton>
          </Flex>
        </Alert>
      </AlertWrapper>
    ),
  };
};

const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const fadeTop = keyframes`
  from {
    transform: translate(-50%, -70%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const AlertWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

const Alert = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% + 150px);
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  padding: 2rem;
  animation: ${fadeTop} 0.5s both;
`;

const Headline = styled.div`
  font-weight: 700;
  font-size: 1.6rem !important;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

const AlertDescription = styled.div`
  font-size: 1.3rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const AlertButton = styled(Button)`
  margin-right: 2rem;
  background-color: #a94442;
  border: 2px solid #a94442;

  &:focus,
  &:hover {
    border: 2px solid #ce8180;
  }

  ${({ error }) =>
    error &&
    css`
      margin: 0;
      color: black;
      background-color: #fafafa;
      border: 1px solid #fafafa;

      &:focus {
        border: 2px solid #ce8180;
      }
    `}
`;
