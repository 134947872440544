export const Logo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.013 24.8">
    <g transform="translate(-149.5 -289.2)">
      <g transform="translate(149.5 289.2)">
        <g>
          <g id="Group_991" data-name="Group 991" transform="translate(0 10.18)">
            <path
              d="M157.893,309.624v7.581h-6.769v-7.581h6.769M159.517,308H149.5v10.83h10.017V308Z"
              transform="translate(-149.5 -308)"
            ></path>
          </g>
          <g transform="translate(12.996 3.682)">
            <path
              id="Path_1498"
              data-name="Path 1498"
              d="M181.893,297.624v7.581h-6.769v-7.581h6.769M183.517,296H173.5v10.83h10.017V296Z"
              transform="translate(-173.5 -296)"
            ></path>
          </g>
          <g transform="translate(3.628)">
            <path
              d="M161.507,290.824v4.224h-3.682v-4.224h3.682m1.624-1.624H156.2v7.473h6.931V289.2Z"
              transform="translate(-156.2 -289.2)"
            ></path>
          </g>
          <g transform="translate(13.375 17.328)">
            <path
              d="M179.507,322.824v4.224h-3.682v-4.224h3.682m1.624-1.625H174.2v7.473h6.931V321.2Z"
              transform="translate(-174.2 -321.2)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
