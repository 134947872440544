/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { composerCxt } from 'contexts/ComposerContext';
import { Button } from 'components/atoms/Button';
import { Checkbox, Input } from 'components/atoms/Form';
import { Tag } from 'components/atoms/Tag';

export const ActualizeTemplateDialog = ({ id, version, options, fetch }) => {
  const { getLiteral, pagesIds, groups } = useContext(composerCxt);

  const [selectAll, setSelectAll] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedComponents, setSelectedComponents] = useState({});

  const allOptions = useMemo(() => {
    const obj = {};
    filteredOptions.forEach((el) => {
      obj[el.id] = true;
    });

    return obj;
  }, [filteredOptions]);

  const allPagesNames = useMemo(() => {
    const obj = {};
    options.forEach((component) => {
      if (obj[component.group]) {
        return;
      }

      const componentGroup = groups.find((el) => el.id === component.group);
      if (!componentGroup || !componentGroup.siteId) {
        return '';
      }

      const componentPage = pagesIds.find((el) => el.id === componentGroup.siteId);

      if (!componentPage || !componentPage.description) {
        obj[component.group] = getLiteral('Uklad').toLowerCase();
      } else {
        obj[component.group] = componentPage.description;
      }
    });

    return obj;
  }, [options]);

  useEffect(() => {
    if (!selectAll) {
      setSelectedComponents({});
    } else {
      setSelectedComponents(allOptions);
    }
  }, [selectAll]);

  return (
    <>
      <Input
        title={getLiteral('SzukajPoNazwie')}
        onChange={(event) =>
          setFilteredOptions(
            event.target.value
              ? options.filter((component) => {
                  return (
                    component.name.toUpperCase().includes(event.target.value.toUpperCase()) ||
                    allPagesNames[component.group].toUpperCase().includes(event.target.value.toUpperCase())
                  );
                })
              : options,
          )
        }
      />
      <ComponentsPanel>
        {filteredOptions.map((component) => {
          const key = component.id;

          return (
            <ComponentDiv key={key}>
              <Checkbox
                onChange={() => {
                  setSelectedComponents((state) => {
                    const newState = { ...state };
                    newState[key] = !state[key];
                    return newState;
                  });
                }}
                checked={selectedComponents[key] === true}
              />
              <TagContainer>
                <Tag type={component.type}>{component.type}</Tag>
              </TagContainer>
              <span>{`${component.name} (${allPagesNames[component.group]})`}</span>
            </ComponentDiv>
          );
        })}
      </ComponentsPanel>
      <SelectAll onClick={() => setSelectAll(!selectAll)}>
        {selectAll !== true ? (
          <>
            {filteredOptions.length < options.length ? (
              <span>{getLiteral('ZaznaczWszystkieWyswietlonePozycje')}</span>
            ) : (
              <span>{getLiteral('ZaznaczWszystko')}</span>
            )}
          </>
        ) : (
          <span>{getLiteral('OdznaczWszystko')}</span>
        )}
      </SelectAll>
      <Button
        loading={fetch.loading.toString()}
        onClick={() =>
          fetch.execute({
            id: id,
            version: version,
            components: Object.keys(selectedComponents).filter((el) => selectedComponents[el] === true),
          })
        }
      >
        {getLiteral('AktualizujKompozycje')}
      </Button>
    </>
  );
};

const TagContainer = styled.div`
  & > span {
    margin-top: -1px;
  }
`;

const ComponentsPanel = styled.div`
  overflow: auto;
  height: 80%;
  max-height: 470px;
  margin-bottom: 1rem;
  border: 1px solid #ececec;
  padding: 1rem;
`;

const ComponentDiv = styled.div`
  padding: 0.5rem 1rem 0.5rem 0;
  display: flex;
`;

const SelectAll = styled.div`
  margin-bottom: 2rem;
  cursor: pointer;
`;
