import styled, { css } from 'styled-components';

export const Label = styled.label`
  pointer-events: none;
  font-size: 1.2rem;
  line-height: 20px;
  display: inline-block;
  background-color: #fff;
  padding: 0 0.7rem;
  position: absolute;
  top: -10px;
  left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  z-index: 1;

  ${({ error }) =>
    error
      ? css`
          color: red;
        `
      : ''}

  ${({ isReadOnly }) =>
    isReadOnly === 'true' &&
    css`
      background: linear-gradient(180deg, var(--white) 0%, hsl(0, 0%, 95%) 100%);
    `}
  ${({ detached }) =>
    detached &&
    css`
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 2.6rem;
      background-color: transparent;
      position: relative;
      top: auto;
      left: auto;
      z-index: auto;
      padding-left: 0;
      padding-right: 0;
    `}
`;
