import styled, { css } from 'styled-components';

export const Group = styled.div`
  position: relative;
  ${({ mb }) =>
    mb
      ? css`
          margin-bottom: ${mb}rem;
        `
      : css`
          margin-bottom: 0rem;
        `}
`;
