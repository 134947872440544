/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { sprintf } from 'sprintf-js';

import { CopyComponent } from 'components/atoms/CopyComponent';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { Loader } from 'components/atoms/Loader';
import { Editor } from 'components/molecules/Editor';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_TEMPLATES_GET_CSS, EDP_MODULES, API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY } from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import useOnScreen from 'utils/useOnScreen';

export const TemplateCssCode = () => {
  const [cssCode, setCssCode] = useState('');
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const { id, version, editView } = useContext(templateCxt);
  const [currentComponent, setCurrentComponent] = useState({});
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [refreshCss, setRefreshCss] = useState(false);
  const { pathname, hash } = useLocation();

  const postEditStandardComponent = useFetch(API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY, 'POST');
  const getCssCode = useFetch(`${API_TEMPLATES_GET_CSS}${id}/version/${version}`, 'GET');

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    setIsComponentVisible(isVisible);
    if (isVisible === true) {
      getCssCode.execute();
    }
  }, [isVisible, refreshCss]);

  useEffect(() => {
    if (getCssCode.response) {
      setCssCode(getCssCode.response.content);
    } else if (getCssCode.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getCssCode.error.message,
        context: 'error',
      });
    }
  }, [getCssCode.response, getCssCode.error]);

  const submitFunction = (name) => {
    postEditStandardComponent.execute({
      standardComponentId: currentComponent.id,
      standardComponentVersion: currentComponent.version,
      designId: id,
      designVersion: version,
      newComponentName: name,
    });
  };

  const { openDialog, dialog, closeDialog } = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: (
      <CopyComponent
        submitFunction={submitFunction}
        placeholder={currentComponent.name}
        loading={postEditStandardComponent.loading.toString()}
        type="standard"
      />
    ),
  });

  useEffect(() => {
    if (postEditStandardComponent.error) {
      createToast({
        title: getLiteral('NieMoznaUtworzycKomponentu'),
        message: postEditStandardComponent.error.message,
        context: 'error',
      });
      return;
    }

    if (postEditStandardComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUtworzony'),
        message: `${getLiteral('PrzekierowanoDoEdycjiKomponentu')} ${postEditStandardComponent.response.id}`,
        context: 'success',
      });

      window.open(
        `${EDP_MODULES}${postEditStandardComponent.response.id}/version/${
          postEditStandardComponent.response.version
        }?link=${encodeURIComponent(pathname + hash)}`,
        '_blank',
      );

      setRefreshCss((state) => !state);
      closeDialog();
    }
  }, [postEditStandardComponent.response, postEditStandardComponent.error]);

  const goToComponent = (editor, edit) => {
    const regex = /\/*component_css_start id=([a-zA-Z\d.]+) version=([\d]+) type=([\w]+)( name=([\s\S]+))?\*\//;

    const currline = editor.getSelectionRange().start.row;
    const textValue = editor.getValue();
    let upperLines = '';
    if (textValue) {
      upperLines = textValue.split('\n').slice(0, currline + 1);

      for (let i = upperLines.length - 1; i >= 0; i--) {
        const match = upperLines[i].match(regex);
        if (match && match[1]) {
          const id = match[1];
          const version = match[2] ?? 1;
          const type = match[3] ?? 'standard';
          const name = match[5] ?? '';

          if (edit) {
            if (type === 'custom' || type === 'individual') {
              window.open(
                `${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`,
                '_blank',
              );
            } else {
              setCurrentComponent({
                id,
                version,
                type,
                name,
              });
              openDialog();
            }
          } else {
            window.open(`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`, '_blank');
          }

          break;
        }
      }
    }
  };

  const commands = [
    {
      name: 'goToComponent',
      bindKey: { win: 'Ctrl-P', mac: 'Command-P' },
      exec: (editor) => goToComponent(editor, false),
      readOnly: true,
    },
  ];

  if (editView) {
    commands.push({
      name: 'editComponent',
      bindKey: { win: 'Ctrl-M', mac: 'Command-M' },
      exec: (editor) => goToComponent(editor, true),
      readOnly: true,
    });
  }

  return (
    <div ref={ref}>
      {getCssCode.loading || isComponentVisible === false ? (
        <Loader></Loader>
      ) : (
        <>
          <InfoLabel
            text={sprintf(
              getLiteral(
                'PonizejZnajdujeSieAktualnaZawartoscPlikuStyle_lessCssDlaWybranegoSzablonuMozeszGoPrzegladacWCeluZnalezieniaFragmentowKoduCssAbyPrzejscDoKomponentuOdpowiedzialnegoZaDanyFragmentKoduUstawKursorNaOdpowiedniejLiniiANastepnieWcisnijXWNowymOknieZostanieOtwartaK',
              ),
              'ctrl + M',
            )}
          ></InfoLabel>
          <Editor mode="less" value={cssCode} readOnly={true} commands={commands} refreshCommands={id} />
          {dialog}
        </>
      )}
    </div>
  );
};
