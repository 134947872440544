/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { PullRequestLabel, STATUS_TYPES } from 'components/organisms/ComponentsPullRequests';
import {
  PullRequestCompareDialog,
  PullRequestDialogStyles,
} from 'components/organisms/PullRequest/PullRequestCompareDialog';
import { componentCxt } from 'contexts/ComponentContext';
import { composerCxt } from 'contexts/ComposerContext';
import { API_PULL_REQUESTS_SET_STATUS } from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { toastCtx } from 'contexts/ToastContext';

export const PullRequestInfo = ({ control }) => {
  const { getLiteral, getStatusLabel } = useContext(composerCxt);
  const { pullRequest, deletePullRequest, parentId, parentVersion, setPullRequest } = useContext(componentCxt);
  const { createToast } = useContext(toastCtx);

  const postPullRequestStatus = useFetch(`${API_PULL_REQUESTS_SET_STATUS}`, 'POST');
  const state = useWatch({ control });

  const pullRequestDetailsDialog = useDialog({
    content: (
      <PullRequestCompareDialog
        data={state}
        diffData={{ id: parentId, version: parentVersion }}
        pullRequestData={pullRequest}
        changePullRequestStatus={postPullRequestStatus}
      />
    ),
    title: getLiteral('SzczegolyAkceptacjiZmian'),
    styles: PullRequestDialogStyles,
  });

  useEffect(() => {
    if (postPullRequestStatus.response) {
      pullRequestDetailsDialog.closeDialog();

      const newPullRequestData = { ...pullRequest };
      newPullRequestData.activity.push(postPullRequestStatus.response);

      newPullRequestData.activity = [
        ...new Map(newPullRequestData.activity.map((item) => [item['createUser'], item])).values(),
      ];

      const uniqueStatuses = [...new Set(newPullRequestData.activity.map((item) => item.status))].filter(
        (status) => status !== STATUS_TYPES.TO_VERIFY,
      );

      newPullRequestData.status = uniqueStatuses.length === 1 ? uniqueStatuses[0] : STATUS_TYPES.NEEDS_WORK;

      setPullRequest(newPullRequestData);
    } else if (postPullRequestStatus.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postPullRequestStatus.error.message,
        context: 'error',
      });
    }
  }, [postPullRequestStatus.response, postPullRequestStatus.error]);

  return (
    <StyledLink>
      <div>
        <PullRequestLabel type={pullRequest.status}>{getStatusLabel(pullRequest.status)}</PullRequestLabel>
        <span>
          {pullRequest.status === STATUS_TYPES.TO_VERIFY
            ? getLiteral('KomponentOczekujeNaAkcpetacjePrzedWydaniemZmian')
            : pullRequest.status === STATUS_TYPES.ACCEPTED
            ? `${getLiteral('KomponentZostalZaakceptowanyMozeszWydawacZmiany')} ${getLiteral(
                'ZapisZmianBedzieWymagalPonownejAkceptacji',
              )}`
            : getLiteral('KomponentZostalOdrzuconyWydanieZmianZostaloWstrzymane')}
        </span>
      </div>
      <div>
        <ActionButton onClick={() => deletePullRequest(pullRequest.id)}>{getLiteral('Wycofaj')}</ActionButton>
        {pullRequest.activity ? (
          <ActionButton onClick={() => pullRequestDetailsDialog.openDialog()}>{getLiteral('Szczegoly')}</ActionButton>
        ) : null}
      </div>
      {pullRequestDetailsDialog.dialog}
    </StyledLink>
  );
};

const ActionButton = styled.button`
  border-radius: 4px;
  padding: 1rem;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: #e0e0e0;

  &:hover {
    background-color: var(--gray-light);
  }
`;

const StyledLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
