import styled, { css } from 'styled-components';

export const LoaderBackground = styled.div`
  ${({ content }) =>
    content &&
    css`
      background-color: rgba(255, 255, 255, 0.75);
      position: fixed;
      top: 0;
      left: 300px;
      width: var(--widthWithoutAside);
      height: 100vh;
      z-index: 9999;
    `}
`;

export const Loader = styled.div`
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  opacity: 1 !important;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  ${({ relative }) =>
    relative &&
    css`
      position: absolute;
      top: calc(50% - 5em);
      left: calc(50% - 5em);
      margin: 0;
      z-index: 9999;
    `}

  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const SmallLoaderAfter = css`
  &:after {
    content: '';
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 4px solid transparent;
    border-right: 4px solid #000;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
  }
`;
