/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import { Col, Container, Content, Row } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Action, ManagerFileListItem } from 'components/atoms/ManagerFileListItem';
import { componentCxt } from 'contexts/ComponentContext';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_MODULES_GET_GFX, API_GFX_ADD } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { useDialog } from 'utils/useDialog';
import { FileManagerDialogComponent } from 'components/atoms/FileManagerDialogComponent';
import { FileAddOrChooseDialogComponent } from 'components/atoms/FileAddOrChooseDialogComponent';
import { base64replace } from 'helpers/base64helper';

export const ModuleFormFiles = ({ control, refreshFiles, saveCall }) => {
  const [files, setFiles] = useState([]);
  const { getLiteral } = useContext(composerCxt);
  const { id, type, version } = useContext(componentCxt);

  const getClientFiles = useFetch(`${API_MODULES_GET_GFX}${id}/version/${version}`, 'GET');

  useEffect(() => {
    getClientFiles.execute();
  }, [id, version, refreshFiles]);

  useEffect(() => {
    if (getClientFiles.response) {
      setFiles(getClientFiles.response);
    }
  }, [getClientFiles.response]);

  return (
    <Wrapper>
      <StyledContent>
        <StyledContainer>
          <Row>
            {files.length === 0 ? (
              <span style={{ padding: '0 1.5rem' }}>{getLiteral('BrakPlikowDoWyswietlenia')}</span>
            ) : null}
            {files.map((item) => {
              return (
                <Col size={3} key={item.name}>
                  <ManagerFileListItem
                    name={item.name}
                    path={item.path}
                    replaceComponent={
                      <ReplaceFileComponent control={control} name={item.name} type={type} saveCall={saveCall} />
                    }
                    {...item}
                  />
                </Col>
              );
            })}
          </Row>
        </StyledContainer>
      </StyledContent>
    </Wrapper>
  );
};

ModuleFormFiles.propTypes = {
  saveCall: PropTypes.func,
};

const ReplaceFileComponent = ({ control, name, type, saveCall }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const { replaceImageInComponentCode } = useContext(componentCxt);
  const postClientFileAdd = useFetch(API_GFX_ADD, 'POST');
  const currentFormState = useWatch({ control });

  const dialogFileManager = useDialog({
    title: getLiteral('WybierzPlikZManagera'),
    content: (
      <FileManagerDialogComponent
        replaceFile={(customName) => {
          replaceImageInComponentCode(currentFormState.sources, name, customName);
          saveCall();
          dialogFileReplace.closeDialog();
          dialogFileManager.closeDialog();
        }}
      />
    ),
  });

  const dialogFileReplace = useDialog({
    title: (
      <>
        {getLiteral('PodmienPlikNaWlasny')}: <strong>{name}</strong>
      </>
    ),
    content: (
      <>
        {postClientFileAdd.loading ? (
          <Loader />
        ) : (
          <FileAddOrChooseDialogComponent
            isStandardComponent={type === 'standard'}
            openManager={dialogFileManager.openDialog}
            addNewFile={(base64, file) => {
              postClientFileAdd.execute({
                gfx: file.name,
                standardFiles: type === 'standard',
                gfxContent: base64replace(base64),
              });
            }}
          />
        )}
      </>
    ),
  });

  useEffect(() => {
    if (postClientFileAdd.response) {
      replaceImageInComponentCode(currentFormState.sources, name, postClientFileAdd.response.name);
      saveCall();
      dialogFileReplace.closeDialog();
    } else if (postClientFileAdd.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('BladWysylaniaDanych'),
        context: 'error',
      });
    }
  }, [postClientFileAdd.response, postClientFileAdd.error]);

  return (
    <>
      <Action type="button" onClick={() => dialogFileReplace.openDialog()}>
        <FontAwesomeIcon icon={faSync} />
      </Action>
      {dialogFileReplace.dialog}
      {dialogFileManager.dialog}
    </>
  );
};

ReplaceFileComponent.propTypes = {
  saveCall: PropTypes.func,
};

const StyledContent = styled(Content)`
  width: 100%;
`;

const StyledContainer = styled(Container)`
  padding: 0;
  max-width: 100%;
  min-height: 10vh;
`;

const Wrapper = styled.div`
  ${({ isHidden }) =>
    isHidden === 'true'
      ? css`
          display: none;
        `
      : ''}
`;
