import { faPlusSquare, faMinusSquare, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';

import { composerCxt } from 'contexts/ComposerContext';
import { EDP_MODULES } from 'helpers/endpoints';

export default function CompareCompositionList(props) {
  const [item, setItem] = useState(props.elements[0] ?? null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { getLiteral } = useContext(composerCxt);

  function renderRow(rowProps) {
    const { index, style } = rowProps;

    const handleClick = (index) => {
      const el = props.elements[index];
      setItem(el);
      setSelectedIndex(index);
    };

    const textStyle = {
      fontSize: '1.3rem',
    };

    const renderIcon = (el) => {
      if (el.oldCode.length === 0) {
        return <FontAwesomeIcon icon={faPlusSquare} style={{ color: '#00875a', marginRight: '4px' }} />;
      }

      if (el.newCode.length === 0) {
        return <FontAwesomeIcon icon={faMinusSquare} style={{ color: '#a94442', marginRight: '4px' }} />;
      }

      return <FontAwesomeIcon icon={faEdit} style={{ color: '#0052cc', marginRight: '4px' }} />;
    };

    const secondaryStyle = { display: 'block', marginLeft: '17px' };

    return (
      <ListItem button style={style} key={index} onClick={() => handleClick(index)} selected={index === selectedIndex}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="h6" style={textStyle}>
              <>
                {renderIcon(props.elements[index])}
                <strong>{`${getLiteral('TypKomponentu')}: ${props.elements[index].group}`}</strong>
              </>
            </Typography>
          }
          secondary={
            <Typography variant="h6" style={textStyle}>
              <span style={secondaryStyle}>{`File: ${props.elements[index].type}`}</span>
              <span style={secondaryStyle}>{`User: ${props.elements[index].newComponentCreateUser}`}</span>
              <span style={secondaryStyle}>{`Changed: ${props.elements[index].newComponentCreateTime}`}</span>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };

  return (
    <Wrapper>
      <FixedSizeList height={900} width={400} itemSize={100} itemCount={props.elements.length}>
        {renderRow}
      </FixedSizeList>
      {item ? (
        <DiffView>
          <ReactDiffViewer
            oldValue={item.oldCode}
            newValue={item.newCode}
            splitView={true}
            disableWordDiff={true}
            leftTitle={
              <>
                <StyledLink href={`${EDP_MODULES}${item.oldComponentId}/version/${item.oldComponentVersion}`}>
                  {`${getLiteral('PoprzedniaWersja')} - ${item.oldComponentId} v.${item.oldComponentVersion}`}
                </StyledLink>
              </>
            }
            rightTitle={
              <>
                <StyledLink href={`${EDP_MODULES}${item.newComponentId}/version/${item.newComponentVersion}`}>
                  {`${getLiteral('AktualnaWersja')} - ${item.newComponentId} v.${item.newComponentVersion}`}
                </StyledLink>
              </>
            }
            compareMethod={'diffLines'}
          />
        </DiffView>
      ) : null}
    </Wrapper>
  );
}

const StyledLink = styled.a`
  color: #000;
  text-decoration: none;
`;

const DiffView = styled.div`
  overflow: auto;
  height: 900px;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 0 2vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
`;
