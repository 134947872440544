import styled, { css } from 'styled-components';

export const Heading = styled.h1`
  font-size: 2.6rem;
  font-weight: 600;

  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          font-size: 2.6rem;
        `;
      case 'medium':
        return css`
          font-size: 2rem;
        `;
      case 'small':
        return css`
          font-size: 1.6rem;
        `;
      default:
        return css`
          font-size: 2.6rem;
        `;
    }
  }}
`;
