import styled, { css } from 'styled-components';

import { SmallLoaderAfter } from 'components/atoms/Loader';

export const standardButton = `
  color: #fff;
  fill: #fff;
  border: none;
  height: 4rem;
  font-weight: normal;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  &[disabled] {
    background-color: var(--buttonDisabledColorBg);
    color: var(--buttonDisabledColor);
    fill: var(--buttonDisabledColor);
    pointer-events: none;
  }
  > svg {
    margin-right: 1rem;
    font-size: 2rem;
  }
  span.svg-icon {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 1.5rem;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: block;
      path,
      ellipse {
        fill: inherit;
      }
    }
  }
`;

const buttonStyles = css`
  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
      position: relative;

      ${SmallLoaderAfter}

      &:after {
        border-color: transparent #fff transparent #fff;
      }
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ color }) => {
    switch (color) {
      case 'green':
        return css`
          background-color: var(--green);
        `;
      case 'red':
        return css`
          background-color: var(--red);
        `;
      case 'gray':
        return css`
          background-color: var(--gray);
          color: var(--black);
          fill: var(--black);
        `;
      case 'white':
        return css`
          background-color: var(--white);
          color: var(--black);
          fill: var(--black);
          border: 1px solid var(--gray-dark);
        `;
      default:
        return css`
          background-color: var(--blue);
        `;
    }
  }}
`;

export const Button = styled.button`
  ${standardButton}
  ${buttonStyles}
`;

export const ButtonLink = styled.a`
  ${standardButton}
  ${buttonStyles}
  text-decoration: none;
`;

const CompactStyles = css`
  height: 3.1rem;
  font-size: 1.4rem;
  line-height: 2.6rem;
  font-weight: normal;
  padding: 0.6rem 1.2rem;
  &:disabled {
    background-color: var(--buttonDisabledColorBg);
    color: var(--buttonDisabledColor);
    fill: var(--buttonDisabledColor);
    span.svg-icon svg {
      path,
      ellipse {
        fill: inherit;
      }
    }
  }
  ${({ color }) => {
    switch (color) {
      case 'green':
        return css`
          background-color: var(--green);
        `;
      case 'red':
        return css`
          background-color: var(--red);
        `;
      case 'gray':
        return css`
          background-color: var(--gray);
          color: var(--black);
          fill: var(--black);
        `;
      case 'white':
        return css`
          background-color: var(--white);
          color: var(--black);
          fill: var(--black);
          border: 1px solid var(--gray-dark);
        `;
      default:
        return css`
          background-color: var(--blue);
        `;
    }
  }}
`;

export const CompactButton = styled(Button)`
  ${CompactStyles}
`;

export const CompactButtonLink = styled(ButtonLink)`
  ${CompactStyles}
`;

export const AddButton = styled.button`
  background-color: var(--white);
  height: 5rem;
  position: sticky;
  bottom: 1rem;
  width: 100%;
  border: 2px dashed var(--blue);
  border-radius: var(--border-radius);
  margin-top: 2rem;
  font-size: 1.8rem;
  color: var(--blue);
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

export const ButtonGroup = styled.div`
  display: inline-block;
  & > button,
  & > a {
    background-color: transparent;
    color: var(--text);
    fill: var(--text);
    position: relative;
    &:disabled {
      background-color: transparent;
      color: var(--buttonDisabledColor);
      svg {
        fill: var(--buttonDisabledColor);
      }
    }
    &:after {
      content: '';
      width: 1px;
      height: calc(100% - 0rem);
      background: var(--gray);
      position: absolute;
      display: inline-block;
      right: 0;
      height: calc(100% - 1.2rem);
    }
  }
  & > button:last-child:after,
  & > a:last-child:after {
    display: none;
  }
  & > button:first-child,
  & > a:first-child {
    padding-left: 0;
  }
  button + & button:first-child,
  a + & a:first-child,
  a + & button:first-child,
  button + & a:first-child {
    padding-left: 1.2rem;
  }
`;
