import { useContext, useRef, useState } from 'react';

import { Group, Input } from './Form';
import { InfoLabel } from './InfoLabel';

import { Button } from 'components/atoms/Button';
import { composerCxt } from 'contexts/ComposerContext';

export const CopyComponent = ({ type, submitFunction, loading, placeholder, passwordField = false }) => {
  const { getLiteral } = useContext(composerCxt);

  const formNameRef = useRef(null);
  const passwordRef = useRef(null);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);

  return (
    <>
      <form>
        {type === 'standard' ? (
          <InfoLabel
            type="info"
            text={getLiteral(
              'ToJestKomponentStandardowyAbyMocGoEdytowacZostanieStworzonaKopiaWKtorejBedzieszMoglGoZmodyfikowac',
            )}
          />
        ) : null}
        <Group>
          {passwordField && (
            <Input
              ref={passwordRef}
              name="password"
              type="password"
              autocomplete="off"
              title={getLiteral('WpiszHasloZabezpieczajace')}
              error={isEmptyPassword ? isEmptyPassword : false}
              onChange={({ target }) => {
                if (target.value) {
                  setIsEmptyPassword(false);
                } else {
                  setIsEmptyPassword(`${getLiteral('PoleObowiazkowe')}!`);
                }
              }}
            />
          )}
          <Input
            ref={formNameRef}
            title={getLiteral('NazwaNowegoKomponentu')}
            error={isEmptyError ? isEmptyError : false}
            autoFocus
            defaultValue={placeholder}
            onChange={({ target }) => {
              if (target.value) {
                setIsEmptyError(false);
              } else {
                setIsEmptyError(`${getLiteral('PoleObowiazkowe')}!`);
              }
            }}
          />
        </Group>
        <Button
          type="submit"
          loading={loading}
          onClick={(event) => {
            event.preventDefault();
            let submit = () => submitFunction(formNameRef.current.value);

            if (passwordField) {
              if (!(passwordRef.current && passwordRef.current.value && !isEmptyPassword)) {
                setIsEmptyPassword(true);
                return;
              }
              submit = () => submitFunction(formNameRef.current.value, passwordRef.current.value);
            }

            formNameRef.current && formNameRef.current.value && !isEmptyError
              ? submit()
              : setIsEmptyError(isEmptyError ? isEmptyError : `${getLiteral('PoleObowiazkowe')}!`);
          }}
        >
          {getLiteral('Stworz')}
        </Button>
      </form>
    </>
  );
};
