/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Container, Layout, Content } from 'components/atoms/Grid';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { composerCxt } from 'contexts/ComposerContext';
import { useHashTabs } from 'utils/useHashTabs';
import { TranslationQueue } from 'components/organisms/TranslationQueue';
import { TranslationHistory } from 'components/organisms/TranslationHistory';

const TranslationPage = () => {
  const { getLiteral } = useContext(composerCxt);
  const [historyErrorsCount, setHistoryErrorsCount] = useState(0);
  const [translationsWaitingCount, setTranslationWaiting] = useState(0);
  const configTabs = [
    {
      title: getLiteral('Podglad'),
      component: <TranslationQueue setTranslationWaiting={setTranslationWaiting} />,
    },
    {
      title: getLiteral('Historia'),
      component: <TranslationHistory setHistoryErrorsCount={setHistoryErrorsCount} />,
    },
  ];

  const { tabs, tabsPanel } = useHashTabs(configTabs);

  return (
    <Layout>
      <Aside>
        {translationsWaitingCount > 0 || historyErrorsCount > 0 ? (
          <QueueInfo>
            <h1>{getLiteral('Statystyki')}</h1>
            {translationsWaitingCount > 0 && (
              <div>
                <span>
                  {getLiteral('LiczbaTlumaczenOczekujacych')}: {translationsWaitingCount}
                </span>
              </div>
            )}
            {historyErrorsCount > 0 && (
              <div>
                <span>
                  {getLiteral('LiczbaTlumaczenZakonczonychBledem')}: {historyErrorsCount}
                </span>
              </div>
            )}
          </QueueInfo>
        ) : null}
      </Aside>
      <Content>
        <Container>
          <Header title={getLiteral('KolejkaTlumaczen')} tabs={tabs} />
          {tabsPanel}
        </Container>
      </Content>
    </Layout>
  );
};

const QueueInfo = styled.div`
  padding: 1.5rem 2.5rem;

  span {
    font-size: 1.3rem;
  }

  h1 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  div {
    margin-bottom: 0.5rem;
  }
`;

export default TranslationPage;
