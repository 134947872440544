/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Content, Layout } from 'components/atoms/Grid';
import { Aside } from 'components/organisms/Aside';
import { ModuleForm } from 'components/organisms/ModuleForm';
import ComponentCxtProvider from 'contexts/ComponentContext';

const ModulePage = () => {
  return (
    <ComponentCxtProvider>
      <Layout>
        <Aside />
        <Content>
          <Container>
            <ModuleForm />
          </Container>
        </Content>
      </Layout>
    </ComponentCxtProvider>
  );
};

export default ModulePage;
