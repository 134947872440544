/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Group } from './Group';
import { Label } from './Label';

const LabelDrop = styled(Label)`
  position: static;
`;

export const PictureDropZone = ({ onChange, title, text, preview, readOnly, setOnChangePreview = true, ...props }) => {
  const [imagePreview, setImagePreview] = useState(null);

  // When preview is returned from endpoint
  // render as a base64 string
  useEffect(() => {
    if (preview) {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        setImagePreview(dataURL);

        if (setOnChangePreview) {
          onChange(dataURL);
        }

        canvas = null;
      };
      img.src = preview;
    } else {
      setImagePreview(null);
    }
  }, [preview]);

  // Callback after file input changed
  const renderImage = (target) => {
    const file = target.files[0];
    const reader = new FileReader();

    reader.onloadend = function convertToBase64() {
      setImagePreview(reader.result);
      onChange(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Group mb={2}>
      {title && <LabelDrop>{title}</LabelDrop>}
      <Wrapper>
        {imagePreview ? (
          <Img src={imagePreview} alt={title} />
        ) : (
          <WrapperTitle readOnly={readOnly}>{readOnly ? '' : text}</WrapperTitle>
        )}
        {!readOnly ? <File type="file" {...props} onChange={(e) => renderImage(e.target)} /> : null}
      </Wrapper>
    </Group>
  );
};

const File = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Img = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
`;

const Wrapper = styled.div`
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
`;

const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 200px;
  font-size: 1.8rem;
  font-weight: 700;

  ${({ readOnly }) =>
    readOnly
      ? css`
          cursor: not-allowed;
          background-color: #f0f0f0;
        `
      : ''}
`;
