/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Container, Layout, Content } from 'components/atoms/Grid';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { QueueBuilds } from 'components/organisms/QueueBuilds';
import { QueueHistory } from 'components/organisms/QueueHistory';
import { composerCxt } from 'contexts/ComposerContext';
import { useHashTabs } from 'utils/useHashTabs';

const QueuePage = () => {
  const { getLiteral } = useContext(composerCxt);
  const [historyErrorsCount, setHistoryErrorsCount] = useState(0);
  const [buildsWaitingCount, setBuildsWaiting] = useState(0);

  const configTabs = [
    {
      title: getLiteral('Podglad'),
      component: <QueueBuilds setBuildsWaiting={setBuildsWaiting} />,
    },
    {
      title: getLiteral('Historia'),
      component: <QueueHistory setHistoryErrorsCount={setHistoryErrorsCount} />,
    },
  ];

  const { tabs, tabsPanel } = useHashTabs(configTabs);

  return (
    <Layout>
      <Aside>
        {buildsWaitingCount > 0 || historyErrorsCount > 0 ? (
          <QueueInfo>
            <h1>{getLiteral('Statystyki')}</h1>
            {buildsWaitingCount > 0 && (
              <div>
                <span>
                  {getLiteral('LiczbaSzablonowOczekujących')}: {buildsWaitingCount}
                </span>
              </div>
            )}
            {historyErrorsCount > 0 && (
              <div>
                <span>
                  {getLiteral('LiczbaSzablonowZakonczonychBledem')}: {historyErrorsCount}
                </span>
              </div>
            )}
          </QueueInfo>
        ) : null}
      </Aside>
      <Content>
        <Container>
          <Header title={getLiteral('ZadaniaWTle')} tabs={tabs} />
          {tabsPanel}
        </Container>
      </Content>
    </Layout>
  );
};

const QueueInfo = styled.div`
  padding: 1.5rem 2.5rem;

  span {
    font-size: 1.3rem;
  }

  h1 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  div {
    margin-bottom: 0.5rem;
  }
`;

export default QueuePage;
