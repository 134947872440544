import styled, { css } from 'styled-components';
import { useContext, useState } from 'react';
import { AccordionDetails, AccordionSummary, Grid, Avatar, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';
import { SUBSCRIPTION_DIALOG_TYPE_ACTIVATION } from 'components/organisms/SubscriptionDialog';
import { composerCxt } from 'contexts/ComposerContext';
import {
  IconArrowDown,
  // IconStar,
  IconSuccess,
  Pictogramcode,
  PictogramImage,
  PictogramLines,
  PictogramText,
} from 'components/atoms/Icon';
import { StyledAccordion, Masonry, RoundedTag } from 'components/organisms/MarketingArticle';

export const LiteVsProPol = () => {
  const { openSubscriptionDialog, isComposerProForced, composerSubscriptionLevel } = useContext(composerCxt);

  const [accProExpanded, setAccProExpanded] = useState(false);
  const [accFaqExpanded, setFaqProExpanded] = useState(false);

  const handleClickOpenDialog = () => {
    openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_ACTIVATION);
  };

  const accProHandleChange = (panel) => (event, isExpanded) => {
    setAccProExpanded(isExpanded ? panel : false);
  };

  const accFaqHandleChange = (panel) => (event, isExpanded) => {
    setFaqProExpanded(isExpanded ? panel : false);
  };

  return (
    <Article>
      <section className="lite">
        <header>
          <RoundedTag type="lite">Composer Lite</RoundedTag>
          <h1>
            <span>Szybkie i Łatwe</span> Komponowanie Sklepów
          </h1>
          <p>
            Composer Lite to ogrom korzyści zupełnie za darmo. Skorzystaj z gotowych kompozycji Standard i twórz własne
            szablony z wykorzystaniem gotowych komponentów. Composer Lite umożliwia podstawowe opcje personalizacji,
            takie jak zmiana umiejscowienia poszczególnych elementów, ukrywanie, dodawanie opcjonalnych komponentów
            Standard oraz ich wymianę.
          </p>
        </header>
        <CentereContainerdGrid container spacing={2}>
          <Grid item xs={6}>
            <ShadowedImg>
              <PictogramText className="pictogram-text" />
              <PictogramImage className="pictogram-image" />
              <picture>
                <source
                  srcSet="/sources/szybkie-latwe-komponowanie-sklepow.png 1x, /sources/szybkie-latwe-komponowanie-sklepow@2x.png 2x"
                  type="image/png"
                />
                <img alt="Szybkie i łatwe komponowanie sklepów" src="/sources/szybkie-latwe-komponowanie-sklepow.png" />
              </picture>
            </ShadowedImg>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <ul>
              <li>
                <IconSuccess />
                Dostęp do kompozycji Standard
              </li>
              <li>
                <IconSuccess />
                Wymiana i dodawanie komponentów Standard
              </li>
              <li>
                <IconSuccess />
                Ukrywanie komponentów Standard
              </li>
              <li>
                <IconSuccess />
                Sterowanie miejscem wyświetlania komponentów Standard
              </li>
              <li>
                <IconSuccess />
                Dostęp do słownika tłumaczeń
              </li>
              <li>
                <IconSuccess />
                Dostęp do schematów kolorystycznych
              </li>
              <li>
                <IconSuccess />
                Możliwość zmiany fontów i zaokrągleń przycisków oraz pól formularzy
              </li>
              <li>
                <IconSuccess />
                Możliwość personalizacji CSS poprzez arkusz własnych styli
              </li>
              <li>
                <IconSuccess />
                Dostęp do aktualizacji kompozycji i komponentów Standard
              </li>
            </ul>
          </Grid>
        </CentereContainerdGrid>
      </section>
      <section className="pro">
        <header>
          <RoundedTag type="pro">Composer Pro</RoundedTag>
          <h1>
            <span>Profesjonalnie i bez ograniczeń</span> Komponowanie Sklepów
          </h1>
          <p>
            Composer Pro to propozycja dla najbardziej wymagających. Dzięki edycji kodu źródłowego komponentów możesz
            tworzyć w pełni spersonalizowane kompozycje. Ogranicza cię tylko wyobraźnia! Composer Pro to rozwiązanie
            skierowane do developerów i umożliwia pełną edycję kodu CSS, JavaScript oraz XML/XSLT.
          </p>
        </header>
        <LiteGrid alignItems="center" container spacing={2}>
          <Grid item xs={5}>
            <StyledAccordion expanded={accProExpanded === 'acc-pro1'} onChange={accProHandleChange('acc-pro1')}>
              <AccordionSummary
                expandIcon={<IconArrowDown className="variant-color" color="text" />}
                aria-controls="acc-pro"
              >
                <Typography className="variant-color">Twórz własne komponenty</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Composer Pro umożliwia budowanie w pełni spersonalizowanych rozwiązań. Stwórz nowe komponenty i zbuduj
                  sklep swoich marzeń. Operacje wymagają znajomości kodu CSS, JavaScript oraz XML/XSLT.
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion expanded={accProExpanded === 'acc-pro2'} onChange={accProHandleChange('acc-pro2')}>
              <AccordionSummary
                expandIcon={<IconArrowDown className="variant-color" color="text" />}
                aria-controls="acc-pro"
              >
                <Typography className="variant-color">Edytuj komponenty Standard</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Wprowadzaj własne zmiany do gotowych rozwiązań Standard. Dzięki Composer Pro możesz przygotować kopię
                  wybranych komponentów i nanieść swoje modyfikacje dzięki czemu w szybki sposób spersonalizujesz nie
                  tylko wygląd ale i funkcjonalność swojego sklepu.
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion expanded={accProExpanded === 'acc-pro3'} onChange={accProHandleChange('acc-pro3')}>
              <AccordionSummary
                expandIcon={<IconArrowDown className="variant-color" color="text" />}
                aria-controls="acc-pro"
              >
                <Typography className="variant-color">Twórz szablony Smarty i XSLT</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Composer Pro to szereg korzyści dla web developerów, dlatego w ramach usługi możesz także tworzyć,
                  edytować i wykorzystywać w sklepie szablony własne Smarty oraz XSLT.
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion expanded={accProExpanded === 'acc-pro4'} onChange={accProHandleChange('acc-pro4')}>
              <AccordionSummary
                expandIcon={<IconArrowDown className="variant-color" color="text" />}
                aria-controls="acc-pro"
              >
                <Typography className="variant-color">Przenoś szablony między sklepami</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Dzięki aktywnej subskrypcji Composer Pro możesz pobrać wszystkie pliki dowolnego szablonu w formacie
                  XSLT lub Smarty, zmienić go i wykorzystać w innym panelu IdoSell z aktywną usługą Composer Pro.
                </Typography>
              </AccordionDetails>
            </StyledAccordion>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <ShadowedImg>
              <PictogramLines className="pictogram-lines" />
              <Pictogramcode className="pictogram-code" />
              <picture>
                <source
                  srcSet="/sources/img-profesionalne-komponowanie-sklepow.png 1x, /sources/img-profesionalne-komponowanie-sklepow@2x.png 2x"
                  type="image/png"
                />
                <img
                  alt="Profesjonalne i bez ograniczeń komponowanie sklepów"
                  src="/sources/img-profesionalne-komponowanie-sklepow.png"
                />
              </picture>
            </ShadowedImg>
          </Grid>
        </LiteGrid>
      </section>
      <section>
        {!isComposerProForced() && composerSubscriptionLevel !== COMPOSER_SUBSCRIPTION_LEVEL_PRO && (
          <Link onClick={handleClickOpenDialog}>
            <picture>
              <source
                srcSet={`/sources/banner-composer-pro-cena-1360px-pol.webp 1x, /sources/banner-composer-pro-cena-1360px-pol@2x.webp 2x`}
                type="image/png"
              />
              <img alt="Twórz i edytuj własne kompozycje" src={`/sources/banner-composer-pro-cena-1360px-pol.webp`} />
            </picture>
          </Link>
        )}
      </section>
      <section className="faq pro">
        <header>
          <h2>Najczęściej zadawane pytania</h2>
        </header>
        <FaqAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq1'} onChange={accFaqHandleChange('acc-faq1')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Zakończyłem pracę nad szablonem w Composer Pro. Czy mogę korzystać z tego szablonu w ramach Composer
                Lite?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nie, publikacja i wykorzystywanie w sklepie szablonu z wyedytowanym kodem źródłowym oraz szablonów
                własnych XSLT/Smarty wymaga aktywnej subskrypcji Composer Pro.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq2'} onChange={accFaqHandleChange('acc-faq2')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">Czy mogę zrezygnować z Composer Pro?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Tak, rezygnacja z Composer Pro możliwa jest w dowolnym momencie. Przed rezygnacją z usługi upewnij się,
                że żaden ze sklepów w panelu administracyjnym nie korzysta z szablonów wymagających subskrypcji.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq3'} onChange={accFaqHandleChange('acc-faq3')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Czy publikacja każdego szablonu z Composer wymaga opłaty?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nie, usługa Composer Pro obejmuje wyłącznie te szablony, w których samodzielnie wyedytowano kod źródłowy
                co najmniej jednego komponentu oraz szablony własne XSLT/Smarty.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq4'} onChange={accFaqHandleChange('acc-faq4')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Zamówiłem szablon w IdoSell. Czy muszę aktywować Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Szablony opracowane przez IdoSell nie wymagają aktywacji Composer Pro i są dostępne do publikacji w
                sklepach bezpłatnie.{' '}
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq5'} onChange={accFaqHandleChange('acc-faq5')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Zleciłem prace kontraktowe. Czy zmiany wprowadzone przez IdoSell spowodują, że zostanie mi aktywowany
                Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Nie, zlecając nam prace kontraktowe nie musisz aktywować Composer Pro.</Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq6'} onChange={accFaqHandleChange('acc-faq6')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Chcę samodzielnie wprowadzić zmiany w szablonie przygotowanym przez IdoSell. Czy muszę aktywować
                Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Tak, aby edytować kod źródłowy szablonu opracowanego przez IdoSell niezbędna jest usługa Composer Pro.
                Po zapisaniu zmian w kodzie źródłowym co najmniej jednego komponentu, szablon zostanie przekształcony w
                kompozycję własną. Jej publikacja w sklepach będzie wymagała aktywnej subskrypcji Composer Pro.{' '}
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq8'} onChange={accFaqHandleChange('acc-faq8')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Stworzyłem własny szablon przed wprowadzeniem Composer Lite i Pro. Czy mogę go bezpłatnie wykorzystywać
                w swoich sklepach?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nie, usługa Composer Pro jest aktywowana automatycznie, jeśli wykryjemy, że choć jeden z twoich sklepów
                korzysta z szablonu własnego XSLT/Smarty lub kompozycji własnej posiadającej samodzielnie zmodyfikowane
                komponenty.{' '}
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq9'} onChange={accFaqHandleChange('acc-faq9')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Chcę ukryć jeden z elementów mojego szablonu. Czy muszę aktywować Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Nie, możliwość ukrywania komponentów jest bezpłatna w ramach Composer Lite. </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq10'} onChange={accFaqHandleChange('acc-faq10')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Chcę wymienić jeden z elementów mojego szablonu. Czy muszę aktywować Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nie, jeśli wymienisz go na inny komponent Standard. Wymiana komponentów Standard na komponenty własne
                wymaga aktywacji Composer Pro.{' '}
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq11'} onChange={accFaqHandleChange('acc-faq11')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">Czy korzystając z Composer Lite mogę edytować CSS?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                W ramach Composer Lite możliwe jest dodawanie własnych stylów w arkuszu custom.css dostępnym w module
                zarządzania szablonami strony. Edycja kodu CSS poszczególnych komponentów możliwa jest wyłącznie w
                Composer Pro.{' '}
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq12'} onChange={accFaqHandleChange('acc-faq12')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Chcę zmienić font i kolorystykę mojego szablonu. Czy muszę aktywować Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nie, korzystając z Composer Lite otrzymujesz bezpłatny dostęp do modułu schematów kolorystycznych oraz
                zmiany fontów i zaokrągleń przycisków, a także pól formularzy.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion expanded={accFaqExpanded === 'acc-faq13'} onChange={accFaqHandleChange('acc-faq13')}>
            <AccordionSummary
              expandIcon={<IconArrowDown className="variant-color" color="text" />}
              aria-controls="acc-faq"
            >
              <Typography className="variant-color">
                Chcę edytować literały i dodać nowe tłumaczenia. Czy muszę aktywować Composer Pro?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nie, korzystając z Composer Lite otrzymujesz bezpłatny dostęp do słownika tłumaczeń.
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
        </FaqAccordion>
      </section>
    </Article>
  );
};

const CentereContainerdGrid = styled(Grid)`
  align-items: center;
`;

const Article = styled.div`
  .lite .MuiGrid-container {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`;

const Banner = styled.div`
  cursor: pointer;
`;

const Link = styled.a`
  cursor: pointer;
`;

const OpinionPaper = styled(Paper)`
  padding: 3rem;
  font-size: 1.6rem;
  line-height: 2.6rem;
  &.MuiPaper-elevation1 {
    box-shadow: 15px 15px 50px #0000001a;
  }
  .person {
    color: #85868a;
    display: flex;
    align-items: center;
    .MuiAvatar-root {
      margin-right: 1rem;
    }
  }
  .stars {
    margin-top: 2rem;
    margin-bottom: 1rem;
    .svg-icon {
      width: 1.6rem;
      margin-right: 0.6rem;
    }
  }
  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #85868a;
  }
`;

const FaqAccordion = styled.div`
  margin-top: 4.8rem;
`;

const ShadowedImg = styled.div`
  position: relative;
  img {
    box-shadow: 15px 15px 50px #00000029;
    border-radius: 0.8rem;
  }
  .pictogram-text {
    max-width: 6.4rem;
    max-height: 6.4rem;
    position: absolute;
    top: -6%;
    left: 7%;
  }
  .pictogram-image {
    max-width: 9.2rem;
    max-height: 9.2rem;
    position: absolute;
    bottom: 8%;
    right: -5%;
  }
  .pictogram-lines {
    max-width: 6.4rem;
    max-height: 6.4rem;
    position: absolute;
    top: -7%;
    right: 7%;
  }
  .pictogram-code {
    max-width: 9.2rem;
    max-height: 9.2rem;
    position: absolute;
    bottom: -6%;
    left: 7%;
  }
`;

const LiteGrid = styled(Grid)`
  &.MuiGrid-root {
    margin-top: 7rem;
    margin-bottom: 10rem;
  }
`;
