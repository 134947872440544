/* eslint-disable react-hooks/exhaustive-deps */
import { faEdit, faRedoAlt, faSave, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router';
import styled from 'styled-components';

import { Tag } from 'components/atoms/Tag';
import { Button } from 'components/atoms/Button';
import { CommitMessageDialog } from 'components/atoms/CommitMessageDialog';
import { ComponentOlderVersionsDialog } from 'components/atoms/ComponentOlderVersionsDialog';
import { Col, Row } from 'components/atoms/Grid';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { ModulePreview } from 'components/atoms/ModulePreview';
import { Footer } from 'components/molecules/Footer';
import { Header } from 'components/molecules/Header';
import { ModuleInfo } from 'components/molecules/ModuleInfo';
import { STATUS_TYPES } from 'components/organisms/ComponentsPullRequests';
import { ITEM_TYPES } from 'components/organisms/ItemHistory';
import { ModuleCompositions } from 'components/organisms/ModuleCompositions';
import { ModuleFormBasicData } from 'components/organisms/ModuleFormBasicData';
import { ModuleFormCode } from 'components/organisms/ModuleFormCode';
import { ModuleFormFiles } from 'components/organisms/ModuleFormFiles';
import { ModuleFormSettings } from 'components/organisms/ModuleFormSettings';
import { ModuleItemHistory } from 'components/organisms/ModuleItemHistory';
import { PullRequestInfo } from 'components/organisms/PullRequest/PullRequestInfo';
import { componentCxt } from 'contexts/ComponentContext';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { useAccess } from 'helpers/access';
import {
  API_MODULES_EDIT,
  API_MODULES_GET_COMPONENT_DESIGNS,
  API_MODULES_GET_VERSIONS,
  API_MODULES_MERGE,
  EDP_DEFAULT,
  EDP_MODULES,
} from 'helpers/endpoints';
import { isComponentEditable } from 'helpers/isComponentEditable';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { useTabs } from 'utils/useTabs';

export const ModuleForm = () => {
  const history = useHistory();
  const { getLiteral, getTagIcon, getTagLabel } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const {
    id,
    name,
    type,
    badges,
    version,
    recommendedId,
    editable,
    allowedActions,
    isDraft,
    parentId,
    sources,
    pullRequest,
    getMergedObject,
    getDefaultValues,
    startEdit,
    startEditLoading,
    setPullRequest,
    setJsLocalLoadSetting,
  } = useContext(componentCxt);
  const { isSupport } = useContext(userCtx);

  const [componentDesigns, setComponentDesigns] = useState([]);
  const [componentVersions, setComponentVersions] = useState([]);
  const [refreshUsedFiles, setRefreshUsedFiles] = useState(false);
  const [saveAfterFileReplace, setSaveAfterFileReplace] = useState(false);
  const [isComponentDisabled, setIsComponentDisabled] = useState(!!recommendedId);
  const [formData, setFormData] = useState([]);
  const [skipPrompt, setSkipPrompt] = useState(false);
  const canEditStandardComponents = useAccess('canEditStandardComponents');

  const formRef = useRef();

  const postMergeComponent = useFetch(`${API_MODULES_MERGE}`, 'POST');
  const postEditComponent = useFetch(`${API_MODULES_EDIT}`, 'POST');
  const postComponentDesigns = useFetch(API_MODULES_GET_COMPONENT_DESIGNS, 'POST');

  const canUserEditComponent = isComponentEditable({
    userCanEdit: editable,
    componentEditable: allowedActions.edit,
    validFunction: useAccess,
  });

  let queryVersion = id;
  if (isDraft === 'y') {
    queryVersion = parentId;
  }

  const getComponentVersions = useFetch(`${API_MODULES_GET_VERSIONS}${queryVersion}`, 'GET');

  // Handle on save button click
  const handleSaveSubmit = (formData) => {
    const obj = setJsLocalLoadSetting(getMergedObject(formData));

    if (obj.isDraft === 'y') {
      postEditComponent.execute(obj);
    }
  };

  useEffect(() => {
    getComponentVersions.execute();
  }, [id]);

  useEffect(() => {
    if (getComponentVersions.response) {
      setComponentVersions(getComponentVersions.response);
    } else if (getComponentVersions.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      history.push(EDP_DEFAULT);
    }
  }, [getComponentVersions.response, getComponentVersions.error]);

  const saveData = (saveData) => {
    setSkipPrompt(true);
    postMergeComponent.execute(getMergedObject({ ...formData, ...saveData }));
    commitMessageDialog.closeDialog();
  };

  const commitMessageDialog = useDialog({
    title: getLiteral('OpiszSwojeZmiany'),
    content: <CommitMessageDialog onSubmit={saveData} type={type} />,
  });
  const restoreVersionDialog = useDialog({
    title: getLiteral('WybierzWersjeKtoraChceszZobaczyc'),
    content: (
      <ComponentOlderVersionsDialog
        componentVersions={componentVersions}
        closeCb={() => restoreVersionDialog.closeDialog()}
      />
    ),
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    reset,
    setFocus,
    formState: { isDirty },
  } = useForm({
    defaultValues: getDefaultValues,
  });

  const canUserEditWrapperComponent = () => getDefaultValues.clientReadOnly === 'n' || isSupport;

  useEffect(() => {
    setSkipPrompt(isDirty === false);
  }, [isDirty]);

  const currentFormState = useWatch({ control });

  useEffect(() => {
    reset({ ...currentFormState, sources });
    if (saveAfterFileReplace) {
      handleSaveSubmit({ ...currentFormState, sources });
      setSaveAfterFileReplace(false);
    }
  }, [sources]);

  const tabsArray = [
    {
      title: getLiteral('PodstawoweDane'),
      component: (
        <ModuleFormBasicData
          readOnly={!(isDraft === 'y' && canUserEditComponent)}
          register={register}
          control={control}
        ></ModuleFormBasicData>
      ),
    },
    {
      title: canUserEditWrapperComponent() && getLiteral('KodKomponentu'),
      component: canUserEditWrapperComponent() && (
        <ModuleFormCode
          control={control}
          setFocus={setFocus}
          readOnly={!(isDraft === 'y' && canUserEditComponent)}
          saveCall={handleSubmit(handleSaveSubmit)}
        />
      ),
    },
    {
      title: getLiteral('Ustawienia'),
      component: (
        <ModuleFormSettings
          setValue={setValue}
          register={register}
          readOnly={!(isDraft === 'y' && canUserEditComponent)}
          setIsComponentDisabled={setIsComponentDisabled}
        />
      ),
    },
    {
      title: getLiteral('Kompozycje'),
      component: (
        <ModuleCompositions
          id={id}
          version={version}
          compositions={componentDesigns}
          loading={postComponentDesigns.loading}
        />
      ),
    },
    {
      title: getLiteral('Historia'),
      component: (
        <ModuleItemHistory
          items={componentVersions}
          loading={getComponentVersions.loading}
          itemType={ITEM_TYPES.COMPONENT}
          control={control}
        />
      ),
    },
    {
      title: getLiteral('Pliki'),
      component: (
        <ModuleFormFiles
          control={control}
          refreshFiles={refreshUsedFiles}
          saveCall={() => setSaveAfterFileReplace(true)}
        />
      ),
    },
  ];

  const { tabs, tabsPanel } = useTabs(tabsArray, true);

  const onSubmit = async (formData) => {
    if (!canUserEditComponent) {
      return;
    }

    handleSaveData(formData);
  };

  const handleSaveData = (formData) => {
    if (isDraft === 'y') {
      setFormData(getMergedObject(formData));
    }
  };

  useEffect(() => {
    reset(getDefaultValues, {
      keepValues: false,
    });
    postComponentDesigns.execute({ id: id, version: version, onlyDrafts: isDraft === 'y' });
  }, [id, version]);

  useEffect(() => {
    if (postComponentDesigns.response) {
      setComponentDesigns(postComponentDesigns.response);
    }
  }, [postComponentDesigns.response, postComponentDesigns.error]);

  useEffect(() => {
    if (postEditComponent.response) {
      if (pullRequest && type === 'standard') {
        const newPullRequestData = { ...pullRequest };
        newPullRequestData.status = STATUS_TYPES.TO_VERIFY;
        newPullRequestData.activity.forEach((el, index) => {
          newPullRequestData.activity[index].status = STATUS_TYPES.TO_VERIFY;
        });

        setPullRequest({ ...newPullRequestData });
      }

      //po zapisie zmian odświeżamy zakładkę używanych plików w komponencie
      setRefreshUsedFiles((state) => !state);
      createToast({
        title: getLiteral('zapisanoZmiany'),
        context: 'success',
      });

      reset(postEditComponent.response, {
        keepValues: true,
      });
    } else if (postEditComponent.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: `${postEditComponent.error.message}.`,
        context: 'error',
      });
    }
  }, [postEditComponent.response, postEditComponent.error]);

  useEffect(() => {
    if (postMergeComponent.response) {
      if (postMergeComponent.response.pullRequest) {
        setPullRequest(postMergeComponent.response.pullRequest);
        createToast({
          title: `${getLiteral('zapisanoZmiany')}!`,
          message: `${getLiteral('WniosekOAkceptacjeZmianZostalWystawiony')}.`,
          context: 'success',
        });
      } else {
        createToast({
          title: `${getLiteral('zapisanoZmiany')}!`,
          message: `${getLiteral('TwojeZmianyZostalyPomyslnieZapisaneUtworzylesNowaWersjeKomponentu')}.`,
          context: 'success',
        });
        history.push(
          `${EDP_MODULES}${postMergeComponent.response.id}/version/${postMergeComponent.response.version}${window.location.search}`,
        );
      }
    } else if (postMergeComponent.error) {
      setSkipPrompt(false);
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: `${postMergeComponent.error.message}.`,
        context: 'error',
      });
    }
  }, [postMergeComponent.response, postMergeComponent.error]);

  const keyDownEvent = (e) => {
    if (e.key?.toLowerCase() === 's' && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownEvent, false);

    return () => document.removeEventListener('keydown', keyDownEvent);
  });

  const renderedBadges = badges?.map((badge) => (
    <Tag type={badge.type} title={badge.title} key={`component_${badge.type}`}>
      {badge.icon ? getTagIcon(badge.icon) : ''}
      {getTagLabel(badge.type, badge.label)}
    </Tag>
  ));

  return (
    <>
      {skipPrompt === false && (
        <Prompt
          when={isDraft === 'y' && skipPrompt === false}
          message={getLiteral('NiezapisaneZmianyZostanaUtraconeCzyNaPewnoChceszKontynuowac')}
        />
      )}
      <FormElement onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Header
          title={
            <StyledTitle>
              <strong>{name}</strong>
              {renderedBadges}
            </StyledTitle>
          }
          titleSize={9}
          tabs={tabs}
          info={pullRequest && <InfoLabel text={<PullRequestInfo control={control} />} marginBottom={false} />}
        >
          {canUserEditComponent && canUserEditWrapperComponent() && (
            <>
              {componentVersions.length > 1 || isDraft === 'y' ? (
                <RestoreButton
                  type="submit"
                  onClick={() => {
                    setSkipPrompt(true);
                    restoreVersionDialog.openDialog();
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                  {isDraft === 'y' ? getLiteral('OdrzucZmiany') : getLiteral('Przywroc')}
                </RestoreButton>
              ) : null}
            </>
          )}
        </Header>

        <Row>
          <Col size={9}>{tabsPanel}</Col>
          <StickyCol size={3}>
            {isDraft === 'y' ? (
              <ModulePreview
                componentId={id}
                componentVersion={version}
                componentDesigns={componentDesigns}
                updateCall={handleSubmit(handleSaveSubmit)}
              />
            ) : canUserEditWrapperComponent() ? (
              <ModuleInfo />
            ) : null}
          </StickyCol>
        </Row>
        {!isComponentDisabled && canUserEditComponent && (
          <Footer>
            {type === 'standard' ? (
              canEditStandardComponents.condition && (
                <div>
                  {isDraft === 'y' ? (
                    <FinishEditSection
                      handleSubmit={handleSubmit}
                      handleSaveData={handleSaveData}
                      handleSaveSubmit={handleSaveSubmit}
                      editLoading={postEditComponent.loading}
                      mergeLoading={postMergeComponent.loading}
                      commitMessageDialog={commitMessageDialog}
                      saveData={saveData}
                    />
                  ) : (
                    canUserEditWrapperComponent() && (
                      <StartEditSection startLoading={startEditLoading} onClick={() => startEdit()} />
                    )
                  )}
                </div>
              )
            ) : (
              <div>
                {isDraft === 'y' ? (
                  <FinishEditSection
                    handleSubmit={handleSubmit}
                    handleSaveData={handleSaveData}
                    handleSaveSubmit={handleSaveSubmit}
                    editLoading={postEditComponent.loading}
                    mergeLoading={postMergeComponent.loading}
                    commitMessageDialog={commitMessageDialog}
                  />
                ) : canUserEditWrapperComponent() ? (
                  <StartEditSection startLoading={startEditLoading} onClick={() => startEdit()} />
                ) : null}
              </div>
            )}
          </Footer>
        )}
      </FormElement>
      {commitMessageDialog.dialog}
      {restoreVersionDialog.dialog}
    </>
  );
};

const FinishEditSection = ({
  handleSubmit,
  handleSaveSubmit,
  handleSaveData,
  editLoading,
  mergeLoading,
  commitMessageDialog,
  saveData,
}) => {
  const { getLiteral } = useContext(composerCxt);
  const { pullRequest } = useContext(componentCxt);

  return (
    <>
      <Button type="button" onClick={handleSubmit(handleSaveSubmit)} loading={editLoading.toString()}>
        <FontAwesomeIcon icon={faSave} />
        <span>{getLiteral('ZapiszZmiany')}</span>
      </Button>
      {pullRequest ? (
        pullRequest.status === STATUS_TYPES.ACCEPTED ? (
          <FinishEdit
            type="button"
            loading={mergeLoading.toString()}
            onClick={() => {
              saveData({ commitMessage: pullRequest.description, skipSave: true });
            }}
          >
            <FontAwesomeIcon icon={faUpload} />
            <span>{getLiteral('Publikuj')}</span>
          </FinishEdit>
        ) : null
      ) : (
        <FinishEdit
          type="button"
          loading={mergeLoading.toString()}
          onClick={() => {
            handleSubmit(handleSaveData)();
            commitMessageDialog.openDialog();
          }}
        >
          <FontAwesomeIcon icon={faUpload} />
          <span>{getLiteral('ZakonczEdycje')}</span>
        </FinishEdit>
      )}
    </>
  );
};

const StartEditSection = ({ startLoading, onClick }) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <Button type="button" onClick={() => onClick()} loading={startLoading.toString()}>
      <FontAwesomeIcon icon={faEdit} />
      <span>{getLiteral('RozpocznijEdycje')}</span>
    </Button>
  );
};

const StickyCol = styled(Col)`
  position: sticky;
  top: 100px;
  align-self: flex-start;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;

  strong {
    margin-right: 1rem;
  }
`;

const FormElement = styled.form`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-height: 900px) {
    min-height: calc(100vh + 300px);
  }
`;

const FinishEdit = styled(Button)`
  margin-left: 2rem;
  background-color: var(--green);
`;

const RestoreButton = styled(Button)`
  background-color: #a94442;
  width: 100%;
`;
