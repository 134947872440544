import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useContext } from 'react';

import { composerCxt } from 'contexts/ComposerContext';

export const Page404 = () => {
  const { getLiteral } = useContext(composerCxt);
  const history = useHistory();

  return (
    <Article>
      <section className="lite">
        <h1>
          <span>Błąd 404</span> Nie znaleziono strony
        </h1>
        <p>
          {getLiteral('StronaKtorejSzukaszNieIstniejeSkorzystajZMenuPoLewejStronieAbyPrzejscNaInnaStrone')} <br />
          {getLiteral('lub')}{' '}
          <Link
            onClick={() => {
              history.push('/');
            }}
          >
            {getLiteral('przejdxNaStroneGlowna')}
          </Link>
          .
        </p>
      </section>
    </Article>
  );
};

const Article = styled.div`
  .lite .MuiGrid-container {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`;

const Link = styled.a`
  cursor: pointer;
  color: var(--proColor);
`;
