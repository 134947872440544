/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import 'react-image-lightbox/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-image-lightbox';
import {
  faArrowsAltV as dragAndDropIcon,
  faCode,
  faEdit,
  faEye,
  faEyeSlash,
  faRetweet,
  faSync,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { SmallLoaderAfter } from './Loader';

import { CopyComponent } from 'components/atoms/CopyComponent';
import { TypeLabel } from 'components/atoms/TypeLabel';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { useAccess } from 'helpers/access';
import {
  API_MODULES_START_EDIT,
  API_MODULES_HIDE_MODULE,
  API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY,
  EDP_MODULES,
} from 'helpers/endpoints';
import { COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';
import { isComponentEditable } from 'helpers/isComponentEditable';
import { isRemovableComponent } from 'helpers/isRemovableComponent';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';

export const ModuleWrapper = ({
  items,
  compositionId,
  compositionVersion,
  name,
  id,
  group,
  groupDescription,
  editable,
  allowedActions,
  lastUpdateUser,
  lastUpdateTime,
  disabled,
  onRemove,
  hidden,
  changeHidden,
  replaceable,
  replaceModule,
  version,
  isRemovable,
  pageName,
  updateComponentCallback,
  isEnvelopeSection,
  wrapperId,
  wrapperVersion,
  wrapperIsDraft,
  isWrapperHidden,
  position,
  ...props
}) => {
  const { isSupport } = useContext(userCtx);
  const { pathname, hash } = useLocation();
  const { createToast } = useContext(toastCtx);
  const { getLiteral, composerSubscriptionLevel } = useContext(composerCxt);
  const { editView } = useContext(templateCxt);
  const canUserEditComponent = isComponentEditable({
    userCanEdit: editable,
    componentEditable: allowedActions.edit,
    validFunction: useAccess,
  });
  const canEditStandardComponents = useAccess('canEditStandardComponents');

  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [hiddenStatus, setHiddenStatus] = useState(hidden);
  const postWrapperModuleHidden = useFetch(API_MODULES_HIDE_MODULE, 'POST');
  const postEditStandardComponent = useFetch(API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY, 'POST');
  const getStartComponentEdit = useFetch(`${API_MODULES_START_EDIT}${id}/version/${version}`, 'GET');
  const firstUpdate = useRef(true);
  const history = useHistory();

  const editComponent = () => {
    if (props.type === 'standard') {
      openDialog();
    } else if (props.isDraft === 'n') {
      getStartComponentEdit.execute();
    } else {
      history.push(`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`);
    }
  };

  const submitFunction = (name) => {
    postEditStandardComponent.execute({
      standardComponentId: id,
      standardComponentVersion: version,
      designId: compositionId,
      designVersion: props.designVersion,
      newComponentName: name,
    });
  };

  const { openDialog, dialog } = useDialog({
    title: getLiteral('TworzenieKomponentu'),
    content: (
      <CopyComponent
        submitFunction={submitFunction}
        loading={postEditStandardComponent.loading.toString()}
        placeholder={name}
        type={props.type}
      />
    ),
  });

  const updateToLatestVersion = () => {
    if (updateComponentCallback) {
      const replaceModuleElement = {
        id,
        version,
        hidden: hiddenStatus,
        wrapperId,
      };

      const newModule = { id: props.recommendedId ? props.recommendedId : id };
      updateComponentCallback({
        oldModule: replaceModuleElement,
        newModule: newModule,
        pageName,
        wrapperId,
        wrapperVersion,
      });
    }
  };

  // After status change, send to endpoint for Wrapper (2-level component)
  useEffect(() => {
    if (!firstUpdate.current) {
      if (!isWrapperHidden) {
        postWrapperModuleHidden.execute(
          { moduleId: id, status: hiddenStatus, wrapperId, designId: compositionId, designVersion: compositionVersion },
          null,
          false,
          (errorResult) => {
            // ERROR_LAST_MODULE_ON_PAGE_IN_WRAPPER = 107: last component in Wrapper
            if (errorResult.code === 107) {
              setHiddenStatus('n');
            }
          },
        );
      }
    }
    firstUpdate.current = false;
  }, [hiddenStatus]);

  useEffect(() => {
    if (postWrapperModuleHidden.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postWrapperModuleHidden.error.message,
        context: 'error',
      });
    } else if (postWrapperModuleHidden.response && postWrapperModuleHidden.response.status === 'ok') {
      createToast({
        title: hiddenStatus === 'n' ? getLiteral('KomponentWidoczny') : getLiteral('KomponentNieWidoczny'),
        message: getLiteral('PomyslnieZapisanoDane'),
        context: 'success',
      });
    }
  }, [postWrapperModuleHidden.response, postWrapperModuleHidden.error]);

  useEffect(() => {
    if (postEditStandardComponent.error) {
      createToast({
        title: getLiteral('NieMoznaUtworzycKomponentu'),
        message: postEditStandardComponent.error.message,
        context: 'error',
      });
      return;
    }

    if (postEditStandardComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUtworzony'),
        message: `${getLiteral('PrzekierowanoDoEdycjiKomponentu')} ${postEditStandardComponent.response.id}`,
        context: 'success',
      });

      history.push(
        `${EDP_MODULES}${postEditStandardComponent.response.id}/version/${
          postEditStandardComponent.response.version
        }?link=${encodeURIComponent(pathname + hash)}`,
      );
    }
  }, [postEditStandardComponent.response, postEditStandardComponent.error]);

  useEffect(() => {
    if (getStartComponentEdit.response) {
      createToast({
        title: `${getLiteral('WersjaRoboczaZostalaUtworzona')}!`,
        message: `${getLiteral('JestesWTrybieEdycjiKomponentu')}.`,
        context: 'success',
      });
      history.push(
        `${EDP_MODULES}${getStartComponentEdit.response.id}/version/${
          getStartComponentEdit.response.version
        }?link=${encodeURIComponent(pathname + hash)}`,
      );
    } else if (getStartComponentEdit.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getStartComponentEdit.error.message,
        context: 'error',
      });
    }
  }, [getStartComponentEdit.response, getStartComponentEdit.error]);

  useEffect(() => {
    if (!firstUpdate.current) {
      setHiddenStatus(isWrapperHidden ? 'y' : 'n');
    }
  }, [isWrapperHidden]);

  return (
    <StyledModule
      ref={props.innerRef || null}
      {...props}
      isDisabled={disabled.toString()}
      isHidden={isWrapperHidden || hiddenStatus === 'y'}
    >
      <StyledMoveIcon
        disabled={disabled || !isRemovableComponent(group, isSupport) || isEnvelopeSection || !wrapperIsDraft}
        isDisabled={disabled.toString()}
        {...props.dragHandleProps}
      >
        <FontAwesomeIcon icon={dragAndDropIcon} />
      </StyledMoveIcon>
      <ComponentIconDiv>
        {props.image ? (
          <ComponentIconImg
            src={`/components/${props.image}`}
            onClick={() => setIsLightBoxOpen(true)}
          ></ComponentIconImg>
        ) : (
          <NoImageIcon icon={faCode} />
        )}
      </ComponentIconDiv>
      {isLightBoxOpen && (
        <Lightbox onCloseRequest={() => setIsLightBoxOpen(false)} mainSrc={`/components/${props.image}`} />
      )}
      <div>
        <Tag type={props.type}>{props.type}</Tag>
        {props.isDraft === 'y' ? <TypeLabel type="draft" text={getLiteral('wersjaRobocza')}></TypeLabel> : null}
        {!!props.recommendedId ? (
          <Tag
            type="deprecated"
            title={getLiteral(
              'KomponentNieJestJuzPrzezNasWpieranyAktualizacjaKomponentuSpowodujeWymianeNaKomponentRekomendowany',
            )}
          >
            {getLiteral('wycofywany')}
          </Tag>
        ) : (
          <>
            {props.isLatestVersion === 'n' && (
              <Tag
                type="hasNeverVersion"
                title={getLiteral('KomponentPosiadaNowszaWersjeZaktualizujKomponentAbyCieszycSieZNajnowszychZmian')}
              >
                {getLiteral('doAktualizacji')}
              </Tag>
            )}
          </>
        )}
        <StyledName>
          <NameElement>
            <SupportLink
              title={name}
              to={`${EDP_MODULES}${id}/version/${version}?link=${encodeURIComponent(pathname + hash)}`}
            >
              {name}
            </SupportLink>
          </NameElement>
        </StyledName>
        <StyledAttribiutes>
          <span>
            {getLiteral('Grupa')}: <strong>{groupDescription ? groupDescription : group}</strong>,
          </span>
          <span>
            {getLiteral('Wersja')}: <strong>{`${version}`}</strong>,
          </span>
          <span>
            {getLiteral('Zaaktulizowano')}: <strong>{lastUpdateTime}</strong> {getLiteral('Przez')}{' '}
            <strong>{lastUpdateUser}</strong>
          </span>
        </StyledAttribiutes>
      </div>
      <ActionsWrapper>
        {!disabled && (props.isLatestVersion === 'n' || !!props.recommendedId) ? (
          <>
            <ActionButton
              title={getLiteral('AktualizujKomponent')}
              type="button"
              onClick={() => updateToLatestVersion()}
            >
              <FontAwesomeIcon icon={faSync} />
            </ActionButton>
          </>
        ) : null}
        {!disabled && isRemovable && canUserEditComponent && (
          <ActionButton
            loading={getStartComponentEdit.loading.toString()}
            title={getLiteral('EdytujKomponent')}
            type="button"
            onClick={() => editComponent()}
          >
            <FontAwesomeIcon icon={faEdit} />
          </ActionButton>
        )}
        {!disabled && wrapperIsDraft && editView && replaceable ? (
          <ActionButton
            title={getLiteral('WymienKomponent')}
            type="button"
            onClick={() =>
              replaceModule({
                compositionId,
                name,
                id,
                group,
                lastUpdateUser,
                lastUpdateTime,
                disabled,
                onRemove,
                hidden,
                changeHidden,
                replaceable,
                replaceModule,
                version,
                wrapperId,
                ...props,
              })
            }
            disabled={isWrapperHidden}
          >
            <FontAwesomeIcon icon={faRetweet} />
          </ActionButton>
        ) : null}
        {!disabled && isEnvelopeSection === false ? (
          <ActionButton
            title={hiddenStatus === 'n' ? getLiteral('KomponentWidoczny') : getLiteral('KomponentNieWidoczny')}
            type="button"
            onClick={() => (hiddenStatus === 'n' ? setHiddenStatus('y') : setHiddenStatus('n'))}
            disabled={isWrapperHidden}
          >
            {hiddenStatus === 'n' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
          </ActionButton>
        ) : null}
        {disabled && isWrapperHidden === 'y' ? (
          <ActionButton title={getLiteral('KomponentNieWidoczny')} type="button" disabled={disabled}>
            <FontAwesomeIcon icon={faEyeSlash} />
          </ActionButton>
        ) : null}
        {!disabled &&
          wrapperIsDraft &&
          editView &&
          (composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO ||
            canEditStandardComponents.condition ||
            isSupport) && (
            <ActionButton
              title={getLiteral('UsunKomponent')}
              type="button"
              onClick={() => {
                onRemove(id, version, position, pageName, wrapperId, wrapperVersion);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ActionButton>
          )}
      </ActionsWrapper>
      {dialog}
    </StyledModule>
  );
};

ModuleWrapper.defaultProps = {
  isEnvelopeSection: false,
};

export const DraggableModuleWrapper = ({ id, index, ...props }) => {
  const { isSupport } = useContext(userCtx);

  return (
    <>
      <Draggable
        index={index}
        draggableId={`${id.toString()}#${props.wrapperId}`}
        isDragDisabled={!isRemovableComponent(props.group, isSupport) || !props.wrapperIsDraft}
      >
        {(provided) => (
          <ModuleWrapper
            items={props.components}
            id={id}
            {...provided}
            {...provided.draggableProps}
            position={index + 1} //positions have indexes from 1 not from 0
            {...props}
            wrapperIsDraft={props.wrapperIsDraft}
          />
        )}
      </Draggable>
    </>
  );
};

const NameElement = styled.span`
  margin-right: 1.5rem;
`;

const NoImageIcon = styled(FontAwesomeIcon)`
  width: 50px !important;
  color: #2e2e2e;
  height: 50px;
`;

const ComponentIconDiv = styled.div`
  width: 200px;
  margin: 0 2rem;
  display: flex;
  justify-content: center;
`;

const ComponentIconImg = styled.img`
  height: fit-content;
  max-height: 150px;
  cursor: pointer;
`;

const SupportLink = styled(Link)`
  color: #000;
`;

const ActionsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  min-width: 40px;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: #e0e0e0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${({ withText }) =>
    withText &&
    css`
      padding: 0 1.5rem;

      span {
        margin-right: 1rem;
      }
    `}

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
      position: relative;

      ${SmallLoaderAfter}

      &:after {
        border-color: transparent #fff transparent #fff;
      }
    `}
`;

const StyledMoveIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  cursor: move;

  ${({ theme, disabled, isDisabled }) => css`
    border-right: 1px solid ${theme.colors.BG_BORDER_COLOR};

    ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}

    ${isDisabled === 'true' ? 'display: none;' : ''}
  `}
`;

const StyledName = styled.div`
  font-size: 1.8rem;
  margin: 0.5rem 0;

  br {
    display: none;
  }

  b,
  strong {
    font-weight: 600;
  }
`;

const StyledAttribiutes = styled.div`
  font-size: 1.3rem;
  color: #999;

  span {
    margin-right: 1rem;
  }
`;

const StyledModule = styled.div`
  width: 100%;
  height: 170px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0.5rem;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.BG_BORDER_COLOR};
  `}

  ${({ changed }) =>
    changed === true
      ? css`
          background-color: #ecf7f9;
          border-color: #0488e7;
        `
      : ''}

  ${({ isDisabled }) =>
    isDisabled === 'true'
      ? css`
          padding: 0 1rem;
          min-height: 60px;
        `
      : ''}

  ${({ isHidden }) =>
    isHidden
      ? css`
          opacity: 0.5;
        `
      : ''}
`;
