/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { Button } from 'components/atoms/Button';
import { Input, Group } from 'components/atoms/Form';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_TEMPLATES_DUPLICATE, API_TEMPLATES_CHECK_NANE, EDP_TEMPLATES } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const CopyTeamplateForm = ({ id, description, onSuccesCallback, version }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const [isEmptyError, setIsEmptyError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formNameRef = useRef(null);
  const history = useHistory();

  // Fetches
  const postDuplicateTemplate = useFetch(API_TEMPLATES_DUPLICATE, 'POST');
  const postCheckUniqueName = useFetch(API_TEMPLATES_CHECK_NANE, 'POST');

  const submitFunction = (name) => {
    postCheckUniqueName.execute({ name });
  };

  useEffect(() => {
    if (postCheckUniqueName.response) {
      if (!postCheckUniqueName.response.isUnique) {
        setIsEmptyError(`${getLiteral('NazwaKompozycjiMusiBycUnikalna')}!`);
      } else {
        postDuplicateTemplate.execute({ id, description, name: formNameRef.current.value, version });
      }
    } else if (postCheckUniqueName.error) {
      setIsEmptyError(`${getLiteral('NazwaKompozycjiMusiBycUnikalna')}!`);
    }
  }, [postCheckUniqueName.response, postCheckUniqueName.error]);

  // Template duplicating completed
  useEffect(() => {
    if (postDuplicateTemplate.error) {
      createToast({
        title: getLiteral('NieMoznaSkopiowacKompozycji'),
        message: postDuplicateTemplate.error.message,
        context: 'error',
      });
      return;
    }

    if (postDuplicateTemplate.response) {
      createToast({
        title: getLiteral('KompozycjaZostalaUtworzona'),
        message: `${getLiteral('PrzekierowanoDoKompozycji')} ${postDuplicateTemplate.response.id}`,
        context: 'success',
      });
      if (onSuccesCallback !== undefined) {
        onSuccesCallback(true);
      }

      history.push(`${EDP_TEMPLATES}${postDuplicateTemplate.response.id}`);
    }
  }, [postDuplicateTemplate.response, postDuplicateTemplate.error]);

  useEffect(() => {
    if (postCheckUniqueName.loading || postDuplicateTemplate.loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [postCheckUniqueName.loading, postDuplicateTemplate.loading]);

  return (
    <form>
      <Group>
        <Input
          ref={formNameRef}
          title={getLiteral('PodajNowaNazweDlaKompozycji')}
          error={isEmptyError ? isEmptyError : false}
          autoFocus
          onChange={({ target }) => {
            if (target.value) {
              setIsEmptyError(false);
            } else {
              setIsEmptyError(`${getLiteral('PoleObowiazkowe')}!`);
            }
          }}
        />
      </Group>

      <Button
        type="submit"
        color="green"
        loading={isLoading.toString()}
        onClick={(event) => {
          event.preventDefault();
          formNameRef.current && formNameRef.current.value && !isEmptyError
            ? submitFunction(formNameRef.current.value)
            : setIsEmptyError(isEmptyError ? isEmptyError : `${getLiteral('PoleObowiazkowe')}!`);
        }}
      >
        {getLiteral('Stworz')}
      </Button>
    </form>
  );
};
