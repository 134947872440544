/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_DICTIONARY_ADD } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const DictionaryNewLiteralForm = ({ onSubmitCallback, standardLiterals = false }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const postNewLiteral = useFetch(API_DICTIONARY_ADD, 'POST');

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const obj = {
      literals: {
        pol: [
          {
            key: data.newLiteral,
            value: data.newLiteral,
          },
        ],
      },
      standardLiterals: standardLiterals,
    };

    postNewLiteral.execute(obj, () =>
      onSubmitCallback({
        key: data.newLiteral,
        value: data.newLiteral,
      }),
    );
  };

  useEffect(() => {
    if (postNewLiteral.response) {
      createToast({
        delay: 5,
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('PoprawnieZapisanoTlumaczenia'),
        context: 'success',
      });
    }
    if (postNewLiteral.error) {
      createToast({
        delay: 5,
        title: getLiteral('NieUdaloSieZapisacLiteralu'),
        message: postNewLiteral.error.message,
        context: 'error',
      });
    }
  }, [postNewLiteral.response, postNewLiteral.error]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {standardLiterals && <InfoLabel type="info" text={getLiteral('DodajeszLiteralStandardowy')}></InfoLabel>}
      <Input name="newLiteral" ref={register} title={getLiteral('PodajTrescLiteralu')} />
      <Button type="submit" color="green" loading={postNewLiteral.loading.toString()}>
        {getLiteral('Dodaj')}
      </Button>
    </form>
  );
};
