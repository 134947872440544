import { Aside } from 'components/organisms/Aside';
import { Container, Content, Layout } from 'components/atoms/Grid';
import { MarketingArticle } from 'components/organisms/MarketingArticle';

const ArticlePage = () => {
  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          <MarketingArticle />
        </Container>
      </Content>
    </Layout>
  );
};

export default ArticlePage;
