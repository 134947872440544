import styled from 'styled-components';

export const PageHeader = styled.header`
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 4rem;
  padding: 40px 0;
  z-index: 999;
  background-color: #fff;
`;
