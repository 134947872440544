import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 1400px;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 900px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Row = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;

  ${({ justify }) => {
    if (justify === 'center')
      return css`
        justify-content: center;
      `;
  }}

  ${({ align }) => {
    if (align === 'center')
      return css`
        align-items: center;
      `;
  }}

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}rem;
    `}
`;

export const Col = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;

  ${({ size }) => {
    if (size === 1)
      return css`
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
      `;
    if (size === 2)
      return css`
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
      `;
    if (size === 3)
      return css`
        flex: 0 0 25%;
        max-width: 25%;
      `;
    if (size === 4)
      return css`
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      `;
    if (size === 5)
      return css`
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      `;
    if (size === 6)
      return css`
        flex: 0 0 50%;
        max-width: 50%;
      `;
    if (size === 7)
      return css`
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
      `;
    if (size === 8)
      return css`
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
      `;
    if (size === 9)
      return css`
        flex: 0 0 75%;
        max-width: 75%;
      `;
    if (size === 10)
      return css`
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
      `;
    if (size === 11)
      return css`
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
      `;
    if (size === 12)
      return css`
        flex: 0 0 100%;
        max-width: 100%;
      `;
  }}
`;

export const CompactContainer = styled(Container)`
  max-width: 930px;
`;

export const CompactRow = styled(Row)`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
`;

export const CompactCol = styled(Col)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`;

export const FullCenter = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Fluid = styled.div`
  width: var(--widthWithoutAside);
  position: relative;
  left: 50%;
  margin-left: calc(-50vw + 150px);
`;

export const Marginer = styled.div`
  ${({ bottom, top }) => css`
    ${bottom &&
    css`
      margin-bottom: ${bottom}rem;
    `}
    ${top &&
    css`
      margin-top: ${top}rem;
    `}
  `}
`;

export const Layout = styled.div`
  display: flex;
`;

export const Content = styled.div`
  width: var(--widthWithoutAside);
`;

export const StickyMargin = styled(Marginer)`
  position: sticky;
  background-color: var(--white);
  z-index: 100;
  top: -10px;
`;
