import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Tag } from 'components/atoms/Tag';
import { SortingComponent } from 'components/atoms/SortingComponent';
import { composerCxt } from 'contexts/ComposerContext';
import { EDP_LIBRARIES } from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { sortingFunction } from 'helpers/sortingFunctions';
import { useAccess } from 'helpers/access';

export const LibraryModule = ({ name, description, id, createDate, createUser, deleteDate }) => {
  const { getLiteral } = useContext(composerCxt);
  const canEditLibraries = useAccess('canAccessLibraries');

  return (
    <Group key={id}>
      <div>
        <GroupDetails>
          <i>{name}</i>, {getLiteral('Zaaktulizowano')}: <strong>{createDate}</strong> {getLiteral('Przez')}{' '}
          <strong>{createUser} </strong>
          {deleteDate && (
            <Tag type="deprecated" title={getLiteral('wycofywany')}>
              {getLiteral('wycofywany')}
            </Tag>
          )}
        </GroupDetails>
        <GroupDescription>{description}</GroupDescription>
      </div>
      {canEditLibraries.condition && (
        <ActionLink to={`${EDP_LIBRARIES}${id}`}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </ActionLink>
      )}
    </Group>
  );
};

export const LibraryList = () => {
  const { getLiteral, libraries } = useContext(composerCxt);
  const canEditLibraries = useAccess('canAccessLibraries');

  const [filters, setFilters] = useState({
    text: '',
    type: '',
    sort: 'date',
  });

  return (
    <div>
      <SortingComponent
        setTextFilter={(value) => setFilters((state) => ({ ...state, text: value }))}
        setSortFilter={(value) => setFilters((state) => ({ ...state, sort: value }))}
        setTypeFilter={(value) => setFilters((state) => ({ ...state, type: value }))}
        typeTitle={getLiteral('SzukajWZaleznosciach')}
        typeOptions={libraries.map((lib) => makeSelectOption(lib.id, lib.name))}
      />
      <div>
        {libraries
          .filter(({ name, description, requiredLibraries }) => {
            if (!name.includes(filters.text) && !description.includes(filters.text)) return false;
            if (filters.type && !requiredLibraries.filter((id) => filters.type === id).length) return false;
            return true;
          })
          .sort(sortingFunction(filters))
          .map((group) => (
            <LibraryModule key={group.id} {...group} />
          ))}
      </div>
      {canEditLibraries.condition && <AddGroup to={`${EDP_LIBRARIES}0`}>{getLiteral('DodajNowyElement')}</AddGroup>}
    </div>
  );
};

const Group = styled.div`
  border: var(--border);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--gray-light);
  }

  &:first-child {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  &:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const GroupDescription = styled.div``;

const GroupDetails = styled.div`
  font-size: 1.3rem;
  display: block;
  margin-bottom: 0.5rem;

  i {
    font-style: italic;
  }
`;

const ActionLink = styled(Link)`
  min-width: 40px;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: var(--gray-light);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
`;

const AddGroup = styled(Link)`
  background-color: var(--white);
  height: 5rem;
  position: sticky;
  bottom: 1rem;
  width: 100%;
  border: 2px dashed var(--blue);
  border-radius: var(--border-radius);
  margin-top: 2rem;
  font-size: 1.8rem;
  color: var(--blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;
