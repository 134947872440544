/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import styled from 'styled-components';

import { useFetch } from 'utils/useFetch';
import { useTabs } from 'utils/useTabs';
import { API_MODULES_GET } from 'helpers/endpoints';
import { Loader } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { StickyMargin } from 'components/atoms/Grid';

export const CompareVersionsDialog = ({ data, diffData }) => {
  const { getLiteral } = useContext(composerCxt);
  const getModuleData = useFetch(`${API_MODULES_GET}/id/${diffData.id}/version/${diffData.version}`, 'GET');

  const oldValues = {
    javascript: getModuleData.response?.sources.javascript?.replace(/\r\n/g, '\n'),
    jsLocal: getModuleData.response?.sources.jsLocal?.replace(/\r\n/g, '\n'),
    css: getModuleData.response?.sources.css?.replace(/\r\n/g, '\n'),
    xml: getModuleData.response?.sources.xml?.replace(/\r\n/g, '\n'),
  };
  const newValues = {
    javascript: data.sources.javascript.replace(/\r\n/g, '\n'),
    jsLocal: data.sources.jsLocal.replace(/\r\n/g, '\n'),
    css: data.sources.css.replace(/\r\n/g, '\n'),
    xml: data.sources.xml.replace(/\r\n/g, '\n'),
  };

  const tabsArr = [
    newValues.javascript !== oldValues.javascript && {
      title: getLiteral('JavaScript'),
      component: (
        <ReactDiffViewer
          oldValue={oldValues.javascript}
          newValue={newValues.javascript}
          splitView={true}
          disableWordDiff={true}
          leftTitle={getLiteral('PoprzedniaWersja')}
          rightTitle={getLiteral('AktualnaWersja')}
          compareMethod={'diffLines'}
        />
      ),
    },
    newValues.jsLocal !== oldValues.jsLocal && {
      title: getLiteral('WydzielonyJavascript'),
      component: (
        <ReactDiffViewer
          oldValue={oldValues.jsLocal}
          newValue={newValues.jsLocal}
          splitView={true}
          disableWordDiff={true}
          leftTitle={getLiteral('PoprzedniaWersja')}
          rightTitle={getLiteral('AktualnaWersja')}
          compareMethod={'diffLines'}
        />
      ),
    },
    newValues.css !== oldValues.css && {
      title: getLiteral('CssLess'),
      component: (
        <ReactDiffViewer
          oldValue={oldValues.css}
          newValue={newValues.css}
          splitView={true}
          disableWordDiff={true}
          leftTitle={getLiteral('PoprzedniaWersja')}
          rightTitle={getLiteral('AktualnaWersja')}
          compareMethod={'diffLines'}
        />
      ),
    },
    newValues.xml !== oldValues.xml && {
      title: getLiteral('XMLXSLT'),
      component: (
        <ReactDiffViewer
          oldValue={oldValues.xml}
          newValue={newValues.xml}
          splitView={true}
          disableWordDiff={true}
          leftTitle={getLiteral('PoprzedniaWersja')}
          rightTitle={getLiteral('AktualnaWersja')}
          compareMethod={'diffLines'}
        />
      ),
    },
  ].filter((item) => !!item); // Select only those elements that have changed
  const tabs = useTabs(tabsArr);

  useEffect(() => {
    if (diffData.id && diffData.version) {
      getModuleData.execute();
    }
  }, [diffData.id, diffData.version]);

  return (
    <>
      {getModuleData.loading ? (
        <Loader />
      ) : tabsArr.length ? (
        <>
          <StickyMargin bottom={2}>{tabs.tabs}</StickyMargin>
          {tabs.tabsPanel}
        </>
      ) : (
        <NoChangesWrapper>{getLiteral('BrakHistoriiZmian')}</NoChangesWrapper>
      )}
    </>
  );
};

const NoChangesWrapper = styled.div`
  text-align: center;
  font-weight: 600;
`;
