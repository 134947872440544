/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faCodeBranch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import { Marginer } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { RowButton, StyledLink, Table } from 'components/atoms/Table';
import { Label } from 'components/atoms/TypeLabel';
import {
  PullRequestCompareDialog,
  PullRequestDialogStyles,
} from 'components/organisms/PullRequest/PullRequestCompareDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { API_MODULES_GET, API_PULL_REQUESTS_GET, API_PULL_REQUESTS_SET_STATUS, EDP_MODULES } from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';
import { toastCtx } from 'contexts/ToastContext';
import { useAccess } from 'helpers/access';

export const STATUS_TYPES = {
  ACCEPTED: 'accepted',
  TO_VERIFY: 'to_verify',
  NEEDS_WORK: 'needs_work',
};

export const ComponentsPullRequests = () => {
  const { getLiteral } = useContext(composerCxt);

  const [pagesCount, setPagesCount] = useState(0);
  const [history, setHistory] = useState([]);

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getPullRequestsList = useFetch(`${API_PULL_REQUESTS_GET}/page/${page}`, 'GET');

  useEffect(() => {
    getPullRequestsList.execute();
  }, [page]);

  useEffect(() => {
    if (getPullRequestsList.response) {
      setHistory(getPullRequestsList.response.models);
      setPagesCount(getPullRequestsList.response.pagesCount);
    }
  }, [getPullRequestsList.response, getPullRequestsList.error]);

  return (
    <>
      {getPullRequestsList.loading ? (
        <Loader />
      ) : (
        <>
          {history.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>{getLiteral('nazwa')}</th>
                    <th>{getLiteral('OpisZmian')}</th>
                    <th>{getLiteral('Autor')}</th>
                    <th>{getLiteral('Utworzono')}</th>
                    <th>{getLiteral('Status')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((el) => (
                    <PullRequestRow key={el.id} {...el} pullRequestData={el} refreshList={getPullRequestsList} />
                  ))}
                </tbody>
              </Table>
              <Marginer bottom={2} top={2}>
                {pagesCount ? <Pagination {...paginationProps} /> : null}
              </Marginer>
            </>
          ) : (
            <span>{getLiteral('BrakKomponentowWymagajacychAkceptacji')}</span>
          )}
        </>
      )}
    </>
  );
};

const PullRequestRow = ({
  id,
  description,
  component,
  activity,
  createTime,
  createUser,
  status,
  refreshList,
  pullRequestData,
}) => {
  const { pathname, hash } = useLocation();
  const { getLiteral, getStatusLabel } = useContext(composerCxt);
  const { id: userId } = useContext(userCtx);
  const { createToast } = useContext(toastCtx);
  const canAcceptPullRequests = useAccess('canAcceptChanges');

  const [data, setData] = useState({});
  const [diffData, setDiffData] = useState({});
  const [componentId, setId] = useState(false);
  const [version, setVersion] = useState(false);

  const getComponentData = useFetch(`${API_MODULES_GET}/id/${componentId}/version/${version}`, 'GET');
  const postPullRequestStatus = useFetch(`${API_PULL_REQUESTS_SET_STATUS}`, 'POST');

  const getUserPullRequestStatus = (pullRequest) => {
    const userActivity = pullRequest.activity.find((el) => el.createUser === userId);
    return userActivity ? userActivity.status : STATUS_TYPES.TO_VERIFY;
  };

  const compareDialog = useDialog({
    content: (
      <PullRequestCompareDialog
        data={data}
        diffData={diffData}
        pullRequestData={pullRequestData}
        changePullRequestStatus={postPullRequestStatus}
      />
    ),
    title: getLiteral('SzczegolyAkceptacjiZmian'),
    styles: PullRequestDialogStyles,
  });

  useEffect(() => {
    if (postPullRequestStatus.response) {
      refreshList.execute();
    } else if (postPullRequestStatus.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postPullRequestStatus.error.message,
        context: 'error',
      });
    }
  }, [postPullRequestStatus.response, postPullRequestStatus.error]);

  useEffect(() => {
    if (getComponentData.response) {
      setData(getComponentData.response);
    }
  }, [getComponentData.response, getComponentData.error]);

  useEffect(() => {
    if (data?.id) {
      compareDialog.openDialog();
      setId(false);
    }
  }, [data]);

  useEffect(() => {
    if (componentId) {
      getComponentData.execute();
    }
  }, [componentId]);

  return (
    <>
      <tr key={`${id}`}>
        <td>
          <StyledLink
            to={`${EDP_MODULES}${component.id}/version/${component.version}?link=${encodeURIComponent(
              pathname + hash,
            )}`}
          >
            link
          </StyledLink>
          {`${component.name} v.${component.parentVersion}`}
        </td>
        <td>{description}</td>
        <td>{createUser}</td>
        <td>{createTime}</td>
        <td>
          <PullRequestLabel type={status}>{getStatusLabel(status)}</PullRequestLabel>
        </td>
        <td>
          <ActionButtons>
            <RowButton
              loading={getComponentData.loading.toString()}
              title={getLiteral('Porownaj')}
              onClick={() => {
                setId(component.id);
                setVersion(component.version);
                setDiffData({ id: component.parentId, version: component.parentVersion });
              }}
            >
              <FontAwesomeIcon icon={faCodeBranch}></FontAwesomeIcon>
            </RowButton>
            {userId !== createUser && canAcceptPullRequests.condition ? (
              <>
                {getUserPullRequestStatus({ status, activity }) !== STATUS_TYPES.ACCEPTED && (
                  <AcceptButton
                    loading={postPullRequestStatus.loading.toString()}
                    title={getLiteral('AkceptujZmiany')}
                    onClick={() => {
                      postPullRequestStatus.execute({
                        id: id,
                        status: STATUS_TYPES.ACCEPTED,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  </AcceptButton>
                )}
                {getUserPullRequestStatus({ status, activity }) !== STATUS_TYPES.NEEDS_WORK && (
                  <RemoveButton
                    loading={postPullRequestStatus.loading.toString()}
                    title={getLiteral('OdrzucZmiany')}
                    onClick={() => {
                      postPullRequestStatus.execute({
                        id: id,
                        status: STATUS_TYPES.NEEDS_WORK,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                  </RemoveButton>
                )}
              </>
            ) : null}
          </ActionButtons>
        </td>
      </tr>
      {compareDialog.dialog}
    </>
  );
};

export const PullRequestLabel = styled(Label)`
  ${({ type }) => {
    switch (type) {
      case STATUS_TYPES.ACCEPTED:
        return css`
          background-color: var(--green);
        `;
      case STATUS_TYPES.TO_VERIFY:
        return css`
          background-color: var(--draftColor);
        `;
      default:
        return css`
          background-color: var(--red);
        `;
    }
  }}
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
`;
export const RemoveButton = styled(RowButton)`
  color: var(--text);
  background-color: #ffe8e8;

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
    `}
`;
export const AcceptButton = styled(RemoveButton)`
  background-color: #d9ffdc;
`;
