/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/atoms/Form';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { File, Name, Picture, StyledTags } from 'components/atoms/ManagerFileListItem';
import { SortingComponent } from 'components/atoms/SortingComponent';
import { SupportMark } from 'components/atoms/SupportMark';
import { composerCxt } from 'contexts/ComposerContext';
import FileSvg from 'data/icon-file.svg';
import { API_GFX_GET } from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { pictureRegex } from 'helpers/regexp';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const FileManagerDialogComponent = ({ replaceFile }) => {
  const { getLiteral } = useContext(composerCxt);
  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [filters, setFilters] = useState({
    text: '',
    type: '',
    sort: 'date',
    standardFiles: false,
  });

  const getClientGfxList = useFetch(API_GFX_GET, 'POST');
  const { page, paginationProps, Pagination, resetPage } = usePagination({
    pageLimit: pagesCount,
  });

  const searchTimeoutRef = useRef();

  // Getting files after component mount
  useEffect(() => {
    if (!filters.text) {
      getClientGfxList.execute({
        page: page,
        standardFiles: filters.standardFiles,
        sort: filters.sort,
        name: filters.text,
      });
    } else {
      clearTimeout(searchTimeoutRef.current);
      searchTimeoutRef.current = setTimeout(
        () =>
          getClientGfxList.execute({
            page: page,
            standardFiles: filters.standardFiles,
            sort: filters.sort,
            name: filters.text,
          }),
        500,
      );
    }
  }, [filters.standardFiles, page, filters.sort, filters.text]);

  useEffect(() => {
    if (getClientGfxList.response) {
      setFiles(getClientGfxList.response.models);
      setPagesCount(getClientGfxList.response.pagesCount);

      // Creating set of uniqe extension names
      const uniqeTypesList = new Set(getClientGfxList.response.models.map((item) => item.name.split('.').slice(-1)[0]));

      // Building select option based on unique set
      // if someone of item in set is undefined, function change it into value "without extension"
      setTypes(
        [...uniqeTypesList].map((item) => makeSelectOption(item || 'none', item || getLiteral('BezRozszerzenia'))),
      );
    }
  }, [getClientGfxList.response]);

  return (
    <DialogInfoWrapper>
      <StandardFilesMarginer bottom={2}>
        <Checkbox
          checked={filters.standardFiles}
          bottom="0"
          title={
            <>
              {getLiteral('PlikiStandardowe')}
              <SupportMark />
            </>
          }
          onChange={() => {
            resetPage();
            setFilters((state) => ({ ...state, standardFiles: !state.standardFiles }));
          }}
        />
      </StandardFilesMarginer>
      <SortingComponent
        setTextFilter={(value) => {
          resetPage();
          setFilters((state) => ({ ...state, text: value }));
        }}
        setTypeFilter={(value) => setFilters((state) => ({ ...state, type: value }))}
        setSortFilter={(value) => {
          resetPage();
          setFilters((state) => ({ ...state, sort: value }));
        }}
        typeTitle={getLiteral('SortujPoTypie')}
        typeOptions={types}
      />
      <FixedRow>
        {getClientGfxList.loading ? (
          <Loader relative />
        ) : (
          <>
            {files.length ? (
              <>
                <ItemsRow>
                  {files
                    .filter(({ name }) => {
                      if (filters.type === 'none' && name.split('.').length === 1) return true;
                      if (filters.type && !name.includes(`.${filters.type}`)) return false;

                      return true;
                    })
                    .map((file) => {
                      const ext = file.name.split('.').slice(-1)[0];

                      return (
                        <Col size={3} key={file.name}>
                          <File>
                            <StyledTags ext={ext} clientGfx={file.clientGfx} isStandardFile={file.isStandardFile} />
                            <Picture>
                              <img
                                src={pictureRegex.test(file.path) ? `/gfx/${file.path}` : FileSvg}
                                className={!pictureRegex.test(file.path) ? 'no-bg' : ''}
                                alt={file.name}
                                loading="lazy"
                              />
                            </Picture>
                            <Bottom>
                              <Name title={file.name}>{file.name}</Name>
                            </Bottom>
                            <Action onClick={() => replaceFile(file.name, file.isStandardFile)}>
                              {getLiteral('WybierzTenPlik')}
                            </Action>
                          </File>
                        </Col>
                      );
                    })}
                </ItemsRow>
                <StyledMarginer top={1}>{pagesCount ? <Pagination {...paginationProps} /> : null}</StyledMarginer>
              </>
            ) : null}
          </>
        )}
      </FixedRow>
    </DialogInfoWrapper>
  );
};

const StandardFilesMarginer = styled(Marginer)`
  display: flex;
  justify-content: end;
`;

const StyledMarginer = styled(Marginer)`
  width: 100%;
`;

const ItemsRow = styled(Row)`
  margin: 0;
`;

const FixedRow = styled(Row)`
  overflow: auto;
  height: calc(100% - 100px);
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Action = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.6rem;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

const DialogInfoWrapper = styled.div`
  min-width: 60vw;
  height: 70vh;
  max-width: 1000px;

  .data {
    padding-left: 3rem;
  }

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    padding-left: 1.2rem;
  }
  p {
    margin-bottom: 2rem;
    font-size: 1.3rem;
  }

  li {
    margin-bottom: 1rem;
  }
`;
