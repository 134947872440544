import { css } from 'styled-components';

export const cssVars = css`
  :root {
    --gray: #dddddd;
    --gray-light: #f0f0f0;
    --gray-dark: #999;
    --black: #000000;
    --green: #28a745;
    --blue: #0288d1;
    --red: #dd0909;
    --white: #fff;
    --text: #1d1f21;

    --tabUnderlineColor: #02a767;

    --border: 1px solid var(--gray);
    --border-light: 1px solid var(--gray-light);
    --border-radius: 5px;

    --scrollbarBG: var(--gray);
    --thumbBG: #0288d1;

    --widthWithoutAside: calc(100vw - 320px);

    /*Nowy interfejs Composer*/
    --listItemColor: #1d1f21;
    --listItemColorLight: #999;
    --listItemBorderColor: #e0e0e0;

    --standardColorHue: 210;
    --standardColorSaturation: 6%;
    --standardColorLight: 12%;
    --standardColor: hsl(var(--standardColorHue), var(--standardColorSaturation), var(--standardColorLight));
    --standardColorBorder: hsl(
      var(--standardColorHue),
      calc(var(--standardColorSaturation) - 6%),
      calc(var(--standardColorLight) + 75%)
    );
    --standardColorBg: hsl(
      var(--standardColorHue),
      calc(var(--standardColorSaturation) - 6%),
      calc(var(--standardColorLight) + 87%)
    );

    --customColorHue: 212;
    --customColorSaturation: 98%;
    --customColorLight: 43%;
    --customColor: hsl(var(--customColorHue), var(--customColorSaturation), var(--customColorLight));
    --customColorBorder: hsl(var(--customColorHue), var(--customColorSaturation), var(--customColorLight));
    --customColorBg: hsl(
      var(--customColorHue),
      calc(var(--customColorSaturation) - 25%),
      calc(var(--customColorLight) + 48%)
    );

    --draftColorHue: 46;
    --draftColorSaturation: 100%;
    --draftColorLight: 38%;
    --draftColor: hsl(var(--draftColorHue), var(--draftColorSaturation), var(--draftColorLight));
    --draftColorBorder: hsl(var(--draftColorHue), var(--draftColorSaturation), var(--draftColorLight));
    --draftColorBg: hsl(var(--draftColorHue), var(--draftColorSaturation), calc(var(--draftColorLight) + 56%));

    --liteColorHue: 157;
    --liteColorSaturation: 98%;
    --liteColorLight: 33%;
    --liteColor: hsl(var(--liteColorHue), var(--liteColorSaturation), var(--liteColorLight));
    --liteColorBorder: hsl(var(--liteColorHue), var(--liteColorSaturation), var(--liteColorLight));
    --liteColorBg: hsl(var(--liteColorHue), calc(var(--liteColorSaturation) + 2%), calc(var(--liteColorLight) + 61%));

    --proColorHue: 276;
    --proColorSaturation: 73%;
    --proColorLight: 47%;
    --proColor: hsl(var(--proColorHue), var(--proColorSaturation), var(--proColorLight));
    --proColorBorder: hsl(var(--proColorHue), var(--proColorSaturation), var(--proColorLight));
    --proColorBg: hsl(var(--proColorHue), calc(var(--proColorSaturation) + 27%), calc(var(--proColorLight) + 47%));

    --buttonDisabledColorBg: #f4f4f4;
    --buttonDisabledColor: #9b9b9b;
  }
`;
