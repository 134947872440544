import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Col, Marginer, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';
import { marketingCtx } from 'contexts/MarketingContext';
import { useQueryObject } from 'utils/useQueryObject';

export const BackwardLink = ({ title, to }) => {
  return (
    <HeaderBackLink to={to}>
      <FontAwesomeIcon icon={faArrowLeft} />
      {title}
    </HeaderBackLink>
  );
};

export const Header = ({ title, tabs, backwardTitle, backwardTo, children, titleSize = 6, info = null }) => {
  const { getLiteral } = useContext(composerCxt);
  const { topBannerEnabled } = useContext(marketingCtx);
  const [isScrolled, setIsScrolled] = useState(false);
  const query = useQueryObject();

  const minimizeOn = topBannerEnabled() ? 340 : 0;

  const scrollEvent = () => {
    if (window.scrollY > minimizeOn) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);

    return () => window.removeEventListener('scroll', scrollEvent);
  });

  return (
    <HeaderWrapper isScrolled={isScrolled.toString()}>
      {backwardTitle && backwardTo && (
        <HeadersBackward className="--hide-on-scroll">
          <BackwardLink title={backwardTitle} to={backwardTo} />
        </HeadersBackward>
      )}
      {!backwardTitle && !backwardTo && query?.link && (
        <HeadersBackward className="--hide-on-scroll">
          <BackwardLink title={getLiteral('Powrot')} to={decodeURIComponent(query.link)} />
        </HeadersBackward>
      )}
      <Row align="center">
        <Col size={titleSize}>
          <h1>{title}</h1>
        </Col>
        <Col size={12 - titleSize}>
          <HeaderChildAligner>{children}</HeaderChildAligner>
        </Col>
      </Row>
      {info && (
        <div className="--hide-on-scroll">
          <Marginer top={1}>{info}</Marginer>
        </div>
      )}
      {tabs && <HeaderTabs>{tabs}</HeaderTabs>}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  border-bottom: var(--border-light);
  margin-bottom: 2rem;
  padding: 45px 0;
  width: 100%;
  transition: padding 0.3s;
  position: sticky;
  top: 0;
  z-index: 499;
  background-color: var(--white);
  margin-bottom: 3rem;

  .--hide-on-scroll {
    transition: opacity 0.3s;
  }

  h1 {
    font-size: 2.6rem;
    font-weight: bold;
  }

  ${({ isScrolled }) =>
    isScrolled === 'true' &&
    css`
      padding: 10px 0 30px;

      .--hide-on-scroll {
        opacity: 0;
        z-index: 0;
      }

      div.--hide-on-scroll {
        height: 0px;
      }
    `}
`;

const HeaderTabs = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
`;

const HeadersBackward = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
`;

const HeaderBackLink = styled(Link)`
  text-decoration: none;
  font-size: 1.3rem;
  color: var(--black);

  svg {
    margin-right: 1rem;
  }
`;

const HeaderChildAligner = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
