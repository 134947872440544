import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';

import { IconClose } from 'components/atoms/Icon';

export const CustomDialogTitle = (props) => {
  return (
    <StyledDialogTitle id={props.id}>
      <Flex>
        {props.subtitle && props.title ? (
          <TitleGroupFlex>
            <BigTitle>{props.title}</BigTitle>
            <Title>{props.subtitle}</Title>
          </TitleGroupFlex>
        ) : (
          <BigTitle>{props.title}</BigTitle>
        )}
        <CloseButton onClick={props.closeCallback}>
          <IconClose />
        </CloseButton>
      </Flex>
    </StyledDialogTitle>
  );
};

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = ({ children }) => <div style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>{children}</div>;
const BigTitle = ({ children }) => <div style={{ fontSize: '2.2rem', fontWeight: '700' }}>{children}</div>;

const TitleGroupFlex = styled(Flex)`
  align-items: flex-end;
  width: 66.66666%;
`;

const CloseButton = styled.button`
  margin-top: 0.8rem;
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  span.svg-icon {
    width: inherit;
    height: inherit;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
