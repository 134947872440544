/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Marginer, CompactRow } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { API_TEMPLATES_LIST } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';
import { TemplateListTiles } from 'components/organisms/TemplateListTiles';
import { TemplatePublishedListTable } from 'components/organisms/TemplatePublishedListTable';

export const TEMPLATE_LIST_MODE_MANAGE = 'management';
export const TEMPLATE_LIST_MODE_SELECTION = 'selection';

export const TemplateCustomList = ({
  mode = TEMPLATE_LIST_MODE_MANAGE,
  type = 'custom',
  render = 'tiles',
  setHasElements,
  setNumElements,
  standardEdition = false,
  templateSelected,
  setTemplateSelected,
}) => {
  const [list, setList] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getListTemplates = useFetch(`${API_TEMPLATES_LIST}`, 'POST');

  useEffect(() => {
    const data = {
      page,
      sort: 'last-edit',
      order: 'desc',
      type,
      standardEdition,
    };

    getListTemplates.execute(data);
  }, [page, type]);

  useEffect(() => {
    if (getListTemplates.response) {
      setList(getListTemplates.response.models);
      setPagesCount(getListTemplates.response.pagesCount);

      if (setHasElements) {
        setHasElements(getListTemplates.response.pagesCount !== 0);
      }

      if (getListTemplates.response.models && setNumElements) {
        setNumElements(getListTemplates.response.totalNumber);
      }
    }
  }, [getListTemplates.response]);

  const renderedList =
    render === 'tiles' ? (
      <TemplateListTiles
        list={list}
        listTemplates={getListTemplates.response}
        mode={mode}
        type={type}
        templateSelected={templateSelected}
        setTemplateSelected={setTemplateSelected}
      />
    ) : (
      <TemplatePublishedListTable
        list={list}
        listTemplates={getListTemplates.response}
        mode={mode}
        type={type}
        templateSelected={templateSelected}
        setTemplateSelected={setTemplateSelected}
        standardEdition={standardEdition}
      />
    );

  return (
    <div key={`list_${type}`}>
      <CompactRow>{getListTemplates.loading ? <Loader relative /> : renderedList}</CompactRow>
      <Marginer bottom={2} top={1}>
        {pagesCount ? <Pagination {...paginationProps} /> : null}
      </Marginer>
    </div>
  );
};

TemplateCustomList.prototype = {
  type: PropTypes.string.isRequired,
  setHasElements: PropTypes.func,
};
