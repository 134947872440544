import { useContext, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';

import { TEMPLATE_LIST_MODE_SELECTION, TemplateCustomList } from './TemplateCustomList';

import { CompactContainer, Content } from 'components/atoms/Grid';
import { CustomDialogTitle } from 'components/atoms/CustomDialogTitle';
import CustomDialog from 'components/atoms/CustomDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { Button } from 'components/atoms/Button';

export const TemplateListDialog = ({ open, setOpen, nextStep }) => {
  const { getLiteral } = useContext(composerCxt);
  const [numStandardDesigns, setNumStandardDesigns] = useState('');
  const [templateSelected, setTemplateSelected] = useState('');

  const handleClose = () => {
    setOpen(false);
    setTemplateSelected('');
  };
  const handleSubmit = () => {
    nextStep(templateSelected);
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle
        title={getLiteral('WybierzWzorKompozycji')}
        subtitle={`${getLiteral('LiczbaDostepnychKompozycji')} ${numStandardDesigns}`}
        closeCallback={handleClose}
      />
      <DialogContent>
        <DialogListContent>
          <DialogListCompactContainer>
            <TemplateCustomList
              mode={TEMPLATE_LIST_MODE_SELECTION}
              type="standard"
              setNumElements={setNumStandardDesigns}
              templateSelected={templateSelected}
              setTemplateSelected={setTemplateSelected}
            ></TemplateCustomList>
          </DialogListCompactContainer>
        </DialogListContent>
      </DialogContent>
      <CustomDialogFooter>
        <Flex>
          <Button disabled={!templateSelected} onClick={handleSubmit} color="green">
            {getLiteral('PodajDaneKompozycji')}
          </Button>
          <Button onClick={handleClose} color="white">
            {getLiteral('Anuluj')}
          </Button>
        </Flex>
      </CustomDialogFooter>
    </CustomDialog>
  );
};

const DialogListContent = styled(Content)`
  margin-left: -4px;
  margin-right: -4px;
  width: auto;
`;

const DialogListCompactContainer = styled(CompactContainer)`
  width: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const CustomDialogFooter = styled.div`
  padding: 2rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  button {
    margin-right: 1rem;
  }
`;
