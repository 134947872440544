import PropTypes from 'prop-types';

export const isComponentEditable = ({ componentEditable, userCanEdit, validFunction }) => {
  const canChangeComponentEditable = validFunction('canChangeComponentEditable');
  return componentEditable && (userCanEdit === 'y' || canChangeComponentEditable.condition);
};

isComponentEditable.propTypes = {
  editable: PropTypes.bool,
  useAccess: PropTypes.func,
};
