import { useContext } from 'react';
import styled from 'styled-components';

import { composerCxt } from 'contexts/ComposerContext';

export const SupportMark = () => {
  const { getLiteral } = useContext(composerCxt);

  return <SupportMarkSpan>[{getLiteral('support')}]</SupportMarkSpan>;
};

const SupportMarkSpan = styled.span`
  padding-left: 0.5rem;
  color: red;
  font-size: 1.3rem;
  font-weight: 100;
`;
