import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { templateListItemCtx } from 'contexts/TemplateListItemContext';
import { API_PREVIEW, EDP_TEMPLATES } from 'helpers/endpoints';
import { TEMPLATE_LIST_MODE_MANAGE } from 'components/organisms/TemplateCustomList';
import { ButtonGroup, CompactButton, CompactButtonLink } from 'components/atoms/Button';
import { PublishDialog } from 'components/atoms/PublishDialog';
import { IconEdit, IconListPoint, IconPreview, IconPublish } from 'components/atoms/Icon';

export const TemplateItemButtons = ({ mode, onSelect, itemSelected }) => {
  const history = useHistory();
  const [openPublish, setOpenPublish] = useState(false);
  const { getLiteral } = useContext(composerCxt);
  const user = useContext(userCtx);
  const { id, version, isDraft, languages, canPublish, canEdit, startEdit, typet } = useContext(templateListItemCtx);

  return (
    <>
      {mode === TEMPLATE_LIST_MODE_MANAGE ? (
        typet !== 'standard' && (
          <>
            <PublishDialog
              id={id}
              version={version}
              languages={languages}
              open={openPublish}
              setOpen={setOpenPublish}
            />
            <CompactButton
              color="green"
              disabled={!canPublish()}
              onClick={() => setOpenPublish(true)}
              title={getLiteral('publikuj')}
            >
              <IconPublish />
              <span>{getLiteral('publikuj')}</span>
            </CompactButton>
          </>
        )
      ) : (
        <CompactButton disabled={itemSelected} type="button" color="green" onClick={() => onSelect(id)}>
          {getLiteral('WybierzWzor')}
        </CompactButton>
      )}
      <ButtonGroup>
        {mode === TEMPLATE_LIST_MODE_MANAGE && (
          <>
            <EditButton id={id} isDraft={isDraft} userCanEditComposition={canEdit()} startEdit={startEdit} />
            <CompactButton color="green" type="button" onClick={() => history.push(`${EDP_TEMPLATES}${id}`)}>
              <IconListPoint color="green" />
              {getLiteral('Szczegoly')}
            </CompactButton>
          </>
        )}
        <CompactButtonLink target="_blank" href={urlPreviewDesign(user.domain, id, version)}>
          <IconPreview color="green" />
          {getLiteral('Podglad')}
        </CompactButtonLink>
      </ButtonGroup>
    </>
  );
};

const windowOpenPreviewDesign = (domain, id, version) => {
  let url = urlPreviewDesign(domain, id, version);
  window.open(url, '_blank');
};

const urlPreviewDesign = (domain, id, version) => {
  return `${domain}${API_PREVIEW}?designId=${id}&designVersion=${version}`;
};

const EditButton = ({ id, isDraft, userCanEditComposition, startEdit }) => {
  const history = useHistory();
  const { getLiteral } = useContext(composerCxt);
  return (
    <CompactButton
      onClick={() => {
        if (isDraft !== 'y') {
          startEdit();
        } else {
          history.push(`${EDP_TEMPLATES}${id}`);
        }
      }}
      disabled={!userCanEditComposition}
      type="button"
    >
      <IconEdit color="green" />
      {getLiteral('Edytuj')}
    </CompactButton>
  );
};
