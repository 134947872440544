import { useContext, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import styled from 'styled-components';
import { sprintf } from 'sprintf-js';

import { toastCtx } from 'contexts/ToastContext';
import { composerCxt } from 'contexts/ComposerContext';
import { useFetch } from 'utils/useFetch';
import { API_COMPOSER_SUBSCRIPTION_PRICE, API_TEMPLATES_PRO_GET_PUBLISHED } from 'helpers/endpoints';
import { Loader } from 'components/atoms/Loader';
import { Button } from 'components/atoms/Button';
import CustomDialog from 'components/atoms/CustomDialog';
import { CustomDialogTitle } from 'components/atoms/CustomDialogTitle';

export const SUBSCRIPTION_DIALOG_TYPE_ACTIVATION = 'activation';
export const SUBSCRIPTION_DIALOG_TYPE_ACTIVATED = 'activated';
export const SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION = 'deactivation';
export const SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED = 'deactivated';

export const SubscriptionDialog = ({ open, setOpen, type }) => {
  const { getLiteral, setComposerVersionPro, setComposerVersionLite } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const [designsPro, setDesignsPro] = useState([]);
  const [composerProPrice, setComposerProPrice] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  const getPublishedCompositionsPro = useFetch(`${API_TEMPLATES_PRO_GET_PUBLISHED}/forceUpdate/1`);
  const getComposerProPrice = useFetch(`${API_COMPOSER_SUBSCRIPTION_PRICE}`);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    switch (type) {
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATION:
        setComposerVersionPro();
        break;
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION:
        setComposerVersionLite();
        break;
      default:
        setOpen(false);
    }
    return true;
  };

  const getCancelButtonLabel = () => {
    let buttonLabel;
    switch (type) {
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATION:
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION:
        buttonLabel = getLiteral('Anuluj');
        break;
      default:
        buttonLabel = '';
    }
    return buttonLabel;
  };

  const getConfirmButtonLabel = () => {
    let buttonLabel;
    switch (type) {
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATION:
        buttonLabel = sprintf(getLiteral('AktywujeComposerProXMies'), composerProPrice);
        break;
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION:
        buttonLabel = getLiteral('RezygnujeZComposerPro');
        break;
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATED:
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED:
        buttonLabel = getLiteral('OK');
        break;
      default:
        buttonLabel = '';
    }
    return buttonLabel;
  };

  const getTitle = () => {
    let title;
    switch (type) {
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATION:
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATED:
        title = getLiteral('AktywacjaComposerPro');
        break;
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION:
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED:
        title = getLiteral('RezygnacjaZComposerPro');
        break;
      default:
        title = '';
    }
    return title;
  };

  useEffect(() => {
    if (
      type === SUBSCRIPTION_DIALOG_TYPE_ACTIVATED ||
      type === SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED ||
      (type === SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION && loadDesignsProIfNeeded())
    ) {
      setDataLoaded(true);
    } else if (type === SUBSCRIPTION_DIALOG_TYPE_ACTIVATION && loadComposerProPrice()) {
      if (getComposerProPrice.response && getComposerProPrice.response.price) {
        setDataLoaded(true);
      } else {
        setOpen(false);
        createToast({
          title: getLiteral('CosPoszloNieTak'),
          message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
          context: 'error',
        });
      }
    }
  }, [open, getComposerProPrice.response, getComposerProPrice.response]);

  useEffect(() => {
    if (getPublishedCompositionsPro.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (!getPublishedCompositionsPro.response) {
      setDesignsPro([]);
    }

    if (getPublishedCompositionsPro.response) {
      let designsNames = [];
      getPublishedCompositionsPro.response.map((el, index) => {
        designsNames.push(el.design_name);
      });
      let uniqueDesigns = designsNames.filter((v, i, a) => a.indexOf(v) === i);
      setDesignsPro(uniqueDesigns);
    }
  }, [getPublishedCompositionsPro.response, getPublishedCompositionsPro.error]);

  useEffect(() => {
    if (getComposerProPrice.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      return;
    }

    if (getComposerProPrice.response && getComposerProPrice.response.price) {
      setComposerProPrice(getComposerProPrice.response.price);
    }
  }, [getComposerProPrice.response, getComposerProPrice.error]);

  const loadDesignsProIfNeeded = () => {
    if (open && !getComposerProPrice.response) {
      getComposerProPrice.execute();
      return false;
    } else if (open && getComposerProPrice.response) {
      return true;
    }
  };

  const loadComposerProPrice = () => {
    if (open && !getComposerProPrice.response) {
      getComposerProPrice.execute();
      return false;
    } else if (open && getComposerProPrice.response) {
      return true;
    }
  };

  const getBody = () => {
    let body;
    switch (type) {
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATION:
        body = getLiteral(
          'UslugaComposerProObejmujeWszystkieSklepyWPaneluPublikacjaIKorzystanieWSklepieZKompozycjiWlasnejProSzablonuWlasnegoXSLTOrazSzablonuWlasnegoSMARTYWymagaAktywnejSubskrypcjiWPrzypadkuRezygnacjiZUslugiKoniecznaJestZmianaOpublikowanejKompozycjiWlasnejProSzablon',
        );
        break;
      case SUBSCRIPTION_DIALOG_TYPE_ACTIVATED:
        body = getLiteral(
          'AktywacjaComposerProPrzebieglaPomyslnieOdTejChwiliZyskujeszPelnyDostepDoKoduXrodlowegoKomponentowISzablonowOgraniczaCieTylkoWyobraxnia',
        );
        break;
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATION:
        body = getLiteral(
          'WPrzypadkuRezygnacjiZUslugiComposerProUpewnijSieZeNieWykorzystujeszKompozycjiWlasnejProSzablonuWlasnegoXSLTLubSzablonuWlasnegoSMARTYWZadnymJezykuISklepieWPaneluAbyMocZrezygnowacZSubskrypcjiSklepMozeJedynieKorzystacZDowolnejKompozycjiStandardKompozycjiWlas',
        );
        break;
      case SUBSCRIPTION_DIALOG_TYPE_DEACTIVATED:
        body = getLiteral(
          'DezaktywacjaComposerProPrzebieglaPomyslnieWszystkieUtworzoneKompozycjeZostalyZapamietaneIBedaGotoweDoUzyciaGdyPonownieAktywujeszComposerProOdTejChwiliZyskujeszDostepDoPodstawowejEdycjiKompozycjiWitamyPonownieWComposerLite',
        );
        break;
      default:
        body = '';
    }
    return body;
  };

  const getDesignsList = () => {
    if (!designsPro.length) {
      return '';
    }
    return (
      <>
        <StyledH3>{getLiteral('SzablonyWymagajaceWymiany')}</StyledH3>
        <ListWithBullets>
          {designsPro.map((design) => {
            return <li key={design}>{design}</li>;
          })}
        </ListWithBullets>
      </>
    );
  };

  return (
    getTitle() &&
    getBody() &&
    getConfirmButtonLabel() && (
      <CustomDialog open={open} onClose={handleClose}>
        {!dataLoaded ? (
          <Loader />
        ) : (
          <>
            <CustomDialogTitle title={getTitle()} closeCallback={handleClose} />
            <StyledDialogContent>
              <p>{getBody()}</p>
              {getDesignsList()}
            </StyledDialogContent>
            <CustomDialogFooter>
              <Flex>
                <Button disabled={!!designsPro.length} onClick={handleSubmit} color="green">
                  {getConfirmButtonLabel()}
                </Button>
                {getCancelButtonLabel() && (
                  <Button onClick={handleClose} color="white">
                    {getCancelButtonLabel()}
                  </Button>
                )}
              </Flex>
            </CustomDialogFooter>
          </>
        )}
      </CustomDialog>
    )
  );
};

const StyledH3 = styled.h3`
  font-size: 2rem;
  line-height: 2.6rem;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

const ListWithBullets = styled.ul`
  font-size: 1.4rem;
  line-height: 2rem;
  list-style: disc;
  padding-left: 1.5rem;
`;

const StyledDialogContent = styled(DialogContent)`
  max-width: 50rem;
  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

const CustomDialogFooter = styled.div`
  padding: 2rem;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  button {
    margin-right: 1rem;
  }
`;
