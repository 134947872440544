import { useCallback, useState } from 'react';

import { Fetch } from 'helpers/fetch';

/**
 * Execute request and return response
 * @param {string} endpoint link to execute request
 * @param {string} method http method type
 */
export const useFetch = (endpoint, method = 'GET') => {
  const [pagination, setPagination] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Function execute request, and set response when data are ready
  const execute = useCallback(
    (body, onSuccessCallback, isFormData, onErrorCallback) => {
      const getData = async () => {
        setError(false);
        setResponse(null);
        setLoading(true);
        setPagination(null);

        try {
          const res = await Fetch[method](endpoint, body, isFormData);

          if (res.status.value === 'error') {
            setError(res.status);
            if (onErrorCallback) {
              onErrorCallback(res.status);
            }
            setLoading(false);
            return;
          }
          if (res.pagination) {
            setPagination(res.pagination);
          }
          setResponse(res.response);
          if (onSuccessCallback) {
            onSuccessCallback(res.response);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setError({ value: 'error', message: err.message });
        }
      };

      getData();
    },
    [endpoint, method],
  );

  return {
    response,
    pagination,
    loading,
    error,
    executed: (response && !loading) || error,
    execute,
  };
};
