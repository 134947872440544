import React, { useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Textarea } from 'components/atoms/Form';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';

export const CommentWidget = ({ changeKey, comments, draft, onDraftChange, onSubmit, onDiscard, addNew, loading }) => {
  const { getLiteral } = useContext(composerCxt);
  const { getUserIconLink } = useContext(userCtx);

  const renderComment = (comment) => {
    if (comment.replaceAfterSave) {
      return null;
    }

    return (
      <Comment key={comment.id}>
        <CommentHeader>
          <img src={getUserIconLink(comment.createUser)} alt={comment.createUser} />
          <strong>{comment.createUserName}</strong>
          <CommentCreateTimeContainer>
            <span>{comment.createTime}</span>
          </CommentCreateTimeContainer>
        </CommentHeader>
        <span>{comment.content}</span>
      </Comment>
    );
  };
  const input = useCallback((e) => onDraftChange(changeKey, e.target.value), [onDraftChange, changeKey]);
  const submit = useCallback(() => onSubmit(changeKey), [onSubmit, changeKey]);
  const discard = useCallback(() => onDiscard(changeKey), [onDiscard, changeKey]);

  useEffect(() => {
    if (addNew) {
      const element = document.getElementById('addCommentWidget');

      if (element) {
        const elementTextArea = document.getElementById('addCommentWidgetTextArea');
        elementTextArea.focus({ preventScroll: true });
        element.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
      }
    }
  }, [addNew]);

  return (
    <>
      <ol>{comments.map(renderComment)}</ol>
      {addNew && (
        <AddComment id={'addCommentWidget'}>
          <Textarea
            minRows={4}
            id={'addCommentWidgetTextArea'}
            placeholder={getLiteral('WpiszKomentarz')}
            value={draft}
            autofocus
            onChange={input}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button loading={loading.toString()} disabled={!!draft === false || draft.trim() === ''} onClick={submit}>
              {getLiteral('DodajKomentarz')}
            </Button>
            <Button type="warning" onClick={discard}>
              {getLiteral('Anuluj')}
            </Button>
          </div>
        </AddComment>
      )}
    </>
  );
};

const CommentCreateTimeContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const AddComment = styled.div`
  border: 1px solid var(--gray);
  padding: 1rem;
`;

const CommentHeader = styled.div`
  img {
    border-radius: 50%;
    margin-right: 1rem;
    width: 50px;
  }

  white-space: nowrap;
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
`;

const Comment = styled.div`
  width: 100%;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid var(--gray);
`;
