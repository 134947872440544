/* eslint-disable react-hooks/exhaustive-deps */
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faEdit, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { CommitMessageDialog } from 'components/atoms/CommitMessageDialog';
import { PublishDialog } from 'components/atoms/PublishDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_TEMPLATES_IS_PUBLISHABLE } from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { useAccess } from 'helpers/access';

export const TemplateCommit = () => {
  const { isDraft, commitButton, isPublishable, type, id, version, languages, editView, allowedActions } = useContext(
    templateCxt,
  );
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const [openPublish, setOpenPublish] = useState(false);
  const canEditStandardCompositions = useAccess('canEditStandardDesigns');

  //jeżeli nie można opublikować - będziemy sprawdzać co jakiś czas
  const [canPublish, setCanPublish] = useState(isPublishable);
  const checkIsPublishable = useFetch(`${API_TEMPLATES_IS_PUBLISHABLE}`, 'POST');

  const canPublishInterval = useRef(null);

  const commitMessageDialog = useDialog({
    title: getLiteral('OpiszSwojeZmiany'),
    content: (
      <CommitMessageDialog
        onSubmit={(data) => {
          commitButton(data);
          commitMessageDialog.closeDialog();
        }}
      />
    ),
  });

  //sprawdzenie co 30 sekund
  const startCounter = () =>
    (canPublishInterval.current = setInterval(() => {
      checkIsPublishable.execute({ id, version });
    }, 30000));

  const stopCounter = () => clearInterval(canPublishInterval.current);

  useEffect(() => {
    setCanPublish(isPublishable);
    if (isPublishable === false && !editView) {
      startCounter();
    }
    return () => stopCounter();
  }, [id, version]);

  useEffect(() => {
    if (checkIsPublishable && checkIsPublishable.response && checkIsPublishable.response.isPublishable === true) {
      setCanPublish(true);
      stopCounter();
    }
  }, [checkIsPublishable.response]);

  const handleClickOpenPublish = () => {
    if (canPublish) {
      setOpenPublish(true);
      return;
    }

    createToast({
      title: getLiteral('TaWersjaKompozycjiNieMozeZostacOpublikowana'),
      message: getLiteral('JestesmyWTrakcieGenerowaniaZooptymalizowanejWersjiTejKompozycjiSprobujPonownieZaKilkaMinut'),
      context: 'info',
    });
  };

  const handleClickOpenCommitMsg = () => {
    if (editView) {
      commitMessageDialog.openDialog();
    } else {
      commitButton();
    }
  };

  return (
    <>
      <Wrapper>
        <PublishDialog
          id={id}
          version={version}
          languages={languages}
          open={openPublish}
          setOpen={setOpenPublish}
        ></PublishDialog>
        {(type !== 'standard' || canEditStandardCompositions.condition) && allowedActions.edit ? (
          <>
            <CommitButton type="button" onClick={() => handleClickOpenCommitMsg()}>
              {isDraft === 'y' ? (
                <>
                  <FontAwesomeIcon icon={faSave} />
                  <span>{getLiteral('ZakonczEdycje')}</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faEdit} />
                  <span>{getLiteral('RozpocznijEdycje')}</span>
                </>
              )}
            </CommitButton>
          </>
        ) : null}
        {editView || !allowedActions.publish ? null : (
          <PublishButton isPublishable={canPublish === true ? 0 : 1} onClick={handleClickOpenPublish}>
            <FontAwesomeIcon icon={faUpload} />
            <span>
              {canPublish === true ? getLiteral('OpublikujKompozycjeWSklepie') : getLiteral('TrwaGenerowanieSzablonu')}
            </span>
          </PublishButton>
        )}
      </Wrapper>
      {commitMessageDialog.dialog}
    </>
  );
};

const PublishButton = styled(Button)`
  min-width: 15vh;
  margin-left: 1rem;
  background-color: var(--green);

  ${(props) =>
    props.isPublishable
      ? css`
          background: #e0e0e0;
        `
      : ''};
`;

const CommitButton = styled(Button)`
  min-width: 15vh;
  margin-left: 1rem;
`;

const Wrapper = styled.div`
  margin-left: auto;
`;
