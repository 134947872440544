/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapValues } from 'lodash';
import 'prism-themes/themes/prism-vs.css';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  Diff,
  expandFromRawCode,
  findChangeByOldLineNumber,
  getChangeKey,
  Hunk,
  markEdits,
  parseDiff,
  tokenize,
  useSourceExpansion,
} from 'react-diff-view';
import 'react-diff-view/style/index.css';
import * as refractor from 'refractor';
import { diffLines, formatLines } from 'unidiff';

import { CommentWidget } from 'components/organisms/PullRequest/CommentWidget';
import ExpandButton from 'components/organisms/PullRequest/ExpandButtton';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_PULL_REQUESTS_ADD_COMMENT } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

const EMPTY_HUNKS = [];

export const PullRequestDiff = ({ oldText, newText, language, diffComment = {}, pullRequestId, file }) => {
  const [{ hunks }, setDiff] = useState('');
  const [comment, setComment] = useState(false);
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const sourceLines = useMemo(() => (oldText ? oldText.split('\n') : []), []);
  const linesCount = sourceLines.length;
  const postAddComment = useFetch(`${API_PULL_REQUESTS_ADD_COMMENT}`, 'POST');

  const hunksWithCommentLinesExpanded = useMemo(() => {
    if (!hunks) {
      return [];
    }

    const commentLines = Object.keys(diffComment).map((n) => parseLineKeyToOldLineNumber(hunks, n));

    return commentLines.reduce(
      (hunks, line) =>
        expandFromRawCode(hunks, sourceLines, line - 2 > 0 ? line - 2 : line, line + 3 < linesCount ? line + 3 : line),
      hunks,
    );
  }, [hunks, sourceLines, diffComment]);

  const [hunksWithSourceExpansion, expandCode] = useSourceExpansion(hunksWithCommentLinesExpanded, oldText);

  const tokens = useMemo(() => {
    const options = {
      refractor,
      highlight: true,
      language: language,
      enhancers: [markEdits(hunksWithSourceExpansion, { type: 'block' })],
    };
    return tokenize(hunksWithSourceExpansion, options);
  }, [hunksWithSourceExpansion]);

  const [widgets, { addWidget, afterSaveCallback }] = useWidgets(
    diffComment,
    comment !== false,
    setComment,
    file,
    pullRequestId,
  );

  const gutterEvents = useMemo(() => {
    return {
      onClick({ change }) {
        const key = getChangeKey(change);
        addWidget(key);
      },
    };
  }, [addWidget]);

  const renderHunk = useCallback(
    (output, currentHunk, index, hunks) => {
      const previousHunk = hunks[index - 1];
      const nextHunk = hunks[index + 1];
      const previousEnd = previousHunk ? previousHunk.oldStart + previousHunk.oldLines : 1;
      const currentStart = currentHunk.oldStart;
      const currentEnd = currentHunk.oldStart + currentHunk.oldLines;
      const nextStart = nextHunk ? nextHunk.oldStart : 1;
      const isHeadHunk = index === 0;
      const isTailHunk = index === hunks.length - 1;

      if ((isHeadHunk && currentStart > 1) || currentStart - previousEnd > 10) {
        output.push(
          <ExpandButton
            key={`up-${currentHunk.content}`}
            direction="up"
            start={currentHunk.oldStart}
            linesCount={linesCount}
            lines={currentHunk.oldLines}
            onExpand={expandCode}
          />,
        );
      }

      output.push(<Hunk key={currentHunk.content} hunk={currentHunk} gutterEvents={gutterEvents} />);

      if ((isTailHunk && currentEnd < linesCount) || currentEnd < nextStart - 1) {
        output.push(
          <ExpandButton
            key={`down-${currentHunk.content}`}
            direction="down"
            start={currentHunk.oldStart}
            lines={currentHunk.oldLines}
            linesCount={linesCount}
            onExpand={expandCode}
          />,
        );
      }

      return output;
    },
    [expandCode, gutterEvents, linesCount],
  );

  useEffect(() => {
    const diffText = formatLines(diffLines(oldText, newText), { context: 3 });
    const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });
    setDiff(diff);
  }, [oldText, newText, setDiff]);

  useEffect(() => {
    if (comment) {
      postAddComment.execute({
        pullRequestId: comment.pullRequestId,
        fileName: comment.fileName,
        lineCode: comment.lineCode,
        content: comment.content,
      });
    }
  }, [comment]);

  useEffect(() => {
    if (postAddComment.response) {
      setComment(false);
      afterSaveCallback({ ...postAddComment.response, key: postAddComment.response.lineCode });
    } else if (postAddComment.error) {
      setComment(false);
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: postAddComment.error.message,
        context: 'error',
      });
    }
  }, [postAddComment.response, postAddComment.error]);

  return (
    <div>
      <Diff
        viewType="split"
        diffType="modify"
        hunks={hunksWithSourceExpansion || EMPTY_HUNKS}
        tokens={tokens}
        widgets={widgets}
        renderGutter={renderGutter}
        renderToken={renderToken}
      >
        {(hunks) => hunks.reduce(renderHunk, [])}
      </Diff>
    </div>
  );
};

const parseLineKeyToOldLineNumber = (hunks, key) => {
  const type = key[0];
  const number = parseInt(key.slice(1), 10);

  if (type === 'I') {
    const oldChange = findChangeByOldLineNumber(hunks, number);
    return oldChange && oldChange.number ? oldChange.number : number;
  }

  return number;
};

const renderToken = (token, defaultRender, i) => {
  switch (token.type) {
    case 'space':
      return (
        <span key={i} className="space">
          {token.children && token.children.map((token, i) => renderToken(token, defaultRender, i))}
        </span>
      );
    default:
      return defaultRender(token, i);
  }
};

const renderGutter = ({ renderDefault, inHoverState }) =>
  inHoverState ? <FontAwesomeIcon icon={faPlus} /> : renderDefault();

const useWidgets = (diffComment, loading, setComment, file, pullRequestId) => {
  const [widgetsData, dispatch] = useReducer((state, action) => {
    const previous = state[action?.payload?.key] ?? {};

    switch (action.type) {
      case 'add': {
        Object.keys(state).forEach((keyName) => (state[keyName].addNew = false));

        if (state[action.payload.key]) {
          return {
            ...state,
            [action.payload.key]: {
              ...previous,
              addNew: true,
            },
          };
        }

        return {
          ...state,
          [action.payload.key]: {
            draft: '',
            addNew: true,
            comments: [],
          },
        };
      }
      case 'cancel':
        Object.keys(state).forEach((keyName) => (state[keyName].addNew = false));
        return {
          ...state,
        };
      case 'afterSaveCallback':
        previous.comments = previous.comments.map((el) => {
          if (el.replaceAfterSave) {
            return { ...action.payload };
          }

          return el;
        });

        return {
          ...state,
          [action.payload.key]: {
            ...previous,
            addNew: false,
            draft: '',
          },
        };
      case 'input':
        return {
          ...state,
          [action.payload.key]: {
            ...previous,
            draft: action.payload.content,
          },
        };
      case 'submit':
        setComment({
          pullRequestId: pullRequestId,
          fileName: file,
          lineCode: action.payload.key,
          content: previous.draft,
        });

        return {
          ...state,
          [action.payload.key]: {
            ...previous,
            comments: [
              ...previous.comments,
              {
                replaceAfterSave: true,
              },
            ],
          },
        };
      default:
        return state;
    }
  }, diffComment);

  const addWidget = useCallback((key) => dispatch({ type: 'add', payload: { key } }), []);

  const afterSaveCallback = useCallback((data) => dispatch({ type: 'afterSaveCallback', payload: { ...data } }), []);

  const remove = useCallback((key) => dispatch({ type: 'cancel', payload: { key } }), []);

  const writeComment = useCallback((key, content) => dispatch({ type: 'input', payload: { key, content } }), []);

  const submitComment = useCallback((key, content) => dispatch({ type: 'submit', payload: { key, content } }), []);

  const renderWidget = (data, key) => (
    <CommentWidget
      changeKey={key}
      {...data}
      loading={loading}
      onDraftChange={writeComment}
      onSubmit={submitComment}
      onDiscard={remove}
    />
  );

  return [mapValues(widgetsData, renderWidget), { addWidget, submitComment, afterSaveCallback }];
};
