import React, { useContext } from 'react';
import SelectComponent from 'react-select';

import { Label } from '.';

import { Group } from 'components/atoms/Form/Group';
import { composerCxt } from 'contexts/ComposerContext';

const selectStyles = {
  container: () => ({ width: '100%', position: 'relative', boxSizing: 'border-box' }),
  control: (provided) => ({
    ...provided,
    minHeight: '36px',
    borderColor: 'var(--gray)',
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '7px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided) => ({
    ...provided,
    appearance: 'none',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const Select = React.forwardRef(({ bottom, placeholder, ...props }, ref) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <Group mb={bottom || 2} style={{ width: '100%', zIndex: '100' }}>
      {placeholder && <Label isReadOnly={props.isDisabled?.toString()}>{placeholder}</Label>}
      <SelectComponent
        ref={ref}
        isSearchable
        {...props}
        placeholder=""
        styles={selectStyles}
        menuPortalTarget={document.body}
        noOptionsMessage={() => getLiteral('BrakWynikow')}
      />
    </Group>
  );
});
