/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react';

import { Container, Layout, Content } from 'components/atoms/Grid';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { LibraryList } from 'components/organisms/LibraryList';
import { ModuleGroupsList } from 'components/organisms/ModuleGroupsList';
import { TemplatePagesList } from 'components/organisms/TemplatePagesList';
import { composerCxt } from 'contexts/ComposerContext';
import { useHashTabs } from 'utils/useHashTabs';

const ConfigPage = () => {
  const { getLiteral } = useContext(composerCxt);

  const configTabs = useMemo(
    () => [
      {
        title: getLiteral('TypyKomponentow'),
        component: <ModuleGroupsList />,
      },
      {
        title: getLiteral('Podstrony'),
        component: <TemplatePagesList />,
      },
      {
        title: getLiteral('BibliotekiJavascript'),
        component: <LibraryList />,
      },
    ],
    [],
  );

  const { tabs, tabsPanel } = useHashTabs(configTabs);

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          <Header title={getLiteral('KonfiguracjaSzablonow')} tabs={tabs} />
          {tabsPanel}
        </Container>
      </Content>
    </Layout>
  );
};

export default ConfigPage;
