import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Label, Group } from './';

const StyledInput = styled.input`
  height: 36px;
  border: var(--border);
  border-radius: var(--border-radius);
  font-size: 1.4rem;
  padding: 0 1rem;
  width: 100%;

  ${({ error }) =>
    error
      ? css`
          border-color: red;
        `
      : ''}

  ${({ readOnly }) =>
    readOnly
      ? css`
          cursor: not-allowed;
          background-color: #f0f0f0;
          color: #00000088;
        `
      : ''}
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: red;
`;

export const Input = forwardRef(({ title, error, bottom, attrTitle = '', isLabelDetached = false, ...props }, ref) => (
  <Group mb={bottom || 2}>
    {title && (
      <Label detached={isLabelDetached} error={error} isReadOnly={props.readOnly?.toString()}>
        {title}
      </Label>
    )}
    <StyledInput ref={ref} {...props} title={attrTitle} error={error} />
    {error ? <Error>{error}</Error> : null}
  </Group>
));
