// Function wrapper for fetch
// fetch data and retuern json
// throw error if fetch didn't get/send data
const http = async (endpoint, method, body, isFormData = false) => {
  const res = await fetch(`${endpoint}`, {
    // const res = await fetch(`/proxy.php?action=${endpoint}`, {
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    body: body ? (isFormData ? body : JSON.stringify(body)) : null,
    method,
  });

  if (!res.ok) {
    throw new Error('Something goes wrong!');
  }

  try {
    const text = await res.text();
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    throw new Error('Server error, please contact with support');
  }
};

// Object contains http request methods as mockup
export const Fetch = {
  GET: (endpoint) => http(endpoint, 'GET', null),
  POST: (endpoint, body, isFormData) => http(endpoint, 'POST', body, isFormData),
};
