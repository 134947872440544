/* eslint-disable react-hooks/exhaustive-deps */
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import { Marginer } from './Grid';

import { Button } from 'components/atoms/Button';
import { Select } from 'components/atoms/Form/Select';
import { Heading } from 'components/atoms/Heading';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { API_PREVIEW } from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';

export const ModulePreview = ({ updateCall, componentDesigns }) => {
  const { getLiteral } = useContext(composerCxt);
  const user = useContext(userCtx);

  const shopIds = user.shops
    ? user.shops.map((el) => {
        return { value: el.id, label: el.name };
      })
    : [];

  const [shopId, setShopId] = useState(shopIds[0]);
  const [defaultLanguage, setDefaultLanguage] = useState(false);
  const [designLanguages, setDesignLanguages] = useState([]);
  const [componentDesignsSelect, setComponentDesignsSelect] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState([]);

  useEffect(() => {
    if (componentDesigns) {
      setComponentDesignsSelect(
        componentDesigns.map((el) => {
          return { ...makeSelectOption(el.id, `${el.name} v.${el.version}`), version: el.version };
        }),
      );

      const firstElement = componentDesigns[0];

      if (firstElement) {
        setSelectedDesign(firstElement);
        setDesignLanguages(
          firstElement.languages.map((lang) => {
            if (lang.id === user.language) {
              setDefaultLanguage(makeSelectOption(lang.id, lang.name));
            }

            return makeSelectOption(lang.id, lang.name);
          }),
        );
      }
    }
  }, [componentDesigns]);

  const handleFormConfirm = () => {
    window.open(
      `${user.domain}${API_PREVIEW}?designId=${selectedDesign.id}&designVersion=${selectedDesign.version}&shopId=${shopId.value}&lang=${defaultLanguage.value}`,
      '_blank',
    );

    if (updateCall) {
      updateCall();
    }
  };

  return (
    <>
      {componentDesigns.length ? (
        <Wrapper>
          <Marginer bottom={2}>
            <Heading size="small">{getLiteral('PodgladKomponentuWSklepie')}</Heading>
          </Marginer>
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            placeholder={getLiteral('Sklep')}
            onChange={(selected) => setShopId(selected)}
            value={shopId}
            options={shopIds}
            bottom={2}
          />
          <Select
            value={{ value: selectedDesign.id, label: selectedDesign.name, version: selectedDesign.version }}
            placeholder={getLiteral('SzablonDocelowy')}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={(selected) =>
              setSelectedDesign({ id: selected.value, version: selected.version, name: selected.label })
            }
            options={componentDesignsSelect}
            bottom={2}
          />
          <Select
            value={defaultLanguage}
            placeholder={getLiteral('Jezyk')}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={(selected) => setDefaultLanguage(selected)}
            options={designLanguages}
            bottom={2}
          />
          <PreviewButton onClick={handleFormConfirm} primary>
            <CommitIcon>
              <FontAwesomeIcon icon={faMagic} />
            </CommitIcon>
            {getLiteral('Podglad')}
          </PreviewButton>
        </Wrapper>
      ) : null}
    </>
  );
};

const PreviewButton = styled(Button)`
  background-color: #0488e7;
  width: 100%;
`;

const CommitIcon = styled.span`
  font-size: 2rem;
  margin-right: 1.5rem;
`;

const Wrapper = styled.div`
  margin-bottom: 10rem;
`;
