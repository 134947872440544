/* eslint-disable react-hooks/exhaustive-deps */
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CustomDialog from 'components/atoms/CustomDialog';
import { CustomDialogTitle } from 'components/atoms/CustomDialogTitle';

const OverflowFixStyles = css`
  .MuiDialog-paper,
  .MuiDialogContent-root {
    overflow: visible;
  }
`;

export const useDialog = ({ content, title, openCallback, styles = OverflowFixStyles }) => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (isOpened && openCallback) {
      openCallback();
    }
  }, [isOpened]);

  return {
    openDialog: () => {
      setIsOpened(true);
      document.body.classList.add('--modal-open');
    },
    closeDialog: () => {
      setIsOpened(false);
      document.body.classList.remove('--modal-open');
    },
    dialog: isOpened ? (
      <>
        <DialogStyled
          open={isOpened}
          onClose={() => setIsOpened(false)}
          aria-labelledby="form-dialog-title"
          styles={styles}
        >
          <CustomDialogTitle id="form-dialog-title" title={title} closeCallback={() => setIsOpened(false)} />
          <DialogContent>{content}</DialogContent>
          <DialogActions></DialogActions>
        </DialogStyled>
      </>
    ) : null,
  };
};

const DialogStyled = styled(CustomDialog)`
  ${({ styles }) => styles}
`;
