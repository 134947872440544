/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_PAGES_ADD } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const NewPageTypeForm = ({ onSuccessCallback }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const { register, handleSubmit } = useForm();

  const postNewPageType = useFetch(API_PAGES_ADD, 'POST');

  const onSubmit = (data) => {
    const reqBody = {
      ...data,
      descriptions: [
        {
          language: 'pol',
          description: data.description,
        },
      ],
    };

    postNewPageType.execute(reqBody, (res) => {
      if (onSuccessCallback) {
        onSuccessCallback(res);
      }
    });
  };

  useEffect(() => {
    if (postNewPageType.error) {
      createToast({
        delay: 5,
        context: 'error',
        message: postNewPageType.error.message,
        title: getLiteral('WystapilNieoczekiwanyProblem'),
      });
    }

    if (postNewPageType.response) {
      createToast({
        delay: 5,
        context: 'success',
        message: getLiteral('DodawaniePodstronyPowiodloSieIZostalesPrzekierowanyNaStroneJejEdycji'),
        title: getLiteral('PoprawnieDodanoPodstrone'),
      });
    }
  }, [postNewPageType.error, postNewPageType.response]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input ref={register} name="name" title={getLiteral('PodajNazweStrony')} />
      <Input ref={register} name="description" title={getLiteral('PodajOpisDlaStrony')} />

      <Button type="submit" color="blue">
        {getLiteral('Utworz')}
      </Button>
    </form>
  );
};
