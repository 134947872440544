import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';

import { Label, Group } from './';

const StyledTextarea = styled(TextareaAutosize)`
  min-height: 40px;
  border: var(--border);
  border-radius: var(--border-radius);
  font-size: 1.4rem;
  padding: 1rem;
  width: 100%;
  resize: vertical;

  ${({ error }) =>
    error
      ? css`
          border-color: red;
        `
      : ''}

  ${({ readOnly }) =>
    readOnly
      ? css`
          background-color: #f0f0f0;
          color: #00000088;
          resize: none;
        `
      : ''}
`;

const Error = styled.span`
  font-size: 1.2rem;
  color: red;
`;

export const Textarea = React.forwardRef(
  ({ title, placeholder, error, bottom, attrTitle = '', isLabelDetached = false, ...props }, ref) => (
    <Group mb={bottom || 2}>
      {title && (
        <Label detached={isLabelDetached} isReadOnly={props.readOnly?.toString()}>
          {title}
        </Label>
      )}
      <StyledTextarea ref={ref} {...props} title={attrTitle} placeholder={placeholder} error={error} />
      {error ? <Error>{error}</Error> : null}
    </Group>
  ),
);
