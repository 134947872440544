/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { TableCell } from '@material-ui/core';
import styled from 'styled-components';

import { composerCxt } from 'contexts/ComposerContext';
import { templateListItemCtx } from 'contexts/TemplateListItemContext';
import { Button, ButtonGroup } from 'components/atoms/Button';
import { IconPublish } from 'components/atoms/Icon';
import { PublishDialog } from 'components/atoms/PublishDialog';

export const TemplateRowItem = () => {
  const [openPublish, setOpenPublish] = useState(false);
  const { getLiteral } = useContext(composerCxt);
  const { canPublish, id, name, version, image, languages, getPublishedDesignInfo } = useContext(templateListItemCtx);

  return (
    <>
      <TableCell>
        <TitleCell>
          <SquareDiv>
            <Preview src={image} alt={id} effect="opacity" />
          </SquareDiv>
          <Title>
            <span>{name}</span>
            <span>
              {getLiteral('Wersja')}: {version}
            </span>
          </Title>
        </TitleCell>
      </TableCell>
      <TableCell>{getPublishedDesignInfo('shop_name')}</TableCell>
      <TableCell>{getPublishedDesignInfo('languages')}</TableCell>
      <TableCell align="center">
        <PublishDialog id={id} version={version} languages={languages} open={openPublish} setOpen={setOpenPublish} />
        <ButtonGroup>
          <Button disabled={!canPublish()} onClick={() => setOpenPublish(true)} title={getLiteral('Publikuj')}>
            <IconPublish color="text" />
          </Button>
        </ButtonGroup>
      </TableCell>
    </>
  );
};

const TitleCell = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  > span {
    margin: 0;
  }
`;

const Title = styled.div`
  span {
    padding-left: 10px;
    :last-child {
      color: var(--gray-dark);
      font-size: 1.2rem;
    }
  }
`;

const SquareDiv = styled.div`
  width: 100px;
  min-width: 100px;
  position: relative;
  overflow: hidden;
  :before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Preview = styled(LazyLoadImage)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;
