/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Col, Row } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { SortingComponent } from 'components/atoms/SortingComponent';
import { TemplateFileListItem } from 'components/atoms/TemplateFileListItem';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import {
  API_TEMPLATES_GET_FILES,
  API_TEMPLATES_REMOVE_FILE,
  API_TEMPLATES_REPLACE_FILE,
  API_GFX_ADD,
} from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { sortingFunction } from 'helpers/sortingFunctions';
import { useFetch } from 'utils/useFetch';
import { base64replace } from 'helpers/base64helper';

export const TemplateFiles = () => {
  const { id, version, editView } = useContext(templateCxt);
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);

  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [filters, setFilters] = useState({
    text: '',
    type: '',
    sort: 'date',
  });

  const getTemplateFiles = useFetch(`${API_TEMPLATES_GET_FILES}/id/${id}/version/${version}`, 'GET');
  const postTemplateFileRemove = useFetch(API_TEMPLATES_REMOVE_FILE, 'POST');
  const postTemplateFileReplace = useFetch(API_TEMPLATES_REPLACE_FILE, 'POST');
  const postClientFileAdd = useFetch(API_GFX_ADD, 'POST');

  // Getting files after component mount
  useEffect(() => {
    getTemplateFiles.execute();
  }, []);

  useEffect(() => {
    if (getTemplateFiles.response) {
      setFiles(getTemplateFiles.response);

      // Creating set of uniqe extension names
      const uniqeTypesList = new Set(getTemplateFiles.response.map((item) => item.name.split('.')[1]));

      // Building select option based on unique set
      // if someone of item in set is undefined, function change it into value "without extension"
      setTypes(
        [...uniqeTypesList].map((item) => makeSelectOption(item || 'none', item || getLiteral('BezRozszerzenia'))),
      );
    }
  }, [getTemplateFiles.response]);

  useEffect(() => {
    if (postTemplateFileRemove.response) {
      createToast({
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('PrzywroconoOryginalnyPlik'),
        context: 'success',
        delay: 4,
      });
    }
  }, [postTemplateFileRemove.response]);

  useEffect(() => {
    if (postTemplateFileReplace.response) {
      createToast({
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        context: 'success',
        message: getLiteral('PoprawniePodmienionoPlikNaWlasny'),
        delay: 4,
      });
    }
  }, [postTemplateFileReplace.response]);

  /**
   * Remove linking to the client's file
   * @param {string} name id of removing file
   */
  const removeFile = (name) => {
    postTemplateFileRemove.execute({ id, gfx: name }, () =>
      setFiles((state) =>
        state.map((file) => {
          if (file.name === name) {
            delete file.clientGfx;
          }

          return file;
        }),
      ),
    );
  };

  /**
   * Add new file into manager, and replace given file with the new one
   * @param {string} base64 value from file reader
   * @param {File} file file API object
   * @param {string} gfx name of replacing file
   */
  const addFileAndReplace = (base64, { name, type }, gfx) => {
    postClientFileAdd.execute(
      {
        gfx: name,
        gfxContent: base64replace(base64),
      },
      (response) => {
        postTemplateFileReplace.execute(
          {
            id,
            gfx,
            clientGfx: response.name,
          },
          (response) => {
            setFiles((state) =>
              state.map((file) => {
                if (file.name === gfx) {
                  file.clientGfx = {
                    ...response,
                  };
                }

                return file;
              }),
            );
          },
        );
      },
    );
  };

  /**
   * Add new file into backend and link new file into
   * @param {string} name id of replacing file
   * @param {string} clientGfx source of file, base64
   */
  const replaceFile = (gfx, clientGfx) => {
    postTemplateFileReplace.execute(
      {
        id,
        gfx,
        clientGfx,
      },
      (response) => {
        setFiles((state) =>
          state.map((file) => {
            if (file.name === gfx) {
              file.clientGfx = {
                ...response,
              };
            }

            return file;
          }),
        );
      },
    );
  };

  // Show loader if files are not downloaded
  if (getTemplateFiles.loading || !getTemplateFiles.response) {
    return <Loader />;
  }

  return (
    <>
      <CustomRow mb={2} align="center">
        <SortingComponent
          setTextFilter={(value) => setFilters((state) => ({ ...state, text: value }))}
          setTypeFilter={(value) => setFilters((state) => ({ ...state, type: value }))}
          setSortFilter={(value) => setFilters((state) => ({ ...state, sort: value }))}
          typeTitle={getLiteral('SortujPoTypie')}
          typeOptions={types}
        />
      </CustomRow>
      <Row>
        {files
          .filter(({ name }) => {
            if (!name.includes(filters.text)) return false;
            if (filters.type === 'none' && name.split('.').length === 1) return true;
            if (filters.type && !name.includes(`.${filters.type}`)) return false;

            return true;
          })
          .sort(sortingFunction(filters))
          .map((file) => (
            <Col size={3} key={file.name}>
              <TemplateFileListItem
                {...file}
                removeFile={removeFile}
                replaceFile={replaceFile}
                addFileAndReplace={addFileAndReplace}
                editable={editView}
              />
            </Col>
          ))}
      </Row>
    </>
  );
};

const CustomRow = styled.div`
  position: sticky;
  top: 81px;
  padding: 1.5rem 0 1rem;
  background-color: #fff;
  z-index: 19;
`;
