import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: none;
    padding: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: middle;
  }

  thead {
    th {
      border-bottom: 2px solid #ebecf0;
      color: #7a869a;
    }
  }

  tbody {
    tr {
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
    td {
      vertical-align: middle;
    }
  }
`;

export const StyledLink = styled(Link)`
  content: '';
  color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
`;

export const RowButton = styled(Button)`
  padding: 0;
  width: 40px;
  position: relative;
  z-index: 10;
  margin-right: 1rem;

  svg {
    margin: 0;
  }

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
    `}
`;
