/**
 * Return sroting function with one will sort elements by passed filters argument
 * @param {*} filters object including sort property
 * @returns sorting function
 */
export const sortingFunction = (filters) => {
  return (a, b) => {
    if (filters.sort === 'name') {
      const aName = a.name.trim().toLowerCase();
      const bName = b.name.trim().toLowerCase();

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    }
    if (filters.sort === 'date') return new Date(b.createTime) - new Date(a.createTime);
  };
};
