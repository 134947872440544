import React from 'react';
import { Redirect } from 'react-router';

import CompareComposition from 'components/atoms/CompareComposition';
import { Content, Layout } from 'components/atoms/Grid';
import { Aside } from 'components/organisms/Aside';
import { useAccess } from 'helpers/access';
import { EDP_DEFAULT } from 'helpers/endpoints';

const CompareCompositionPage = () => {
  const { condition } = useAccess('canCompareCompositions');

  if (!condition) {
    return <Redirect to={EDP_DEFAULT} />;
  }

  return (
    <Layout>
      <Aside></Aside>
      <Content>
        <CompareComposition />
      </Content>
    </Layout>
  );
};

export default CompareCompositionPage;
