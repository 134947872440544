/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { Input, Textarea } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { Col, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';
import { makeSelectOption } from 'helpers/makeSelectOption';

export const LibraryForm = ({ register, errors, clearErrors, control, readOnly }) => {
  const { getLiteral, libraries } = useContext(composerCxt);

  // Calculating new options every time a library list change
  const options = useMemo(() => {
    return libraries.map((item) => makeSelectOption(item.id, item.name));
  }, [libraries]);

  return (
    <Row>
      <input type="hidden" ref={register()} name="deleteDate" />
      <Col size={9}>
        <Input
          ref={register({
            required: true,
          })}
          name="name"
          type="text"
          title={getLiteral('NazwaBiblioteki')}
          error={errors.name && getLiteral('PoleObowiazkowe')}
          onChange={() => clearErrors('name')}
          readOnly={readOnly}
        />
      </Col>
      <Col size={3}>
        <Input
          ref={register({
            pattern: /[0-9]*/,
          })}
          name="priority"
          type="number"
          title={getLiteral('Priorytet')}
          readOnly={readOnly}
        />
      </Col>
      <Col size={12}>
        <Textarea ref={register} name="description" title={getLiteral('Opis')} readOnly={readOnly} />
      </Col>
      <Col size={12}>
        <Controller
          name="requiredLibraries"
          control={control}
          as={
            <Select
              options={options}
              isMulti
              isClearable
              isDisabled={readOnly}
              placeholder={getLiteral('WymaganeBiblioteki')}
            />
          }
        />
      </Col>
    </Row>
  );
};
