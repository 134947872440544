/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from 'react';
import AceEditor from 'react-ace';

import 'react-ace-builds/webpack-resolver-min';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-less';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/snippets/css';
import 'ace-builds/src-noconflict/snippets/less';
import 'ace-builds/src-noconflict/snippets/xml';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-static_highlight';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { API_DICTIONARY_GET_LITERALS } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import useOnScreen from 'utils/useOnScreen';

export const Editor = ({
  mode,
  onChange,
  value,
  readOnly,
  focus,
  getLiteralsDictionary,
  commands,
  refreshCommands = false,
}) => {
  const editor = useRef(null);
  const divRef = useRef();
  const isVisible = useOnScreen(divRef);
  const user = useContext(userCtx);
  const { getLiteral } = useContext(composerCxt);

  const getCompleterValues = useFetch(`${API_DICTIONARY_GET_LITERALS}${user.language}/type/all/`, 'GET');

  useEffect(() => {
    if (getLiteralsDictionary === true && mode === 'xml' && readOnly === false) {
      getCompleterValues.execute();
    }

    if (getLiteralsDictionary === false) {
      editor.current.editor.completers = editor.current.editor.completers.filter((el) => !!el.isCustom === false);
    }
  }, []);

  useEffect(() => {
    if (commands) {
      commands.forEach((command) => {
        editor.current.editor.commands.addCommand(command);
      });
    }
  }, [refreshCommands]);

  useEffect(() => {
    if (isVisible && editor) {
      editor.current.editor.resize();
    }
  }, [isVisible]);

  useEffect(() => {
    if (getCompleterValues.response) {
      var xmlCompleter = {
        getCompletions: function (editor, session, pos, prefix, callback) {
          var wordList = Object.entries(getCompleterValues.response);
          callback(
            null,
            wordList.map((word) => {
              return {
                caption: word[1].clientValue ?? word[1].originalValue,
                value: `<iai:variable vid="${word[0]}"/>`,
                meta: getLiteral('Tlumaczenia'),
              };
            }),
          );
        },
        isCustom: true,
      };

      editor.current.editor.completers.push(xmlCompleter);
    }
  }, [getCompleterValues.response, getCompleterValues.error]);

  return (
    <div ref={divRef}>
      <AceEditor
        ref={editor}
        focus={focus}
        mode={mode}
        theme="monokai"
        onChange={onChange}
        defaultValue={value}
        wrapEnabled={true}
        value={value}
        showPrintMargin={false}
        showGutter={true}
        readOnly={readOnly}
        style={{
          width: '100%',
          height: 'calc(100vh - 380px)',
          border: 'var(--border)',
          borderRadius: 'var(--border-radius)',
        }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </div>
  );
};
