import { forwardRef } from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Group } from 'components/atoms/Form/Group';

const icon = <FontAwesomeIcon icon={faCheck} />;

export const RadioButton = forwardRef(({ title, message, readOnly, bottom, ...props }, ref) => {
  return (
    <Group mb={bottom || '0'}>
      <RadioLabel readOnly={readOnly} icon={icon}>
        <input type="radio" ref={ref} disabled={readOnly} {...props} />
        <span>
          <div id="service_content">
            <h1>{title}</h1>
            <p>{message}</p>
          </div>
        </span>
      </RadioLabel>
    </Group>
  );
});

const RadioLabel = styled.label`
  #service_content {
    text-align: center;
    width: 110px;
    height: 110px;
    padding: 1rem;
  }

  h1 {
    font-weight: 800;
  }

  p {
    padding: 5rem 0rem 2rem 0rem;
    font-size: 20px;
  }

  display: block;
  margin-right: 2rem;

  input[type='radio'] {
    display: none;
    &:not(:disabled) ~ span {
      cursor: pointer;
    }
    &:disabled ~ span {
      color: hsla(150, 5%, 75%, 1);
      border-color: hsla(150, 5%, 75%, 1);
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  input[type='radio']:checked + span {
    background: hsla(150, 75%, 50%, 1);
    color: hsla(215, 0%, 100%, 1);
    box-shadow: 0px 0px 20px hsla(150, 100%, 50%, 0.75);
    &::after {
      color: hsla(215, 5%, 25%, 1);
      border: 2px solid #0fb108;
      font-size: 24px;
      font-family: 'Font Awesome 5 Free';
      content: '\\2714';
      font-weight: 900;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      background: white;
      box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
    }
  }

  > span {
    height: 100%;
    display: block;
    background: white;
    border: 2px solid hsla(150, 75%, 50%, 1);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
    position: relative;
  }
`;
