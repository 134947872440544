/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Loader } from 'components/atoms/Loader';
import { API_TXT } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const textsCtx = createContext();

export const TextCtxProvider = ({ children }) => {
  const getTxt = useFetch(API_TXT, 'GET');

  useEffect(() => {
    getTxt.execute();
  }, []);

  // If translation with a given key doesn't exist, will return this key as string
  const getLiteralData = (key, showWarning) => {
    return getTxt.response[key] ? getTxt.response[key] : <DangerLabel showWarning={showWarning}>{key}</DangerLabel>;
  };

  return <textsCtx.Provider value={{ getLiteralData }}>{!getTxt.response ? <Loader /> : children}</textsCtx.Provider>;
};

const DangerLabel = styled.span`
  ${({ showWarning }) =>
    showWarning
      ? css`
          display: inline-block;
          background-color: #a94442;
          padding: 3px 8px;
          font-weight: bold;
          color: #fff;
        `
      : ''}
`;
