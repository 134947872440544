const formatYmd = (date) => date.toISOString().slice(0, 10);

export const getTodayDate = () => {
  const today = new Date();
  return formatYmd(today);
};

export const getWeekAgoDate = () => {
  const today = new Date();
  const weekAgo = new Date();
  weekAgo.setDate(today.getDate() - 7);

  return formatYmd(weekAgo);
};
