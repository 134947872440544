import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { hashToName } from 'helpers/hashToName';

/**
 * Create tabs and return prepared structures
 * @param {*} tabs array of tabs objects
 * @returns object with components tabs and panel
 */
export const useHashTabs = (tabs) => {
  const { search } = useLocation();

  return {
    tabs: (
      <TabsWrapper>
        {tabs.map(({ title, titleSuffix }, index) => {
          let hashName = hashToName(title);

          return (
            <Tab key={hashName} to={`${search}${hashName}`} index={index} name={hashName}>
              {title + (titleSuffix ? titleSuffix : '')}
            </Tab>
          );
        })}
      </TabsWrapper>
    ),
    tabsPanel: tabs.map(({ component, title }, index) => {
      const hashName = hashToName(title);
      return (
        <TabPanel key={hashName} name={hashName} index={index}>
          {component}
        </TabPanel>
      );
    }),
  };
};

const Tab = ({ children, name, index, ...props }) => {
  const { hash } = useLocation();

  return (
    <TabStyled {...props} active={(hash === name || (!hash && index === 0)).toString()}>
      {children}
    </TabStyled>
  );
};

const TabPanel = ({ children, name, index, ...props }) => {
  const { hash } = useLocation();

  return (
    <div {...props} style={{ display: name === hash || (!hash && index === 0) ? 'block' : 'none' }}>
      {children}
    </div>
  );
};

const TabsWrapper = styled.div`
  display: flex;
`;

const TabStyled = styled(Link)`
  background: none;
  font-size: 1.6rem;
  line-height: 2.1rem;
  padding: 0 0 1rem 0;
  color: var(--gray-dark);
  position: relative;
  margin: 0 2rem 0 0;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: var(--tabUnderlineColor);
    width: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    transition: width 0.2s ease-in-out;
    margin: auto;
  }

  ${({ active }) =>
    active === 'true' &&
    css`
      color: var(--text);

      &:after {
        width: 100%;
        transition: width 0.2s;
      }
    `}
`;
