import { Switch } from 'react-router-dom';

import { AuthRoute } from 'components/AuthRoute';
import CompareCompositionPage from 'components/pages/CompareCompositionPage';
import ConfigPage from 'components/pages/ConfigPages';
import DefaultPage from 'components/pages/DefaultPage';
import DictionaryPage from 'components/pages/DictionaryPage';
import FileManagerPage from 'components/pages/FileManagerPage';
import IntallationsPage from 'components/pages/IntallationsPage';
import LibraryPage from 'components/pages/LibraryPage';
import ModulePage from 'components/pages/ModulePage';
import QueuePage from 'components/pages/QueuePage';
import PullRequestsPage from 'components/pages/PullRequestsPage';
import { ComposerCxtProvider } from 'contexts/ComposerContext';
import { ToastCtxProvider } from 'contexts/ToastContext';
import { UserCtxProvider } from 'contexts/UserContext';
import { MarketingCtxProvider } from 'contexts/MarketingContext';
import TemplatePage from 'components/pages/TemplatePage';
import ModuleTypePage from 'components/pages/ModuleTypePage';
import ModulesListPage from 'components/pages/ModulesListPage';
import RemovedElementsPage from 'components/pages/RemovedElementsPage';
import PageTypePage from 'components/pages/PageTypePage';
import EventsLogPage from 'components/pages/EventsLogPage';
import StandardCompositionsPage from 'components/pages/StandardCompositionsPage';
import MigrationPage from 'components/pages/MigrationPage';
import TranslationPage from 'components/pages/TranslationPage';
import ArticlePage from 'components/pages/ArticlePage';
import {
  EDP_CONFIG,
  EDP_DEFAULT,
  EDP_DICTIONARY,
  EDP_EVENTS_LOG,
  EDP_FILES,
  EDP_GROUPS_ID,
  EDP_LIBRARIES_ID,
  EDP_MIGRATION,
  EDP_MODULES,
  EDP_MODULES_ID,
  EDP_PAGES_ID,
  EDP_PULL_INSTALLATIONS,
  EDP_PULL_REQUESTS,
  EDP_QUEUE,
  EDP_TRANSLATION,
  EDP_MARKETING_ID,
  EDP_REMOVED_ELEMENTS,
  EDP_TEMPLATES_COMPARE,
  EDP_TEMPLATES_ID,
  EDP_TEMPLATES_STANDARDS,
} from 'helpers/endpoints';

function App() {
  return (
    <UserCtxProvider>
      <ToastCtxProvider>
        <ComposerCxtProvider>
          <MarketingCtxProvider>
            <Switch>
              <AuthRoute exact path={EDP_DEFAULT} component={DefaultPage} />
              <AuthRoute
                exact
                path={EDP_TEMPLATES_STANDARDS}
                component={StandardCompositionsPage}
                access="canEditStandardDesigns"
              />

              <AuthRoute path={EDP_TEMPLATES_ID} component={TemplatePage} />

              <AuthRoute exact path={EDP_MODULES} component={ModulesListPage} />
              <AuthRoute exact path={EDP_MODULES_ID} component={ModulePage} />
              <AuthRoute
                path={EDP_TEMPLATES_COMPARE}
                component={CompareCompositionPage}
                access="canCompareCompositions"
              />
              <AuthRoute path={EDP_FILES} component={FileManagerPage} />
              <AuthRoute exact path={EDP_DICTIONARY} component={DictionaryPage} />
              <AuthRoute exact path={EDP_LIBRARIES_ID} component={LibraryPage} access="canAccessLibraries" />
              <AuthRoute exact path={EDP_CONFIG} component={ConfigPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_GROUPS_ID} component={ModuleTypePage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_PAGES_ID} component={PageTypePage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_QUEUE} component={QueuePage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_REMOVED_ELEMENTS} component={RemovedElementsPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_EVENTS_LOG} component={EventsLogPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_MIGRATION} component={MigrationPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_PULL_REQUESTS} component={PullRequestsPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_PULL_INSTALLATIONS} component={IntallationsPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_TRANSLATION} component={TranslationPage} access="canAccessConfigs" />
              <AuthRoute exact path={EDP_MARKETING_ID} component={ArticlePage} />
            </Switch>
          </MarketingCtxProvider>
        </ComposerCxtProvider>
      </ToastCtxProvider>
    </UserCtxProvider>
  );
}

export default App;
