import { useLocation } from 'react-router';

export const useQueryObject = () => {
  const { search } = useLocation();

  if (!search || search.length <= 1) {
    return null;
  }

  return JSON.parse('{"' + search.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) => {
    return key === '' ? value : decodeURIComponent(value);
  });
};
