/* eslint-disable react-hooks/exhaustive-deps */
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const Pagination = ({ pages, current, changePage, setPage, pageInput }) => {
  return (
    <PaginationWrapper>
      <PaginationButton type="button" onClick={() => setPage(current - 1)} isDisabled={(current === 1).toString()}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </PaginationButton>
      <PaginationDetails>
        <input value={pageInput} type="number" onChange={({ target }) => changePage(target.value)} />
        <span>{pages}</span>
      </PaginationDetails>
      <PaginationButton type="button" onClick={() => setPage(current + 1)} isDisabled={(current === pages).toString()}>
        <FontAwesomeIcon icon={faAngleRight} />
      </PaginationButton>
    </PaginationWrapper>
  );
};

export const usePagination = ({ pageLimit }) => {
  const [page, setPage] = useState(1);
  const [pageInput, setPageInput] = useState(1);
  const [pageMax, setPageMax] = useState(pageLimit);

  const debounce = useRef(null);

  const setValue = (value) => {
    if (isNaN(value)) {
      return 1;
    }

    const numericValue = parseInt(value, 10);
    if (numericValue <= 0) {
      return 1;
    }
    if (numericValue > pageMax) {
      return pageMax;
    }

    return numericValue;
  };

  const onChange = (value) => {
    setPageInput(setValue(value));
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setPage(setValue(value));
    }, 300);
  };

  useEffect(() => setPageMax(pageLimit), [pageLimit]);

  return {
    pageMax,
    page,
    paginationProps: {
      pages: pageMax,
      current: page,
      pageInput,
      changePage: onChange,
      setPage: (value) => {
        setPage(setValue(value));
        setPageInput(setValue(value));
      },
    },
    resetPage: () => {
      setPage(1);
      setPageInput(1);
    },
    Pagination,
  };
};

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationButton = styled.button`
  background-color: #e0e0e0;
  border: none;
  font-size: 2rem;
  border-radius: 100%;
  width: 45px;
  margin: 0 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;

  ${({ isDisabled }) =>
    isDisabled === 'false'
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
          opacity: 0.5;
        `}
`;

const PaginationDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 45px;
    height: 45px;
    border: 2px solid #e0e0e0;
    background-color: #fff;
    appearance: none;
    -moz-appearance: textfield;
    text-align: center;
  }

  span {
    width: 45px;
    height: 45px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
