/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_MODULES_GET_TOKEN_FOR_COMPONENT } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const ModuleShareForm = ({ id }) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const { register, handleSubmit, errors } = useForm();

  const postShareComponent = useFetch(API_MODULES_GET_TOKEN_FOR_COMPONENT, 'POST');

  const onSubmitHandle = (data) => {
    postShareComponent.execute({ ...data, id });
  };

  useEffect(() => {
    if (postShareComponent.response) {
      createToast({
        title: getLiteral('KomponentZostalUdostepniony'),
        message: getLiteral('LinkDoUdostepnieniaZostalSkopiowanyDoSchowka'),
        context: 'success',
      });
    }
  }, [postShareComponent.response]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandle)}>
      <Input
        ref={register({ required: true })}
        name="password"
        type="password"
        autocomplete="off"
        title={getLiteral('WpiszHasloZabezpieczajace')}
        error={errors.password && getLiteral('PoleObowiazkowe')}
      />
      {postShareComponent.response && (
        <Input
          readOnly
          style={{ cursor: 'pointer', textOverflow: 'ellipsis' }}
          title={getLiteral('KluczDostepuDoKomponentuKliknijAbySkopiowac')}
          value={`${window.location.href.split('?')[0]}?token=${postShareComponent.response.token}`}
          onClick={(e) => {
            navigator.clipboard.writeText(e.target.value);
            createToast({
              title: getLiteral('SkopiowanoDoSchowka'),
              message: getLiteral('UzyjOpcjiWklejaniaAbySkorzystacZZawartosci'),
              context: 'info',
              delay: 3,
            });
          }}
        />
      )}
      <Button type="submit" loading={postShareComponent.loading.toString()}>
        {getLiteral('UdostepnijKomponent')}
      </Button>
    </form>
  );
};
