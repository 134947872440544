import { useContext } from 'react';
import styled, { css } from 'styled-components';

import { Tag } from './Tag';

import { composerCxt } from 'contexts/ComposerContext';

export const TypeLabel = ({ type, text, title }) => {
  const { getLiteral } = useContext(composerCxt);

  if (!text) {
    text = type === 'custom' ? getLiteral('wlasny') : type;
  }

  return (
    <Label type={type} title={title}>
      {text}
    </Label>
  );
};

export const Label = styled(Tag)`
  border-radius: 3px;
  margin-bottom: 3px;

  ${({ type }) => {
    switch (type) {
      case 'standard':
        return css`
          background-color: var(--standardColor);
        `;
      case 'beta':
        return css`
          background-color: #e2ab04;
        `;
      case 'custom':
        return css`
          background-color: #7b50c1;
        `;
      case 'draft':
        return css`
          background-color: var(--draftColor);
        `;
      case 'hasNeverVersion':
        return css`
          background-color: #e2823b;
        `;
      case 'deprecated':
        return css`
          background-color: var(--red);
        `;
      default:
        return css`
          background-color: #999;
        `;
    }
  }}
`;
