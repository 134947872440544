/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { Tag } from 'components/atoms/Tag';
import { Marginer } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/atoms/Table';
import { composerCxt } from 'contexts/ComposerContext';
import { API_MODULES_SEARCH, EDP_MODULES } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const ModuleTypeUsedIn = ({ id }) => {
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const { getLiteral, getTagIcon, getTagLabel } = useContext(composerCxt);
  const [pagesCount, setPagesCount] = useState(0);

  const postSearchModulesById = useFetch(API_MODULES_SEARCH, 'POST');

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  useEffect(() => {
    postSearchModulesById.execute(
      {
        page: page,
        sort: 'create-date',
        order: 'desc',
        group: id,
      },
      (data) => setPagesCount(data.pagesCount),
    );
  }, [id]);

  return (
    <>
      {postSearchModulesById.loading && <Loader relative />}
      <Marginer bottom={2}>
        <Table>
          <thead>
            <tr>
              <th>{getLiteral('nazwa')}</th>
              <th>{getLiteral('Wersja')}</th>
              <th>{getLiteral('Grupa')}</th>
              <th>{getLiteral('Typ')}</th>
              <th>{getLiteral('Zaaktulizowano')}</th>
              <th>{getLiteral('Autor')}</th>
            </tr>
          </thead>
          <tbody>
            {postSearchModulesById.response &&
              postSearchModulesById.response.models.map((item) => (
                <tr
                  key={`${item.id}_${item.version}`}
                  onClick={() =>
                    history.push(
                      `${EDP_MODULES}${item.id}/version/${item.version}?link=${encodeURIComponent(pathname + hash)}`,
                    )
                  }
                >
                  <td style={{ maxWidth: 300 }}>{item.name}</td>
                  <td>{item.version}</td>
                  <td>{item.group}</td>
                  <td>
                    <Tags>
                      {item.badges?.map((badge) => (
                        <Tag type={badge.type} title={badge.title} key={`${badge.type}_${item.id}`}>
                          {badge.icon ? getTagIcon(badge.icon) : ''}
                          {getTagLabel(badge.type, badge.label)}
                        </Tag>
                      ))}
                    </Tags>
                  </td>
                  <td>{item.lastModificationDate}</td>
                  <td>{item.createUser}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Marginer>
      {pagesCount && <Pagination {...paginationProps} />}
      {!postSearchModulesById.response && !postSearchModulesById.executed && (
        <NotAssigned>{getLiteral('BibliotekaNieJestPrzypisanaDoZadnegoKomponentu')}</NotAssigned>
      )}
    </>
  );
};

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const NotAssigned = styled.div`
  font-weight: 700;
  text-align: center;
  margin-top: 3rem;
`;
