import { useContext } from 'react';

import { Input, Textarea } from 'components/atoms/Form';
import { Col, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';

export const ModuleTypeForm = ({ register, errors, clearErrors, readOnly }) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <Row>
      <input {...register('siteId')} type="hidden" />
      <Col size={7}>
        <Input ref={register} name="id" type="text" title={getLiteral('Identyfikator')} readOnly />
      </Col>
      <Col size={7}>
        <Input
          ref={register({
            required: true,
          })}
          name="name"
          type="text"
          title={getLiteral('Nazwa')}
          error={errors.name && getLiteral('PoleObowiazkowe')}
          onChange={() => clearErrors('name')}
          readOnly={readOnly}
        />
      </Col>
      <Col size={7}>
        <Textarea
          ref={register({
            required: true,
          })}
          name="description"
          title={getLiteral('Opis')}
          error={errors.description && getLiteral('PoleObowiazkowe')}
          onChange={() => clearErrors('description')}
          readOnly={readOnly}
        />
      </Col>
    </Row>
  );
};
