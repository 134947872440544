/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { sprintf } from 'sprintf-js';
import styled, { css } from 'styled-components';

import { Select } from 'components/atoms/Form/Select';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import {
  API_MODULES_ADD_MODULE_IN_WRAPPER,
  API_MODULES_MOVE_MODULE,
  API_MODULES_REPLACE_WRAPPER_MODULE,
  API_TEMPLATES,
  API_TEMPLATES_ADD_MODULE,
  API_TEMPLATES_DELETE_MODULE,
  API_MODULES_DELETE_MODULE_IN_WRAPPER,
  API_TEMPLATES_MOVE_MODULE,
  API_TEMPLATES_REPLACE_MODULE,
} from 'helpers/endpoints';
import { useAlert } from 'utils/useAlert';
import { useFetch } from 'utils/useFetch';

export const pageCxt = createContext();

const PageCxtProvider = ({ children }) => {
  const template = useContext(templateCxt);
  const { createToast } = useContext(toastCtx);

  const pageTypeCacheKey = `pageType_${template.parentId || template.id}`;
  const lastPageType = JSON.parse(localStorage.getItem(pageTypeCacheKey));

  const { pages } = useContext(composerCxt);
  const [pageType, setPageType] = useState(lastPageType || pages.find((x) => x.value === 'projector'));
  const [page, setPage] = useState([]);
  const [replaceData, setReplaceData] = useState(null);
  const [replaceDataWrapperModule, setReplaceDataWrapperModule] = useState(null);
  const [addingDataWrapperModule, setAddingDataWrapperModule] = useState({});
  const [deleteDataWrapperModule, setDeleteDataWrapperModule] = useState({});

  const postPageModule = useFetch(API_TEMPLATES_ADD_MODULE, 'POST');
  const postWrapperModule = useFetch(API_MODULES_ADD_MODULE_IN_WRAPPER, 'POST');
  const postPageMoveModule = useFetch(API_TEMPLATES_MOVE_MODULE, 'POST');
  const postWrapperMoveModule = useFetch(API_MODULES_MOVE_MODULE, 'POST');
  const postTemplateDeleteModule = useFetch(API_TEMPLATES_DELETE_MODULE, 'POST');
  const postTemplateDeleteWrapperModule = useFetch(API_MODULES_DELETE_MODULE_IN_WRAPPER, 'POST');
  const postModuleReplace = useFetch(API_TEMPLATES_REPLACE_MODULE, 'POST');
  const postWrapperModuleReplace = useFetch(API_MODULES_REPLACE_WRAPPER_MODULE, 'POST');
  const getPageModules = useFetch(
    `${API_TEMPLATES}/get-page/compositionId/${template.id}/compositionVersion/${template.version}/page/${
      pageType ? pageType.value : ''
    }`,
    'GET',
  );

  useEffect(() => {
    localStorage.setItem(pageTypeCacheKey, JSON.stringify(pageType));
  }, [pageType, template.id, template.parentId]);

  const { getLiteral } = useContext(composerCxt);

  const replaceModuleOnPage = ({ oldModule, newModule }) => {
    setReplaceData(oldModule);

    postModuleReplace.execute({
      oldComponentId: oldModule.id,
      oldComponentVersion: oldModule.version,
      newComponentId: newModule.id,
      designId: template.id,
      designVersion: template.version,
    });
  };

  const replaceModuleWrapperItemOnPage = ({ oldModule, newModule, pageName, wrapperId, wrapperVersion }) => {
    setReplaceDataWrapperModule({
      pageName,
      oldModule,
    });

    postWrapperModuleReplace.execute({
      oldComponentId: oldModule.id,
      oldComponentVersion: oldModule.version,
      newComponentId: newModule.id,
      wrapperId,
      wrapperVersion: wrapperVersion,
      designId: template.id,
      designVersion: template.version,
    });
  };

  // Remove module from list
  const removeModule = (id, version, position, section, wrapperId = null, wrapperVersion = null) => {
    setDeleteDataWrapperModule({
      moduleId: id,
      section,
      wrapperId,
    });

    if (wrapperId === null) {
      postTemplateDeleteModule.execute({
        compositionId: template.id,
        container: section,
        moduleId: id,
        moduleVersion: version,
        modulePosition: position,
      });
    } else {
      postTemplateDeleteWrapperModule.execute({
        wrapperId,
        wrapperVersion,
        moduleId: id,
        moduleVersion: version,
        modulePosition: position,
        container: section,
        designId: template.id,
        designVersion: template.version,
      });
    }
  };

  // Executed execute endpoint call after the module deleting is completed
  useEffect(() => {
    if (postTemplateDeleteModule.error || postTemplateDeleteWrapperModule.error) {
      createToast({
        title: getLiteral('WystapilBladPodczasUsuwaniaKomponentu'),
        message: postTemplateDeleteModule.error.message ?? postTemplateDeleteWrapperModule.error.message,
        context: 'error',
      });
      return;
    }

    if (postTemplateDeleteModule.response) {
      createToast({
        title: sprintf(getLiteral('KomponentXZostalUsuniety'), deleteDataWrapperModule.moduleId),
        message: `${getLiteral('PomyslnieUsunietoKomponentZKompozycji')}!`,
        context: 'success',
        delay: 2,
      });

      setPage((state) => state.filter((module) => module.id !== deleteDataWrapperModule.moduleId));
    }

    if (postTemplateDeleteWrapperModule.response) {
      createToast({
        title: sprintf(getLiteral('KomponentXZostalUsuniety'), deleteDataWrapperModule.moduleId),
        message: `${getLiteral('PomyslnieUsunietoKomponentZKompozycji')}!`,
        context: 'success',
        delay: 2,
      });

      setPage((state) =>
        state.filter((module) => {
          if (module.id === deleteDataWrapperModule.wrapperId) {
            module.components = module.components.filter(
              (component) => component.id !== deleteDataWrapperModule.moduleId,
            );
          }

          return module;
        }),
      );
    }
  }, [
    postTemplateDeleteModule.response,
    postTemplateDeleteModule.error,
    postTemplateDeleteWrapperModule.response,
    postTemplateDeleteWrapperModule.error,
  ]);

  const removeAlert = useAlert({
    onAccept: (params) => removeModule(...params),
    text: getLiteral('TaOperacjaUsunieKomponentZKompozycji'),
  });

  const addModuleToPage = ({ module, container }) => {
    postPageModule.execute({ moduleId: module, container, compositionId: template.id });
  };

  const addModuleToWrapperOnPage = ({ module, container, wrapperId }) => {
    setAddingDataWrapperModule({
      pageName: container,
      wrapperId,
    });

    postWrapperModule.execute({
      wrapperId,
      moduleId: module,
      container,
      designId: template.id,
      designVersion: template.version,
    });
  };

  useEffect(() => {
    getPageModules.execute();
    // Get page components if compositions change
  }, [template.id, template.version, pageType, template.refreshPage]);

  useEffect(() => {
    if (getPageModules.response) {
      setPage(getPageModules.response.page || []);
    }
  }, [getPageModules.response]);

  // Executed after the module adding is completed
  useEffect(() => {
    if (postPageModule.error) {
      createToast({
        title: getLiteral('NieMoznaDodacKomponentu'),
        message: postPageModule.error.message,
        context: 'error',
      });
    }
    if (postPageModule.response) {
      setPage((state) => [...state, postPageModule.response]);
    }
  }, [postPageModule.response, postPageModule.error]);

  // Executed after the module adding to Wrapper is completed
  useEffect(() => {
    if (postWrapperModule.error) {
      createToast({
        title: getLiteral('NieMoznaDodacKomponentu'),
        message: postWrapperModule.error.message,
        context: 'error',
      });
    }
    if (postWrapperModule.response) {
      setPage((state) =>
        state.map((module) => {
          if (module.id === addingDataWrapperModule.wrapperId) {
            return {
              ...module,
              components: [...module.components, postWrapperModule.response],
            };
          }

          return module;
        }),
      );
    }
  }, [postWrapperModule.response, postWrapperModule.error]);

  // Effect for module replacing
  useEffect(() => {
    if (postModuleReplace.error) {
      createToast({
        context: 'error',
        title: getLiteral('WystapilBladPodczasUsuwaniaKomponentu'),
        message: postModuleReplace.error.message,
      });
      return;
    }

    if (postModuleReplace.response) {
      createToast({
        context: 'success',
        title: `${getLiteral('KomponentWymieniony')}!`,
        message: `${getLiteral('PomyslnieWymienionoKomponent')}!`,
        delay: 5,
      });

      setPage((state) =>
        state.map((module) => {
          if (module.id === replaceData.id) {
            return {
              ...postModuleReplace.response,
              hidden: replaceData.hidden,
            };
          }

          return module;
        }),
      );
      setReplaceData(null);
    }
  }, [postModuleReplace.response, postModuleReplace.error]);

  // Effect for module replacing in Wrapper
  useEffect(() => {
    if (postWrapperModuleReplace.error) {
      createToast({
        context: 'error',
        title: getLiteral('WystapilBladPodczasUsuwaniaKomponentu'),
        message: postWrapperModuleReplace.error.message,
      });
      return;
    }

    if (postWrapperModuleReplace.response) {
      createToast({
        context: 'success',
        title: `${getLiteral('KomponentWymieniony')}!`,
        message: `${getLiteral('PomyslnieWymienionoKomponent')}!`,
        delay: 5,
      });

      setPage((state) =>
        state.map((module) => {
          if (module.id === replaceDataWrapperModule.oldModule.wrapperId) {
            const updatedWrapperComponents = module.components.map((component) => {
              if (component.id === replaceDataWrapperModule.oldModule.id) {
                return {
                  ...postWrapperModuleReplace.response,
                  replaceable: true,
                  hidden: replaceDataWrapperModule.hidden,
                  lastUpdateTime: postWrapperModuleReplace.response.lastModificationDate,
                };
              }

              return component;
            });

            module.components = updatedWrapperComponents;
          }

          return module;
        }),
      );
    }
  }, [postWrapperModuleReplace.response, postWrapperModuleReplace.error]);

  // function executed every drop event was called
  // Reorder lists and move elements from source to destiny list
  const onDragEnd = useCallback(
    (result) => {
      const { source, destination, type } = result;

      if (!destination) {
        return;
      }

      let removed = {};
      let srcDroppableWrapperId;

      if (type === 'component') {
        const srcArray = [...page];

        // Get element from source array, and remove from it
        [removed] = srcArray.splice(source.index, 1);

        // put element to the destination array
        srcArray.splice(destination.index, 0, removed);

        srcArray[source.index].changed = true;
        srcArray[destination.index].changed = true;

        // Update provider state
        setPage(srcArray);
      } else {
        const srcArray = [...page];
        [, srcDroppableWrapperId] = source.droppableId.split('#');

        let srcDroppableWrapperIndex = Object.keys(page).filter(function (key) {
          return page[key]['id'] === srcDroppableWrapperId;
        })[0];

        const srcWrapperChildrenArray = [...page][srcDroppableWrapperIndex]['components'];

        // Get element from source array, and remove from it
        [removed] = srcWrapperChildrenArray.splice(source.index, 1);

        // put element to the destination array
        srcWrapperChildrenArray.splice(destination.index, 0, removed);

        srcWrapperChildrenArray[source.index].changed = true;
        srcWrapperChildrenArray[destination.index].changed = true;

        srcArray[srcDroppableWrapperIndex]['components'] = srcWrapperChildrenArray;

        // Update provider state
        setPage(srcArray);
      }

      // Call endpoint
      if (type === 'wrapperChildren') {
        postWrapperMoveModule.execute({
          wrapperId: srcDroppableWrapperId,
          moduleId: removed.id,
          oldPosition: source.index + 1, //positions have indexes from 1
          newPosition: destination.index + 1,
          designId: template.id,
          designVersion: template.version,
        });
      } else {
        postPageMoveModule.execute({
          compositionId: template.id,
          moduleId: removed.id,
          oldPosition: source.index + 1, //positions have indexes from 1
          newPosition: destination.index + 1,
          oldContainer: source.droppableId,
          newContainer: destination.droppableId,
        });
      }
    },
    [page],
  );

  return (
    <pageCxt.Provider
      value={{
        page,
        pageType,
        removeModule: removeAlert.openAlert,
        addModuleToPage,
        replaceModuleOnPage,
        getPageModules,
        replaceModuleWrapperItemOnPage,
        addModuleToWrapperOnPage,
      }}
    >
      <Heading>{getLiteral('EdytowanaStrona')}:</Heading>
      <Marginer bottom={2}>
        <Row>
          <Col size={3}>
            <SelectPage
              current={pageType && (pageType.value === 'projector').toString()}
              onClick={() => setPageType(pages.find((x) => x.value === 'projector'))}
            >
              {getLiteral('KartaProduktu')}
              {pageType && pageType.value === 'projector' && <FontAwesomeIcon icon={faCheck} />}
            </SelectPage>
          </Col>
          <Col size={3}>
            <SelectPage
              current={pageType && (pageType.value === 'main').toString()}
              onClick={() => setPageType(pages.find((x) => x.value === 'main'))}
            >
              {getLiteral('StronaGlowna')}
              {pageType && pageType.value === 'main' && <FontAwesomeIcon icon={faCheck} />}
            </SelectPage>
          </Col>
          <Col size={3}>
            <SelectPage
              current={pageType && (pageType.value === 'search').toString()}
              onClick={() => setPageType(pages.find((x) => x.value === 'search'))}
            >
              {getLiteral('ListaProduktow')}
              {pageType && pageType.value === 'search' && <FontAwesomeIcon icon={faCheck} />}
            </SelectPage>
          </Col>
          <Col size={3}>
            <Select
              value={pageType}
              options={pages}
              placeholder={getLiteral('WybierzStrone')}
              onChange={(selected) => setPageType(selected)}
              styles={{ container: () => ({ width: '100%', position: 'relative', boxSizing: 'border-box' }) }}
            />
          </Col>
        </Row>
      </Marginer>
      <DragDropContext onDragEnd={onDragEnd}>{pageType && children}</DragDropContext>
      {removeAlert.alert}
    </pageCxt.Provider>
  );
};

const SelectPage = styled.button`
  background-color: #fff;
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  width: 100%;
  padding: 0 1.5rem;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  ${({ current }) =>
    current === 'true'
      ? css`
          background-color: #ecf7f9;
          border-color: #0488e7;
        `
      : ''}

  svg {
    margin-left: auto;
    color: #0488e7;
  }
`;

const Heading = styled.h3`
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

export default PageCxtProvider;
