/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { ItemHistory, ITEM_TYPES } from './ItemHistory';
import { TemplateCssCode } from './TemplateCssCode';
import { TemplateJsCode } from './TemplateJsCode';

import { Container } from 'components/atoms/Grid';
import { Tag } from 'components/atoms/Tag';
import { Footer } from 'components/molecules/Footer';
import { Header } from 'components/molecules/Header';
import { TemplateCommit } from 'components/molecules/TemplateCommit';
import { TemplatePrieview } from 'components/molecules/TemplatePrieview';
import { TemplateSettings } from 'components/organisms/TemplateSettings';
import { TemplatePageWrapper } from 'components/organisms/TemplatePageWrapper';
import { composerCxt } from 'contexts/ComposerContext';
import PageCxtProvider from 'contexts/PagesContext';
import { TemplateStyleGuide } from 'components/organisms/TemplateStyleGuide';
import { TemplateSections } from 'components/organisms/TemplateSections';
import { TemplateFiles } from 'components/organisms/TemplateFiles';
import { templateCxt } from 'contexts/TemplateContext';
import { useHashTabs } from 'utils/useHashTabs';

export const TemplatePageContent = () => {
  const { getLiteral, getTagIcon, getTagLabel } = useContext(composerCxt);
  const { name, templateVersions, badges, allowedActions } = useContext(templateCxt);

  const tabsArray = useMemo(
    () =>
      [
        {
          title: getLiteral('Strony'),
          component: (
            <PageCxtProvider>
              <TemplatePageWrapper />
            </PageCxtProvider>
          ),
        },
        {
          title: getLiteral('Uklad'),
          component: <TemplateSections />,
        },
        {
          title: getLiteral('KomponentyStyli'),
          component: <TemplateStyleGuide />,
        },
        {
          title: getLiteral('Pliki'),
          component: <TemplateFiles />,
        },
        {
          title: getLiteral('Ustawienia'),
          component: <TemplateSettings />,
        },
        {
          title: getLiteral('Historia'),
          component: (
            <ItemHistory
              items={templateVersions}
              loading={templateVersions.length === 0}
              itemType={ITEM_TYPES.DESIGN}
            ></ItemHistory>
          ),
        },
        {
          title: getLiteral('CssLess'),
          component: <TemplateCssCode />,
        },
        {
          title: getLiteral('JavaScript'),
          component: <TemplateJsCode />,
        },
      ].filter((tab) => !!tab),
    [templateVersions],
  );

  const renderedBadges = badges?.map((badge) => (
    <Tag type={badge.type} title={badge.title} key={`component_${badge.type}`}>
      {badge.icon ? getTagIcon(badge.icon) : ''}
      {getTagLabel(badge.type, badge.label)}
    </Tag>
  ));

  const { tabs, tabsPanel } = useHashTabs(tabsArray, { useDisplay: true });

  return (
    <Container>
      <Header
        title={
          <StyledTitle>
            <strong>{name}</strong>
            {renderedBadges}
          </StyledTitle>
        }
        tabs={tabs}
      >
        <TemplatePrieview />
      </Header>
      {tabsPanel}
      {(allowedActions.edit || allowedActions.publish) && (
        <Footer>
          <TemplateCommit />
        </Footer>
      )}
    </Container>
  );
};

const StyledTitle = styled.div`
  display: flex;
  align-items: center;

  strong {
    margin-right: 1rem;
  }
`;
