/* eslint-disable react-hooks/exhaustive-deps */
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { Container, Content, Layout } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Footer } from 'components/molecules/Footer';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { useAccess } from 'helpers/access';
import { API_PAGES_GET_PAGE, API_PAGES_EDIT, API_PAGES_DELETE, EDP_CONFIG } from 'helpers/endpoints';
import { hashToName } from 'helpers/hashToName';
import { useAlert } from 'utils/useAlert';
import { useFetch } from 'utils/useFetch';

const PageTypePage = () => {
  const { id } = useParams();
  const { getLiteral, methods } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);
  const user = useContext(userCtx);
  const canEditConfigs = useAccess('canEditConfigs');

  const getPageData = useFetch(`${API_PAGES_GET_PAGE}/${id}`);
  const postUpdatePageData = useFetch(API_PAGES_EDIT, 'POST');
  const postDeletePageData = useFetch(API_PAGES_DELETE, 'POST');

  const { handleSubmit, register, reset, watch, errors, clearErrors } = useForm();
  const state = watch();

  const markAsDeprecatedAlert = useAlert({
    onAccept: () => postDeletePageData.execute({ id }),
    text: getLiteral(
      'UsuniecieGroupySpowodujeBlokadeMozliwosciDodawaniaKolejnychKomponentowTegoTypuIUsuniecieJeJWPrzyszlosci',
    ),
  });

  useEffect(() => {
    if (id) {
      getPageData.execute();
    }
  }, [id]);

  useEffect(() => {
    if (getPageData.response) {
      reset(getPageData.response);
    }
  }, [getPageData.response]);

  useEffect(() => {
    if (postUpdatePageData.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postUpdatePageData.error.message,
        delay: 5,
      });
    }

    if (postUpdatePageData.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
    }
  }, [postUpdatePageData.response, postUpdatePageData.error]);

  useEffect(() => {
    if (postDeletePageData.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postDeletePageData.error.message,
        delay: 5,
      });
    }

    if (postDeletePageData.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
    }
  }, [postDeletePageData.response, postDeletePageData.error]);

  const onSubmit = (data) => {
    const updateObject = {
      id: id,
      name: data.name,
      descriptions: [
        {
          description: data.description,
          language: user.language,
        },
      ],
    };

    postUpdatePageData.execute(updateObject, () => {
      methods.fetchGroups();
    });
  };

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          {getPageData.loading && <Loader relative />}
          <Header
            title={getPageData.response && getPageData.response.name}
            backwardTitle={getLiteral('Powrot')}
            backwardTo={`${EDP_CONFIG}${hashToName(getLiteral('Podstrony'))}`}
          />

          <form>
            <Input
              ref={register({
                required: true,
              })}
              name="name"
              type="text"
              title={getLiteral('Nazwa')}
              error={errors.name && getLiteral('PoleObowiazkowe')}
              onChange={() => clearErrors('name')}
              readOnly
            />
            <Input
              ref={register({
                required: true,
              })}
              name="description"
              type="text"
              title={getLiteral('Opis')}
              error={errors.description && getLiteral('PoleObowiazkowe')}
              onChange={() => clearErrors('description')}
              readOnly={!!state.deleteDate || !canEditConfigs.condition}
            />
          </form>

          {getPageData.executed && (
            <Footer>
              <div>
                {getPageData.response && !state.deleteDate && (
                  <>
                    <Button
                      style={{ margin: '0 1rem' }}
                      color="green"
                      type="button"
                      disabled={!canEditConfigs.condition}
                      onClick={handleSubmit(onSubmit)}
                    >
                      <FontAwesomeIcon icon={faSave} />
                      {getLiteral('ZapiszZmiany')}
                    </Button>

                    <Button
                      type="button"
                      color="red"
                      disabled={!canEditConfigs.condition}
                      onClick={() => markAsDeprecatedAlert.openAlert()}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      {getLiteral('OznaczJakoWycofywane')}
                    </Button>
                  </>
                )}
              </div>
            </Footer>
          )}
        </Container>
      </Content>
      {markAsDeprecatedAlert.alert}
    </Layout>
  );
};

export default PageTypePage;
