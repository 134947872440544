import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Marginer } from 'components/atoms/Grid';
import { SortingComponent } from 'components/atoms/SortingComponent';
import { Tag } from 'components/atoms/Tag';
import { NewPageTypeForm } from 'components/organisms/NewPageTypeForm';
import { composerCxt } from 'contexts/ComposerContext';
import { useAccess } from 'helpers/access';
import { EDP_PAGES } from 'helpers/endpoints';
import { sortingFunction } from 'helpers/sortingFunctions';
import { useDialog } from 'utils/useDialog';

export const Page = ({ name, description, id, deleteDate }) => {
  const { getLiteral } = useContext(composerCxt);
  const canDeubgComposer = useAccess('canDebugComposer');

  return (
    <Group key={id}>
      <div>
        <GroupDetails>
          <i>{name}</i>
          {deleteDate && (
            <TagContainer>
              <Tag type="deprecated" title={getLiteral('wycofywany')}>
                {getLiteral('wycofywany')}
              </Tag>
            </TagContainer>
          )}
        </GroupDetails>
        <GroupDescription>{description}</GroupDescription>
      </div>
      {canDeubgComposer.condition && (
        <ActionLink to={`${EDP_PAGES}${id}`}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </ActionLink>
      )}
    </Group>
  );
};

export const TemplatePagesList = () => {
  const {
    getLiteral,
    pagesIds,
    methods: { addPageType },
  } = useContext(composerCxt);

  const newTypeDialog = useDialog({
    title: getLiteral('DodajNowyTypPodstrony'),
    content: (
      <NewPageTypeForm
        onSuccessCallback={(res) => {
          newTypeDialog.closeDialog();
          addPageType(res);
        }}
      />
    ),
  });

  const [filters, setFilters] = useState({
    text: '',
    sort: 'name',
  });

  return (
    <Marginer bottom={5}>
      <SortingComponent setTextFilter={(value) => setFilters((state) => ({ ...state, text: value }))} />
      <div>
        {pagesIds
          .filter(({ name, description }) => {
            if (!name.includes(filters.text) && !description.includes(filters.text)) return false;
            return true;
          })
          .sort(sortingFunction(filters))
          .map((group) => (
            <Page key={group.name} {...group} />
          ))}
      </div>
      <AddGroup type="button" onClick={() => newTypeDialog.openDialog()}>
        {getLiteral('DodajNowyElement')}
      </AddGroup>
      {newTypeDialog.dialog}
    </Marginer>
  );
};

const Group = styled.div`
  border: var(--border);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--gray-light);
  }

  &:first-child {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  &:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const ActionLink = styled(Link)`
  min-width: 40px;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: var(--gray-light);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
`;

const GroupDescription = styled.div``;

const GroupDetails = styled.div`
  font-size: 1.3rem;
  display: block;
  margin-bottom: 0.5rem;

  i {
    font-style: italic;
  }
`;

const AddGroup = styled.button`
  background-color: var(--white);
  height: 5rem;
  position: sticky;
  bottom: 1rem;
  width: 100%;
  border: 2px dashed var(--blue);
  border-radius: var(--border-radius);
  margin-top: 2rem;
  font-size: 1.8rem;
  color: var(--blue);
  cursor: pointer;
`;

const TagContainer = styled.div`
  display: inline-block;
  margin-left: 0.4rem;
`;
