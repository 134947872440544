/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/atoms/Table';
import { Label } from 'components/atoms/TypeLabel';
import { ClearButton } from 'components/organisms/AdvancedFilters';
import { composerCxt } from 'contexts/ComposerContext';
import { getTodayDate, getWeekAgoDate } from 'helpers/dateHelper';
import { API_GFX_GET_HISTORY } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const FileManagerHistoryList = () => {
  const { getLiteral } = useContext(composerCxt);
  const [pagesCount, setPagesCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const inputRef = useRef();
  const [items, setItems] = useState([]);
  const [historyStartTime, setHistoryStartTime] = useState(getWeekAgoDate());
  const [historyEndTime, setHistoryEndTime] = useState(getTodayDate());
  const [textSearch, setTextSearch] = useState('');

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getLabelLiteral = (type) => {
    if (type === 'edit') {
      return getLiteral('Edycja');
    }

    if (type === 'remove') {
      return getLiteral('Usuniecie');
    }

    return getLiteral('dodanie');
  };

  let queryText = '';
  if (textSearch.length) {
    queryText = `/text/${encodeURIComponent(textSearch)}`;
  }

  const getStandardFilesHistory = useFetch(
    `${API_GFX_GET_HISTORY}/startDate/${historyStartTime}/endDate/${historyEndTime}/page/${page}${queryText}`,
    'GET',
  );

  const filterHistoryExec = () => {
    getStandardFilesHistory.execute();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      filterHistoryExec();
    }
  };

  useEffect(() => {
    if (getStandardFilesHistory.response) {
      setItems(getStandardFilesHistory.response.models);
      setPagesCount(getStandardFilesHistory.response.pagesCount);
    }
  }, [getStandardFilesHistory.response, getStandardFilesHistory.error]);

  useEffect(() => {
    getStandardFilesHistory.execute();
  }, [page, refresh]);

  return (
    <>
      <Row>
        <Col size={3}>
          <Input
            type="date"
            name="startDate"
            title={getLiteral('ZakresOd')}
            value={historyStartTime}
            onChange={(event) => setHistoryStartTime(event.target.value)}
          />
        </Col>
        <Col size={3}>
          <Input
            type="date"
            name="endDate"
            title={getLiteral('ZakresDo')}
            value={historyEndTime}
            min={historyStartTime}
            onChange={(event) => setHistoryEndTime(event.target.value)}
          />
        </Col>
        <Col size={3}>
          <Wrapper>
            <Input
              type="text"
              ref={inputRef}
              onKeyDown={handleKeyDown}
              name="text"
              title={getLiteral('Identyfikator')}
              onChange={(event) => setTextSearch(event.target.value)}
            />
            <ClearButton
              type="button"
              onClick={() => {
                setTextSearch('');
                inputRef.current.value = '';
                setRefresh((value) => !value);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ClearButton>
          </Wrapper>
        </Col>
        <Col size={3}>
          <Button onClick={filterHistoryExec}>{getLiteral('Filtruj')}</Button>
        </Col>
      </Row>
      {getStandardFilesHistory.loading ? (
        <Loader />
      ) : (
        <>
          {items.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>{getLiteral('Identyfikator')}</th>
                    <th>{getLiteral('TypModyfikacji')}</th>
                    <th>{getLiteral('DataModyfikacji')}</th>
                    <th>{getLiteral('Autor')}</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((el) => {
                    return (
                      <tr key={`${el.id}_${el.user}`}>
                        <th>{el.id}</th>
                        <th>
                          <StyledLabel type={el.changeType}>{getLabelLiteral(el.changeType)}</StyledLabel>
                        </th>
                        <th>{el.modificationDate}</th>
                        <th>{el.user}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Marginer bottom={2} top={2}>
                {pagesCount ? <Pagination {...paginationProps} /> : null}
              </Marginer>
            </>
          ) : (
            <span>{getLiteral('BrakHistoriiZmian')}</span>
          )}
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  margin-right: 1rem;
  position: relative;
`;

const StyledLabel = styled(Label)`
  color: white;
  ${({ type }) => {
    switch (type) {
      case 'remove':
        return css`
          background-color: var(--red);
        `;
      case 'add':
        return css`
          background-color: var(--green);
        `;
      default:
        return css`
          background-color: var(--standardColor);
        `;
    }
  }};
`;
