/* eslint-disable no-dupe-keys */
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Textarea } from 'components/atoms/Form';
import { Loader } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { useAlert } from 'utils/useAlert';

const editedStyles = { border: '1px solid #0389E6', backgroundColor: '#EFF8FF' };

const TranslateLiteralHelper = ({ translate, onChange, restore, literalKey, standardLiterals, isAutoTranslation }) => {
  const { getLiteral } = useContext(composerCxt);

  const restoreAlert = useAlert({
    onAccept: () => {
      restore();
      setValue(translate.originalValue);
      setEdited(false);
    },
    text: `${getLiteral('WykonanieTejAkcjiPrzywrociWartoscDomyslna')}. ${getLiteral(
      'LiteralZostanieUsunietyJezeliWartoscDomyslnaNieIstniejeWSlownikuStandardowym',
    )}`,
  });

  const [edited, setEdited] = useState('');
  const elementValue = translate ? translate.clientValue || translate.originalValue : null;

  const [value, setValue] = useState(elementValue);

  useEffect(() => {
    setEdited(false);
  }, [translate.clientValue]);

  return (
    <>
      <Textarea
        bottom="0"
        name="literal"
        value={value}
        style={edited ? { ...editedStyles } : {}}
        title={value ? '' : literalKey}
        onChange={(e) => {
          onChange(e.target.value);
          setValue(e.target.value);
          setEdited(true);
        }}
        readOnly={isAutoTranslation}
      />
      {translate.clientValue && translate.originalValue !== translate.clientValue && !standardLiterals && (
        <UndoButton type="button" title={getLiteral('PrzywrocDomyslny')} onClick={() => restoreAlert.openAlert()}>
          <FontAwesomeIcon icon={faUndo} />
        </UndoButton>
      )}
      {restoreAlert.alert}
    </>
  );
};

export const DictionaryLangList = ({
  loading,
  translateLiterals,
  changeLiteral,
  removeLiteral,
  source,
  target,
  page,
  standardLiterals,
  isAutoTranslate = false,
}) => {
  const { getLiteral } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  if (translateLiterals && Array.isArray(translateLiterals) && !translateLiterals.length) {
    return <Wrapper>{getLiteral('NieZnalezionoTlumaczenDlaTegoJezyka')}</Wrapper>;
  }

  let areTranslationsLoaded = false;

  if (translateLiterals) {
    const transKeys = Object.keys(translateLiterals);
    areTranslationsLoaded = translateLiterals[transKeys[0]][source] && translateLiterals[transKeys[0]][target];
  }

  const getTitleForLiteral = (literal, source, key) => {
    if (key !== literal[source].originalValue) {
      //klucz jest różny od wartości oryginalnej
      return key;
    }

    return '';
  };

  return (
    <div>
      {areTranslationsLoaded &&
        Object.entries(translateLiterals).map(([key, literal], index) => (
          <Outer key={key}>
            <Wrapper>
              <Index>{50 * ((page || 1) - 1) + index + 1}.</Index>
              <Textarea
                bottom="0"
                value={
                  literal[source].clientValue && source !== target
                    ? literal[source].clientValue
                    : literal[source].originalValue
                }
                readOnly
                attrTitle={getLiteral('KliknijAbySkopiowacWartosc')}
                title={getTitleForLiteral(literal, source, key)}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    literal[source].clientValue && source !== target
                      ? literal[source].clientValue
                      : literal[source].originalValue,
                  );
                  createToast({
                    title: getLiteral('SkopiowanoDoSchowka'),
                    message: getLiteral('UzyjOpcjiWklejaniaAbySkorzystacZZawartosci'),
                    context: 'info',
                    delay: 3,
                  });
                }}
              />
            </Wrapper>
            {target && (
              <Wrapper>
                <TranslateLiteralHelper
                  literalKey={key}
                  standardLiterals={standardLiterals}
                  translate={literal[target]}
                  onChange={(value) => changeLiteral(key, value)}
                  restore={() => removeLiteral(key)}
                  isAutoTranslation={isAutoTranslate}
                />
              </Wrapper>
            )}
          </Outer>
        ))}
      {loading && <Loader relative />}
    </div>
  );
};

const Outer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;
  border-radius: 5px;

  &:hover {
    background-color: var(--gray-light);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 50%;
  padding-left: 65px;
  padding-right: 10px;
`;

const Index = styled.span`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #666666;
`;

const UndoButton = styled.button`
  background-color: transparent;
  color: #cccccc;
  border: none;
  position: absolute;
  left: calc(100% - 15px);
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  transition: color 0.25s;
  z-index: 99;

  &:hover {
    color: #000;
  }
`;
