import { useState } from 'react';
import styled, { css } from 'styled-components';

export const DropField = ({ title, titleOnDragOver, onBaseRender, styles }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  // Callback after file input changed
  const renderImage = (target) => {
    const file = target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = function convertToBase64() {
        onBaseRender(reader.result, file);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Wrapper
      onDragOver={() => !isDragOver && setIsDragOver(true)}
      dragover={isDragOver.toString()}
      onDragLeave={() => setIsDragOver(false)}
      style={styles}
    >
      {isDragOver ? titleOnDragOver : title}
      <File
        type="file"
        onClick={(e) => (e.target.value = '')}
        onChange={(e) => renderImage(e.target)}
        onDrop={() => setIsDragOver(false)}
      />
    </Wrapper>
  );
};

const File = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Wrapper = styled.div`
  border: 2px dashed #e0e0e0;
  border-radius: 5px;
  position: relative;
  min-height: 100px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color;
  width: 100%;

  ${({ dragover }) =>
    dragover === 'true'
      ? css`
          background-color: #f0f0f0;
        `
      : ''}
`;
