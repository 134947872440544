import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createContext, useState, useCallback, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

export const toastCtx = createContext();

export const ToastCtxProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  // Create Toast
  const createToast = ({ title, message, context, delay }) => {
    const id = Date.now();

    if (delay === undefined) {
      //default value for toast delay - 10s
      delay = 10;
    }

    if (toasts.length >= 3) {
      clearTimeout(toasts[0].handleTimeout);

      setToasts((state) => {
        state.shift();
        return [...state, { id, title, message, context, delay }];
      });
    } else {
      setToasts((state) => [...state, { id, title, message, context, delay }]);
    }
  };

  // Remove Toast
  const removeToast = useCallback(
    (id, noEffect) => {
      if (noEffect) {
        setToasts((state) => state.filter((item) => item.id !== id));
        return;
      }

      const element = toasts.find((el) => el.id === id);
      element.removed = true;

      setToasts((state) => [...state.filter((item) => item.id !== id), element]);
      setTimeout(() => setToasts((state) => state.filter((item) => item.id !== id)), 500);
    },
    [toasts],
  );

  // Check if new toasts was added, and set debounce effect to them if has a delay property
  useEffect(() => {
    toasts.forEach((toast) => {
      if (toast.delay) {
        const delay = toast.delay;
        delete toast.delay;

        toast.handleTimeout = setTimeout(() => removeToast(toast.id), delay * 1000);
        setToasts((state) => [...state.filter((item) => item.id !== toast.id), toast]);
      }
    });
  }, [toasts, removeToast]);

  return (
    <toastCtx.Provider value={{ createToast }}>
      {children}
      <ToastWrapper>
        {toasts &&
          toasts.map((item) => (
            <Toast key={item.id} removed={item.removed} context={item.context}>
              <ToastHeadline>
                <strong>{item.title}</strong>
                <ToastClose
                  onClick={() => {
                    clearTimeout(item.handleTimeout);
                    removeToast(item.id, true);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </ToastClose>
              </ToastHeadline>
              <div>{item.message}</div>
            </Toast>
          ))}
      </ToastWrapper>
    </toastCtx.Provider>
  );
};

const fadeLeft = keyframes`
  from {
    transform: translateX(120%);
  }
  to {
    transform: translateX(0);
  }
`;
const fadeRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(120%);
  }
`;

const ToastWrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9999999;
`;

const Toast = styled.div`
  padding: 2rem;
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 7px;
  width: 500px;
  font-size: 1.2rem;

  ${({ removed }) =>
    removed
      ? css`
          animation: ${fadeRight} 0.5s backwards;
        `
      : css`
          animation: ${fadeLeft} 0.5s backwards;
        `}

  ${({ context }) => {
    switch (context) {
      case 'success':
        return css`
          background-color: #d9ffdc;
        `;
      case 'error':
        return css`
          background-color: #ffe8e8;
        `;
      case 'info':
        return css`
          background-color: #ecf7f9;
        `;
      default:
        break;
    }
  }}
`;

const ToastHeadline = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 1rem;

  &:after {
    content: '';
    width: 70px;
    height: 2px;
    background-color: #333;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  strong {
    font-size: 1.4rem;
  }
`;

const ToastClose = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
`;
