import { useContext, useEffect, useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAlert } from 'utils/useAlert';
import { useDialog } from 'utils/useDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { FileReplaceDialogComponent } from 'components/atoms/FileReplaceDialogComponent';
import { FileManagerDialogComponent } from 'components/atoms/FileManagerDialogComponent';
import { FileInfoDialogoComponent, FileInfoDialogStyles } from 'components/atoms/FileInfoDialogoComponent';
import { Loader } from 'components/atoms/Loader';
import {
  Action,
  ActionWrapper,
  Bottom,
  File,
  FileDownload,
  FilePicture,
  Name,
  StyledTags,
} from 'components/atoms/ManagerFileListItem';

export const TemplateFileListItem = ({
  name,
  path,
  clientGfx,
  removeFile,
  replaceFile,
  editable,
  addFileAndReplace,
  isStandardFile,
  ...restProps
}) => {
  const picturePath = clientGfx ? `/gfx/${clientGfx.path}` : `/gfx/${path}`;
  const ext = name.split('.')[1];
  const { getLiteral } = useContext(composerCxt);
  const [selfLoader, setSelfLoader] = useState(false);
  const dialogFileInfo = useDialog({
    title: getLiteral('InformacjeOPliku'),
    content: (
      <FileInfoDialogoComponent
        picturePath={`/gfx/${path}`}
        name={name}
        clientGfx={clientGfx}
        isStandardFile={isStandardFile}
        {...restProps}
      />
    ),
    styles: FileInfoDialogStyles,
  });
  const dialogFileManager = useDialog({
    title: (
      <>
        {getLiteral('WybierzPlikZManagera')}: <strong>{name}</strong>
      </>
    ),
    content: (
      <FileManagerDialogComponent
        replaceFile={(customName) => {
          setSelfLoader(true);
          dialogFileReplace.closeDialog();
          dialogFileManager.closeDialog();
          replaceFile(name, customName);
        }}
      />
    ),
  });

  const dialogFileReplace = useDialog({
    title: (
      <>
        {getLiteral('PodmienPlikNaWlasny')}: <strong>{name}</strong>
      </>
    ),
    content: (
      <FileReplaceDialogComponent
        openManager={dialogFileManager.openDialog}
        picturePath={picturePath}
        name={name}
        addNewFile={(base64, file) => {
          setSelfLoader(true);
          dialogFileReplace.closeDialog();
          addFileAndReplace(base64, file, name);
        }}
        {...clientGfx}
      />
    ),
  });
  const { openAlert, alert } = useAlert({
    onAccept: () => {
      setSelfLoader(true);
      removeFile(name);
    },
    text: getLiteral('CzyJestesPewienZeChceszPrzywrocicOryginalneZdjecie'),
  });

  useEffect(() => {
    setSelfLoader(false);
  }, [clientGfx]);

  return (
    <>
      <File isRemoving={selfLoader.toString()}>
        <StyledTags ext={ext} clientGfx={clientGfx} isStandardFile={isStandardFile} />
        <FilePicture name={name} picturePath={picturePath} />
        <Bottom>
          <Name title={name}>{name}</Name>
          <ActionWrapper>
            <FileDownload name={name} picturePath={picturePath} />
            {editable && (
              <Action type="button" onClick={() => dialogFileReplace.openDialog()}>
                <FontAwesomeIcon icon={faSync} />
              </Action>
            )}
            <Action type="button" onClick={() => dialogFileInfo.openDialog()}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Action>
            {clientGfx && editable && (
              <Action type="button" onClick={openAlert}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Action>
            )}
          </ActionWrapper>
        </Bottom>
        {selfLoader && <Loader relative />}
      </File>
      {alert}
      {dialogFileInfo.dialog}
      {dialogFileReplace.dialog}
      {dialogFileManager.dialog}
    </>
  );
};
