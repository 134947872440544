import { Content, Layout } from 'components/atoms/Grid';
import { TemplateInfo } from 'components/molecules/TemplateInfo';
import { Aside } from 'components/organisms/Aside';
import { TemplatePageContent } from 'components/organisms/TemplatePageContent';
import TemplateCxtProvider from 'contexts/TemplateContext';

const TemplatePage = () => (
  <TemplateCxtProvider>
    <Layout>
      <Aside isOpenMenu={false}>
        <TemplateInfo />
      </Aside>
      <Content>
        <TemplatePageContent />
      </Content>
    </Layout>
  </TemplateCxtProvider>
);

export default TemplatePage;
