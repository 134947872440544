import { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useFetch } from '../utils/useFetch';
import { API_TEMPLATES_BRANCH, EDP_TEMPLATES } from '../helpers/endpoints';

import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';

export const templateListItemCtx = createContext();

export const TemplateListItemCtxProvider = ({ children, template }) => {
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const history = useHistory();

  let queryVersion = '';
  if (template.version) {
    queryVersion = `/version/${template.version}`;
  }
  const getCreateBranch = useFetch(`${API_TEMPLATES_BRANCH}${template.id}${queryVersion}`, 'GET');

  const startEdit = () => {
    getCreateBranch.execute();
  };

  const canPublish = () => {
    return template.allowedActions.publish;
  };

  const canEdit = () => {
    return template.allowedActions.edit;
  };

  const getPublishedDesignInfo = (field) => {
    if (template.publishedInfo) {
      return template.publishedInfo.map((data) => {
        return <span key={`${data[field]}_${field}`}>{data[field]}</span>;
      });
    }
    return '';
  };

  // Creating branch for editing
  useEffect(() => {
    if (getCreateBranch.response) {
      createToast({
        title: `${getLiteral('WersjaRoboczaZostalaUtworzona')}!`,
        message: `${getLiteral('JestesWTrybieEdycjiKompozycji')}.`,
        context: 'success',
      });
      history.push(`${EDP_TEMPLATES}${getCreateBranch.response.id}`);
    } else if (getCreateBranch.error) {
      createToast({
        title: `${getLiteral('WersjaRoboczaNieMoglaZostacUtworzona')}!`,
        message: getCreateBranch.error.message,
        context: 'error',
      });
    }
  }, [getCreateBranch.response, getCreateBranch.error]);

  return (
    <templateListItemCtx.Provider value={{ ...template, canPublish, canEdit, getPublishedDesignInfo, startEdit }}>
      {children}
    </templateListItemCtx.Provider>
  );
};
