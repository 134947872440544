/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faSave, faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Container, Content, Layout } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Editor } from 'components/molecules/Editor';
import { Footer } from 'components/molecules/Footer';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { LibraryForm } from 'components/organisms/LibraryForm';
import { LibraryUsedInComponents } from 'components/organisms/LibraryUsedInComponents';
import { composerCxt } from 'contexts/ComposerContext';
import {
  API_LIBS_GET_ID,
  API_LIBS_ADD,
  API_LIBS_UPDATE,
  API_LIBS_DELETE,
  EDP_LIBRARIES,
  API_LIBS_RESTORE,
  EDP_CONFIG,
} from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { hashToName } from 'helpers/hashToName';
import { useHashTabs } from 'utils/useHashTabs';
import { useFetch } from 'utils/useFetch';
import { useAlert } from 'utils/useAlert';
import { toastCtx } from 'contexts/ToastContext';
import { useAccess } from 'helpers/access';

const LibraryPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const canEditConfigs = useAccess('canEditConfigs');
  const { getLiteral, methods, libraries } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const getLibraryData = useFetch(`${API_LIBS_GET_ID}/${id}`);
  const getLibraryRestore = useFetch(`${API_LIBS_RESTORE}/${id}`);
  const postNewLibrary = useFetch(API_LIBS_ADD, 'POST');
  const postUpdateLibrary = useFetch(API_LIBS_UPDATE, 'POST');
  const postMarkAsDeprecated = useFetch(`${API_LIBS_DELETE}`, 'POST');

  const { handleSubmit, reset, watch, setValue, control, ...props } = useForm({
    defaultValues: {
      content: '',
      name: '',
      deleteDate: null,
      description: '',
      priority: 0,
      requiredLibraries: [],
    },
  });
  const state = watch();

  const { tabs, tabsPanel } = useHashTabs(
    [
      {
        title: getLiteral('PodstawoweInformacje'),
        component: (
          <LibraryForm
            {...props}
            control={control}
            readOnly={(getLibraryData.response && !!state.deleteDate) || !canEditConfigs.condition}
          />
        ),
      },
      {
        title: getLiteral('KodBiblioteki'),
        component: (
          <Controller
            control={control}
            name="content"
            render={({ onChange, value }) => (
              <Editor
                mode="javascript"
                onChange={onChange}
                readOnly={(getLibraryData.response && !!state.deleteDate) || !canEditConfigs.condition}
                value={value}
              />
            )}
          />
        ),
      },
      id !== '0' && {
        title: getLiteral('WystepowanieWKomponentach'),
        component: <LibraryUsedInComponents id={id} />,
      },
    ].filter((item) => !!item),
  );

  const markAsDeprecatedAlert = useAlert({
    onAccept: () => postMarkAsDeprecated.execute({ id }),
    text: getLiteral('EdycjaBibliotekiOznaczonejJakoWycofywanaNieBedzieMozliwe'),
  });

  // Get data on component mount
  useEffect(() => {
    if (id && parseInt(id, 10) !== 0) {
      getLibraryData.execute();
    }
  }, [id]);

  // Handling library response
  useEffect(() => {
    if (getLibraryData.response) {
      let requiredLibraries = getLibraryData.response.requiredLibraries.map((item) => {
        const lib = libraries.find(({ id }) => id === item);
        return makeSelectOption(lib.id, lib.name);
      });
      reset({ ...getLibraryData.response, requiredLibraries });
    }
  }, [getLibraryData.response]);

  // Handling library response
  useEffect(() => {
    if (getLibraryRestore.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });

      let requiredLibraries = getLibraryRestore.response.requiredLibraries.map((item) => {
        const lib = libraries.find(({ id }) => id === item);
        return makeSelectOption(lib.id, lib.name);
      });
      methods.updateLibrary(getLibraryRestore.response);
      reset({ ...getLibraryRestore.response, requiredLibraries });
    }
  }, [getLibraryRestore.response]);

  // Adding new library efect
  useEffect(() => {
    if (postNewLibrary.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postNewLibrary.error.message,
        delay: 5,
      });
    }

    if (postNewLibrary.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
      methods.addLibrary(postNewLibrary.response);
      history.push(`${EDP_LIBRARIES}${postNewLibrary.response.id}`);
    }
  }, [postNewLibrary.response, postNewLibrary.error]);

  // Update effect
  useEffect(() => {
    if (postUpdateLibrary.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postUpdateLibrary.error.message,
        delay: 5,
      });
    }

    if (postUpdateLibrary.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
      methods.updateLibrary(postUpdateLibrary.response);
    }
  }, [postUpdateLibrary.response, postUpdateLibrary.error]);

  // Deprecating effect
  useEffect(() => {
    if (postMarkAsDeprecated.error) {
      createToast({
        context: 'error',
        title: getLiteral('OperacjaZapisuNiePowiodlaSie'),
        message: postMarkAsDeprecated.error.message,
        delay: 5,
      });
    }

    if (postMarkAsDeprecated.response) {
      createToast({
        context: 'success',
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('zapisanoZmiany'),
        delay: 5,
      });
      methods.updateLibrary({ ...getLibraryData.response, deleteDate: 'true' });
      setValue('deleteDate', 'true');
    }
  }, [postMarkAsDeprecated.response, postMarkAsDeprecated.error]);

  const onSubmit = (values) => {
    const data = {
      content: '',
      ...values,
      requiredLibraries: values.requiredLibraries.map(({ value }) => value),
      id: id,
    };

    if (parseInt(id, 10) === 0) {
      postNewLibrary.execute(data);
    } else {
      postUpdateLibrary.execute(data);
    }
  };

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          {(postUpdateLibrary.loading || getLibraryData.loading || postNewLibrary.loading) && <Loader relative />}
          <Header
            tabs={tabs}
            title={
              parseInt(id, 10) !== 0
                ? getLibraryData.response
                  ? getLibraryData.response.name
                  : ''
                : getLiteral('DodajNowaBiblioteke')
            }
            backwardTitle={getLiteral('Powrot')}
            backwardTo={`${EDP_CONFIG}${hashToName(getLiteral('BibliotekiJavascript'))}`}
          ></Header>
          <form onSubmit={handleSubmit(onSubmit)}>{tabsPanel}</form>
          {getLibraryData.executed ? (
            <Footer>
              <div>
                {getLibraryData.response && !state.deleteDate ? (
                  <>
                    <ButtonMargin
                      color="green"
                      type="button"
                      disabled={!canEditConfigs.condition}
                      onClick={handleSubmit(onSubmit)}
                    >
                      <FontAwesomeIcon icon={faSave} />
                      {getLiteral('ZapiszZmiany')}
                    </ButtonMargin>

                    {getLibraryData.response ? (
                      <ButtonMargin
                        type="button"
                        color="red"
                        disabled={!canEditConfigs.condition}
                        onClick={() => markAsDeprecatedAlert.openAlert(0)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        {getLiteral('OznaczJakoWycofywane')}
                      </ButtonMargin>
                    ) : null}
                  </>
                ) : (
                  <Button
                    type="button"
                    onClick={() => getLibraryRestore.execute()}
                    loading={getLibraryRestore.loading.toString()}
                  >
                    <FontAwesomeIcon icon={faTrashRestore} />
                    {getLiteral('Przywroc')}
                  </Button>
                )}
              </div>
            </Footer>
          ) : (
            <Footer>
              <div>
                <ButtonMargin
                  color="green"
                  type="button"
                  disabled={!canEditConfigs.condition}
                  onClick={handleSubmit(onSubmit)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {getLiteral('Dodaj')}
                </ButtonMargin>
              </div>
            </Footer>
          )}
        </Container>
      </Content>
      {markAsDeprecatedAlert.alert}
    </Layout>
  );
};

const ButtonMargin = styled(Button)`
  margin: 0 1rem;
`;

export default LibraryPage;
