import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MenuLink = ({ to, children, ...props }) => {
  const { pathname, hash } = useLocation();

  return (
    <StyledLink {...props} to={to} active={`${pathname}${hash}`.includes(to).toString()}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  font-size: 1.5rem;
  display: block;
  text-decoration: none;
  color: #000;
  position: relative;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &:hover {
    color: #999;
  }

  ${({ active }) =>
    active === 'true' &&
    css`
      text-decoration: underline;
    `}
`;
