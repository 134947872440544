/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Container, Layout, Content } from 'components/atoms/Grid';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { RemovedItems } from 'components/organisms/RemovedItems';
import { composerCxt } from 'contexts/ComposerContext';
import { API_MODULES_GET_LIST_TO_RESTORE, API_TEMPLATES_GET_LIST_TO_RESTORE } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { useHashTabs } from 'utils/useHashTabs';

const RemovedElementsPage = () => {
  const { getLiteral } = useContext(composerCxt);
  const [designs, setDesigns] = useState([]);
  const [components, setComponents] = useState([]);

  const getDesigns = useFetch(API_TEMPLATES_GET_LIST_TO_RESTORE, 'GET');
  const getComponents = useFetch(API_MODULES_GET_LIST_TO_RESTORE, 'GET');

  const filterElements = (elements) => {
    const groupById = elements.reduce(function (rv, x) {
      (rv[x['id']] = rv[x['id']] || []).push(x);
      return rv;
    }, {});

    Object.keys(groupById).forEach((key) => {
      groupById[key] = groupById[key].reduce(function (prev, current) {
        return prev.version > current.version ? prev : current;
      });
    });

    return Object.values(groupById);
  };

  useEffect(() => {
    if (getDesigns.response) {
      setDesigns(filterElements(getDesigns.response));
    }
  }, [getDesigns.response, getDesigns.error]);

  useEffect(() => {
    if (getComponents.response) {
      setComponents(filterElements(getComponents.response));
    }
  }, [getComponents.response, getComponents.error]);

  useEffect(() => {
    getDesigns.execute();
    getComponents.execute();
  }, []);

  const configTabs = [
    {
      title: getLiteral('Kompozycje'),
      component: <RemovedItems items={designs} loading={getDesigns.loading} itemsType="design" />,
    },
    {
      title: getLiteral('Komponenty'),
      component: <RemovedItems items={components} loading={getDesigns.loading} itemsType="component" />,
    },
  ];

  const { tabs, tabsPanel } = useHashTabs(configTabs);

  return (
    <Layout>
      <Aside>
        {designs.length > 0 || components.length > 0 ? (
          <QueueInfo>
            <h1>{getLiteral('Statystyki')}</h1>
            {designs.length > 0 && (
              <div>
                <span>
                  {getLiteral('LiczbaSzablonowUsunietych')}: {designs.length}
                </span>
              </div>
            )}
            {components.length > 0 && (
              <div>
                <span>
                  {getLiteral('LiczbaKomponentowUsunietych')}: {components.length}
                </span>
              </div>
            )}
          </QueueInfo>
        ) : null}
      </Aside>
      <Content>
        <Container>
          <Header title={getLiteral('ElementyUsuniete')} tabs={tabs} />
          {tabsPanel}
        </Container>
      </Content>
    </Layout>
  );
};

const QueueInfo = styled.div`
  padding: 1.5rem 2.5rem;

  span {
    font-size: 1.3rem;
  }

  h1 {
    margin-bottom: 1rem;
    font-weight: 700;
  }

  div {
    margin-bottom: 0.5rem;
  }
`;

export default RemovedElementsPage;
