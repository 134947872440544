import { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input, Radio } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { Col, Marginer, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';
import { makeSelectOption } from 'helpers/makeSelectOption';

export const NewFilters = ({ setData, defaultData }) => {
  const { getLiteral, libraries } = useContext(composerCxt);
  const { register, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      isDraft: '',
      ...defaultData,
    },
  });

  const watchIsDraft = watch('isDraft');

  const librariesOptions = libraries.map(({ id, name }) => makeSelectOption(id, name));

  return (
    <Form onSubmit={handleSubmit(setData)}>
      <Row>
        <Col size={6}>
          <Input ref={register} name="description" title={getLiteral('SzukajWOpisach')} />
        </Col>
        <Col size={6}>
          <Input ref={register} name="id" title={getLiteral('SzukajId')} />
        </Col>
        <Col size={6}>
          <Input ref={register} type="date" name="date" title={getLiteral('SzukajNieStarszeNiz')} />
        </Col>
        <Col size={6}>
          <Input ref={register} name="designId" title={getLiteral('SzukajIdKompozycji')} />
        </Col>
        <Col size={6}>
          <Input
            ref={register}
            type="number"
            min="1"
            name="designVersion"
            title={getLiteral('SzukajWWersjiKompozycji')}
          />
        </Col>
        <Col size={6}>
          <Controller
            control={control}
            name="jsLibrary"
            as={<Select options={librariesOptions} isClearable placeholder={getLiteral('SzukajPoBibliotekachJS')} />}
          />
        </Col>
      </Row>
      <Marginer bottom={1}>
        <RadioLabel>{getLiteral('wersjaRobocza')}</RadioLabel>
      </Marginer>
      <Controller
        name="isDraft"
        control={control}
        as={
          <NoMarginRow mb={2}>
            <Radio
              title={getLiteral('Tak')}
              value="y"
              checked={watchIsDraft === 'y'}
              onChange={(e) => setValue('isDraft', e.target.value)}
            />
            <Radio
              title={getLiteral('Nie')}
              value="n"
              checked={watchIsDraft === 'n'}
              onChange={(e) => setValue('isDraft', e.target.value)}
            />
            <Radio
              title={getLiteral('NieDotyczy')}
              value=""
              checked={watchIsDraft === ''}
              onChange={() => setValue('isDraft', '')}
            />
          </NoMarginRow>
        }
      />
      <Button type="submit">{getLiteral('Zastosuj')}</Button>
    </Form>
  );
};

const Form = styled.form`
  width: 600px;
`;

const RadioLabel = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
`;

const NoMarginRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;
