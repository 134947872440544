import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)`
  .MuiDialog-container {
    margin-left: 300px;
  }
  .MuiDialog-paperWidthSm {
    min-width: 500px;
    max-height: 80vh;
    max-width: initial;
  }
  .MuiDialogContent-root {
    padding-left: 20px;
    padding-right: 20px;
  }
  p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`;

export default CustomDialog;
