/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { Marginer } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/atoms/Table';
import { composerCxt } from 'contexts/ComposerContext';
import { API_TRANSLATION_GET_QUEUE } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';
import { getLanguageLabel } from 'helpers/getLanguageLabel';

export const TranslationQueue = ({ setTranslationWaiting }) => {
  const { getLiteral, languages } = useContext(composerCxt);
  const [pagesCount, setPagesCount] = useState(0);
  const [translations, setTranslations] = useState([]);
  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });
  const getLiteralsToTranslate = useFetch(`${API_TRANSLATION_GET_QUEUE}/page/${page}`, 'GET');

  useEffect(() => {
    if (getLiteralsToTranslate.response) {
      setTranslations(getLiteralsToTranslate.response.models);
      setPagesCount(getLiteralsToTranslate.response.pagesCount);
      setTranslationWaiting(getLiteralsToTranslate.response.models.length);
    }
  }, [getLiteralsToTranslate.response, getLiteralsToTranslate.error]);
  useEffect(() => {
    getLiteralsToTranslate.execute();
  }, [page]);

  return (
    <>
      {getLiteralsToTranslate.loading ? (
        <Loader />
      ) : translations.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>{getLiteral('JezykZrodlowy')}</th>
                <th>{getLiteral('JezykDocelowy')}</th>
                <th>{getLiteral('Autor')}</th>
                <th>{getLiteral('Status')}</th>
                <th>{getLiteral('DataUtworzenia')}</th>
              </tr>
            </thead>
            <tbody>
              {translations.map((el) => {
                return (
                  <tr key={`${el.source_lang}_${el.target_lang}`}>
                    <th>{getLanguageLabel(el.source_lang, languages)}</th>
                    <th>{getLanguageLabel(el.target_lang, languages)}</th>
                    <th>{el.author}</th>
                    <th>{!!el.startTime ? `${getLiteral('Rozpoczeto')} ${el.startTime}` : getLiteral('Oczekuje')}</th>
                    <th>{el.added_time}</th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Marginer bottom={2} top={2}>
            {pagesCount ? <Pagination {...paginationProps} /> : null}
          </Marginer>
        </>
      ) : (
        <span>{getLiteral('BrakTlumaczenOczekujacychWKolejce')}</span>
      )}
    </>
  );
};
