import styled from 'styled-components';
import { useContext } from 'react';

import { composerCxt } from 'contexts/ComposerContext';
import { userCtx } from 'contexts/UserContext';
import { SUBSCRIPTION_DIALOG_TYPE_ACTIVATION } from 'components/organisms/SubscriptionDialog';

export const AsideBanner = () => {
  const user = useContext(userCtx);
  const { openSubscriptionDialog, getLiteral } = useContext(composerCxt);

  const handleClickOpenDialog = () => {
    openSubscriptionDialog(SUBSCRIPTION_DIALOG_TYPE_ACTIVATION);
  };

  return (
    <Link onClick={handleClickOpenDialog}>
      <img alt={getLiteral('AktywujComposerPro')} src={`/sources/banner-composer-pro-small-${user.language}.png`} />
    </Link>
  );
};

const Link = styled.a`
  cursor: pointer;
`;
