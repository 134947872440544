/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_GROUPS_ADD } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { universalSiteData } from 'helpers/universalSiteData';

export const NewModuleTypeForm = ({ onSuccessCallback }) => {
  const { getLiteral, pagesIds, layoutsIds } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const { register, handleSubmit, control } = useForm();

  const postNewModuleType = useFetch(API_GROUPS_ADD, 'POST');

  const onSubmit = (data) => {
    postNewModuleType.execute({ ...data, siteId: data.siteId.value }, (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    });
  };

  const options = useMemo(() => {
    return [
      makeSelectOption(
        universalSiteData.siteId,
        `${getLiteral('komponentWidocznyNaKazdejPodstronie')} (${universalSiteData.name})`,
      ),
      ...layoutsIds.map((el) => makeSelectOption(el.id, `${el.description} (${el.name})`)),
      ...pagesIds.map((el) => makeSelectOption(el.id, `${el.description} (${el.name})`)),
    ];
  }, [pagesIds, layoutsIds]);

  useEffect(() => {
    if (postNewModuleType.error) {
      createToast({
        delay: 5,
        context: 'error',
        message: postNewModuleType.error.message,
        title: getLiteral('WystapilNieoczekiwanyProblem'),
      });
    }
  }, [postNewModuleType.error]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input ref={register} name="id" title={getLiteral('PodajKluczId')} />
      <Input ref={register} name="name" title={getLiteral('PodajNazweTypu')} />
      <Input ref={register} name="description" title={getLiteral('PodajOpis')} />
      <Controller
        name="siteId"
        control={control}
        as={<Select options={options} placeholder={getLiteral('WybierzPodstroneKomponentu')} />}
      />
      <Button type="submit" color="blue" loading={postNewModuleType.loading.toString()}>
        {getLiteral('Utworz')}
      </Button>
    </form>
  );
};
