/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useCallback } from 'react';
import { faArrowsAltV, faEdit, faEye, faEyeSlash, faRetweet, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Marginer } from 'components/atoms/Grid';
import { SmallLoaderAfter } from 'components/atoms/Loader';
import { CopyTeamplateForm } from 'components/molecules/CopyTeamplateForm';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import {
  API_TEMPLATES_CAN_COMPOSITION_BE_UPDATED,
  API_TEMPLATES_CREATE_UPDATED_DRAFT_COMPOSITION,
  EDP_TEMPLATES,
} from 'helpers/endpoints';
import { COMPOSER_SUBSCRIPTION_LEVEL_PRO } from 'helpers/composerVersion';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { ActualizeTemplateDialog } from 'components/atoms/ActualizeTemplateDialog';
import { useAccess } from 'helpers/access';

export const TemplateInfo = () => {
  const {
    name,
    id,
    description,
    image,
    version,
    parentVersion,
    isDraft,
    createUser,
    type,
    lastModificationDate,
    refreshPage,
    setRefreshPage,
    openRemoveAlert,
  } = useContext(templateCxt);

  const { isSupport } = useContext(userCtx);
  const { createToast } = useContext(toastCtx);
  const { getLiteral, composerSubscriptionLevel } = useContext(composerCxt);
  const history = useHistory();
  const user = useContext(userCtx);
  const [componentsToUpdate, setComponentsToUpdate] = useState([]);

  const canEditStandardDesigns = useAccess('canEditStandardDesigns');
  const canEditStandardComponents = useAccess('canEditStandardComponents');

  const getComponentsToUpdate = useFetch(`${API_TEMPLATES_CAN_COMPOSITION_BE_UPDATED}`, 'POST');
  const actualizeTemplate = useFetch(`${API_TEMPLATES_CREATE_UPDATED_DRAFT_COMPOSITION}`, 'POST');

  useEffect(() => {
    if (getComponentsToUpdate.response && getComponentsToUpdate.response.components) {
      setComponentsToUpdate(getComponentsToUpdate.response.components);
    } else {
      setComponentsToUpdate([]);
    }
  }, [getComponentsToUpdate.response, getComponentsToUpdate.error]);

  useEffect(() => {
    if (actualizeTemplate.response) {
      createToast({
        title: `${getLiteral('KompozycjaZostalaZaktualizowana')}!`,
        message: `${getLiteral('JestesWTrybieEdycjiKompozycji')}.`,
        context: 'success',
      });

      setRefreshPage(!refreshPage);
      history.push(`${EDP_TEMPLATES}${actualizeTemplate.response.id}/${actualizeTemplate.response.version}`);
      getComponentsToUpdate.execute({ id, version });
      actualizeTemplateDialog.closeDialog();
    } else if (actualizeTemplate.error) {
      createToast({
        title: `${getLiteral('WersjaRoboczaNieMoglaZostacUtworzona')}!`,
        message: actualizeTemplate.error.message,
        context: 'error',
      });
    }
  }, [actualizeTemplate.response, actualizeTemplate.error]);

  useEffect(() => {
    getComponentsToUpdate.execute({ id, version });
  }, [id, version]);

  const [duplicated, setDuplicated] = useState(false);

  const { openDialog, closeDialog, dialog } = useDialog({
    title: getLiteral('NazwaKompozycji'),
    content: <CopyTeamplateForm id={id} description={description} onSuccesCallback={setDuplicated} version={version} />,
  });

  useEffect(() => {
    if (duplicated) {
      closeDialog();
      setDuplicated(false);
    }
  }, [duplicated]);

  const onTemplateNameClick = useCallback(() => {
    navigator.clipboard.writeText(id);
    createToast({
      title: `${getLiteral('SkopiowanoDoSchowka')}!`,
      message: getLiteral('IdentyfikatorKompozycjiZostaloSkopiowaneDoSchowka'),
      context: 'success',
      delay: 3,
    });
  }, [id]);

  const actualizeTemplateDialog = useDialog({
    title: getLiteral('ZaznaczKomponentyDoAktualizacji'),
    content: (
      <ActualizeTemplateDialog options={componentsToUpdate} id={id} version={version} fetch={actualizeTemplate} />
    ),
    styles: css`
      .MuiDialog-paper,
      .MuiDialogContent-root {
        width: 60vw;
        max-width: 1400px;
        height: 700px;
      }
    `,
  });

  const actualizeTemplateButton = () => {
    return (
      <>
        <StyledAction loading={actualizeTemplate.loading} onClick={actualizeTemplateDialog.openDialog}>
          {getLiteral('AktualizujKompozycje')}
          <ComponentsToUpdate title={getLiteral('LiczbaKomponentowKtoreWymagajaAktualizacji')}>
            {componentsToUpdate.length}
          </ComponentsToUpdate>
        </StyledAction>
        {actualizeTemplateDialog.dialog}
      </>
    );
  };

  return (
    <StyledDiv>
      <Marginer bottom={2}>
        {image ? (
          <StyledInfoImg>
            <Preview src={image} />
          </StyledInfoImg>
        ) : null}
        <StyledInfo>
          <span>{getLiteral('NazwaKompozycji')}:</span>
        </StyledInfo>
        <StyledInfo onClick={onTemplateNameClick}>
          <strong>{`${name} v.${parentVersion ? parentVersion : version}`}</strong>
        </StyledInfo>
        <StyledInfo>
          <span>{getLiteral('Zaaktulizowano')}:</span>
        </StyledInfo>
        <StyledInfo>
          <strong>{`${lastModificationDate} ${getLiteral('Przez')} ${createUser}`}</strong>
        </StyledInfo>
      </Marginer>
      {isDraft === 'n' ? (
        <Operations>
          <div className="mb-1">
            <strong>{getLiteral('OperacjeNaKompozycji')}:</strong>
          </div>
          {componentsToUpdate.length > 0 && type !== 'standard' ? actualizeTemplateButton() : null}
          <Action onClick={openDialog}>{getLiteral('KopiujKompozycje')}</Action>
          {type !== 'standard' ? (
            <>
              <WarningAction onClick={openRemoveAlert}>
                {getLiteral('UsunKompozycje')}
                <span></span>
              </WarningAction>
            </>
          ) : null}
        </Operations>
      ) : (
        <>
          {componentsToUpdate.length > 0 && (type !== 'standard' || canEditStandardDesigns.condition) ? (
            <Operations>
              <div className="mb-1">
                <strong>{getLiteral('OperacjeNaKompozycji')}:</strong>
              </div>
              {actualizeTemplateButton()}
            </Operations>
          ) : null}
        </>
      )}

      <Operations>
        <div className="mb-1">
          <strong>{getLiteral('Legenda')}:</strong>
        </div>
        <div>
          <StyledInfo>
            <LegendFlex>
              <LegendIcon>
                <FontAwesomeIcon icon={faEye} />
              </LegendIcon>
              <LegendSpan>{getLiteral('KomponentWidoczny')}</LegendSpan>
            </LegendFlex>
          </StyledInfo>
          <StyledInfo>
            <LegendFlex>
              <LegendIcon>
                <FontAwesomeIcon icon={faEyeSlash} />
              </LegendIcon>
              <LegendSpan>{getLiteral('KomponentNieWidoczny')}</LegendSpan>
            </LegendFlex>
          </StyledInfo>
          <StyledInfo>
            <LegendFlex>
              <LegendIcon>
                <FontAwesomeIcon icon={faRetweet} />
              </LegendIcon>
              <LegendSpan>{getLiteral('WymianaKomponentu')}</LegendSpan>
            </LegendFlex>
          </StyledInfo>
          <StyledInfo>
            <LegendFlex>
              <LegendIcon>
                <FontAwesomeIcon icon={faEdit} />
              </LegendIcon>
              <LegendSpan>{getLiteral('EdycjaKomponentu')}</LegendSpan>
            </LegendFlex>
          </StyledInfo>
          <StyledInfo>
            <LegendFlex>
              <LegendIcon>
                <FontAwesomeIcon icon={faSync} />
              </LegendIcon>
              <LegendSpan>{getLiteral('AktualizacjaKomponentu')}</LegendSpan>
            </LegendFlex>
          </StyledInfo>
          {(composerSubscriptionLevel === COMPOSER_SUBSCRIPTION_LEVEL_PRO ||
            canEditStandardComponents.condition ||
            isSupport) && (
            <StyledInfo>
              <LegendFlex>
                <LegendIcon>
                  <FontAwesomeIcon icon={faTimes} />
                </LegendIcon>
                <LegendSpan>{getLiteral('UsuniecieKomponentu')}</LegendSpan>
              </LegendFlex>
            </StyledInfo>
          )}
          <StyledInfo>
            <LegendFlex>
              <LegendIcon>
                <FontAwesomeIcon icon={faArrowsAltV} />
              </LegendIcon>
              <LegendSpan>{getLiteral('ZmianaPozycjiKomponentu')}</LegendSpan>
            </LegendFlex>
          </StyledInfo>
        </div>
      </Operations>
      <Operations>
        <div className="mb-1">
          <strong>{getLiteral('PrzydatneLinki')}:</strong>
        </div>
        <div>
          <PanelLink href={`${user.domain}/panel/color-schemes.php`} target="_blank">
            {getLiteral('SchematyKolorystyczne')}
          </PanelLink>
        </div>
        <div>
          <PanelLink href={`${user.domain}/panel/designs.php`} target="_blank">
            {getLiteral('ZarzadzanieSzablonamiStrony')}
          </PanelLink>
        </div>
      </Operations>
      {dialog}
    </StyledDiv>
  );
};

const Action = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:before {
    content: '>';
    margin-right: 1rem;
  }
`;

const StyledAction = styled(Action)`
  ${({ loading }) =>
    loading === true &&
    css`
      pointer-events: none;
    `}

  span {
    margin-left: 5px;

    ${({ loading }) =>
      loading === true &&
      css`
        color: transparent;
        pointer-events: none;
        position: relative;
        background-color: transparent;

        ${SmallLoaderAfter}

        &:after {
          border-color: #000 transparent #000 transparent;
          top: 0;
          left: 0;
        }
      `}
  }
`;

const WarningAction = styled(StyledAction)`
  color: #ca0f0c;
`;

const ComponentsToUpdate = styled.span`
  background: #e3e3e3;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #6e6e6e;
  display: inline-block;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
  width: 2rem;

  font-size: 1rem;
`;

const LegendFlex = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const LegendSpan = styled.span`
  &:before {
    content: '-';
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const LegendIcon = styled.div`
  min-width: 20px;
  display: flex;
  justify-content: center;
`;

const Preview = styled.img`
  width: 100%;
  display: block;
`;

const PanelLink = styled.a`
  width: 100%;
  padding: 0;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
  margin-bottom: 0.5rem;

  &:before {
    content: '>';
    display: inline-block;
    margin-right: 1rem;
    text-decoration: none;
  }
`;

const StyledInfo = styled.div`
  margin-bottom: 1rem;

  div {
    margin-bottom: 0.5rem;

    &.mb-1 {
      margin-bottom: 1rem;
    }
  }

  p {
    font-style: italic;
  }
`;

const StyledInfoImg = styled(StyledInfo)`
  min-height: 10vh;
`;

const Operations = styled(StyledInfo)`
  margin-top: 2vh;
`;

const StyledDiv = styled.div`
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;

  strong {
    font-weight: 1.8rem;
    text-align: right;
  }

  div {
    font-size: 1.4rem;
  }

  p {
    font-size: 1.3rem;
  }
`;
