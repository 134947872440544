import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';

export const ClearableFilter = ({ onRemove, name, translatedName, value }) => {
  if (!value) {
    return null;
  }

  return (
    <RemoveButton type="button" onClick={() => onRemove(name)}>
      <FontAwesomeIcon icon={faTimes} />
      <span>{translatedName}: </span>
      <strong>"{value}"</strong>
    </RemoveButton>
  );
};

const RemoveButton = styled(Button)`
  height: 3.6rem;
  padding: 1.5rem;
  font-size: 1.4rem;
  background-color: var(--gray);
  color: var(--black);
  margin-right: 1rem;
  white-space: nowrap;

  svg {
    color: var(--gray-dark);
  }

  > span {
    margin-right: 0.5rem;
  }
`;
