import styled, { css } from 'styled-components';
import { useContext } from 'react';

import { composerCxt } from 'contexts/ComposerContext';

export const Tag = ({ children, type, text, title }) => {
  const { getLiteral } = useContext(composerCxt);

  if (!children && text) {
    children = type;
  }

  if (children === 'custom' || children === 'custom-std') {
    children = getLiteral('wlasny');
  }

  if (children === 'individual') {
    children = getLiteral('Indywidualny');
  }

  return (
    <StyledTag type={type} title={title}>
      {children}
    </StyledTag>
  );
};

export const Tags = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  align-items: flex-start;
  z-index: 99;
`;

export const StyledTag = styled.span`
  text-transform: capitalize;
  padding: 8px 10px;
  font-size: 1.1rem;
  line-height: 2.4rem;
  font-weight: normal;
  margin-right: 0.5rem;
  height: 27px;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  fill: #fff;
  white-space: nowrap;
  & > svg {
    margin-right: 0.5rem;
  }
  span.svg-icon {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 1.5rem;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: block;
      path {
        fill: currentColor;
      }
    }
  }

  ${({ type }) => {
    switch (type) {
      case 'custom':
      case 'individual':
      case 'custom-std':
        return css`
          border-color: var(--customColorBorder);
          background-color: var(--customColorBg);
          color: var(--customColor);
          fill: var(--customColor);
        `;
      case 'standard':
      case 'model':
        return css`
          border-color: var(--standardColorBorder);
          background-color: var(--standardColorBg);
          color: var(--standardColor);
          fill: var(--standardColor);
        `;
      case 'draft':
        return css`
          border-color: var(--draftColorBorder);
          background-color: var(--draftColorBg);
          color: var(--draftColor);
          fill: var(--draftColor);
        `;
      case 'lite':
        return css`
          border-color: var(--liteColorBorder);
          background-color: var(--liteColorBg);
          color: var(--liteColor);
          fill: var(--liteColor);
        `;
      case 'pro':
        return css`
          border-color: var(--ptoColorBorder);
          background-color: var(--proColorBg);
          color: var(--proColor);
          fill: var(--proColor);
        `;
      case 'ttf':
      case 'eot':
      case 'woff':
      case 'woff2':
        return css`
          background-color: var(--green);
          color: #fff;
        `;
      case 'json':
        return css`
          background-color: #d5ae15;
          color: #fff;
        `;
      case 'jpg':
      case 'png':
      case 'webp':
      case 'svg':
      case 'gif':
        return css`
          background-color: #7b50c1;
          color: #fff;
        `;
      case 'mod':
        return css`
          background-color: #7b50c1;
          color: #fff;
        `;
      case '':
        return css`
          display: none;
        `;
      default:
        return css`
          background-color: #7b50c1;
        `;
    }
  }}
`;

export const ExtensionTag = styled(Tag)``;
