/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';

import { useFetch } from 'utils/useFetch';
import { useAlert } from 'utils/useAlert';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { Select } from 'components/atoms/Form/Select';
import { Marginer, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { RadioButton } from 'components/atoms/Form/RadioButton';
import { Loader } from 'components/atoms/Loader';
import { Button } from 'components/atoms/Button';
import { API_TRANSLATION_COSTS, API_TRANSLATION_LANGS, API_TRANSLATION_TRANSLATE } from 'helpers/endpoints';

export const AutoTranslationDialogComponent = ({ source, target }) => {
  const TRANSLATION_SRV = 'simpleTranslation';
  const TRANSLATION_PACKAGE_SRV = 'packageTranslation';

  const { getLiteral, languages } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const [sourceLang, setSourceLang] = useState(source);
  const [targetLang, setTargetLang] = useState(target);
  const [isLangInQueue, setIsLangInQueue] = useState(false);
  const [isValidate, setIsValidate] = useState();
  const [areTextsToTranslate, setAreTextsToTranslate] = useState();
  const [isSupported, setIsSupported] = useState();
  const [translationCost, setTranslationCost] = useState(0);
  const [translationPackageCost, setTranslationPackageCost] = useState(0);

  const postTranslationRequest = useFetch(API_TRANSLATION_TRANSLATE, 'POST');
  const getTranslationCosts = useFetch(API_TRANSLATION_COSTS, 'POST');
  const postIsSupportedLangs = useFetch(API_TRANSLATION_LANGS, 'POST');

  const { control, watch, setValue } = useForm({});

  const watchTranslationService = watch('translationService');

  const { openAlert, alert } = useAlert({
    onAccept: () => {
      postTranslationRequest.execute({
        sourceLang: sourceLang,
        targetLang: targetLang,
        serviceId: watchTranslationService,
      });
    },
    text: getLiteral('CzyJestesPewienZeChceszUruchomicAutomatyczneTlumaczenie'),
  });

  useEffect(() => {
    setIsValidate(sourceLang && targetLang && watchTranslationService);
  });

  useEffect(() => {
    if (targetLang && targetLang !== '') {
      getTranslationCosts.execute({
        sourceLang: sourceLang,
        targetLang: targetLang,
      });
      postIsSupportedLangs.execute({
        sourceLang: sourceLang,
        targetLang: targetLang,
      });
    }
  }, [sourceLang, targetLang]);

  // Ustawia dane walidacyjne
  useEffect(() => {
    if (postIsSupportedLangs.response) {
      setIsLangInQueue(postIsSupportedLangs.response.inQueue);
      setIsSupported(postIsSupportedLangs.response.result);
    }

    if (getTranslationCosts.response) {
      setTranslationCost(getTranslationCosts.response.cost_services.cost_formatted[TRANSLATION_SRV]);
      setTranslationPackageCost(getTranslationCosts.response.cost_services.cost_formatted[TRANSLATION_PACKAGE_SRV]);

      if (
        getTranslationCosts.response.cost_services.cost[TRANSLATION_SRV] !== 0 &&
        getTranslationCosts.response.cost_services.cost[TRANSLATION_PACKAGE_SRV] !== 0
      ) {
        // mamy wyliczoną cene za tłumaczenie === mamy literały do tłumaczenia
        setAreTextsToTranslate(true);
      } else {
        setAreTextsToTranslate(false);
      }
    }
  }, [postIsSupportedLangs.response, getTranslationCosts.response, areTextsToTranslate]);
  useEffect(() => {
    if (postTranslationRequest.response) {
      if (postTranslationRequest.response) {
        setIsLangInQueue(true);
        createToast({
          delay: 5,
          title: getLiteral('OperacjaPrzebieglaPomyslnie'),
          message: getLiteral('DodanoNoweZlecenieAutomatycznegoTlumaczenia'),
          context: 'success',
        });
      }

      if (postTranslationRequest.error) {
        createToast({
          delay: 5,
          title: getLiteral('CosPoszloNieTak'),
          message: postTranslationRequest.error.message,
          context: 'error',
        });
      }
    }
  }, [postTranslationRequest.response]);

  return (
    <>
      <Wrapper>
        <WrapperChild>
          <Marginer top={0} bottom={2}>
            {typeof isSupported === 'boolean' && !isSupported && (
              <Marginer top={0}>
                <InfoLabel
                  type="danger"
                  text={getLiteral('JezykNieJestWspieranyPrzezAutomatTlumaczacy')}
                  marginBottom={false}
                />
              </Marginer>
            )}
            {typeof areTextsToTranslate === 'boolean' && !areTextsToTranslate && isSupported && !isLangInQueue && (
              <Marginer top={0}>
                <InfoLabel
                  type="warning"
                  text={getLiteral('WszystkieLiteralySaJuzPrzetlumaczone')}
                  marginBottom={false}
                />
              </Marginer>
            )}
            {typeof isLangInQueue === 'boolean' && isLangInQueue && isSupported && (
              <Marginer top={0}>
                <InfoLabel
                  type="warning"
                  text={getLiteral('TrwaAutomatyczneTlumaczenieWkrotcePonownieBedzieDostepnaEdycjaLiteralow')}
                  marginBottom={false}
                />
              </Marginer>
            )}
          </Marginer>
          <Select
            options={languages}
            defaultValue={languages.find((lang) => lang.value === sourceLang)}
            placeholder={getLiteral('WybierzZrodloTlumaczenia')}
            onChange={(option) => setSourceLang(option.value)}
          />
          <Select
            options={languages}
            defaultValue={languages.find((lang) => lang.value === targetLang)}
            placeholder={getLiteral('WybierzCelTlumaczenia')}
            onChange={(option) => setTargetLang(option.value)}
          />
          <Marginer top={0}>
            <Marginer bottom={3}>{getLiteral('UslugiTlumaczeniaAutomatycznego')}:</Marginer>
            {getTranslationCosts.loading && postIsSupportedLangs.loading ? (
              <Loader />
            ) : (
              <Controller
                name="translationService"
                control={control}
                as={
                  <NoMarginRow mb={2}>
                    <RadioButton
                      title={getLiteral('TlumaczenieStandardowe')}
                      message={translationCost}
                      value={TRANSLATION_SRV}
                      checked={watchTranslationService === TRANSLATION_SRV.toString()}
                      onChange={(e) => setValue('translationService', e.target.value)}
                      bottom={0}
                      readOnly={isLangInQueue || !isSupported || !areTextsToTranslate}
                    />
                    <RadioButton
                      title={getLiteral('TlumaczenieIAIAds')}
                      message={translationPackageCost}
                      value={TRANSLATION_PACKAGE_SRV}
                      checked={watchTranslationService === TRANSLATION_PACKAGE_SRV.toString()}
                      onChange={(e) => setValue('translationService', e.target.value)}
                      bottom={0}
                      readOnly
                    />
                  </NoMarginRow>
                }
              />
            )}
            <TranslateButton
              type="button"
              onClick={() => openAlert()}
              disabled={isLangInQueue || !isSupported || !isValidate || !areTextsToTranslate}
            >
              {getLiteral('UruchomTlumaczenie')}
            </TranslateButton>
          </Marginer>
        </WrapperChild>
      </Wrapper>
      {alert}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const WrapperChild = styled.div`
  width: 100%;
  min-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;

  ${({ border }) =>
    border &&
    css`
      border-left: 1px solid #e0e0e0;
      padding-left: 20px;
      padding-right: 0;
    `}
`;

const PublishButton = styled(Button)`
  width: 100%;
`;

const TranslateButton = styled(PublishButton)`
  background-color: #0fb108;
  color: #fff;
`;

const NoMarginRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;
