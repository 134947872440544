import { forwardRef } from 'react';
import styled from 'styled-components';

import { Group } from './';

export const Checkbox = forwardRef(({ title, bottom, ...props }, ref) => {
  return (
    <Group mb={bottom || 1}>
      <ChecboxLabel>
        <input type="checkbox" ref={ref} {...props} />
        <span>{title}</span>
      </ChecboxLabel>
    </Group>
  );
});

const ChecboxLabel = styled.label`
  display: block;

  input {
    display: none;

    &:checked + span {
      &:after {
        opacity: 1;
      }
    }
  }

  > span {
    display: flex;
    position: relative;
    align-items: center;
    user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: block;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      margin-right: 1rem;
    }

    &:after {
      content: '';
      border-radius: 3px;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      display: block;
      background-color: green;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
`;
