/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { Marginer } from 'components/atoms/Grid';
import { Loader } from 'components/atoms/Loader';
import { StyledLink, Table } from 'components/atoms/Table';
import { Tag } from 'components/atoms/Tag';
import { composerCxt } from 'contexts/ComposerContext';
import { API_TEMPLATES_DESIGNS_TO_BUILDS, EDP_TEMPLATES } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const QueueBuilds = ({ setBuildsWaiting }) => {
  const { getLiteral } = useContext(composerCxt);
  const [pagesCount, setPagesCount] = useState(0);
  const [builds, setBuilds] = useState([]);

  const { page, paginationProps, Pagination } = usePagination({
    pageLimit: pagesCount,
  });

  const getDesignsToBuild = useFetch(`${API_TEMPLATES_DESIGNS_TO_BUILDS}/page/${page}`, 'GET');

  useEffect(() => {
    if (getDesignsToBuild.response) {
      setBuilds(getDesignsToBuild.response.models);
      setPagesCount(getDesignsToBuild.response.pagesCount);
      setBuildsWaiting(getDesignsToBuild.response.models.length);
    }
  }, [getDesignsToBuild.response, getDesignsToBuild.error]);

  useEffect(() => {
    getDesignsToBuild.execute();
  }, [page]);

  return (
    <>
      {getDesignsToBuild.loading ? (
        <Loader />
      ) : builds.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>{getLiteral('nazwa')}</th>
                <th>{getLiteral('Typ')}</th>
                <th>{getLiteral('Status')}</th>
                <th>{getLiteral('OpisZmian')}</th>
                <th>{getLiteral('Autor')}</th>
                <th>{getLiteral('Zaaktulizowano')}</th>
              </tr>
            </thead>
            <tbody>
              {builds.map((el) => {
                return (
                  <tr key={`${el.id}_${el.version}`}>
                    <th>
                      <StyledLink to={`${EDP_TEMPLATES}${el.id}/${el.version}`}>link</StyledLink>
                      {`${el.name} v.${el.version}`}
                    </th>
                    <th>
                      <Tag type={el.type}>{el.type}</Tag>
                    </th>
                    <th>{!!el.startTime ? `${getLiteral('Rozpoczeto')} ${el.startTime}` : getLiteral('Oczekuje')}</th>
                    <th>{el.commitMessage}</th>
                    <th>{el.createUser}</th>
                    <th>{el.lastModificationTime}</th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Marginer bottom={2} top={2}>
            {pagesCount ? <Pagination {...paginationProps} /> : null}
          </Marginer>
        </>
      ) : (
        <span>{getLiteral('BrakSzablonowOczekujacychWKolejce')}</span>
      )}
    </>
  );
};
