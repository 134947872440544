import styled from 'styled-components';
import { useContext } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { composerCxt } from 'contexts/ComposerContext';
import { TemplateListItemCtxProvider } from 'contexts/TemplateListItemContext';
import { Table } from 'components/atoms/Table';
import { TemplateRowItem } from 'components/atoms/TemplateRowItem';

export const TemplatePublishedListTable = ({ list, listTemplates, type }) => {
  const { getLiteral } = useContext(composerCxt);

  if ((!list || !list.length) && listTemplates) {
    return <EmptyList>{getLiteral('NieZnalezionoWynikow')}</EmptyList>;
  }

  return (
    <CustomTable>
      <CustomTableHead>
        <TableRow>
          <TableCell>{getLiteral('Szablon')}</TableCell>
          <TableCell>{getLiteral('Sklepy')}</TableCell>
          <TableCell>{getLiteral('WersjeJezykowe')}</TableCell>
          <TableCell align="center">{getLiteral('Opcje')}</TableCell>
        </TableRow>
      </CustomTableHead>
      <TableBody>
        {list.map((template) => {
          return (
            <BorderedTableRow key={`${type}_${template.version}_${template.id}`}>
              <TemplateListItemCtxProvider template={template}>
                <TemplateRowItem />
              </TemplateListItemCtxProvider>
            </BorderedTableRow>
          );
        })}
      </TableBody>
    </CustomTable>
  );
};

const BorderedTableRow = styled(TableRow)`
  td {
    font-size: 1.4rem;
    line-height: 1.9rem;
    border-color: #e0e0e0;
    border-style: solid;
    border-width: 0 0 1px;
    :first-child {
      border-left-width: 1px;
    }
    :last-child {
      border-right-width: 1px;
    }
    span {
      margin-bottom: 4px;
      display: block;
    }
  }
`;

const EmptyList = styled.div`
  margin: auto;
  font-size: 2rem;
  font-weight: 700;
  padding: 3rem 0;
`;

const CustomTable = styled(Table)`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 3rem;
  border-collapse: separate;
  tbody tr {
    :hover {
      background: transparent;
      cursor: auto;
    }
  }
`;

const CustomTableHead = styled(TableHead)`
  tr th {
    background-color: #f0f0f0;
    border-color: #e0e0e0;
    border-style: solid;
    border-width: 1px 0 1px;
    color: var(--text);
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: bold;
    text-align: left;
    :first-child {
      border-radius: 10px 0 0 0;
      border-left-width: 1px;
    }
    :last-child {
      border-radius: 0 10px 0 0;
      border-right-width: 1px;
    }
  }
`;
