// APP Routes
export const EDP_DEFAULT = '/';
export const EDP_NO_ACCESS = '/noaccess';

export const EDP_TEMPLATES = '/compositions/';
export const EDP_TEMPLATES_ID = '/compositions/:id/:version?';

export const EDP_TEMPLATES_COMPARE = '/compare-compositions';
export const EDP_TEMPLATES_STANDARDS = '/standard-compositions';

export const EDP_QUEUE = '/queue';
export const EDP_REMOVED_ELEMENTS = '/removed-elements';
export const EDP_EVENTS_LOG = '/events-log';
export const EDP_MIGRATION = '/migration';
export const EDP_PULL_REQUESTS = '/pull-requests';
export const EDP_PULL_INSTALLATIONS = '/installations';
export const EDP_PULL_DOCUMENTATION = '/api/documentation/show';
export const EDP_TRANSLATION = '/translation';

export const EDP_MARKETING = '/articles/';
export const EDP_MARKETING_ID = '/articles/:article_name';

export const EDP_GROUPS = '/groups/';
export const EDP_GROUPS_ID = '/groups/:id';

export const EDP_PAGES = '/pages/';
export const EDP_PAGES_ID = '/pages/:id';

export const EDP_MODULES = '/modules/';
export const EDP_MODULES_ID = '/modules/:id/version/:version';

export const EDP_LIBRARIES = '/libraries/';
export const EDP_LIBRARIES_ID = '/libraries/:id';

export const EDP_FILES = '/files';

export const EDP_DICTIONARY = '/dictionary';

export const EDP_CONFIG = `/config`;

// API
const prefix = '/api';

export const API_USERS = `${prefix}/auth/get-logged-user/`;

export const API_COMPOSER_VERSION = `${prefix}/index/version/`;
export const API_COMPOSER_VERSION_SET_PRO = `${prefix}/index/set-version-pro/`;
export const API_COMPOSER_VERSION_SET_LITE = `${prefix}/index/set-version-lite/`;
export const API_COMPOSER_SUBSCRIPTION_PRICE = `${prefix}/index/get-subscription-price/`;

export const API_TXT = `${prefix}/txt/get-app-translations`;

export const API_PREVIEW = '/panel/composer/generatePreview.php';

export const API_TEMPLATES = `${prefix}/compositions`;
export const API_TEMPLATES_PUBLSIH = `${API_TEMPLATES}/publish`;
export const API_TEMPLATES_ADD = `${API_TEMPLATES}/add`;
export const API_TEMPLATES_EDIT = `${API_TEMPLATES}/edit`;
export const API_TEMPLATES_DELETE = `${API_TEMPLATES}/delete`;
export const API_TEMPLATES_LIST = `${API_TEMPLATES}/list`;
export const API_TEMPLATES_ID = `${API_TEMPLATES}/get/id`;
export const API_TEMPLATES_GET_VERSIONS = `${API_TEMPLATES}/get-versions`;
export const API_TEMPLATES_ADD_MODULE = `${API_TEMPLATES}/add-module`;
export const API_TEMPLATES_CAN_COMPOSITION_BE_UPDATED = `${API_TEMPLATES}/can-composition-be-updated`;
export const API_TEMPLATES_CREATE_UPDATED_DRAFT_COMPOSITION = `${API_TEMPLATES}/create-updated-draft-composition`;
export const API_TEMPLATES_MOVE_MODULE = `${API_TEMPLATES}/move-module`;
export const API_TEMPLATES_DELETE_MODULE = `${API_TEMPLATES}/delete-module`;
export const API_TEMPLATES_REPLACE_MODULE = `${API_TEMPLATES}/replace-module`;
export const API_TEMPLATES_DUPLICATE = `${API_TEMPLATES}/copy`;
export const API_TEMPLATES_HIDE_MODULE = `${API_TEMPLATES}/hide-module`;
export const API_TEMPLATES_BRANCH = `${API_TEMPLATES}/start-edit/id/`;
export const API_TEMPLATES_MERGE = `${API_TEMPLATES}/merge`;
export const API_TEMPLATES_IS_PUBLISHABLE = `${API_TEMPLATES}/is-publishable`;
export const API_TEMPLATES_COMPARE = `${API_TEMPLATES}/compare-versions`;
export const API_TEMPLATES_CHECK_NANE = `${API_TEMPLATES}/check-name`;
export const API_TEMPLATES_GET_LIST_TO_COMPARE = `${API_TEMPLATES}/get-designs-to-compare`;
export const API_TEMPLATES_GET_FILES = `${API_TEMPLATES}/get-design-graphics`;
export const API_TEMPLATES_REMOVE_FILE = `${API_TEMPLATES}/remove-design-graphic`;
export const API_TEMPLATES_REPLACE_FILE = `${API_TEMPLATES}/set-design-graphic`;
export const API_TEMPLATES_REFRESH_BUILDS = `${API_TEMPLATES}/refresh-designs-builds`;
export const API_TEMPLATES_REPLACE_COMPONENT_BY_CUSTOM_COPY = `${API_TEMPLATES}/replace-component-by-custom-copy`;
export const API_TEMPLATES_DESIGNS_TO_BUILDS = `${API_TEMPLATES}/get-designs-to-build`;
export const API_TEMPLATES_DESIGNS_BUILDS_HISTORY = `${API_TEMPLATES}/get-designs-builds-history`;
export const API_TEMPLATES_GET_CSS = `${API_TEMPLATES}/get-design-css/id/`;
export const API_TEMPLATES_GET_JS = `${API_TEMPLATES}/get-design-js/id/`;
export const API_TEMPLATES_GET_LIST_TO_RESTORE = `${API_TEMPLATES}/list-to-restore`;
export const API_TEMPLATES_RESTORE = `${API_TEMPLATES}/restore`;
export const API_TEMPLATES_GET_PUBLISHED = `${API_TEMPLATES}/get-published-designs`;
export const API_TEMPLATES_PRO_GET_PUBLISHED = `${API_TEMPLATES}/get-published-designs-pro`;
export const API_TEMPLATES_FULL_COPY = `${API_TEMPLATES}/full-copy`;
export const API_TEMPLATES_DOWNLOAD = `${API_TEMPLATES}/externalExport/id`;

export const API_MIGRATION = `${prefix}/migration`;
export const API_MIGRATION_LIST = `${API_MIGRATION}/list`;
export const API_MIGRATION_IMPORT = `${API_MIGRATION}/import`;
export const API_MIGRATION_HISTORY = `${API_MIGRATION}/history`;

export const API_GFX = `${prefix}/gfx`;
export const API_GFX_GET = `${API_GFX}/get-client-gfx`;
export const API_GFX_REMOVE = `${API_GFX}/remove-client-gfx`;
export const API_GFX_ADD = `${API_GFX}/add-client-gfx`;
export const API_GFX_GET_USAGES = `${API_GFX}/get-gfx-usages`;
export const API_GFX_GET_HISTORY = `${API_GFX}/get-history`;

export const API_TRANSLATION = `${prefix}/translation`;
export const API_TRANSLATION_COSTS = `${API_TRANSLATION}/costs`;
export const API_TRANSLATION_LANGS = `${API_TRANSLATION}/langs`;
export const API_TRANSLATION_TRANSLATE = `${API_TRANSLATION}/translate`;
export const API_TRANSLATION_GET_QUEUE = `${API_TRANSLATION}/queue`;
export const API_TRANSLATION_GET_QUEUE_HISTORY = `${API_TRANSLATION}/queue-history`;

export const API_DICTIONARY = `${prefix}/dictionaries`;
export const API_DICTIONARY_GET_TRANSLATIONS = `${API_DICTIONARY}/get-literals-translations`;
export const API_DICTIONARY_GET_LITERALS = `${API_DICTIONARY}/get-literals/lang/`;
export const API_DICTIONARY_SEARCH = `${API_DICTIONARY}/search-literals`;
export const API_DICTIONARY_ADD = `${API_DICTIONARY}/add-custom-literals`;
export const API_DICTIONARY_REMOVE = `${API_DICTIONARY}/remove-custom-literals`;
export const API_DICTIONARY_RESTORE = `${API_DICTIONARY}/restore-custom-literals`;
export const API_DICTIONARY_EXPORT = `${API_DICTIONARY}/export-literals`;
export const API_DICTIONARY_IMPORT = `${API_DICTIONARY}/import-literals`;
export const API_DICTIONARY_GET_HISTORY = `${API_DICTIONARY}/get-history`;

export const API_MODULES = `${prefix}/modules`;
export const API_MODULES_GET = `${API_MODULES}/get`;
export const API_MODULES_ADD = `${API_MODULES}/add`;
export const API_MODULES_EDIT = `${API_MODULES}/edit`;
export const API_MODULES_SEARCH = `${API_MODULES}/search`;
export const API_MODULES_DELETE = `${API_MODULES}/delete`;
export const API_MODULES_COPY = `${API_MODULES}/copy`;
export const API_MODULES_HIDE_MODULE = `${API_MODULES}/hide-module`;
export const API_MODULES_GET_GFX = `${API_MODULES}/get-gfx/id/`;
export const API_MODULES_SET_ICON = `${API_MODULES}/set-icon/`;
export const API_MODULES_START_EDIT = `${API_MODULES}/start-edit/id/`;
export const API_MODULES_MERGE = `${API_MODULES}/merge`;
export const API_MODULES_GET_VERSIONS = `${API_MODULES}/get-versions/id/`;
export const API_MODULES_BY_TYPE = `${API_MODULES}/get-by-type/name/`;
export const API_MODULES_GET_COMPONENT_DESIGNS = `${API_MODULES}/get-component-designs`;
export const API_MODULES_SET_EDITABLE = `${API_MODULES}/set-editable`;
export const API_MODULES_SET_CLIENT_READ_ONLY = `${API_MODULES}/set-client-read-only`;
export const API_MODULES_SET_RECOMMENDED_COMPONENT = `${API_MODULES}/set-recommended-component`;
export const API_MODULES_GET_TOKEN_FOR_COMPONENT = `${API_MODULES}/get-token-for-component-share/`;
export const API_MODULES_GET_COMPONENTS_FOR_DEPRECATED = `${API_MODULES}/get-components-for-deprecated/id/`;
export const API_MODULES_GET_LIST_TO_RESTORE = `${API_MODULES}/list-to-restore`;
export const API_MODULES_RESTORE = `${API_MODULES}/restore`;
export const API_MODULES_MOVE_MODULE = `${API_MODULES}/move-module`;
export const API_MODULES_REPLACE_WRAPPER_MODULE = `${API_MODULES}/replace-module`;
export const API_MODULES_ADD_MODULE_IN_WRAPPER = `${API_MODULES}/add-module`;
export const API_MODULES_DELETE_MODULE_IN_WRAPPER = `${API_MODULES}/delete-module`;

export const API_PULL_REQUESTS = `${prefix}/pullRequests`;
export const API_PULL_REQUESTS_DELETE = `${API_PULL_REQUESTS}/delete-pull-request`;
export const API_PULL_REQUESTS_GET = `${API_PULL_REQUESTS}/get-pull-requests`;
export const API_PULL_REQUESTS_SET_STATUS = `${API_PULL_REQUESTS}/set-pull-request-status`;
export const API_PULL_REQUESTS_ADD_COMMENT = `${API_PULL_REQUESTS}/add-pull-request-comment`;
export const API_PULL_REQUESTS_GET_COMMENTS = `${API_PULL_REQUESTS}/get-pull-request-comments`;

export const API_INSTALLATIONS = `${prefix}/installations`;
export const API_INSTALLATIONS_COMPONENTS = `${API_INSTALLATIONS}/pending-components`;
export const API_INSTALLATIONS_DESIGNS = `${API_INSTALLATIONS}/pending-designs`;
export const API_INSTALLATIONS_EDIT = `${API_INSTALLATIONS}/edit`;
export const API_INSTALLATIONS_CHANGE_DATE = `${API_INSTALLATIONS}/change-date`;

export const API_GROUPS = `${prefix}/groups`;
export const API_GROUPS_GET = `${API_GROUPS}/get`;
export const API_GROUPS_ADD = `${API_GROUPS}/add`;
export const API_GROUPS_EDIT = `${API_GROUPS}/edit`;
export const API_GROUPS_DELETE = `${API_GROUPS}/delete`;

export const API_PAGES = `${prefix}/pages`;
export const API_PAGES_GET = `${API_PAGES}/get`;
export const API_PAGES_GET_PAGE = `${API_PAGES}/get-page/id`;
export const API_PAGES_ADD = `${API_PAGES}/add`;
export const API_PAGES_EDIT = `${API_PAGES}/edit`;
export const API_PAGES_DELETE = `${API_PAGES}/delete/id`;
export const API_PAGES_ALLOWED_TYPES = `${API_PAGES}/allowed-modules-types/`;

export const API_LIBS = `${prefix}/libraries`;
export const API_LIBS_GET = `${API_LIBS}/list`;
export const API_LIBS_GET_ID = `${API_LIBS}/get/id`;
export const API_LIBS_UPDATE = `${API_LIBS}/edit`;
export const API_LIBS_ADD = `${API_LIBS}/add`;
export const API_LIBS_DELETE = `${API_LIBS}/delete`;
export const API_LIBS_RESTORE = `${API_LIBS}/restore/id`;

export const API_LANG_GET = `${prefix}/languages/get`;
