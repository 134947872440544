/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Select } from 'components/atoms/Form/Select';
import { Marginer } from 'components/atoms/Grid';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { SmallLoaderAfter } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { ReactComponent as DownloadIcon } from 'data/icon-download.svg';
import { API_DICTIONARY_EXPORT, API_DICTIONARY_IMPORT, API_TEMPLATES_LIST } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';
import { makeSelectOption } from 'helpers/makeSelectOption';

export const ImportExportDialogComponent = ({ source, target, importCallback, standardLiterals = false }) => {
  const { getLiteral, languages } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const [compositions, setCompositions] = useState([]);
  const [standardCompositions, setStandardCompositions] = useState([]);
  const [sourceLang, setSourceLang] = useState(source);
  const [targetLang, setTargetLang] = useState(target);
  const [filename, setFilename] = useState('');
  const [compositionId, setCompositionId] = useState(false);

  const fileRef = useRef();

  const postTranslationImport = useFetch(API_DICTIONARY_IMPORT, 'POST');
  const getListTemplatesStd = useFetch(`${API_TEMPLATES_LIST}`, 'POST');
  const getListTemplates = useFetch(`${API_TEMPLATES_LIST}`, 'POST');

  const sendFormData = () => {
    const data = new FormData();
    data.append('format', 'xliff');
    data.append('targetLanguage', target);
    data.append('file', fileRef.current.files[0]);
    data.append('standardLiterals', standardLiterals);

    return data;
  };

  let standardLiteralsQuery = standardLiterals ? '/standardLiterals/true' : '';

  useEffect(() => {
    if (postTranslationImport.error) {
      createToast({
        title: getLiteral('WystapilBladPrzyImporciePliku'),
        message: postTranslationImport.error.message,
        context: 'error',
        delay: 0,
      });
      return;
    }

    if (postTranslationImport.response) {
      importCallback();
    }
  }, [postTranslationImport.response, postTranslationImport.error]);

  useEffect(() => {
    if (getListTemplates.response?.models) {
      const models = getListTemplates.response?.models;

      setCompositions(models.map((el) => makeSelectOption(el.id, `${el.name} v.${el.version}`)));
    }
  }, [getListTemplates.response, getListTemplates.error]);

  useEffect(() => {
    if (getListTemplatesStd.response?.models) {
      const models = getListTemplatesStd.response?.models;
      setStandardCompositions(models.map((el) => makeSelectOption(el.id, `${el.name} v.${el.version}`)));
    }
  }, [getListTemplatesStd.response, getListTemplatesStd.error]);

  const maybeLoadOptions = () => {
    if (!getListTemplates.response) {
      getListTemplates.execute({
        page: -1,
        type: 'all_own',
      });

      getListTemplatesStd.execute({
        page: -1,
        type: 'standard',
      });
    }
  };

  return (
    <>
      {standardLiterals && (
        <InfoLabel
          type="info"
          text={getLiteral('EdytujeszLiteralyStandardoweZmianyBedaWidoczneUWszystkichKlientow')}
        ></InfoLabel>
      )}

      {compositionId !== false && (
        <InfoLabel
          type="info"
          text={getLiteral(
            'WybranoOpcjeEksportuLiteralowZeWskazanejKompozycjiDoPlikuEksportuTrafiaTylkoLiteralyWystepujaceWWybranejKompozycjiWKolejnosciIchWystepowaniaNaStronie',
          )}
        />
      )}
      <Wrapper>
        <WrapperChild>
          <Marginer bottom={2}>{getLiteral('ExportujPlikXliff')}</Marginer>
          <Select
            options={languages}
            defaultValue={languages.find((lang) => lang.value === source)}
            placeholder={getLiteral('WybierzZrodloTlumaczenia')}
            onChange={(option) => setSourceLang(option.value)}
          />
          <Select
            options={languages}
            defaultValue={languages.find((lang) => lang.value === target)}
            placeholder={getLiteral('WybierzCelTlumaczenia')}
            onChange={(option) => setTargetLang(option.value)}
          />
          <Select
            options={[makeSelectOption(false, getLiteral('PobierzWszystkieLiteraly'))]
              .concat(compositions)
              .concat(standardCompositions)}
            defaultValue={makeSelectOption(false, getLiteral('PobierzWszystkieLiteraly'))}
            placeholder={getLiteral('LiteralyZKompozycji')}
            autoload={false}
            isLoading={getListTemplates.loading || getListTemplatesStd.loading}
            onFocus={maybeLoadOptions}
            onChange={(option) => setCompositionId(option.value)}
          />
          {sourceLang && targetLang && (
            <ExecuteExport
              onClick={() =>
                createToast({
                  title: getLiteral('ZleconoWygenerowanieEksportuLiteralow'),
                  message: getLiteral('OperacjeMozePotrwacKilkaSekund'),
                  context: 'success',
                  delay: 10,
                })
              }
              download
              href={`${API_DICTIONARY_EXPORT}/format/xliff/sourceLang/${sourceLang}/targetLang/${targetLang}${standardLiteralsQuery}${
                compositionId ? `/compositionId/${compositionId}` : ''
              }`}
            >
              {getLiteral('PobierzTlumaczenie')}
            </ExecuteExport>
          )}
        </WrapperChild>
        <WrapperChild border>
          <Marginer bottom={2}>
            {getLiteral('ImportujPlikDlaJezyka')}:{' '}
            <strong>{languages.find((lang) => lang.value === target).label}</strong>
          </Marginer>
          <Marginer bottom="0">
            <DragDropTranslation>
              {filename ? (
                <>
                  {getLiteral('NazwaPliku')}: <strong>{filename}</strong>
                </>
              ) : (
                <DropFileTitle>
                  <DownloadIcon />
                  <div>
                    <strong>{getLiteral('PrzeciagnijTutaj')}</strong>
                    <span>{getLiteral('lubKliknijTutajAbyDodacNowyPlik')}</span>
                  </div>
                </DropFileTitle>
              )}
              <StyledInput type="file" ref={fileRef} onChange={({ target }) => setFilename(target.files[0].name)} />
            </DragDropTranslation>
          </Marginer>
          <ExecuteImport
            type="button"
            loading={postTranslationImport.loading.toString()}
            onClick={() => postTranslationImport.execute(sendFormData(), null, true)}
          >
            {getLiteral('PrzeslijTlumaczenie')}
          </ExecuteImport>
        </WrapperChild>
      </Wrapper>
    </>
  );
};

const DragDropTranslation = styled.div`
  width: 100%;
  height: 110px;
  padding: 2rem;
  border: 2px dashed #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const DropFileTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  div {
    margin-left: 10px;
  }

  strong {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  > span {
    font-size: 1.2rem;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ExecuteExport = styled.a`
  color: #fff;
  background-color: #0fb108;
  height: 50px;
  padding: 0 25px;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1.4rem;
`;

const ExecuteImport = styled.button`
  color: #fff;
  background-color: #0fb108;
  height: 50px;
  padding: 0 25px;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
  border: none;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 1.4rem;
  margin-top: auto;

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;

      ${SmallLoaderAfter}

      &:after {
        border-top: 4px solid transparent;
        border-right: 4px solid #fff;
        border-bottom: 4px solid transparent;
        border-left: 4px solid #fff;
      }
    `}
`;

const Wrapper = styled.div`
  display: flex;
`;

const WrapperChild = styled.div`
  width: 50%;
  min-width: 350px;
  text-align: center;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  ${({ border }) =>
    border &&
    css`
      border-left: 1px solid #e0e0e0;
      padding-left: 20px;
      padding-right: 0;
    `}
`;
