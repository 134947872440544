import { useContext, useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lightbox from 'react-image-lightbox';
import styled, { css } from 'styled-components';

import { pictureRegex } from 'helpers/regexp';
import { useAlert } from 'utils/useAlert';
import { useDialog } from 'utils/useDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { FileInfoDialogoComponent, FileInfoDialogStyles } from 'components/atoms/FileInfoDialogoComponent';
import { InfoLabel } from 'components/atoms/InfoLabel';
import { Loader } from 'components/atoms/Loader';
import { ExtensionTag, Tags } from 'components/atoms/Tag';
import FileSvg from 'data/icon-file.svg';

export const ManagerFileListItem = ({
  name,
  path,
  clientGfx,
  removeFile,
  isStandardFile,
  loader = false,
  replaceComponent = null,
  ...restProps
}) => {
  const picturePath = clientGfx ? `/gfx/${clientGfx.path}` : `/gfx/${path}`;
  const ext = name.split('.').slice(-1)[0];
  const { getLiteral } = useContext(composerCxt);
  const dialogFileInfo = useDialog({
    title: getLiteral('InformacjeOPliku'),
    content: (
      <FileInfoDialogoComponent
        picturePath={picturePath}
        name={name}
        isStandardFile={isStandardFile}
        {...restProps}
        {...clientGfx}
      />
    ),
    styles: FileInfoDialogStyles,
  });

  const { openAlert, alert } = useAlert({
    onAccept: () => {
      removeFile(name);
    },
    text: (
      <>
        {isStandardFile ? (
          <InfoLabel
            text={getLiteral('UsuwaszPlikStandardowyZmianyBedaWidoczneUWszystkichKlientow')}
            type="danger"
          ></InfoLabel>
        ) : (
          `${getLiteral('CzyChceszUsunacDefinitywniePlik')}?`
        )}
      </>
    ),
  });

  const canRemove = removeFile !== undefined;

  return (
    <>
      <File isRemoving={loader.toString()}>
        <StyledTags ext={ext} clientGfx={clientGfx} isStandardFile={isStandardFile} />
        <FilePicture name={name} picturePath={picturePath} />
        <Bottom>
          <Name title={name}>{name}</Name>
          <ActionWrapper>
            <FileDownload name={name} picturePath={picturePath}></FileDownload>
            {replaceComponent}
            <Action type="button" onClick={() => dialogFileInfo.openDialog()}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Action>
            {canRemove && (
              <Action type="button" onClick={openAlert}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Action>
            )}
          </ActionWrapper>
        </Bottom>
        {loader && <Loader relative />}
      </File>
      {alert}
      {dialogFileInfo.dialog}
    </>
  );
};

export const FileDownload = ({ picturePath, name }) => (
  <StyledLinkDiv>
    <DownloadLink href={`${picturePath}`} download={name}></DownloadLink>
    <FontAwesomeIcon icon={faFileDownload} />
  </StyledLinkDiv>
);

export const StyledTags = ({ ext, clientGfx, isStandardFile }) => {
  const { getLiteral } = useContext(composerCxt);

  return (
    <StyledTagsDiv>
      {ext && <ExtensionTag type={ext.toLowerCase()}>{ext}</ExtensionTag>}
      {clientGfx && <ExtensionTag type="mod">{getLiteral('ZmodyfikowanyPlik')}</ExtensionTag>}
      {isStandardFile && <ExtensionTag type={'standard'}>{'standard'}</ExtensionTag>}
    </StyledTagsDiv>
  );
};

export const FilePicture = ({ name, picturePath }) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);

  return (
    <>
      <Picture
        onClick={() => {
          if (pictureRegex.test(picturePath)) {
            setIsLightBoxOpen(true);
          }
        }}
      >
        <img
          src={pictureRegex.test(picturePath) ? picturePath : FileSvg}
          className={!pictureRegex.test(picturePath) ? 'no-bg' : ''}
          alt={name}
          loading="lazy"
        />
      </Picture>
      {isLightBoxOpen && <Lightbox onCloseRequest={() => setIsLightBoxOpen(false)} mainSrc={picturePath} />}
    </>
  );
};

const StyledTagsDiv = styled(Tags)`
  z-index: 10;
`;

export const File = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;

  > * {
    ${({ isRemoving }) =>
      isRemoving === 'true'
        ? css`
            opacity: 0.5;
          `
        : ''}
  }
`;

export const Picture = styled.div`
  margin-bottom: 1rem;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  img {
    max-height: 240px;
    max-width: 100%;
    height: auto;
    background: repeating-conic-gradient(#d0d0d0 0% 25%, transparent 0% 50%) 50% / 20px 20px;

    &.no-bg {
      background: transparent;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionWrapper = styled.div`
  display: flex;
`;

export const DownloadLink = styled.a`
  content: '';
  color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
`;

export const Name = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 1px;
`;

export const StyledLinkDiv = styled.div`
  width: 25px;
  height: 25px;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  border: none;
  color: #a7a4a4;
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: #000;
  }

  svg {
    width: 10px;
    height: 13px;
  }
`;

export const Action = styled.button`
  background-color: transparent;
  border: none;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #a7a4a4;
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: #000;
  }
`;
