import { useContext, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { Input } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { Col, Row } from 'components/atoms/Grid';
import { ClearButton } from 'components/organisms/AdvancedFilters';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { composerCxt } from 'contexts/ComposerContext';

const selectStyles = {
  container: () => ({ width: '100%', position: 'relative', boxSizing: 'border-box' }),
  control: (provided) => ({
    ...provided,
    borderColor: '#e0e0e0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const SortingComponent = ({
  setTextFilter,
  setTypeFilter,
  setSortFilter,
  typeTitle,
  typeOptions,
  className,
  bottom,
}) => {
  const { getLiteral } = useContext(composerCxt);
  const sortOptions = [
    makeSelectOption('date', getLiteral('SortujPoDacieDodania')),
    makeSelectOption('name', getLiteral('SortujPoNazwie')),
  ];
  const inputRef = useRef();

  return (
    <Row mb={bottom || 2} align="center" className={className}>
      <Col size={5}>
        {setTextFilter && (
          <Wrapper>
            <Input
              title={getLiteral('FiltrujPoNazwie')}
              bottom="0"
              ref={inputRef}
              onChange={({ target }) => setTextFilter(target.value)}
            />
            <ClearButton
              type="button"
              onClick={() => {
                setTextFilter('');
                inputRef.current.value = '';
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ClearButton>
          </Wrapper>
        )}
      </Col>
      {setTypeFilter && (
        <ToRightCol size={3}>
          <Select
            options={typeOptions}
            bottom="0"
            placeholder={typeTitle}
            isClearable
            styles={selectStyles}
            onChange={(option) => setTypeFilter(option ? option.value : '')}
          />
        </ToRightCol>
      )}
      {setSortFilter && (
        <Col size={3}>
          <Select
            bottom="0"
            placeholder={getLiteral('SortujPo')}
            options={sortOptions}
            defaultValue={sortOptions[0]}
            styles={selectStyles}
            isSearchable={false}
            onChange={(option) => setSortFilter(option ? option.value : '')}
          />
        </Col>
      )}
    </Row>
  );
};

const ToRightCol = styled(Col)`
  margin-left: auto;
`;

const Wrapper = styled.div`
  margin-right: 1rem;
  position: relative;
`;
