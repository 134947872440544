/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';

import { Input } from 'components/atoms/Form';
import { Col, Row, Marginer } from 'components/atoms/Grid';
import { SupportMark } from 'components/atoms/SupportMark';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { componentCxt } from 'contexts/ComponentContext';
import { useAccess } from 'helpers/access';
import {
  API_MODULES_GET_COMPONENTS_FOR_DEPRECATED,
  API_MODULES_SET_EDITABLE,
  API_MODULES_SET_RECOMMENDED_COMPONENT,
  API_MODULES_SET_CLIENT_READ_ONLY,
} from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { useFetch } from 'utils/useFetch';
import { userCtx } from 'contexts/UserContext';

export const ModuleFormSettings = ({ register, setValue, setIsComponentDisabled, readOnly }) => {
  const canChangeComponentEditable = useAccess('canChangeComponentEditable');
  const canSetComponentDeprecated = useAccess('canSetComponentDeprecated');
  const { isSupport } = useContext(userCtx);

  const {
    id,
    group,
    type,
    version,
    recommendedId,
    editable,
    isDraft,
    recommendedComponentName,
    jsAddTo,
    jsAddToAttributes,
    clientReadOnly,
    libraries: componentLibraries,
    visibility: componentVisibility,
  } = useContext(componentCxt);

  const postSetEditable = useFetch(`${API_MODULES_SET_EDITABLE}`, 'POST');
  const postSetClientReadOnly = useFetch(`${API_MODULES_SET_CLIENT_READ_ONLY}`, 'POST');
  const postSetDepracated = useFetch(`${API_MODULES_SET_RECOMMENDED_COMPONENT}`, 'POST');
  const getComponentsForDeprecated = useFetch(`${API_MODULES_GET_COMPONENTS_FOR_DEPRECATED}${id}`, 'GET');

  const [componentsForDeprecated, setComponentsForDeprecated] = useState([]);
  const [componentLibrariesOptions, setComponentLibrariesOptions] = useState([]);
  const [editableOption, setEditableOptions] = useState(editable);
  const [clientReadOnlyOption, setClientReadOnlyOption] = useState(clientReadOnly);
  const [jsAddToOption, setJsAddToOptions] = useState(jsAddTo);
  const [jsAddToAttributesValue, setJsAddToAttributesValue] = useState(jsAddToAttributes);

  const [excludedPages, setExcludedPages] = useState([]);

  const { createToast } = useContext(toastCtx);
  const { pagesIds, libraries, getLiteral } = useContext(composerCxt);
  const libraryOptions = libraries.map((el) => makeSelectOption(el.id, el.name));

  useEffect(() => {
    setJsAddToOptions(jsAddTo);
  }, [jsAddTo]);

  useEffect(() => {
    const libs = componentLibraries
      ? componentLibraries
          .map((library) => {
            // Get library from prefetched data
            const data = libraries.find((lib) => lib.id === library);

            // If for some reason library with the given id doesn't exist, return null
            // And then filter array from null values
            if (!data) {
              return null;
            }

            // Return select option
            return data;
          })
          .filter((el) => !!el)
      : [];

    setComponentLibrariesOptions(libs.map((el) => makeSelectOption(el.id, el.name)));
    setJsAddToOptions(jsAddTo);
    setEditableOptions(editable);
    setClientReadOnlyOption(clientReadOnly);

    const visibility = componentVisibility
      ? componentVisibility.map((pageId) => {
          const pageData = pagesIds.find((page) => page.id === pageId);
          if (!pageData) {
            return null;
          }

          return makeSelectOption(pageData.id, pageData.description);
        })
      : [];
    setExcludedPages(visibility);
  }, [id, version]);

  useEffect(() => {
    if (getComponentsForDeprecated.response) {
      setComponentsForDeprecated(getComponentsForDeprecated.response.map((el) => makeSelectOption(el.id, el.name)));
    } else if (getComponentsForDeprecated.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: `${getComponentsForDeprecated.error.message}.`,
        context: 'error',
      });
    }
  }, [getComponentsForDeprecated.response, getComponentsForDeprecated.error]);

  useEffect(() => {
    if (postSetDepracated.response) {
      createToast({
        title: getLiteral('zapisanoZmiany'),
        message: postSetDepracated.response.isDeprecated
          ? getLiteral('KomponentZostalOznaczonyJakoWycofywany')
          : getLiteral('KomponentZostalOznaczonyJakoAktualny'),
        context: 'success',
      });
    } else if (postSetDepracated.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: `${postSetDepracated.error.message}.`,
        context: 'error',
      });
    }
  }, [postSetDepracated.response, postSetDepracated.error]);

  useEffect(() => {
    if (postSetEditable.response) {
      createToast({
        title: getLiteral('zapisanoZmiany'),
        context: 'success',
      });
    } else if (postSetEditable.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: `${postSetEditable.error.message}.`,
        context: 'error',
      });
    }
  }, [postSetEditable.response, postSetEditable.error]);

  useEffect(() => {
    if (postSetClientReadOnly.response) {
      createToast({
        title: getLiteral('zapisanoZmiany'),
        context: 'success',
      });
    } else if (postSetClientReadOnly.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: `${postSetClientReadOnly.error.message}.`,
        context: 'error',
      });
    }
  }, [postSetClientReadOnly.response, postSetClientReadOnly.error]);

  const pages = pagesIds.map((item) => makeSelectOption(item.id, item.description));
  const yesNoOptions = [makeSelectOption('y', getLiteral('tak')), makeSelectOption('n', getLiteral('nie'))];

  const localJsLoadOptions = [
    makeSelectOption('footer', getLiteral('Stopka')),
    makeSelectOption('head', getLiteral('Naglowek')),
    makeSelectOption('after_shop_js', getLiteral('PoPlikuShopJs')),
    makeSelectOption('custom', getLiteral('SamodzielneOsadzeniePliku')),
    makeSelectOption('n', getLiteral('BrakWydzielonegoPliku')),
  ];

  register('jsAddTo');
  register('jsAddToAttributes');
  register('libraries');
  register('visibility');
  register('editable');
  register('clientReadOnly');

  const maybeLoadOptions = () => {
    if (!getComponentsForDeprecated.response) {
      getComponentsForDeprecated.execute();
    }
  };

  return (
    <Wrapper>
      <Row>
        {isSupport && (
          <Col size={9}>
            <Marginer bottom={2}>
              <Heading>
                {getLiteral('KomponentReadOnly')}
                <SupportMark />
              </Heading>
              <Select
                onChange={(item) => {
                  if (isDraft === 'y') {
                    setValue('clientReadOnly', item.value);
                  } else {
                    postSetClientReadOnly.execute({ id: id, clientReadOnly: item.value });
                  }
                  setClientReadOnlyOption(item.value);
                }}
                isDisabled={readOnly ? 1 : 0}
                options={yesNoOptions}
                value={yesNoOptions.find((el) => el.value === clientReadOnlyOption)}
              />
            </Marginer>
          </Col>
        )}
        {canChangeComponentEditable.condition && type === 'custom' && (
          <Col size={9}>
            <Marginer bottom={2}>
              <Heading>
                {getLiteral('KlientMozeEdytowacKomponent')}
                <SupportMark />
              </Heading>
              <Select
                onChange={(item) => {
                  if (isDraft === 'y') {
                    setValue('editable', item.value);
                  } else {
                    postSetEditable.execute({ id: id, editable: item.value });
                  }
                  setEditableOptions(item.value);
                }}
                options={yesNoOptions}
                value={yesNoOptions.find((el) => el.value === editableOption)}
              />
            </Marginer>
          </Col>
        )}
        {isSupport && type === 'standard' && isDraft === 'n' && (
          <Col size={9}>
            <Marginer bottom={2}>
              <Heading>
                {getLiteral('KomponentRekomendowanyWMiejsceWycofywanegoKomponentu')}
                <SupportMark />
              </Heading>
              <Select
                onChange={(item) => {
                  postSetDepracated.execute({ id: id, recommendedId: item.value === 'null' ? null : item.value });
                  setIsComponentDisabled(item.value !== 'null');
                }}
                isDisabled={canSetComponentDeprecated.condition ? 0 : 1}
                autoload={false}
                isLoading={getComponentsForDeprecated.loading}
                onFocus={maybeLoadOptions}
                options={[makeSelectOption('null', getLiteral('KomponentNieJestWycofywany'))].concat(
                  componentsForDeprecated,
                )}
                defaultValue={
                  recommendedId
                    ? makeSelectOption(recommendedId, recommendedComponentName)
                    : makeSelectOption('null', getLiteral('KomponentNieJestWycofywany'))
                }
              />
            </Marginer>
          </Col>
        )}
        <Col size={9}>
          <Marginer bottom={2}>
            <Heading>{getLiteral('BibliotekiJavascriptDolaczoneDoKomponentu')}</Heading>
            <Select
              isDisabled={readOnly ? 1 : 0}
              name="libraries"
              isMulti
              options={libraryOptions}
              placeholder={getLiteral('BrakDolaczonychBibliotekJavascript')}
              onChange={(items) => {
                setValue(
                  'libraries',
                  items.map((el) => el.value),
                );
                setComponentLibrariesOptions(items);
              }}
              value={componentLibrariesOptions}
            />
          </Marginer>
        </Col>
        <Col size={9}>
          <Marginer bottom={2}>
            <Heading>{getLiteral('KolejnoscLadowaniaWydzielonegoKoduJavascript')}</Heading>
            <Select
              isDisabled={readOnly ? 1 : 0}
              name="jsAddTo"
              options={localJsLoadOptions}
              onChange={(item) => {
                setValue('jsAddTo', item.value);
                setJsAddToOptions(item.value);
              }}
              value={localJsLoadOptions.find((item) => item.value === jsAddToOption)}
            />
            {jsAddToOption === 'custom' && (
              <PlaceholderWrapper>
                {getLiteral('PlaceholderDoWstawieniaWKodzie')}: <br />
                <strong>{`<iai:container_js type="custom" container="${group}"/>`}</strong>
                <br />
                <strong>{`<iai:container_js_url type="custom" container="${group}"/>`}</strong>
              </PlaceholderWrapper>
            )}
          </Marginer>
        </Col>
        {jsAddToOption !== 'n' && (
          <Col size={9}>
            <Heading>{getLiteral('AtrybutyHTMLDoWydzielonegoPlikuJavaScript')}</Heading>
            <Input
              name="jsAddToAttributes"
              type="text"
              value={jsAddToAttributesValue}
              readOnly={readOnly ? 1 : 0}
              onChange={(e) => {
                setValue('jsAddToAttributes', e.target.value);
                setJsAddToAttributesValue(e.target.value);
              }}
            />
          </Col>
        )}
        <Col size={9}>
          <Heading>{getLiteral('WybierzStronyNaKtorychKomponentMaBycUkryty')}</Heading>
          <Select
            isDisabled={readOnly ? 1 : 0}
            isMulti
            placeholder={getLiteral('KomponentJestWidocznyNaWszystkichPodstronach')}
            onChange={(items) => {
              setValue(
                'visibility',
                items.map((el) => el.value),
              );
              setExcludedPages(items);
            }}
            options={pages}
            value={excludedPages}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ isHidden }) =>
    isHidden === 'true'
      ? css`
          display: none;
        `
      : ''}
`;

const Heading = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const PlaceholderWrapper = styled.div`
  margin-top: 1rem;
`;
