/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useHistory, useParams } from 'react-router';
import { sprintf } from 'sprintf-js';

import { Loader, LoaderBackground } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import {
  API_TEMPLATES,
  API_TEMPLATES_ID,
  API_TEMPLATES_DUPLICATE,
  API_TEMPLATES_ADD_MODULE,
  API_MODULES_ADD_MODULE_IN_WRAPPER,
  API_TEMPLATES_MOVE_MODULE,
  API_MODULES_MOVE_MODULE,
  API_TEMPLATES_DELETE_MODULE,
  API_TEMPLATES_REPLACE_MODULE,
  API_MODULES_REPLACE_WRAPPER_MODULE,
  API_TEMPLATES_BRANCH,
  API_TEMPLATES_MERGE,
  EDP_TEMPLATES,
  EDP_DEFAULT,
  API_TEMPLATES_DELETE,
  API_TEMPLATES_GET_VERSIONS,
  API_MODULES_DELETE_MODULE_IN_WRAPPER,
  API_TEMPLATES_DOWNLOAD,
} from 'helpers/endpoints';
import { useAlert } from 'utils/useAlert';
import { useFetch } from 'utils/useFetch';

export const templateCxt = createContext();

const TemplateCxtProvider = ({ children }) => {
  const { id, version } = useParams();
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const [template, setTemplate] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [styleGuide, setStyleGuide] = useState([]);
  const [templateVersions, setTemplateVersions] = useState([]);
  const [replaceData, setReplaceData] = useState({});
  const [replaceDataWrapperModule, setReplaceDataWrapperModule] = useState({});
  const [addingDataWrapperModule, setAddingDataWrapperModule] = useState({});
  const [editView, setEditView] = useState(false);
  const [deleteDataWrapperModule, setDeleteDataWrapperModule] = useState({});
  const history = useHistory();

  let queryVersion = '';
  let queryCompositionVersion = '';
  if (version) {
    queryVersion = `/version/${version}`;
    queryCompositionVersion = `/compositionVersion/${version}`;
  }

  const getVersions = useFetch(`${API_TEMPLATES_GET_VERSIONS}`, 'POST');
  const getTemplateData = useFetch(`${API_TEMPLATES_ID}/${id}${queryVersion}`, 'GET');
  const getStyleGuideModules = useFetch(
    `${API_TEMPLATES}/get-page/compositionId/${id}${queryCompositionVersion}/page/css-components`,
    'GET',
  );

  const getCreateBranch = useFetch(`${API_TEMPLATES_BRANCH}${id}${queryVersion}`, 'GET');
  const getMergeBranch = useFetch(`${API_TEMPLATES_MERGE}`, 'POST');
  const postTemplateModule = useFetch(API_TEMPLATES_ADD_MODULE, 'POST');
  const postWrapperModule = useFetch(API_MODULES_ADD_MODULE_IN_WRAPPER, 'POST');
  const postTemplateMoveModule = useFetch(API_TEMPLATES_MOVE_MODULE, 'POST');
  const postWrapperMoveModule = useFetch(API_MODULES_MOVE_MODULE, 'POST');
  const postTemplateDeleteModule = useFetch(API_TEMPLATES_DELETE_MODULE, 'POST');
  const postTemplateDeleteWrapperModule = useFetch(API_MODULES_DELETE_MODULE_IN_WRAPPER, 'POST');
  const postDuplicateTemplate = useFetch(API_TEMPLATES_DUPLICATE, 'POST');
  const postModuleReplace = useFetch(API_TEMPLATES_REPLACE_MODULE, 'POST');
  const postWrapperModuleReplace = useFetch(API_MODULES_REPLACE_WRAPPER_MODULE, 'POST');
  const discardDraftChanges = useFetch(API_TEMPLATES_DELETE, 'POST');

  // Moved out from TemplateInfo module, because on safari exist bug, where alert was hidden, when aside has scroll behavior.
  const deleteTemplate = useFetch(API_TEMPLATES_DELETE, 'POST');

  // Remove module from list
  const removeModule = (id, version, position, section, wrapperId = null, wrapperVersion = null) => {
    setDeleteDataWrapperModule({
      moduleId: id,
      section,
      wrapperId,
    });

    if (wrapperId === null) {
      postTemplateDeleteModule.execute({
        compositionId: template.id,
        container: section,
        moduleId: id,
        moduleVersion: version,
        modulePosition: position,
      });
    } else {
      postTemplateDeleteWrapperModule.execute({
        wrapperId,
        wrapperVersion,
        moduleId: id,
        moduleVersion: version,
        modulePosition: position,
        container: section,
        designId: template.id,
        designVersion: template.version,
      });
    }
  };

  // Executed execute endpoint call after the module deleting is completed
  useEffect(() => {
    if (postTemplateDeleteModule.error || postTemplateDeleteWrapperModule.error) {
      createToast({
        title: getLiteral('WystapilBladPodczasUsuwaniaKomponentu'),
        message: postTemplateDeleteModule.error.message ?? postTemplateDeleteWrapperModule.error.message,
        context: 'error',
      });
      return;
    }

    if (postTemplateDeleteModule.response) {
      createToast({
        title: sprintf(getLiteral('KomponentXZostalUsuniety'), deleteDataWrapperModule.moduleId),
        message: `${getLiteral('PomyslnieUsunietoKomponentZKompozycji')}!`,
        context: 'success',
        delay: 2,
      });

      if (deleteDataWrapperModule.section === 'css-components') {
        setStyleGuide((state) => state.filter((module) => module.id !== deleteDataWrapperModule.moduleId));
      } else {
        setTemplate((state) => ({
          ...state,
          [deleteDataWrapperModule.section]: state[deleteDataWrapperModule.section].filter(
            (module) => module.id !== deleteDataWrapperModule.moduleId,
          ),
        }));
      }
    }

    if (postTemplateDeleteWrapperModule.response) {
      createToast({
        title: sprintf(getLiteral('KomponentXZostalUsuniety'), deleteDataWrapperModule.moduleId),
        message: `${getLiteral('PomyslnieUsunietoKomponentZKompozycji')}!`,
        context: 'success',
        delay: 2,
      });

      if (deleteDataWrapperModule.section === 'css-components') {
        setStyleGuide((state) =>
          state.filter((module) => {
            if (module.id === deleteDataWrapperModule.wrapperId) {
              module.components = module.components.filter(
                (component) => component.id !== deleteDataWrapperModule.moduleId,
              );
            }

            return module;
          }),
        );
      } else {
        setTemplate((state) => {
          return {
            ...state,
            [deleteDataWrapperModule.section]: state[deleteDataWrapperModule.section].filter((module) => {
              if (module.id === deleteDataWrapperModule.wrapperId) {
                module.components = module.components.filter(
                  (component) => component.id !== deleteDataWrapperModule.moduleId,
                );
              }

              return module;
            }),
          };
        });
      }
    }
  }, [
    postTemplateDeleteModule.response,
    postTemplateDeleteModule.error,
    postTemplateDeleteWrapperModule.response,
    postTemplateDeleteWrapperModule.error,
  ]);

  const addModuleToComposition = ({ module, container }) => {
    postTemplateModule.execute({ moduleId: module, container, compositionId: id });
  };

  const addModuleToWrapper = ({ module, container, wrapperId }) => {
    setAddingDataWrapperModule({
      pageName: container,
      wrapperId,
    });

    postWrapperModule.execute({
      wrapperId,
      moduleId: module,
      container,
      designId: template.id,
      designVersion: template.version,
    });
  };

  const setAddedLanguages = (languages) => {
    const newTemplate = { ...template };
    newTemplate.languages = languages;
    setTemplate(newTemplate);
  };

  const replaceModuleInComposition = ({ oldModule, newModule, name }) => {
    setReplaceData({
      name,
      oldModule,
    });

    postModuleReplace.execute({
      oldComponentId: oldModule.id,
      oldComponentVersion: oldModule.version,
      newComponentId: newModule.id,
      designId: template.id,
      designVersion: template.version,
    });
  };

  const replaceModuleInWrapper = ({ oldModule, newModule, pageName, wrapperId, wrapperVersion }) => {
    setReplaceDataWrapperModule({
      pageName,
      oldModule,
    });

    postWrapperModuleReplace.execute({
      oldComponentId: oldModule.id,
      oldComponentVersion: oldModule.version,
      newComponentId: newModule.id,
      wrapperId,
      wrapperVersion: wrapperVersion,
      designId: template.id,
      designVersion: template.version,
    });
  };

  const removeAlert = useAlert({
    onAccept: (params) => removeModule(...params),
    text: getLiteral('TaOperacjaUsunieKomponentZKompozycji'),
  });

  const { alert, openAlert } = useAlert({
    onAccept: () => {
      deleteTemplate.execute({ id, removeParent: true });
    },
    text: getLiteral('TaOperacjaUsunieDefinitywnieKompozycjeWrazZeWszystkimiWczesniejszymiWersjami'),
  });

  const mergeAlert = useAlert({
    onAccept: () => getMergeBranch.execute(),
    text: getLiteral('StworzyszNowaWersjeSwojejKompozycji'),
  });

  const resetToCommit = useCallback(
    (versionToRedirect) => {
      if (template.isDraft === 'y') {
        discardDraftChanges.execute({ id: template.id });
        history.push(history.push(`${EDP_TEMPLATES}${template.parentId}/${versionToRedirect}`));
      } else {
        history.push(history.push(`${EDP_TEMPLATES}${template.id}/${versionToRedirect}`));
      }
    },
    [id, template],
  );

  // Callback executed when commit button will be clicked
  // Create new branch, or merge branch to master
  const commitButton = useCallback(
    (commitData) => {
      if (template.isDraft === 'y') {
        getMergeBranch.execute({ id, commitMessage: commitData.commitMessage });
      } else {
        getCreateBranch.execute();
      }
    },
    [id, template],
  );

  // function executed every drop event was called
  // Reorder lists and move elements from source to destiny list
  const onDragEnd = useCallback(
    (result) => {
      const { source, destination, type } = result;

      if (!destination) {
        return;
      }

      const isStyleGuideChange = 'css-components' === source.droppableId;
      const isStyleGuideWrapperItemChange = source.droppableId.includes('css-components');
      let removed = {};
      let srcDroppableContainer, srcDroppableWrapperId, destDroppableContainer, destDroppableWrapperId;

      if (isStyleGuideChange || isStyleGuideWrapperItemChange) {
        if (type === 'component') {
          const srcArray = [...styleGuide];
          const dstArray = [...styleGuide];

          // Get element from source array, and remove from it
          [removed] = srcArray.splice(source.index, 1);

          // put element to the destination array
          srcArray.splice(destination.index, 0, removed);

          // Update provider state
          setStyleGuide(srcArray);
        } else {
          const srcArray = [...styleGuide];

          [, srcDroppableWrapperId] = source.droppableId.split('#');
          [, destDroppableWrapperId] = destination.droppableId.split('#');

          let srcDroppableWrapperIndex = Object.keys(styleGuide).filter(function (key) {
            return styleGuide[key]['id'] === srcDroppableWrapperId;
          })[0];

          const srcWrapperChildrenArray = [...styleGuide][srcDroppableWrapperIndex]['components'];

          // Get element from source array, and remove from it
          [removed] = srcWrapperChildrenArray.splice(source.index, 1);

          // put element to the destination array
          srcWrapperChildrenArray.splice(destination.index, 0, removed);

          srcWrapperChildrenArray[source.index].changed = true;
          srcWrapperChildrenArray[destination.index].changed = true;

          srcArray[srcDroppableWrapperIndex]['components'] = srcWrapperChildrenArray;

          // Update provider state
          setStyleGuide(srcArray);
        }
      } else if (type === 'component') {
        const srcArray = template[source.droppableId];
        const dstArray = template[destination.droppableId];

        // Get element from source array, and remove from it
        [removed] = srcArray.splice(source.index, 1);

        // put element to the destination array
        dstArray.splice(destination.index, 0, removed);

        // Update provider state
        setTemplate((state) => ({
          ...state,
          [source.droppableId]: srcArray,
          [destination.droppableId]: dstArray,
        }));
      } else {
        [srcDroppableContainer, srcDroppableWrapperId] = source.droppableId.split('#');
        [destDroppableContainer, destDroppableWrapperId] = destination.droppableId.split('#');

        let srcDroppableWrapperIndex = Object.keys(template[srcDroppableContainer]).filter(function (key) {
          return template[srcDroppableContainer][key]['id'] === srcDroppableWrapperId;
        })[0];

        let destDroppableWrapperIndex = Object.keys(template[destDroppableContainer]).filter(function (key) {
          return template[destDroppableContainer][key]['id'] === destDroppableWrapperId;
        })[0];

        const srcArray = template[srcDroppableContainer][srcDroppableWrapperIndex]['components'];
        const dstArray = template[destDroppableContainer][destDroppableWrapperIndex]['components'];

        // Get element from source array, and remove from it
        [removed] = srcArray.splice(source.index, 1);

        // put element to the destination array
        dstArray.splice(destination.index, 0, removed);

        let srcContainerArray = template[srcDroppableContainer];
        let dstContainerArray = template[destDroppableContainer];

        srcContainerArray[srcDroppableWrapperIndex]['components'] = srcArray;
        dstContainerArray[destDroppableWrapperIndex]['components'] = dstArray;

        // Update provider state
        setTemplate((state) => ({
          ...state,
          [srcDroppableContainer]: srcContainerArray,
          [destDroppableContainer]: dstContainerArray,
        }));
      }

      // Call endpoint
      if (type === 'wrapperChildren') {
        postWrapperMoveModule.execute({
          wrapperId: destDroppableWrapperId,
          moduleId: removed.id,
          oldPosition: source.index + 1, //positions have indexes from 1
          newPosition: destination.index + 1,
          designId: template.id,
          designVersion: template.version,
        });
      } else {
        postTemplateMoveModule.execute({
          compositionId: template.id,
          moduleId: removed.id,
          oldPosition: source.index + 1, //positions have indexes from 1
          newPosition: destination.index + 1,
          oldContainer: source.droppableId,
          newContainer: destination.droppableId,
        });
      }
    },
    [template, styleGuide],
  );

  // Get template id from param and fetch template data from endpoint when changed
  useEffect(() => {
    getTemplateData.execute();
    getStyleGuideModules.execute();
  }, [id, version, refreshPage]);

  useEffect(() => {
    if (getVersions.response) {
      setTemplateVersions(getVersions.response);
    } else if (getVersions.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      history.push(EDP_DEFAULT);
    }
  }, [getVersions.response, getVersions.error]);

  // After a template data fetching
  useEffect(() => {
    if (getTemplateData.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      history.push(EDP_DEFAULT);
      return;
    }

    if (getTemplateData.response) {
      // Resets values, because backend returns containers as a null
      const {
        layout_top,
        layout_projector_bottom,
        layout_left,
        layout_projector,
        layout_bottom,
      } = getTemplateData.response;
      setTemplate({
        ...getTemplateData.response,
        layout_top: layout_top || [],
        layout_projector_bottom: layout_projector_bottom || [],
        layout_left: layout_left || [],
        layout_projector: layout_projector || [],
        layout_bottom: layout_bottom || [],
      });

      setEditView(getTemplateData.response.isDraft === 'y' && getTemplateData.response.allowedActions.edit === true);

      getVersions.execute({
        id: getTemplateData.response.isDraft === 'y' ? getTemplateData.response.parentId : getTemplateData.response.id,
      });
    }
  }, [getTemplateData.response, getTemplateData.error]);

  // After a style guide data fetching
  useEffect(() => {
    if (getStyleGuideModules.error) {
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getLiteral('SkontaktujSieZDzialemWsparciaAbyUzyskacDalszaPomoc'),
        context: 'error',
      });
      history.push(EDP_DEFAULT);
      return;
    }

    if (getStyleGuideModules.response) {
      setStyleGuide(getStyleGuideModules.response.page || []);
    }
  }, [getStyleGuideModules.response, getStyleGuideModules.error]);

  // Executed after the module adding is completed
  useEffect(() => {
    if (postTemplateModule.error) {
      createToast({
        title: `${getLiteral('NieMoznaDodacKomponentu')}!`,
        message: postTemplateModule.error.message,
        context: 'error',
      });
    }

    // Set component to the container
    if (postTemplateModule.response) {
      const { container } = postTemplateModule.response;
      if (container === 'css-components') {
        setStyleGuide((state) => [...state, postTemplateModule.response]);
      } else if (container === 'layout' || container === 'envelope') {
        setTemplate((state) => ({
          ...state,
          [container]: postTemplateModule.response,
        }));
      } else {
        setTemplate((state) => ({
          ...state,
          [container]: [...state[container], postTemplateModule.response],
        }));
      }
    }
  }, [postTemplateModule.response, postTemplateModule.error]);

  // Executed after the module adding to Wrapper is completed
  useEffect(() => {
    if (postWrapperModule.error) {
      createToast({
        title: `${getLiteral('NieMoznaDodacKomponentu')}!`,
        message: postWrapperModule.error.message,
        context: 'error',
      });
    }

    // Set component to the container
    if (postWrapperModule.response && addingDataWrapperModule?.wrapperId) {
      // const { container } = postWrapperModule.response;
      const container = addingDataWrapperModule.pageName;
      if (container === 'css-components') {
        setStyleGuide((state) =>
          state.map((module) => {
            if (module.id === addingDataWrapperModule.wrapperId) {
              return {
                ...module,
                components: [...module.components, postWrapperModule.response],
              };
            }

            return module;
          }),
        );
      } else if (container === 'layout' || container === 'envelope') {
        setTemplate((state) => ({
          ...state,
          [container]: postWrapperModule.response,
        }));
      } else {
        setTemplate((state) => ({
          ...state,
          [container]: state[container].map((module) => {
            if (module.id === addingDataWrapperModule.wrapperId) {
              return {
                ...module,
                components: [...module.components, postWrapperModule.response],
              };
            }

            return module;
          }),
        }));
      }

      setAddingDataWrapperModule({});
    }
  }, [postWrapperModule.response, postWrapperModule.error, addingDataWrapperModule]);

  // Effect for composition duplicating
  useEffect(() => {
    if (postDuplicateTemplate.error) {
      createToast({
        context: 'error',
        title: getLiteral('WystapilBladPodczasKopiowaniaKompozycji'),
        message: postDuplicateTemplate.error.message,
      });
      return;
    }

    if (postDuplicateTemplate.response) {
      createToast({
        context: 'success',
        title: `${getLiteral('KompozycjaSkopiowana')}!`,
        message: sprintf(getLiteral('UtworzonaKompozycjaZIdXJestGotowaDoEdycji'), postDuplicateTemplate.response.id),
        delay: 5,
      });
      history.push(`${EDP_TEMPLATES}${postDuplicateTemplate.response.id}`);
    }
  }, [postDuplicateTemplate.response, postDuplicateTemplate.error]);

  // Effect for module replacing
  useEffect(() => {
    if (postModuleReplace.error) {
      createToast({
        context: 'error',
        title: getLiteral('WystapilBladPodczasUsuwaniaKomponentu'),
        message: postModuleReplace.error.message,
      });
      return;
    }

    if (postModuleReplace.response) {
      createToast({
        context: 'success',
        title: `${getLiteral('KomponentWymieniony')}!`,
        message: `${getLiteral('PomyslnieWymienionoKomponent')}!`,
        delay: 5,
      });

      if (replaceData.name === 'css-components') {
        setStyleGuide((state) =>
          state.map((module) => {
            if (module.id === replaceData.oldModule.id) {
              return {
                ...postModuleReplace.response,
                replaceable: true,
                hidden: replaceData.oldModule.hidden,
                lastUpdateTime: postModuleReplace.response.last_modification_date,
              };
            }

            return module;
          }),
        );
      } else if (replaceData.name === 'layout' || replaceData.name === 'envelope') {
        setTemplate((state) => ({
          ...state,
          [replaceData.name]: {
            ...postModuleReplace.response,
            replaceable: true,
            hidden: replaceData.oldModule.hidden,
          },
        }));
      } else {
        setTemplate((state) => ({
          ...state,
          [replaceData.name]: state[replaceData.name].map((module) => {
            if (module.id === replaceData.oldModule.id) {
              return {
                ...postModuleReplace.response,
                replaceable: true,
                hidden: replaceData.oldModule.hidden ?? 'n',
                lastUpdateTime: postModuleReplace.response.last_modification_date,
              };
            }

            return module;
          }),
        }));
      }
    }
  }, [postModuleReplace.response, postModuleReplace.error]);

  // Effect for module replacing in Wrapper
  useEffect(() => {
    if (postWrapperModuleReplace.error) {
      createToast({
        context: 'error',
        title: getLiteral('WystapilBladPodczasUsuwaniaKomponentu'),
        message: postWrapperModuleReplace.error.message,
      });
      return;
    }

    if (postWrapperModuleReplace.response) {
      createToast({
        context: 'success',
        title: `${getLiteral('KomponentWymieniony')}!`,
        message: `${getLiteral('PomyslnieWymienionoKomponent')}!`,
        delay: 5,
      });

      if (replaceDataWrapperModule.pageName === 'css-components') {
        setStyleGuide((state) =>
          state.map((module) => {
            if (module.id === replaceDataWrapperModule.oldModule.wrapperId) {
              const updatedWrapperComponents = module.components.map((component) => {
                if (component.id === replaceDataWrapperModule.oldModule.id) {
                  return {
                    ...postWrapperModuleReplace.response,
                    replaceable: true,
                    hidden: replaceDataWrapperModule.hidden,
                    lastUpdateTime: postWrapperModuleReplace.response.lastModificationDate,
                  };
                }

                return component;
              });

              module.components = updatedWrapperComponents;
            }

            return module;
          }),
        );
      } else if (replaceDataWrapperModule.name === 'layout' || replaceDataWrapperModule.name === 'envelope') {
        setTemplate((state) => ({
          ...state,
          [replaceDataWrapperModule.name]: {
            ...postWrapperModuleReplace.response,
            replaceable: true,
            hidden: replaceDataWrapperModule.oldModule.hidden,
          },
        }));
      } else {
        setTemplate((state) => ({
          ...state,
          [replaceDataWrapperModule.pageName]: state[replaceDataWrapperModule.pageName].map((module) => {
            if (module.id === replaceDataWrapperModule.oldModule.wrapperId) {
              const updatedWrapperComponents = module.components.map((component) => {
                if (component.id === replaceDataWrapperModule.oldModule.id) {
                  return {
                    ...postWrapperModuleReplace.response,
                    replaceable: true,
                    hidden: replaceDataWrapperModule.oldModule.hidden ?? 'n',
                    lastUpdateTime: postWrapperModuleReplace.response.lastModificationDate,
                  };
                }

                return component;
              });

              module.components = updatedWrapperComponents;
            }

            return module;
          }),
        }));
      }
    }
  }, [postWrapperModuleReplace.response, postWrapperModuleReplace.error]);

  // Creating branch for editing
  useEffect(() => {
    if (getCreateBranch.response) {
      createToast({
        title: `${getLiteral('WersjaRoboczaZostalaUtworzona')}!`,
        message: `${getLiteral('JestesWTrybieEdycjiKompozycji')}.`,
        context: 'success',
      });
      history.push(`${EDP_TEMPLATES}${getCreateBranch.response.id}`);
    } else if (getCreateBranch.error) {
      createToast({
        title: `${getLiteral('WersjaRoboczaNieMoglaZostacUtworzona')}!`,
        message: getCreateBranch.error.message,
        context: 'error',
      });
    }
  }, [getCreateBranch.response, getCreateBranch.error]);

  // Discarding changes
  useEffect(() => {
    if (discardDraftChanges.response) {
      createToast({
        title: `${getLiteral('ZmianyZostalyOdrzucone')}!`,
        message: getLiteral('TwojaWersjaRoboczaZostalaPomyslnieOdrzuconaWidziszTerazOryginalnaRewizje'),
        context: 'success',
      });
    }
  }, [discardDraftChanges.response]);

  // Merging branch into master
  useEffect(() => {
    if (getMergeBranch.response) {
      createToast({
        title: `${getLiteral('zapisanoZmiany')}!`,
        message: getLiteral('TwojeZmianyZostalyPomyslnieZapisaneUtworzylesNowaRewizjeSwojejKompozycji'),
        context: 'success',
      });
      history.push(`${EDP_TEMPLATES}${getMergeBranch.response.id}`);
    } else if (getMergeBranch.error) {
      createToast({
        title: `${getLiteral('CosPoszloNieTak')}!`,
        message: getMergeBranch.error.message,
        context: 'error',
      });
    }
  }, [getMergeBranch.response, getMergeBranch.error]);

  // Remove Template
  useEffect(() => {
    if (deleteTemplate.response) {
      createToast({
        title: `${getLiteral('KompozycjaUsunieta')}!`,
        message: getLiteral('AbyPrzywrocicKompozycjeSkontaktujSieZSupportem'),
        context: 'success',
        delay: 3,
      });
      history.push(EDP_DEFAULT);
    }
  }, [deleteTemplate.response]);

  return (
    <templateCxt.Provider
      value={{
        ...template,
        setRefreshPage,
        refreshPage,
        styleGuide,
        editView,
        removeModule: removeAlert.openAlert,
        addModuleToComposition,
        replaceModuleInComposition,
        replaceModuleInWrapper,
        addModuleToWrapper,
        duplicate: postDuplicateTemplate.execute,
        createBranch: getCreateBranch.execute,
        mergeBranch: getCreateBranch.execute,
        commitButton,
        resetToCommit,
        templateVersions,
        setAddedLanguages,
        openRemoveAlert: openAlert,
      }}
    >
      {!template ? <Loader /> : <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>}
      {postTemplateModule.loading ||
      postDuplicateTemplate.loading ||
      getMergeBranch.loading ||
      discardDraftChanges.loading ||
      getCreateBranch.loading ? (
        <LoaderBackground content="true">
          <Loader relative="true" />
        </LoaderBackground>
      ) : null}
      {removeAlert.alert}
      {mergeAlert.alert}
      {alert}
    </templateCxt.Provider>
  );
};

export default TemplateCxtProvider;
