/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { AssetsDividingRadioGroup } from 'components/atoms/AssetsDividingRadioGroup';
import { Button } from 'components/atoms/Button';
import { Input, PictureDropZone, Textarea } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { Col, Container, Marginer, Row } from 'components/atoms/Grid';
import { composerCxt } from 'contexts/ComposerContext';
import { templateCxt } from 'contexts/TemplateContext';
import { toastCtx } from 'contexts/ToastContext';
import { countryToFlag } from 'helpers/countryToFlag';
import { API_TEMPLATES_ADD, API_TEMPLATES_DUPLICATE, API_TEMPLATES_EDIT, API_TEMPLATES_ID } from 'helpers/endpoints';
import { makeSelectOption } from 'helpers/makeSelectOption';
import { useFetch } from 'utils/useFetch';
import { userCtx } from 'contexts/UserContext';

const selectStyles = {
  container: (base) => ({ ...base, width: '100%', position: 'relative', boxSizing: 'border-box' }),
  multiValueRemove: (base, state) => (state.data.isDisabled ? { ...base, display: 'none' } : base),
};

const assetDividingStrategyCSSName = 'assetDividingStrategyCss';
const assetDividingStrategyJSName = 'assetDividingStrategyJs';

// Creating composiitons form
export const TemplateSettings = () => {
  const { createToast } = useContext(toastCtx);
  const { languages, languagesWithIso } = useContext(composerCxt);
  const template = useContext(templateCxt);
  const { setAddedLanguages, editView } = useContext(templateCxt);
  const { getLiteral } = useContext(composerCxt);
  const { isSupport } = useContext(userCtx);

  const [copyId] = useState();
  const [assetDividingStrategyJS, setAssetDividingStrategyJS] = useState(template.assetDividingStrategyJs);
  const [assetDividingStrategyJSAttributes, setAssetDividingStrategyJSAttributes] = useState(
    template.assetDividingStrategyJsAttributes,
  );

  const [assetDividingStrategyCSS, setAssetDividingStrategyCSS] = useState(template.assetDividingStrategyCss);
  const [assetDividingStrategyCSSAttributes, setAssetDividingStrategyCSSAttributes] = useState(
    template.assetDividingStrategyCssAttributes,
  );
  const getTemplateData = useFetch(`${API_TEMPLATES_ID}/${copyId}`, 'GET');
  const postDuplicateTemplate = useFetch(API_TEMPLATES_DUPLICATE, 'POST');
  const postTemplate = useFetch(API_TEMPLATES_ADD, 'POST');
  const postTemplateEdit = useFetch(API_TEMPLATES_EDIT, 'POST');

  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues: {
      ...template,
      languages: template.languages
        ? template.languages.map(({ id, name, iso6391 }) =>
            makeSelectOption(id, `${iso6391 ? `${countryToFlag(iso6391)} ` : ''}${name}`),
          )
        : [],
    },
  });

  // Watching data
  const watchTemplatePreview = watch('image');
  const watchTemplateLanguages = watch('languages');

  // Submit function
  const onSubmit = async (formData) => {
    const body = {
      ...formData,
      languages: formData.languages ? formData.languages.map((lang) => lang.value) : [],
    };

    if (copyId) {
      body.id = copyId;
      postDuplicateTemplate.execute(body);
      return;
    }

    if (template.id) {
      body.id = template.id;
    }

    if (template.id) {
      postTemplateEdit.execute(body);
      return;
    }

    postTemplate.execute(body);
  };

  useEffect(() => {
    const newLanguages = watchTemplateLanguages.map((lang) => {
      const currentLang = languagesWithIso.find((el) => el.id === lang.value);
      return { id: currentLang.id, iso6391: currentLang.iso6391, name: currentLang.name };
    });
    setAddedLanguages(newLanguages);

    if (watchTemplateLanguages.length === 1) {
      setValue(
        'languages',
        watchTemplateLanguages.map((lang) => ({ value: lang.value, label: lang.label, isDisabled: true })),
      );
    } else {
      setValue(
        'languages',
        watchTemplateLanguages.map((lang) => ({ value: lang.value, label: lang.label })),
      );
    }
  }, [watchTemplateLanguages.length]);

  useEffect(() => {
    // Get composition data
    if (copyId) {
      getTemplateData.execute();
    } else if (!template.id) {
      setValue('name', undefined);
      setValue('description', undefined);
      setAssetDividingStrategyJS('none');
      setAssetDividingStrategyCSS('none');
      setValue('image', undefined);
      setValue('languages', null);
    }
  }, [copyId]);

  useEffect(() => {
    setValue(assetDividingStrategyJSName, assetDividingStrategyJS);
  }, [assetDividingStrategyJS]);

  useEffect(() => {
    setValue(assetDividingStrategyCSSName, assetDividingStrategyCSS);
  }, [assetDividingStrategyCSS]);

  // Set template data to form hook
  useEffect(() => {
    if (getTemplateData.response) {
      const {
        name,
        description,
        assetDividingStrategyCSS,
        assetDividingStrategyCSSAttributes,
        assetDividingStrategyJS,
        assetDividingStrategyJSAttributes,
        image,
        languages,
      } = getTemplateData.response;

      setValue('name', name);
      setValue('description', description);
      setAssetDividingStrategyJS(assetDividingStrategyJS);
      setAssetDividingStrategyJSAttributes(assetDividingStrategyJSAttributes);
      setAssetDividingStrategyCSS(assetDividingStrategyCSS);
      setAssetDividingStrategyCSSAttributes(assetDividingStrategyCSSAttributes);
      setValue('image', image);
      setValue(
        'languages',
        languages.map(({ id, name, iso6391 }) =>
          makeSelectOption(id, `${iso6391 ? `${countryToFlag(iso6391)} ` : ''}${name}`),
        ),
      );
    }
  }, [getTemplateData.response]);

  // Effect, post with compositions edit
  useEffect(() => {
    if (postTemplateEdit.error) {
      createToast({
        title: getLiteral('BladWysylaniaDanych'),
        message: postTemplateEdit.error.message,
        context: 'error',
      });
      return;
    }

    if (postTemplateEdit.response) {
      createToast({
        title: getLiteral('ZapisanoDane'),
        message: getLiteral('PomyslnieZapisanoDane'),
        context: 'success',
        delay: 3,
      });
    }
  }, [postTemplateEdit.response, postTemplateEdit.error]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="image" ref={register} />
      <Container>
        <Marginer bottom={2}>
          <Input
            ref={register}
            name="name"
            type="text"
            title={getLiteral('NazwaKompozycji')}
            disabled={editView ? 0 : 1}
          />
          <Textarea ref={register} name="description" title={getLiteral('Opis')} disabled={editView ? 0 : 1} />
          <Marginer bottom={2}>
            <Controller
              as={
                <Select
                  isMulti
                  placeholder={getLiteral('WspieraneJezyki')}
                  styles={selectStyles}
                  defaultValues={watchTemplateLanguages}
                  options={languages}
                  isClearable={false}
                  isDisabled={!editView}
                />
              }
              control={control}
              name="languages"
            />
          </Marginer>
          <Marginer bottom={2}>
            {isSupport && (
              <Row>
                <Col size={6}>
                  <AssetsDividingRadioGroup
                    ref={register}
                    readOnly={!editView}
                    label={getLiteral('SposobPodzialuPlikowCSS')}
                    name={assetDividingStrategyCSSName}
                    onChange={(e) => setAssetDividingStrategyCSS(e.target.value)}
                  />
                  {assetDividingStrategyCSS !== 'none' && (
                    <Marginer top={2}>
                      <Input
                        ref={register}
                        name="assetDividingStrategyCSSAttributes"
                        type="text"
                        value={assetDividingStrategyCSSAttributes}
                        title={getLiteral('AtrybutyHTMLDoOsadzanychAutomatyczniePodzielonychPlikowCSS')}
                        disabled={editView ? 0 : 1}
                        onChange={(e) => setAssetDividingStrategyCSSAttributes(e.target.value)}
                      />
                    </Marginer>
                  )}
                </Col>
                <Col size={6}>
                  <AssetsDividingRadioGroup
                    ref={register}
                    readOnly={!editView}
                    label={getLiteral('SposobPodzialuPlikowJS')}
                    name={assetDividingStrategyJSName}
                    onChange={(e) => setAssetDividingStrategyJS(e.target.value)}
                  />
                  {assetDividingStrategyJS !== 'none' && (
                    <Marginer top={2}>
                      <Input
                        ref={register}
                        name="assetDividingStrategyJSAttributes"
                        type="text"
                        value={assetDividingStrategyJSAttributes}
                        title={getLiteral('AtrybutyHTMLDoOsadzanychAutomatyczniePodzielonychPlikowJavascript')}
                        disabled={editView ? 0 : 1}
                        onChange={(e) => setAssetDividingStrategyJSAttributes(e.target.value)}
                      />
                    </Marginer>
                  )}
                </Col>
              </Row>
            )}
          </Marginer>
          <PictureDropZone
            text={getLiteral('UpuscLubKliknijAbyDodacNoweZdjecie')}
            title={getLiteral('GrafikaKompozycji')}
            preview={watchTemplatePreview}
            onChange={(value) => setValue('image', value)}
            disabled={editView ? 0 : 1}
          />
        </Marginer>
        <Flex>
          <SubmitBtn loading={postTemplateEdit.loading.toString()} type="submit" disabled={editView ? 0 : 1}>
            {getLiteral('ZapiszUstawienia')}
          </SubmitBtn>
        </Flex>
      </Container>
    </Form>
  );
};

const SubmitBtn = styled(Button)`
  ${(props) =>
    props.disabled
      ? css`
          background: #e0e0e0;
        `
      : ''};
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  padding-top: 2rem;
`;
