import { useContext } from 'react';

import { Marginer } from 'components/atoms/Grid';
import { TemplateSectionWrapper } from 'components/molecules/TemplateSectionWrapper';
import { pageCxt } from 'contexts/PagesContext';

// Pages view tab
export const TemplatePageWrapper = () => {
  const {
    page,
    pageType,
    removeModule,
    addModuleToPage,
    replaceModuleOnPage,
    getPageModules,
    replaceModuleWrapperItemOnPage,
    addModuleToWrapperOnPage,
  } = useContext(pageCxt);

  return (
    <Marginer bottom={3}>
      <TemplateSectionWrapper
        items={page}
        borderless
        name={(pageType && pageType.value) || 'page'}
        removeCallback={removeModule}
        addModuleToPage={addModuleToPage}
        replaceModuleOnPage={replaceModuleOnPage}
        getPageModules={getPageModules}
        replaceModuleWrapperItemOnPage={replaceModuleWrapperItemOnPage}
        addModuleToWrapperOnPage={addModuleToWrapperOnPage}
      />
    </Marginer>
  );
};
