import { useContext } from 'react';

import { Container, Layout, Content } from 'components/atoms/Grid';
import { Header } from 'components/molecules/Header';
import { Aside } from 'components/organisms/Aside';
import { DictionaryHistoryList } from 'components/organisms/DictionaryHistoryList';
import { composerCxt } from 'contexts/ComposerContext';
import { useHashTabs } from 'utils/useHashTabs';
import { FileManagerHistoryList } from 'components/organisms/FileManagerHistoryList';

const EventsLogPage = () => {
  const { getLiteral } = useContext(composerCxt);

  const configTabs = [
    {
      title: getLiteral('SlownikTlumaczen'),
      component: <DictionaryHistoryList />,
    },
    {
      title: getLiteral('ManagerPlikow'),
      component: <FileManagerHistoryList />,
    },
  ];

  const { tabs, tabsPanel } = useHashTabs(configTabs);

  return (
    <Layout>
      <Aside></Aside>
      <Content>
        <Container>
          <Header title={getLiteral('DziennikZdarzen')} tabs={tabs} />
          {tabsPanel}
        </Container>
      </Content>
    </Layout>
  );
};

export default EventsLogPage;
