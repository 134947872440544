/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useMemo, useRef } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { Controller } from 'react-hook-form';
import { sprintf } from 'sprintf-js';
import styled from 'styled-components';

import { FileAddOrChooseDialogComponent } from 'components/atoms/FileAddOrChooseDialogComponent';
import { FileManagerDialogComponent } from 'components/atoms/FileManagerDialogComponent';
import { ButtonLink } from 'components/atoms/FileReplaceDialogComponent';
import { Marginer } from 'components/atoms/Grid';
import { Editor } from 'components/molecules/Editor';
import { componentCxt } from 'contexts/ComponentContext';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { API_GFX_ADD, API_MODULES_GET } from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import useOnScreen from 'utils/useOnScreen';
import { useTabs } from 'utils/useTabs';
import { base64replace } from 'helpers/base64helper';

export const ModuleFormCode = ({ control, readOnly, saveCall }) => {
  // States
  const { getLiteral } = useContext(composerCxt);
  const { id, type, isDraft, parentId, parentVersion, jsAddTo, group } = useContext(componentCxt);
  const [parentComponent, setParentComponent] = useState(null);
  const { createToast } = useContext(toastCtx);
  const postClientFileAdd = useFetch(API_GFX_ADD, 'POST');
  const [focusCss, setFocusCss] = useState(false);
  const [focusJs, setFocusJs] = useState(false);
  const [focusJsLocal, setFocusJsLocal] = useState(false);
  const [focusXml, setFocusXml] = useState(false);
  const sourcesKeys = {
    css: getLiteral('CssLess'),
    javascript: getLiteral('JavaScript'),
    jsLocal: getLiteral('WydzielonyJavascript'),
    xml: getLiteral('XMLXSLT'),
  };

  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const getModuleData = useFetch(`${API_MODULES_GET}/id/${parentId}/version/${parentVersion}`, 'GET');

  const renderDiff = useMemo(() => {
    if (!isVisible) {
      return null;
    }

    let hasChanges = false;
    const diffs = Object.entries(sourcesKeys).map(([key, literal]) => {
      let source = parentComponent?.sources[key] ?? '';
      let parentSource = control.fieldsRef.current[`sources[${key}]`]
        ? control.fieldsRef.current[`sources[${key}]`].ref.value.toString()
        : '';

      source = source.replace(/\r\n/g, '\n');
      parentSource = parentSource.replace(/\r\n/g, '\n');
      if (parentSource === source) {
        return null;
      }

      hasChanges = true;

      return (
        <div style={{ marginBottom: '10rem' }} key={key}>
          <ReactDiffViewer
            oldValue={source}
            newValue={parentSource}
            splitView={true}
            disableWordDiff={true}
            leftTitle={`${getLiteral('PoprzedniaWersja')} - ${literal}`}
            rightTitle={`${getLiteral('AktualnaWersja')} - ${literal}`}
            compareMethod={'diffLines'}
          />
        </div>
      );
    });

    return hasChanges ? <Wrapper>{diffs}</Wrapper> : <>{getLiteral('NieWykrytoZmianWKodzieKomponentu')}</>;
  }, [parentComponent, isVisible]);

  useEffect(() => {
    if (!readOnly) {
      getModuleData.execute();
    }
  }, [id]);

  useEffect(() => {
    if (getModuleData.response) {
      setParentComponent(getModuleData.response);
    } else if (getModuleData.error) {
      setParentComponent(null);
      createToast({
        title: getLiteral('CosPoszloNieTak'),
        message: getModuleData.error.message,
        context: 'error',
      });
    }
  }, [getModuleData.response, getModuleData.error]);

  const commands = [
    {
      name: 'saveComponent',
      bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
      exec: () => saveCall(),
      readOnly: true,
    },
  ];

  // Tabs
  const tabsArray = [
    {
      title: getLiteral('CssLess'),
      setFocus: setFocusCss,
      component: (
        <Controller
          control={control}
          name="sources[css]"
          render={({ onChange, value }) => (
            <Editor
              focus={focusCss}
              mode="less"
              onChange={onChange}
              value={value}
              readOnly={readOnly}
              getLiteralsDictionary={false}
              commands={commands}
              refreshCommands={id}
            />
          )}
        />
      ),
    },
    {
      title: getLiteral('JavaScript'),
      setFocus: setFocusJs,
      component: (
        <Controller
          control={control}
          name="sources[javascript]"
          render={({ onChange, value }) => (
            <Editor
              focus={focusJs}
              mode="javascript"
              onChange={onChange}
              value={value}
              readOnly={readOnly}
              getLiteralsDictionary={true}
              commands={commands}
              refreshCommands={id}
            />
          )}
        />
      ),
    },
    {
      title: getLiteral('WydzielonyJavascript'),
      setFocus: setFocusJsLocal,
      component: (
        <Controller
          control={control}
          name="sources[jsLocal]"
          render={({ onChange, value }) => (
            <>
              <Editor
                focus={focusJsLocal}
                mode="javascript"
                onChange={onChange}
                value={value}
                readOnly={readOnly}
                getLiteralsDictionary={true}
                commands={commands}
                refreshCommands={id}
              />
              {jsAddTo === 'custom' && (
                <PlaceholderWrapper>
                  {getLiteral('PlaceholderDoWstawieniaWKodzie')}: <br />
                  <strong>{`<iai:container_js type="custom" container="${group}"/>`}</strong>
                  <br />
                  <strong>{`<iai:container_js_url type="custom" container="${group}"/>`}</strong>
                </PlaceholderWrapper>
              )}
            </>
          )}
        />
      ),
    },
    {
      title: getLiteral('XMLXSLT'),
      setFocus: setFocusXml,
      component:
        control.defaultValuesRef.current.componentModel === 'wrapper' && readOnly ? (
          <Controller
            control={control}
            name="wrapperWithItemsXML"
            render={({ onChange, value }) => (
              <Editor
                focus={focusXml}
                mode="xml"
                onChange={onChange}
                value={value}
                readOnly={readOnly}
                getLiteralsDictionary={true}
                commands={commands}
                refreshCommands={id}
              />
            )}
          />
        ) : (control.defaultValuesRef.current.componentModel === 'wrapper' && !readOnly) ||
          (parentComponent?.model === 'wrapper' && !readOnly) ? (
          <>
            <Marginer bottom={1}>{getLiteral('KodXMLPrzedSkladowymi')}</Marginer>
            <Controller
              control={control}
              name="sources[xmlBefore]"
              render={({ onChange, value }) => (
                <Editor
                  focus={focusXml}
                  mode="xml"
                  onChange={onChange}
                  value={value}
                  readOnly={readOnly}
                  getLiteralsDictionary={true}
                  commands={commands}
                  refreshCommands={id}
                />
              )}
            />
            <Marginer top={3} bottom={1}>
              {getLiteral('KodXMLZaSkladowymi')}
            </Marginer>
            <Controller
              control={control}
              name="sources[xmlAfter]"
              render={({ onChange, value }) => (
                <Editor
                  mode="xml"
                  onChange={onChange}
                  value={value}
                  readOnly={readOnly}
                  getLiteralsDictionary={true}
                  commands={commands}
                  refreshCommands={id}
                />
              )}
            />
          </>
        ) : (
          <Controller
            control={control}
            name="sources[xml]"
            render={({ onChange, value }) => (
              <Editor
                focus={focusXml}
                mode="xml"
                onChange={onChange}
                value={value}
                readOnly={readOnly}
                getLiteralsDictionary={true}
                commands={commands}
                refreshCommands={id}
              />
            )}
          />
        ),
    },
    isDraft === 'y' && {
      title: getLiteral('Zmiany'),
      toRight: true,
      component: <div ref={ref}>{parentComponent && parentComponent.sources ? renderDiff : null}</div>,
    },
  ];

  const { tabs, tabsPanel } = useTabs(tabsArray, true);

  const copyFileUrl = (name, isStandardFile) => {
    const prefix = isStandardFile ? 'standards' : 'custom';

    const textToCopy = `<img src="/gfx/${prefix}/${name}" alt="${name}"/>`;
    navigator.clipboard.writeText(textToCopy);

    createToast({
      title: getLiteral('UchwytDoPlikuZostalSkopiowany'),
      message: sprintf(getLiteral('UzyjXWKodzieKomponentuAbyOdwolacSieDoPliku'), textToCopy),
      context: 'success',
      delay: 60,
    });
  };

  const dialogFileManager = useDialog({
    title: getLiteral('WybierzPlikZManagera'),
    content: (
      <FileManagerDialogComponent
        replaceFile={(customName, isStandardFile) => {
          copyFileUrl(customName, isStandardFile);
          dialogFileReplace.closeDialog();
          dialogFileManager.closeDialog();
        }}
      />
    ),
  });

  const dialogFileReplace = useDialog({
    title: getLiteral('DodajNowyPlikLubWybierzIstniejacyZManagera'),
    content: (
      <FileAddOrChooseDialogComponent
        isStandardComponent={type === 'standard'}
        openManager={dialogFileManager.openDialog}
        addNewFile={(base64, file) => {
          postClientFileAdd.execute({
            gfx: file.name,
            standardFiles: type === 'standard',
            gfxContent: base64replace(base64),
          });
          copyFileUrl(file.name.replace(' ', '_'), type === 'standard');
          dialogFileReplace.closeDialog();
        }}
      />
    ),
  });

  return (
    <>
      {isDraft === 'y' && (
        <FileManagerDiv>
          <ButtonLink
            onClick={(event) => {
              event.preventDefault();
              dialogFileReplace.openDialog();
            }}
          >
            {getLiteral('DodajPlik')}
          </ButtonLink>
        </FileManagerDiv>
      )}
      <Marginer bottom={2}>{tabs}</Marginer>
      {tabsPanel}
      {dialogFileManager.dialog}
      {dialogFileReplace.dialog}
    </>
  );
};

const FileManagerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 380px);
  overflow: auto;
  border: 1px solid #f7f7f7;
`;

const PlaceholderWrapper = styled.div`
  margin-top: 1rem;
`;
