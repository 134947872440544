/* eslint-disable react-hooks/exhaustive-deps */
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Select from 'react-select';
import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import CustomDialog from 'components/atoms/CustomDialog';
import { CustomDialogTitle } from 'components/atoms/CustomDialogTitle';
import { Loader } from 'components/atoms/Loader';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { userCtx } from 'contexts/UserContext';
import { API_TEMPLATES_PUBLSIH, EDP_NO_ACCESS } from 'helpers/endpoints';
import { useFetch } from 'utils/useFetch';

export const PublishDialog = ({ id, version, languages, open, setOpen }) => {
  const { createToast } = useContext(toastCtx);
  const { getLiteral } = useContext(composerCxt);
  const user = useContext(userCtx);
  const history = useHistory();

  const [selectedLanguages, setSelectedLanguages] = useState({});
  const postPublishComposition = useFetch(API_TEMPLATES_PUBLSIH, 'POST');

  useEffect(() => {
    if (
      postPublishComposition.error ||
      (postPublishComposition.response && postPublishComposition.response.status === 'error')
    ) {
      createToast({
        title: getLiteral('NieMoznaOpublikowacKompozycji'),
        message: postPublishComposition.error.message,
        context: 'error',
      });

      // Na potrzeby zadania ICA-3846 zabezpieczenie publikacji kompozycji w innym panelu,
      // bazujemy na kodzie błędu który jest zwracany z backendu 'ERROR_NO_ACCESS'
      if (postPublishComposition.error.code === 9993) {
        history.push(EDP_NO_ACCESS);
      }
      return;
    } else if (postPublishComposition.response) {
      createToast({
        title: `${getLiteral('KompozycjaOpublikowana')}!`,
        message: `${getLiteral('KompozycjaJestTerazWidocznaWSklepie')}!`,
        context: 'success',
      });
    }

    setOpen(false);
  }, [postPublishComposition.response, postPublishComposition.error]);

  const shopIds = user.shops
    ? user.shops.map((el) => {
        return { value: el.id, label: el.name };
      })
    : [];

  const shopLanguages = languages.map((item) => {
    return { value: item.id, label: item.name };
  });

  const handleFormConfirm = () => {
    postPublishComposition.execute({
      designId: id,
      designVersion: version,
      shopsLanguages: selectedLanguages,
    });
  };

  const selectedAll = (shopId) => {
    return !(
      !shopId ||
      undefined === languages ||
      undefined === selectedLanguages[shopId] ||
      languages.length !== selectedLanguages[shopId].length
    );
  };

  const selectLanguagesForShop = (shopId, languages) => {
    let langs = JSON.parse(JSON.stringify(selectedLanguages));
    langs[shopId] = languages;
    setSelectedLanguages(langs);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomDialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {postPublishComposition.loading ? (
          <Loader />
        ) : (
          <>
            <CustomDialogTitle
              id="form-dialog-title"
              title={getLiteral('WybierzSklepyIJezykiWKtorychChceszOpublikowac')}
              closeCallback={handleClose}
            />
            <DialogContent>
              {shopIds.map((item) => {
                return (
                  <div key={item.value}>
                    <SectionName>{item.label}</SectionName>
                    <Select
                      placeholder={getLiteral('WybierzJezykiDlaSklepu')}
                      isMulti
                      value={selectedLanguages[item.value]}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                      onChange={(selected) => {
                        selectLanguagesForShop(item.value, selected);
                      }}
                      options={shopLanguages}
                    />
                    <SelectAll
                      onClick={() => {
                        if (selectedAll(item.value)) {
                          selectLanguagesForShop(item.value, []);
                        } else {
                          selectLanguagesForShop(item.value, shopLanguages);
                        }
                      }}
                    >
                      {selectedAll(item.value) ? getLiteral('OdznaczWszystkieBridge') : getLiteral('WybierzWszystkie')}
                    </SelectAll>
                  </div>
                );
              })}
            </DialogContent>
            <StyledDialogActions>
              <PublishButton
                disabled={Object.values(selectedLanguages).filter((x) => x.length > 0).length === 0}
                onClick={handleFormConfirm}
                primary
              >
                <CommitIcon>
                  <FontAwesomeIcon icon={faUpload} />
                </CommitIcon>
                {getLiteral('OpublikujKompozycjeWSklepie')}
              </PublishButton>
            </StyledDialogActions>
          </>
        )}
      </CustomDialog>
    </>
  );
};

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
`;

const SelectAll = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
`;

const SectionName = styled.h3`
  width: 15vw;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const PublishButton = styled(Button)`
  background-color: var(--green);
  width: 100%;
  ${(props) =>
    props.disabled
      ? css`
          background: #e0e0e0;
        `
      : ''};
`;

const CommitIcon = styled.span`
  font-size: 2rem;
  margin-right: 1.5rem;
`;
