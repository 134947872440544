/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { DictionaryNewLiteralForm } from 'components/organisms/DictionaryNewLiteralForm';
import { Select } from 'components/atoms/Form/Select';
import { Row, Col, Marginer } from 'components/atoms/Grid';
import { ImportExportDialogComponent } from 'components/atoms/ImportExportDialogComponent';
import { SmallLoaderAfter } from 'components/atoms/Loader';
import { DictionaryLangList } from 'components/molecules/DictionaryLangList';
import { composerCxt } from 'contexts/ComposerContext';
import { toastCtx } from 'contexts/ToastContext';
import { useAccess } from 'helpers/access';
import { SupportMark } from 'components/atoms/SupportMark';
import { AutoTranslationDialogComponent } from 'components/atoms/AutoTranslationDialogComponent';
import { InfoLabel } from 'components/atoms/InfoLabel';
import {
  API_DICTIONARY_ADD,
  API_DICTIONARY_GET_TRANSLATIONS,
  API_DICTIONARY_REMOVE,
  API_DICTIONARY_RESTORE,
  API_DICTIONARY_SEARCH,
  API_TEMPLATES_REFRESH_BUILDS,
  API_TRANSLATION_LANGS,
} from 'helpers/endpoints';
import { useAlert } from 'utils/useAlert';
import { useDialog } from 'utils/useDialog';
import { useFetch } from 'utils/useFetch';
import { usePagination } from 'utils/usePagination';

export const DictionaryList = ({ filters, clearFilters }) => {
  const { getLiteral, languages } = useContext(composerCxt);
  const { createToast } = useContext(toastCtx);

  const [translateLiterals, setTranslateLiterals] = useState(null);
  const [defaultLang, setDefaultLang] = useState('');
  const [translateLang, setTranslateLang] = useState('');
  const [pagesCount, setPagesCount] = useState(0);
  const [isLangInQueue, setIsLangInQueue] = useState(false);

  const canUseTranslation = useAccess('canUseTranslation');

  const timeoutRef = useRef();
  const searchTimeoutRef = useRef();
  const changesRef = useRef({});

  const postTranslateLiterals = useFetch(API_DICTIONARY_GET_TRANSLATIONS, 'POST');
  const postUpdateLiterals = useFetch(API_DICTIONARY_ADD, 'POST');
  const postRestoreLiteral = useFetch(API_DICTIONARY_REMOVE, 'POST');
  const postRestoreAllLiterals = useFetch(API_DICTIONARY_RESTORE, 'POST');
  const postSearchLiteral = useFetch(API_DICTIONARY_SEARCH, 'POST');
  const getPublishDictionary = useFetch(API_TEMPLATES_REFRESH_BUILDS, 'POST');
  const postIsSupportedLangs = useFetch(API_TRANSLATION_LANGS, 'POST');

  const exportDialog = useDialog({
    title: getLiteral('ImportExportPlikuZTlumaczeniami'),
    content: (
      <ImportExportDialogComponent
        source={defaultLang}
        target={translateLang}
        standardLiterals={filters.standardLiterals}
        importCallback={() => {
          exportDialog.closeDialog();

          if (filters.text) {
            postSearchLiteral.execute({
              sourceLang: defaultLang,
              targetLang: translateLang,
              text: filters.text,
              standardLiterals: filters.standardLiterals,
            });
          } else {
            postTranslateLiterals.execute({
              page,
              sourceLang: defaultLang,
              targetLang: translateLang,
              onlyNotTranslated: filters.emptyLiterals,
              standardLiterals: filters.standardLiterals,
            });
          }
        }}
      />
    ),
    styles: css`
      .MuiDialog-paper,
      .MuiDialogContent-root {
        width: 35vw;
        min-width: 730px;
      }
    `,
  });

  const autoTranslate = useDialog({
    title: getLiteral('TlumaczenieAutomatyczne'),
    content: <AutoTranslationDialogComponent source={defaultLang} target={translateLang} />,
    styles: css`
      .MuiDialog-paper,
      .MuiDialogContent-root {
        width: 35vw;
        min-width: 730px;
      }
    `,
  });

  const addLiteralDialog = useDialog({
    title: getLiteral('DodajNowyLiteral'),
    content: (
      <DictionaryNewLiteralForm
        onSubmitCallback={() => {
          addLiteralDialog.closeDialog();
          resetPage();
          clearFilters();
        }}
        standardLiterals={filters.standardLiterals}
      />
    ),
  });

  const restoreAlert = useAlert({
    onAccept: () => postRestoreAllLiterals.execute({ language: translateLang }),
    text: `${getLiteral(
      'OperacjaPrzywrociWartosciDomyslneDlaLiteralowStandardowychLiteralyWlasneKtoreNieMajaOdpowiednikaWSlownikuStandardowymNieZostanaUsuniete',
    )}.`,
  });

  const publishAlert = useAlert({
    onAccept: () => getPublishDictionary.execute({ standards: filters.standardLiterals }),
    text: `${getLiteral(
      'NajnowszeWersjeTwoichSzablonowZostanaWygenerowaneWTleObecneWersjeZostanąNadpisaneAbyZobaczycSwojeZmianyUzyjNarzedziaPodgladuLubPonownieOpublikujSzablonWSwoimSklepie',
    )}.`,
  });

  const { page, paginationProps, Pagination, resetPage } = usePagination({
    pageLimit: pagesCount,
  });

  useEffect(() => {
    resetPage();
  }, [filters.text, filters.emptyLiterals]);

  useEffect(() => {
    if (postSearchLiteral.response && postIsSupportedLangs.response && !filters.text) {
      resetPage();
    }

    if (filters.text) {
      clearTimeout(searchTimeoutRef.current);
      searchTimeoutRef.current = setTimeout(
        () =>
          postSearchLiteral.execute({
            sourceLang: defaultLang,
            targetLang: translateLang,
            text: filters.text,
            onlyNotTranslated: filters.emptyLiterals,
            page,
            standardLiterals: filters.standardLiterals,
          }),
        500,
      );
    }
  }, [filters.text, filters.emptyLiterals, translateLang, page, filters.standardLiterals]);

  // Get literals to translate
  useEffect(() => {
    if (translateLang && translateLang !== '' && page && !filters.text) {
      postTranslateLiterals.execute({
        page,
        sourceLang: defaultLang,
        targetLang: translateLang,
        onlyNotTranslated: filters.emptyLiterals,
        standardLiterals: filters.standardLiterals,
      });
      postIsSupportedLangs.execute({
        sourceLang: defaultLang,
        targetLang: translateLang,
      });
      if (postIsSupportedLangs.response) {
        setIsLangInQueue(postIsSupportedLangs.response.inQueue);
      }
    }
  }, [translateLang, defaultLang, page, filters.emptyLiterals, filters.text, filters.standardLiterals]);

  // Handle translate literals getting
  useEffect(() => {
    if (!postTranslateLiterals.response) {
      setTranslateLiterals(null);
    }
    if (postTranslateLiterals.response) {
      setTranslateLiterals(postTranslateLiterals.response.models);
      setPagesCount(postTranslateLiterals.response.pagesCount);
    }
    if (postIsSupportedLangs.response) {
      setIsLangInQueue(postIsSupportedLangs.response.inQueue);
    }
  }, [postTranslateLiterals.response, postIsSupportedLangs.response]);

  // Handle translation update
  useEffect(() => {
    if (postUpdateLiterals.response) {
      if (postUpdateLiterals.response.status === 'ok') {
        createToast({
          delay: 5,
          title: getLiteral('OperacjaPrzebieglaPomyslnie'),
          message: getLiteral('PoprawnieZapisanoTlumaczenia'),
          context: 'success',
        });
      } else {
        createToast({
          delay: 5,
          title: getLiteral('OperacjaPrzebieglaPomyslnie'),
          message: postUpdateLiterals.response.message,
          context: postUpdateLiterals.response.status,
        });
      }
    }
  }, [postUpdateLiterals.response]);

  // Handle translation restore
  useEffect(() => {
    if (postRestoreLiteral.response) {
      createToast({
        delay: 5,
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('PoprawniePrzywroconoTlumaczenia'),
        context: 'success',
      });
    }
  }, [postRestoreLiteral.response]);

  // Handle all translations restore
  useEffect(() => {
    if (postRestoreAllLiterals.response) {
      createToast({
        delay: 5,
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('PoprawniePrzywroconoTlumaczenia'),
        context: 'success',
      });

      if (filters.text) {
        postSearchLiteral.execute({
          sourceLang: defaultLang,
          targetLang: translateLang,
          text: filters.text,
          standardLiterals: filters.standardLiterals,
        });
      } else {
        postTranslateLiterals.execute({
          page,
          sourceLang: defaultLang,
          targetLang: translateLang,
          onlyNotTranslated: filters.emptyLiterals,
          standardLiterals: filters.standardLiterals,
        });
      }
    }
  }, [postRestoreAllLiterals.response]);

  // Handle searching
  useEffect(() => {
    if (!postSearchLiteral.response) {
      setTranslateLiterals(null);
    }
    if (postSearchLiteral.response) {
      setTranslateLiterals(postSearchLiteral.response.models);
      setPagesCount(postSearchLiteral.response.pagesCount);
    }
  }, [postSearchLiteral.response]);

  // Handle searching
  useEffect(() => {
    if (getPublishDictionary.response) {
      createToast({
        title: getLiteral('OperacjaPrzebieglaPomyslnie'),
        message: getLiteral('NajnowszeWersjeTwoichKompozycjiZostanaOdswiezoneWTle'),
        context: 'success',
        delay: 5,
      });
    }
  }, [getPublishDictionary.response]);

  /**
   * Set new value to the reference object, and save it into db after some timeout
   * @param {string} key key of literal
   * @param {string} value new client value
   * @returns void
   */
  const changeLiteral = (key, value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      const body = {
        literals: {},
        standardLiterals: filters.standardLiterals,
      };

      Object.entries(changesRef.current).forEach(
        ([lang, literals]) => (body.literals[lang] = Object.entries(literals).map(([key, value]) => ({ key, value }))),
      );

      // Updating literals
      postUpdateLiterals.execute(body, () => {
        if (Object.keys(changesRef.current).length === 0) {
          return;
        }

        // Setting new state
        setTranslateLiterals((state) => {
          // Creating copy of our state
          const newState = {
            ...state,
          };

          // Iterating of changed literals, and override it on state
          Object.entries(changesRef.current[translateLang]).forEach(([key, value]) => {
            // Update literal only if exist on list
            if (state[key]) {
              newState[key] = {
                ...state[key],
                [translateLang]: {
                  ...state[key][translateLang],
                  clientValue: value,
                },
              };
            }
          });

          return newState;
        });

        changesRef.current = {};
      });
    }, 1000);

    if (!changesRef.current[translateLang]) {
      changesRef.current[translateLang] = {
        [key]: value,
      };
      return;
    }

    changesRef.current[translateLang][key] = value;
  };

  /**
   * Remove client value from database
   * @param {string} key key of literal
   */
  const removeCustomLiteral = (key) => {
    postRestoreLiteral.execute(
      {
        literals: {
          [translateLang]: [
            {
              key,
            },
          ],
        },
      },
      () => {
        if (translateLiterals[key]) {
          setTranslateLiterals((state) => ({
            ...state,
            [key]: {
              ...state[key],
              [translateLang]: {
                ...state[key][translateLang],
                clientValue: '',
              },
            },
          }));
        }
      },
    );
  };

  return (
    <>
      {isLangInQueue ? (
        <Marginer bottom={4}>
          <InfoLabel
            type="warning"
            text={getLiteral('TrwaAutomatyczneTlumaczenieWkrotcePonownieBedzieDostepnaEdycjaLiteralow')}
            marginBottom={false}
          />
        </Marginer>
      ) : null}
      <Row>
        <PaddedCol size={4}>
          <Marginer bottom={1.5}>{getLiteral('DomyslneLiteralyDlaJezyka')}</Marginer>
          <Select
            options={languages}
            onChange={(option) => {
              setDefaultLang(option.value);
              resetPage();
            }}
          />
        </PaddedCol>
        <PaddedCol size={4}>
          <Marginer bottom={1.5}>{getLiteral('NoweLiteralyDlaJezyka')}</Marginer>
          <Select
            options={languages}
            onChange={(option) => {
              setTranslateLang(option.value);
              resetPage();
            }}
            isDisabled={!defaultLang}
          />
        </PaddedCol>
      </Row>
      <Row>
        <NoPaddedCol size={8}>
          <DictionaryLangList
            source={defaultLang}
            target={translateLang}
            page={page}
            loading={postTranslateLiterals.loading || postSearchLiteral.loading}
            translateLiterals={translateLiterals}
            changeLiteral={changeLiteral}
            removeLiteral={removeCustomLiteral}
            standardLiterals={filters.standardLiterals}
            isAutoTranslate={isLangInQueue}
          />
          <PaddedMarginer bottom={2}>{pagesCount ? <Pagination {...paginationProps} /> : null}</PaddedMarginer>
        </NoPaddedCol>
        <StickedPaddedCol size={4}>
          <Marginer bottom={3}>
            <Marginer bottom={2}>
              <PublishButton
                loading={postUpdateLiterals.loading.toString()}
                type="button"
                onClick={() => publishAlert.openAlert()}
                isDisabled={isLangInQueue.toString()}
              >
                {filters.standardLiterals
                  ? getLiteral('OdswiezLiteralyWKompozycjachStandard')
                  : getLiteral('OdswiezLiteralyWKompozycjach')}
              </PublishButton>
            </Marginer>
            {translateLang && (
              <>
                {!filters.standardLiterals && (
                  <Marginer bottom={2}>
                    <RestoreButton
                      type="button"
                      onClick={() => restoreAlert.openAlert()}
                      isDisabled={isLangInQueue.toString()}
                    >
                      <FontAwesomeIcon icon={faUndo} />
                      {getLiteral('PrzywrocWszystkieDomysleLiteraly')}
                    </RestoreButton>
                  </Marginer>
                )}
                <Marginer bottom={5}>
                  <RestoreButton
                    type="button"
                    onClick={() => addLiteralDialog.openDialog()}
                    isDisabled={isLangInQueue.toString()}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {getLiteral('DodajNowyLiteral')}
                  </RestoreButton>
                </Marginer>
                <Marginer bottom={2}>
                  <ExportButton
                    type="button"
                    onClick={() => exportDialog.openDialog()}
                    isDisabled={isLangInQueue.toString()}
                  >
                    {getLiteral('ImportExportPlikuZTlumaczeniami')}
                  </ExportButton>
                </Marginer>
                {canUseTranslation.condition && (
                  <Marginer bottom={2}>
                    <PublishButton
                      type="button"
                      onClick={() => autoTranslate.openDialog()}
                      isDisabled={isLangInQueue.toString() || 'true'}
                    >
                      {getLiteral('TlumaczAutomatycznie')}
                      <SupportMark></SupportMark>
                    </PublishButton>
                  </Marginer>
                )}
              </>
            )}
          </Marginer>
        </StickedPaddedCol>
      </Row>
      {restoreAlert.alert}
      {publishAlert.alert}
      {exportDialog.dialog}
      {autoTranslate.dialog}
      {addLiteralDialog.dialog}
    </>
  );
};

const PublishButton = styled.button`
  background-color: #f0f0f0;
  color: #000;
  height: 50px;
  padding: 0 25px;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 1.4rem;
  position: relative;

  ${({ loading }) =>
    loading === 'true' &&
    css`
      color: transparent;
      pointer-events: none;
      ${SmallLoaderAfter}

      &:after {
        border-color: #fff transparent #fff transparent;
      }
    `}
  ${({ isDisabled }) =>
    isDisabled === 'true'
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
`;

const RestoreButton = styled(PublishButton)`
  svg {
    margin-right: 10px;
    font-size: 1.6rem;
  }
`;

const ExportButton = styled(PublishButton)`
  background-color: #f0f0f0;
  color: #000;
`;

const PaddedCol = styled(Col)`
  padding-left: 65px;
`;
const PaddedMarginer = styled(Marginer)`
  padding-left: 65px;
`;

const NoPaddedCol = styled(Col)`
  padding-right: 0;
  padding-left: 0;
`;

const StickedPaddedCol = styled(PaddedCol)`
  align-self: flex-start;
  margin-top: -65px;
  position: sticky;
  top: 140px;
`;
