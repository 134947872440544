import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AddButton } from 'components/atoms/Button';
import { Marginer } from 'components/atoms/Grid';
import { SortingComponent } from 'components/atoms/SortingComponent';
import { Tag } from 'components/atoms/Tag';
import { NewModuleTypeForm } from 'components/organisms/NewModuleTypeForm';
import { composerCxt } from 'contexts/ComposerContext';
import { useAccess } from 'helpers/access';
import { EDP_GROUPS } from 'helpers/endpoints';
import { sortingFunction } from 'helpers/sortingFunctions';
import { useDialog } from 'utils/useDialog';

export const ModuleType = ({ name, description, id, deleteDate }) => {
  const { getLiteral } = useContext(composerCxt);
  const canDeubgComposer = useAccess('canDebugComposer');

  return (
    <Group key={id}>
      <div>
        <GroupDetails>
          <i>{name}</i>
          {deleteDate && (
            <TagContainer>
              <Tag type="deprecated" title={getLiteral('wycofywany')}>
                {getLiteral('wycofywany')}
              </Tag>
            </TagContainer>
          )}
        </GroupDetails>
        <GroupDescription>{description}</GroupDescription>
      </div>
      {canDeubgComposer.condition && (
        <ActionLink to={`${EDP_GROUPS}${id}`}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </ActionLink>
      )}
    </Group>
  );
};

export const ModuleGroupsList = () => {
  const {
    getLiteral,
    groups,
    pagesIds,
    pages,
    methods: { addModuleType },
  } = useContext(composerCxt);
  const newTypeDialog = useDialog({
    title: getLiteral('DodajNowyTypKomponentow'),
    content: (
      <NewModuleTypeForm
        onSuccessCallback={(res) => {
          newTypeDialog.closeDialog();
          addModuleType(res);
        }}
      />
    ),
  });

  const [filters, setFilters] = useState({
    text: '',
    type: '',
    sort: 'name',
  });

  return (
    <Marginer bottom={5}>
      <SortingComponent
        setTextFilter={(value) => setFilters((state) => ({ ...state, text: value }))}
        setTypeFilter={(value) => setFilters((state) => ({ ...state, type: value }))}
        typeTitle={getLiteral('filtrujPoTypiePodstrony')}
        typeOptions={pages}
      />
      <div>
        {groups
          .filter(({ name, id, siteId, description }) => {
            if (!name.includes(filters.text) && !id.includes(filters.text) && !description.includes(filters.text)) {
              return false;
            }
            if (filters.type !== '') {
              return siteId === pagesIds.find((el) => el.name === filters.type).id;
            }
            return true;
          })
          .sort(sortingFunction(filters))
          .map((group) => (
            <ModuleType key={group.id} {...group} />
          ))}
      </div>
      <AddButton type="button" onClick={() => newTypeDialog.openDialog()}>
        {getLiteral('DodajNowyElement')}
      </AddButton>
      {newTypeDialog.dialog}
    </Marginer>
  );
};

const Group = styled.div`
  border: var(--border);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--gray-light);
  }

  &:first-child {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  &:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const ActionLink = styled(Link)`
  min-width: 40px;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin-right: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: var(--gray-light);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--text);
`;

const GroupDescription = styled.div``;

const GroupDetails = styled.div`
  font-size: 1.3rem;
  display: block;
  margin-bottom: 0.5rem;

  i {
    font-style: italic;
  }
`;

const TagContainer = styled.div`
  display: inline-block;
  margin-left: 0.4rem;
`;
