/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components/atoms/Button';
import { Loader } from 'components/atoms/Loader';
import { StyledLink, Table } from 'components/atoms/Table';
import { CompareVersionsDialog } from 'components/organisms/CompareVersionsDialog';
import { composerCxt } from 'contexts/ComposerContext';
import { EDP_MODULES, EDP_TEMPLATES } from 'helpers/endpoints';
import { useDialog } from 'utils/useDialog';
import { FixedColumn } from 'components/organisms/PendingItems';
import { userCtx } from 'contexts/UserContext';
import { SupportMark } from 'components/atoms/SupportMark';

export const ITEM_TYPES = {
  COMPONENT: 'component',
  DESIGN: 'design',
};

export const ItemHistory = ({ items, loading, itemType = ITEM_TYPES.COMPONENT, data }) => {
  const { getLiteral } = useContext(composerCxt);
  const { isSupport } = useContext(userCtx);
  const [diffData, setDiffData] = useState({});

  const compareDialog = useDialog({
    content: <CompareVersionsDialog data={data} diffData={diffData} />,
    title: getLiteral('RozniceMiedzyWersjami'),
    styles: css`
      .MuiDialog-paper,
      .MuiDialogContent-root {
        width: 75vw;
        height: 85vh;
      }
    `,
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : items.length === 0 ? (
        <div>{getLiteral('BrakHistoriiZmian')}</div>
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>{getLiteral('nazwa')}</th>
                <th>{getLiteral('OpisZmian')}</th>
                <th style={{ minWidth: '200px' }}>{getLiteral('Zaaktulizowano')}</th>
                <th>{getLiteral('Autor')}</th>
                {isSupport && items[0].type === 'standard' ? (
                  <>
                    <th>
                      {getLiteral('Alfa')}
                      <SupportMark />
                    </th>
                    <th>
                      {getLiteral('Beta')}
                      <SupportMark />
                    </th>
                    <th>
                      {getLiteral('Go')}
                      <SupportMark />
                    </th>
                  </>
                ) : null}
                {data && <th>{getLiteral('Porownaj')}</th>}
              </tr>
            </thead>
            <tbody>
              {items.map((el) => {
                return (
                  <tr key={`${el.id}_${el.version}`}>
                    <td>
                      {itemType === ITEM_TYPES.COMPONENT ? (
                        <StyledLink to={`${EDP_MODULES}${el.id}/version/${el.version}`}>link</StyledLink>
                      ) : (
                        <StyledLink to={`${EDP_TEMPLATES}${el.id}/${el.version}`}>link</StyledLink>
                      )}

                      {`${el.name} v.${el.version}`}
                    </td>
                    <td>{el.commitMessage}</td>
                    <td>{el.lastModificationDate}</td>
                    <td>{el.createUser}</td>
                    {isSupport && el.type === 'standard' ? (
                      <>
                        <FixedColumn>{el.alfa ?? getLiteral('bd')}</FixedColumn>
                        <FixedColumn>{el.beta ?? getLiteral('bd')}</FixedColumn>
                        <FixedColumn>{el.go ?? getLiteral('bd')}</FixedColumn>
                      </>
                    ) : null}
                    {data && (
                      <td style={{ textAlign: 'center' }}>
                        {data.version !== el.version || data.isDraft === 'y' ? (
                          <CompareButton
                            type="button"
                            onClick={() => {
                              setDiffData({ id: el.id, version: el.version });
                              compareDialog.openDialog();
                            }}
                          >
                            <FontAwesomeIcon icon={faCodeBranch} />
                          </CompareButton>
                        ) : (
                          <>---</>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {compareDialog.dialog}
        </>
      )}
    </>
  );
};

ItemHistory.defaultProps = {
  loading: false,
};

const CompareButton = styled(Button)`
  padding: 0;
  width: 40px;
  position: relative;
  z-index: 10;

  svg {
    margin: 0;
  }
`;
