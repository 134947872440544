import { useContext } from 'react';

import { TemplateSectionWrapper } from 'components/molecules/TemplateSectionWrapper';
import { templateCxt } from 'contexts/TemplateContext';

// StyleGuide tab component
export const TemplateStyleGuide = () => {
  const { styleGuide, removeModule, addModuleToComposition, getPageModules } = useContext(templateCxt);
  return (
    <TemplateSectionWrapper
      items={styleGuide}
      name="css-components"
      removeCallback={removeModule}
      addModuleToPage={addModuleToComposition}
      getPageModules={getPageModules}
    />
  );
};
