/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { Button } from 'components/atoms/Button';
import { Input } from 'components/atoms/Form';
import { Select } from 'components/atoms/Form/Select';
import { ClearableFilter } from 'components/molecules/ClearableFilter';
import { NewFilters } from 'components/molecules/NewFilters';
import { composerCxt } from 'contexts/ComposerContext';
import { debounce } from 'helpers/debounce';
import { makeSelectDataOption } from 'helpers/makeSelectOption';
import { useDialog } from 'utils/useDialog';
import { userCtx } from 'contexts/UserContext';

export const AdvancedFilters = ({ setFilters, defaultFilters }) => {
  const { getLiteral, groups, pagesIds, libraries } = useContext(composerCxt);
  const { isSupport } = useContext(userCtx);
  const inputRef = useRef();
  const [data, setData] = useState(
    defaultFilters
      ? JSON.parse(
          '{"' + decodeURI(defaultFilters).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        )
      : {},
  );

  const setDataFilters = (data) => {
    setData((state) => ({ ...state, ...data }));
  };

  const clearDataFilters = (name) => {
    setData((state) => ({ ...state, [name]: null }));
  };

  const pagesOptions = useMemo(
    () =>
      pagesIds.map((item) =>
        makeSelectDataOption(item.id, `${item.description} ${isSupport ? `(${item.name})` : ''}`, { name: item.name }),
      ),
    [pagesIds],
  );
  const groupsOptions = useMemo(
    () =>
      (data.designPageId ? groups.filter(({ siteId }) => siteId === data.designPageId) : groups).map((item) =>
        makeSelectDataOption(item.id, `${item.description} ${isSupport ? `(${item.id})` : ''}`, {
          name: item.id,
        }),
      ),
    [data.designPageId, groups],
  );

  const addNewFilterDialog = useDialog({
    title: getLiteral('DodajNowyFiltr'),
    content: (
      <NewFilters
        defaultData={data}
        setData={(value) => {
          setDataFilters(value);
          addNewFilterDialog.closeDialog();
        }}
      />
    ),
  });

  useEffect(() => {
    setFilters(
      Object.entries(data)
        .filter(([key, value]) => value)
        .map(
          ([key, value]) =>
            `${key}=${typeof value === 'object' ? encodeURIComponent(value.value) : encodeURIComponent(value)}`,
        )
        .join('&'),
    );
  }, [data]);

  return (
    <AdvencedFiltersForm>
      <Wrapper>
        <Select
          options={pagesOptions}
          placeholder={getLiteral('WybierzPodstroneKomponentu')}
          defaultValue={data.designPageId ? pagesOptions.find(({ value }) => value === data.designPageId) : null}
          onChange={(item) => setData((state) => ({ ...state, designPageId: item ? item.value : null }))}
          bottom={1}
          filterOption={({ data }, input) => {
            if (!input) return true;

            const lowerInput = input.toLowerCase();
            return data.label.toLowerCase().includes(lowerInput) || data.data.name.toLowerCase().includes(lowerInput);
          }}
          isClearable
        />
      </Wrapper>
      <Wrapper>
        <Select
          options={groupsOptions}
          placeholder={getLiteral('WybierzTypyKomponentu')}
          defaultValue={data.group ? groupsOptions.find(({ value }) => value === data.group) : null}
          onChange={(item) => setData((state) => ({ ...state, group: item ? item.value : null }))}
          bottom={1}
          filterOption={({ data }, input) => {
            if (!input) return true;

            const lowerInput = input.toLowerCase();
            return data.label.toLowerCase().includes(lowerInput) || data.data.name.toLowerCase().includes(lowerInput);
          }}
          isClearable
        />
      </Wrapper>

      <Wrapper>
        <Input
          ref={inputRef}
          name="name"
          bottom={1}
          title={getLiteral('SzukajPoNazwie')}
          onChange={debounce((e) => setData((state) => ({ ...state, name: e.target.value })), 500)}
        />
        {data.name && (
          <ClearButton
            type="button"
            onClick={() => {
              setData((state) => ({ ...state, name: '' }));
              inputRef.current.value = '';
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </ClearButton>
        )}
      </Wrapper>
      <ClearableFilter
        onRemove={clearDataFilters}
        name="description"
        value={data['description']}
        translatedName={getLiteral('Opis')}
      />
      <ClearableFilter
        onRemove={clearDataFilters}
        name="id"
        value={data['id']}
        translatedName={getLiteral('IdKomponentu')}
      />
      <ClearableFilter
        onRemove={clearDataFilters}
        name="date"
        value={data['date']}
        translatedName={getLiteral('NieStarszeNiz')}
      />
      <ClearableFilter
        onRemove={clearDataFilters}
        name="designId"
        value={data['designId']}
        translatedName={getLiteral('IdKompozycji')}
      />
      <ClearableFilter
        onRemove={clearDataFilters}
        name="designVersion"
        value={data['designVersion']}
        translatedName={getLiteral('WersjaKompozycji')}
      />
      <ClearableFilter
        onRemove={clearDataFilters}
        name="jsLibrary"
        value={
          data['jsLibrary'] && typeof data['jsLibrary'] === 'object'
            ? data['jsLibrary'].label
            : libraries.find((item) => item.id === data['jsLibrary'])?.name
        }
        translatedName={getLiteral('BibliotekaJs')}
      />
      <ClearableFilter
        onRemove={clearDataFilters}
        name="isDraft"
        value={data['isDraft'] ? (data['isDraft'] === 'y' ? getLiteral('Tak') : getLiteral('Nie')) : ''}
        translatedName={getLiteral('wersjaRobocza')}
      />
      <div>
        <AddNewFilter type="button" onClick={() => addNewFilterDialog.openDialog()}>
          <FontAwesomeIcon icon={faPlus} />
          {getLiteral('DodajKolejnyFiltr')}
        </AddNewFilter>
      </div>
      {addNewFilterDialog.dialog}
    </AdvencedFiltersForm>
  );
};

const Wrapper = styled.div`
  margin-right: 1rem;
  width: 25%;
  position: relative;
`;

const AdvencedFiltersForm = styled.form`
  background-color: var(--white);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1.5rem 0 0;
  position: sticky;
  top: 67px;
  z-index: 399;
`;

const AddNewFilter = styled(Button)`
  height: 3.6rem;
  padding: 1.5rem;
  font-size: 1.4rem;
`;

export const ClearButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  color: var(--text);
  padding: 0;

  svg {
    margin-right: 0;
  }
`;
