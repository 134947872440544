import styled, { css } from 'styled-components';
import { useContext } from 'react';

import { marketingCtx } from 'contexts/MarketingContext';

export const MarketingBannerTop = ({ fixed = false }) => {
  const { topBannerEnabled, getTopBannerContent } = useContext(marketingCtx);

  return (
    topBannerEnabled() && (
      <FixedDiv>
        {getTopBannerContent()}
        {fixed === true && <Placeholder />}
      </FixedDiv>
    )
  );
};

const FixedDiv = styled.div`
  margin-top: 40px;

  ${({ fixed }) =>
    fixed === true &&
    css`
      overflow: hidden;
      > div {
        max-width: 1400px;
        min-width: 900px;
        position: fixed;
        padding-right: 4rem;
      }
    `}
`;

const Placeholder = styled.span`
  display: block;
  position: relative;
  max-width: 1360px;
  overflow: hidden;
  padding-top: calc(100% / 1360 * 478 + 1px);
`;
