/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { Content, Layout, Container, Marginer } from 'components/atoms/Grid';
import { Header } from 'components/molecules/Header';
import { MarketingBannerTop } from 'components/molecules/MarketingBannerTop';
import { AdvancedFilters } from 'components/organisms/AdvancedFilters';
import { Aside } from 'components/organisms/Aside';
import { ModulesCustomList } from 'components/organisms/ModulesCustomList';
import { composerCxt } from 'contexts/ComposerContext';
import { useHashTabs } from 'utils/useHashTabs';

const ModulesListPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { getLiteral } = useContext(composerCxt);
  const [filters, setFilters] = useState(location.search.replace('?', ''));

  const tabsArr = useMemo(
    () => [
      {
        title: getLiteral('KomponentyWlasne'),
        component: <ModulesCustomList filters={filters} type="custom" />,
      },
      {
        title: getLiteral('KomponentyIndywidualne'),
        component: <ModulesCustomList filters={filters} type="individual" />,
      },
      {
        title: getLiteral('KomponentyStandardowe'),
        component: <ModulesCustomList filters={filters} type="standard" />,
      },
    ],
    [filters],
  );
  const { tabs, tabsPanel } = useHashTabs(tabsArr);

  useEffect(() => {
    location.search = `?${filters}`;
    history.push(location);
  }, [filters]);

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          <MarketingBannerTop />
          <WhiteBackground>
            <Header tabs={tabs} title={getLiteral('ListaKomponentow')} />
            <AdvancedFilters setFilters={setFilters} defaultFilters={location.search.replace('?', '')} />
            <Marginer top={2}>{tabsPanel}</Marginer>
          </WhiteBackground>
        </Container>
      </Content>
    </Layout>
  );
};

export default ModulesListPage;

const WhiteBackground = styled.div`
  background: #fff;
  z-index: 1;
`;
