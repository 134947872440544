import { useState } from 'react';

import { Container, Layout, Content } from 'components/atoms/Grid';
import { DictionaryHeader } from 'components/molecules/DictionaryHeader';
import { Aside } from 'components/organisms/Aside';
import { DictionaryList } from 'components/organisms/DictionaryList';

const DictionaryPage = () => {
  const [filters, setFilters] = useState({
    text: '',
    emptyLiterals: false,
    standardLiterals: false,
  });

  return (
    <Layout>
      <Aside />
      <Content>
        <Container>
          <DictionaryHeader
            filters={filters}
            setText={(value) => setFilters((state) => ({ ...state, text: value }))}
            setEmpty={() => setFilters((state) => ({ ...state, emptyLiterals: !state.emptyLiterals }))}
            setStandardLiterals={() => setFilters((state) => ({ ...state, standardLiterals: !state.standardLiterals }))}
          />
          <DictionaryList
            filters={filters}
            clearFilters={() =>
              setFilters((state) => {
                return {
                  text: '',
                  emptyLiterals: false,
                  standardLiterals: state.standardLiterals,
                };
              })
            }
          />
        </Container>
      </Content>
    </Layout>
  );
};

export default DictionaryPage;
